import immutabilityHelper from 'immutability-helper';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../setup';
import { KTSVG, toAbsoluteUrl } from '../../../../../../_start/helpers';
import {
  defaultPlugin,
  IPlugin,
} from '../../../../module-management/models/Plugin';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { IElement } from '../../../models/Element';
import { setPlugin } from '../../../redux/EditContentActions';
import { PluginsFeaturedPlugins } from './PluginFeaturedPlugins';
import { PluginFeaturedPluginsItemWrapper } from './PluginFeaturedPluginsItemWrapper';

interface Props {
  index: number;
  data: PluginsFeaturedPlugins;
  updateData: (element: IElement, index: number) => void;
}

export const PluginFeaturedPluginsFC: React.FC<Props> = ({
  index,
  data,
  updateData,
}) => {
  const dispatch = useDispatch();

  const plugin = useSelector((state: RootState) => state.editContent.plugin);

  // initialize base from the Post model featured_posts
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if (plugin.id !== 0 && !initialized) {
      // initialize CONTENT featured post image preview
      const tempPlugins = plugin.featured_plugin_details.map((item, idx) => ({
        ...item,
        // image_preview: data.featured_posts[idx].image_preview,
        // image_preview_alt: data.featured_posts[idx].image_preview_alt,
      }));

      update({
        featured_plugins: tempPlugins,
      });

      setInitialized(true);
    }
  }, [plugin]);

  const update = (fieldsToUpdate: Partial<PluginsFeaturedPlugins>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);

    // update the featured_posts on post data
    dispatch(
      setPlugin({
        ...plugin,
        featured_plugin_details: updatedData.featured_plugins,
      })
    );
  };

  const updateFeaturedPlugin = (idx: number, plugin: IPlugin) => {
    let featured_plugins = [...data.featured_plugins];
    featured_plugins[idx] = plugin;

    const updatedData: PluginsFeaturedPlugins = {
      ...data,
      featured_plugins,
    };

    update(updatedData);
  };

  const addPlugin = () => {
    const featured_plugins = immutabilityHelper(data.featured_plugins, {
      $push: [defaultPlugin],
    });

    const updatedData = { ...data, featured_plugins };
    update(updatedData);
  };

  const removePlugin = (itemIndex: number) => {
    let featured_plugins = immutabilityHelper(data.featured_plugins, {
      $splice: [[itemIndex, 1]],
    });

    const updatedData = { ...data, featured_plugins };
    update(updatedData);
  };

  const allPlugins = useSelector(
    (state: RootState) => state.editContent.allPlugins
  );

  const [selectedPluginsMap, setSelectedPluginsMap] = useState<
    Map<number, IPlugin>
  >(new Map());

  useEffect(() => {
    if (allPlugins.length) {
      const iterable: Array<[number, IPlugin]> =
        plugin.featured_plugin_details.map((plugin, idx) => [
          plugin.id,
          plugin,
        ]);

      setSelectedPluginsMap(new Map(iterable));
    }
  }, [allPlugins, plugin]);

  return (
    <ElementWrapper elementIndex={index}>
      <div className="plugin-featured-plugins">
        <div className="plugin-featured-plugins__content">
          {/** begin:: Heading */}
          <TextAreaEdit
            name={'heading'}
            value={data.heading}
            setValue={update}
            className="heading"
          />
          {/** end:: Heading */}

          {/** begin:: Description */}
          <TextAreaEdit
            name={'description'}
            value={data.description}
            setValue={update}
            className="description"
          />
          {/** end:: Description */}
        </div>

        <div className="plugin-featured-plugins__items">
          {data.featured_plugins.map((element, idx) => (
            <PluginFeaturedPluginsItemWrapper
              key={idx}
              itemIndex={idx}
              plugin={element}
              updateSelected={updateFeaturedPlugin}
              selectedMap={selectedPluginsMap}
            >
              <div className="card">
                <span
                  onClick={() => removePlugin(idx)}
                  className="position-absolute z-index-2 top-0 end-0 me-2 mt-2 opacity-75-hover"
                >
                  <KTSVG
                    path="/media/icons/duotone/Interface/Minus-Square.svg"
                    className="svg-icon-danger svg-icon-1"
                  />
                </span>

                <div className="heading">{element.title}</div>

                <div className="description">{element.description}</div>

                <div className="link">
                  <div className="text">Uitleg</div>
                  <KTSVG path="/assets/icons/icon-cart-regular.svg" />
                </div>
              </div>
            </PluginFeaturedPluginsItemWrapper>
          ))}

          <div onClick={addPlugin} className="add-item">
            <div className="add-item__inner">
              <div className="m-auto d-flex flex-column">
                <KTSVG
                  path="/media/icons/duotone/Interface/Plus-Square.svg"
                  className="svg-icon-muted svg-icon-3hx text-center"
                />
                <span className="mt-4 text-gray-500">Add new item</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ElementWrapper>
  );
};
