/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { failureToast, successToast } from '../../../../support/utils';
import { defaultModule, IModule, ModuleType } from '../../models/Module';
import { IRelease } from '../../models/Release';
import {
  getModuleReleases,
  updateModule,
  viewModule,
} from '../../redux/ModuleCRUD';
import { ModuleReleases } from './ModuleReleases';

export const UpdateModule = () => {
  const { id } = useParams<{ id: string }>();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<IModule>(defaultModule);

  const updateData = (fieldsToUpdate: Partial<IModule>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  const [releases, setReleases] = useState<Array<IRelease>>([]);

  const handleFetch = async (id: string) => {
    setLoading(true);

    try {
      const [result, releases] = await Promise.all([
        viewModule(id),
        getModuleReleases(id),
      ]);
      setData(result);
      setReleases(releases);
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  const fetchModuleReleases = useCallback(async () => {
    const releases = await getModuleReleases(id);
    setReleases(releases);
  }, []);

  useEffect(() => {
    if (id) {
      handleFetch(id);
    }
  }, [id]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);

    try {
      await updateModule(data);
      successToast('Module has been updated.');
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  const formSubmitButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Update Module</h3>
        <div className="card-toolbar gap-5">
          <Link
            className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3"
            to={`/module-management/modules`}
          >
            Cancel
          </Link>
          <button
            type="button"
            onClick={() => formSubmitButtonRef.current?.click()}
            disabled={loading}
            className="btn btn-primary fw-bolder px-6 py-3"
          >
            {!loading && <span className="indicator-label">Save</span>}
            {loading && (
              <span className="indicator-progress" style={{ display: 'block' }}>
                Please wait...{' '}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </div>
      {/* begin::Form */}
      <form onSubmit={handleSubmit} className="form d-flex flex-center">
        <div className="card-body mw-800px py-20">
          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Type</label>
            <div className="col-lg-9">
              <select
                className="form-select form-select-lg form-select-solid text-capitalize"
                data-control="select2"
                data-placeholder="Select Status..."
                value={data.type}
                onChange={(e) =>
                  updateData({
                    type: e.target.value as ModuleType,
                  })
                }
              >
                <option value={ModuleType.GENERIC}>{ModuleType.GENERIC}</option>
                <option value={ModuleType.PLUGIN}>{ModuleType.PLUGIN}</option>
                <option value={ModuleType.THEME}>{ModuleType.THEME}</option>
              </select>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Title</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter title"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.title}
                  onChange={(e) =>
                    updateData({
                      title: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Description</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <textarea
                  rows={2}
                  placeholder="Enter description"
                  className="form-control form-control-lg form-control-solid"
                  value={data.description}
                  onChange={(e) =>
                    updateData({
                      description: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row">
            <div className="card card-custom card-flush border border-1">
              <ModuleReleases
                releases={releases}
                fetchModuleReleases={fetchModuleReleases}
              />
            </div>
          </div>

          {/* end::Form row */}
        </div>
      </form>
      {/* end::Form */}
    </div>
  );
};
