import { toAbsoluteUrl } from '../../../../../../_start/helpers';
import { ButtonTooltip } from '../../../components/ButtonTooltip';
import { InlineEdit } from '../../../components/InlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { HomeServices } from './HomeServices';

type HomeServicesContentProps = {
  data: HomeServices;
  update: (fieldsToUpdate: Partial<HomeServices>) => void;
};

export const HomeServicesContent = ({
  data,
  update,
}: HomeServicesContentProps) => {
  return (
    <div className="home-services__content">
      {/* begin::Title */}
      <InlineEdit
        name="title"
        className="content-title"
        value={data.title}
        setValue={update}
        activeVerticalPadding={`py-0`}
        dynamicWidth
      />
      {/* end::Title */}

      {/* begin::Description */}
      <TextAreaEdit
        name="description"
        className="content-description"
        value={data.description}
        setValue={update}
      />
      {/* end::Description */}

      {/* begin::Action Button with Editable Link Tooltip */}
      <ButtonTooltip
        name={`button_url`}
        className="content-action"
        value={data.button_url}
        setValue={update}
      >
        <button className="btn btn-success rounded-pill">
          <InlineEdit
            name="button_title"
            className="px-1 text-white text-center"
            value={data.button_title}
            setValue={update}
            activeVerticalPadding={'py-1'}
            dynamicWidth
            style={{
              maxWidth: '200px',
            }}
          />
        </button>
      </ButtonTooltip>
      {/* end::Action Button with Editable Link Tooltip */}

      {/* begin::Content Image Preview */}
      <div className="content-image">
        {data.image_preview ? (
          <div className="overlay">
            <div className="overlay-wrapper">
              <img
                alt="dummy"
                src={data.image_preview}
                style={{
                  width: '100%',
                  height: '320px',
                  objectFit: 'cover',
                }}
              />
            </div>
            <div className="overlay-layer bg-dark bg-opacity-10 flex-column">
              <div>
                <button
                  name="image_preview"
                  className="btn btn-primary btn-shadow"
                  onClick={() =>
                    update({
                      image_preview: '',
                      image_preview_alt: '',
                    })
                  }
                >
                  Remove
                </button>
                <label
                  className="btn btn-light-primary btn-shadow ms-2"
                  data-bs-toggle="modal"
                  data-bs-target={`#${data.name}-${data.id}`}
                >
                  Change
                </label>
              </div>

              <input
                placeholder="Alt attribute"
                className="w-75 mt-2 form-control form-control-lg form-control-solid"
                type="text"
                value={data.image_preview_alt ?? ''}
                onChange={(event) =>
                  update({ image_preview_alt: event.target.value })
                }
              />
            </div>
          </div>
        ) : (
          <>
            <label
              data-bs-toggle="modal"
              data-bs-target={`#${data.name}-${data.id}`}
            >
              <img
                src={toAbsoluteUrl('/assets/images/700x854.png')}
                style={{
                  width: '100%',
                  height: '320px',
                  objectFit: 'cover',
                }}
                alt=""
              />
            </label>
          </>
        )}
      </div>
      {/* end::Content Image Preview */}
    </div>
  );
};
