import { IFAQCategory } from './FAQCategory';

export interface IFAQ {
  id: number;
  title: string;
  title_description?: string;
  description: string;
  url?: string;
  button_title?: string;
  categories?: Array<IFAQCategory>;
}

export type CreateFAQPayload = {
  title: string;
  title_description?: string;
  description: string;
  url?: string;
  button_title?: string;
  categories?: Array<number>;
};

export type UpdateFAQPayload = CreateFAQPayload;

export const defaultFAQ: IFAQ = {
  id: 0,
  title: '',
  title_description: '',
  description: '',
  url: '',
  button_title: '',
  categories: [],
};
