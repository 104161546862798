import { Redirect, Route, Switch } from "react-router-dom";
import { PageTitle } from "../../../_start/layout/core";
import { MenusPage } from "./pages/MenusPage";
import { UpdateMenu } from "./pages/UpdateMenu";

export const LayoutManagementPage = () => {
	return (
		<Switch>
			{/* begin::Page Routes */}
			<Route exact={true} path="/layout-management/menus">
				<>
					<PageTitle>Menus</PageTitle>
					<MenusPage />
				</>
			</Route>

			<Route exact={true} path="/layout-management/menus/:id/update">
				<>
					<PageTitle>Menus</PageTitle>
					<UpdateMenu />
				</>
			</Route>

			{/* end::Page Routes */}

			<Redirect
				from="/layout-management"
				exact={true}
				to="/layout-management/menus"
			/>

			<Redirect to="/layout-management/menus" />

		</Switch>
	);
};
