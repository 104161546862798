export enum PageEvents {
  EDIT_PAGE_CONTENT_REQUESTED = '[Request Edit Page Content] Action',
  EDIT_PAGE_CONTENT_LOADED = '[Load Edit Page Content] Action',
  EDIT_PAGE_CONTENT_FAILED = '[Failure Edit Page Content] API Response',

  SET_WAS_INITIALLY_SAVED = '[Content Initially Saved] Action',

  SET_POST_TYPES = '[Set Post Types] API',
  SET_CASE_PAGES = '[Set Case Pages] API',
  SET_BLOG_PAGES = '[Set Blog Pages] API',
  SET_CUSTOMERS = '[Set Customers] API',
  SET_EMPLOYEES = '[Set Employees] API',

  SET_PAGE = '[Set Page] Action',

  INSERT_HEADER = '[Insert Header] Action',
  UPDATE_HEADER = '[Update Header] Action',
  REMOVE_HEADER = '[Remove Header] Action',

  INSERT_BODY = '[Insert Body] Action',
  MOVE_BODY = '[Move Body] Action',
  UPDATE_BODY = '[Update Body] Action',
  REMOVE_BODY = '[Remove Body] Action',

  SAVE_PAGE_REQUESTED = '[Request Save Page] Action',
  SAVE_PAGE_LOADED = '[Load Save Page] Action',
  SAVE_PAGE_FAILED = '[Failure Save Page] API Response',
}
