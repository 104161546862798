import React from 'react';
import { FormImageFieldModal } from '../../../../media-management/components/form-image/FormImageFieldModal';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { IElement } from '../../../models/Element';
import { SpecializationFloatingRightModal } from './SpecializationsFloatingRightModal';
import { SpecializationsFloatingRightModalContent } from './SpecializationsFloatingRightModalContent';
import { SpecializationsFloatingRightModalFigure } from './SpecializationsFloatingRightModalFigure';

interface Props {
  index: number;
  data: SpecializationFloatingRightModal;
  updateData: (element: IElement, index: number) => void;
}

export const SpecializationsFloatingRightModalFC: React.FC<Props> = ({
  index,
  data,
  updateData,
}) => {
  const update = (
    fieldsToUpdate: Partial<SpecializationFloatingRightModal>
  ) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  return (
    <>
      <FormImageFieldModal
        modalId={`${data.name}-${data.id}`}
        name={`image_preview`}
        altText={`image_preview_alt`}
        update={update}
      />

      <ElementWrapper elementIndex={index}>
        <div className="specializations-right-modal body-my-8">
          <SpecializationsFloatingRightModalContent
            data={data}
            update={update}
          />
          <SpecializationsFloatingRightModalFigure
            data={data}
            update={update}
          />
        </div>
      </ElementWrapper>
    </>
  );
};
