import {
  CustomerQuestion,
  defaultCustomerQuestionItem,
  ICustomerQuestionItem,
} from './CustomerQuestion';

import updateHelper from 'immutability-helper';
import { CustomerQuestionItem } from './CustomerQuestionItem';
import { useRef } from 'react';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { IElement } from '../../../models/Element';
import { generateListItemId } from '../../../../../support/helpers';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { KTSVG } from '../../../../../../_start/helpers';

type CustomerQuestionFCProps = {
  index: number;
  data: CustomerQuestion;
  updateData: (body: IElement, index: number) => void;
};

export const CustomerQuestionFC = ({
  index,
  data,
  updateData,
}: CustomerQuestionFCProps) => {
  const update = (fieldsToUpdate: Partial<CustomerQuestion>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  const updateItem = (idx: number, item: ICustomerQuestionItem) => {
    let list = [...data.list];
    list[idx] = item;
    const updatedData = { ...data, list };
    updateData(updatedData, index);
  };

  const addListItem = () => {
    const list = updateHelper(data.list, {
      $push: [
        { ...defaultCustomerQuestionItem, id: generateListItemId(data.list) },
      ],
    });

    const updatedData = { ...data, list };
    updateData(updatedData, index);
  };

  const removeListItem = (itemIndex: number) => {
    let list = updateHelper(data.list, {
      $splice: [[itemIndex, 1]],
    });

    const updatedData = { ...data, list };
    updateData(updatedData, index);
  };

  const moveListItem = (dragIndex: number, hoverIndex: number) => {
    let list = updateHelper(data.list, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, data.list[dragIndex]],
      ],
    });

    const updatedData = { ...data, list };
    updateData(updatedData, index);
  };

  const addItemRef = useRef<HTMLLIElement>(null);

  const onMouseEnter = (event: React.MouseEvent<HTMLUListElement>) => {
    addItemRef.current?.classList.remove('d-none');

    if (event.currentTarget === event.target) {
      event.stopPropagation();
    }

    event.currentTarget.style.cursor = 'pointer';
  };

  const onMouseLeave = (event: React.MouseEvent<HTMLUListElement>) => {
    addItemRef.current?.classList.add('d-none');
    event.currentTarget.style.cursor = 'auto';
  };

  return (
    <ElementWrapper elementIndex={index}>
      <div className="case-customer-question body-my-8">
        <div className="case-customer-question__content">
          <div className="content-details">
            <TextAreaEdit
              name="title"
              className="details-title"
              value={data.title}
              setValue={update}
            />

            <TextAreaEdit
              name="description"
              className="details-description"
              value={data.description}
              setValue={update}
            />
          </div>

          <ul
            className="content-list bg-light"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            {data.list.map((item, idx) => (
              <CustomerQuestionItem
                key={idx}
                index={idx}
                item={item}
                customerQuestion={data}
                updateItem={updateItem}
                removeListItem={removeListItem}
                moveListItem={moveListItem}
              />
            ))}

            <li
              ref={addItemRef}
              onClick={addListItem}
              style={{
                minHeight: '76px',
              }}
              className="d-none w-100 h-100 d-flex rounded border-gray-400 border-dashed border-2 opacity-75-hover"
            >
              <div className="position-relative w-100 my-auto px-4 d-flex align-items-center">
                <KTSVG
                  path="/media/icons/duotone/Interface/Plus-Square.svg"
                  className="svg-icon-muted svg-icon-2x text-center"
                />
                <div className="position-absolute start-0 end-0 top-0 bottom-0 w-100 text-center text-gray-500 d-flex align-items-center">
                  <span className="flex-grow-1">Add new item</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </ElementWrapper>
  );
};
