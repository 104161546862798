import { useEffect, useState } from 'react';
import { KTSVG } from '../../../../../../_start/helpers';
import { InlineEdit } from '../../../components/InlineEdit';
import { SVGIconEdit } from '../../../components/SVGIconEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { LinkTooltipWrapper } from '../home-plugins/LinkTooltipWrapper';
import { defaultServiceItem, IServiceItem } from './HomeServices';

type HomeServicesItemProps = {
  service: IServiceItem;
  serviceIndex: number;
  updateService: (idx: number, service: IServiceItem) => void;
};

export const HomeServicesItem = ({
  service,
  serviceIndex,
  updateService,
}: HomeServicesItemProps) => {
  const [data, setData] = useState(defaultServiceItem);

  useEffect(() => {
    setData(service);
  }, [service]);

  const updateData = (fieldsToUpdate: Partial<IServiceItem>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
    updateService(serviceIndex, updatedData);
  };

  return (
    <li className="item">
      {/* begin::Icon */}
      {/* {serviceIndex === 0 && (
        <KTSVG
          path="/assets/icons/icon-sword.svg"
          className="svg-icon-3hx fill-purple1 color-purple5"
        />
      )}

      {serviceIndex === 1 && (
        <KTSVG
          path="/assets/icons/icon-code.svg"
          className="svg-icon-3hx fill-green1 color-green5"
        />
      )}

      {serviceIndex === 2 && (
        <KTSVG
          path="/assets/icons/icon-pencil-line.svg"
          className="svg-icon-3hx fill-yellow1 color-yellow5"
        />
      )}

      {serviceIndex === 3 && (
        <KTSVG
          path="/assets/icons/icon-target.svg"
          className="svg-icon-3hx fill-pink1 color-pink5"
        />
      )} */}
      <SVGIconEdit
        svgIcon={data.icon ?? ''}
        update={(icon) => updateData({ icon: icon })}
        svgClassName={'svg-icon fill-purple1 color-purple5'}
      />
      {/* end::Icon */}

      {/* begin::Title */}
      <InlineEdit
        name="title"
        className="item-title w-100"
        value={service.title}
        setValue={updateData}
        activeVerticalPadding={`py-0`}
      />
      {/* end::Title */}

      {/* begin::Description */}
      <TextAreaEdit
        name="description"
        className="item-description"
        value={service.description}
        setValue={updateData}
      />
      {/* end::Description */}

      <LinkTooltipWrapper
        name="url_link"
        value={service.url_link}
        setValue={updateData}
        className={`z-index-1`}
      >
        <div className="item-action">
          <KTSVG path="/assets/icons/icon-arrow.svg" className="me-3" />
          <span className="text">{data.url_title}</span>
        </div>
      </LinkTooltipWrapper>
    </li>
  );
};
