import { Redirect, Route, Switch } from 'react-router-dom';
import { PageTitle } from '../../../_start/layout/core';
import { CreateTutorialCategory } from './pages/Category/CreateTutorialCategory';
import { TutorialCategoriesPage } from './pages/Category/TutorialCategoriesPage';
import { UpdateTutorialCategory } from './pages/Category/UpdateTutorialCategory';
import { CreateTutorial } from './pages/Tutorial/CreateTutorial';
import { TutorialEditContent } from './pages/Tutorial/TutorialEditContent';
import { TutorialsPage } from './pages/Tutorial/TutorialsPage';
import { UpdateTutorial } from './pages/Tutorial/UpdateTutorial';

export const TutorialManagementPage = () => {
  return (
    <Switch>
      {/* begin::CATEGORY ROUTES */}
      <Route exact={true} path="/tutorial-management/categories">
        <>
          <PageTitle>Categories</PageTitle>
          <TutorialCategoriesPage />
        </>
      </Route>
      <Route exact={true} path="/tutorial-management/categories/create">
        <>
          <PageTitle>Categories</PageTitle>
          <CreateTutorialCategory />
        </>
      </Route>
      <Route exact={true} path="/tutorial-management/categories/:id/update">
        <>
          <PageTitle>Categories</PageTitle>
          <UpdateTutorialCategory />
        </>
      </Route>
      {/* end::CATEGORY ROUTES */}

      {/* begin::POST ROUTES */}
      <Route exact={true} path="/tutorial-management/tutorials">
        <>
          <PageTitle>Tutorials</PageTitle>
          <TutorialsPage />
        </>
      </Route>
      <Route exact={true} path="/tutorial-management/tutorials/create">
        <>
          <PageTitle>Tutorials</PageTitle>
          <CreateTutorial />
        </>
      </Route>
      <Route exact={true} path="/tutorial-management/tutorials/:id/update">
        <>
          <PageTitle>Tutorials</PageTitle>
          <UpdateTutorial />
        </>
      </Route>
      <Route
        exact={true}
        path="/tutorial-management/tutorials/:id/edit-content"
      >
        <>
          <PageTitle>Tutorials</PageTitle>
          <TutorialEditContent />
        </>
      </Route>
      {/* end::POST ROUTES */}

      <Redirect
        from="/tutorial-management"
        exact={true}
        to="/tutorial-management/tutorials"
      />
      <Redirect to="/tutorial-management/tutorials" />
    </Switch>
  );
};
