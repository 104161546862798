/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ActionsDropdown } from '../../../../components/dropdown/ActionsDropdown';
import { DeleteModal } from '../../../../components/modals/DeleteModal';
import { failureToast, successToast } from '../../../../support/utils';
import { defaultRelease, IRelease } from '../../models/Release';
import { deleteRelease } from '../../redux/ReleaseCRUD';

type ModuleReleasesProps = {
  releases: Array<IRelease>;
  fetchModuleReleases: () => Promise<void>;
};

export const ModuleReleases = ({
  releases,
  fetchModuleReleases,
}: ModuleReleasesProps) => {
  const [loading, setLoading] = useState(false);

  /**
   * DELETE
   */
  const [selected, setSelected] = useState<IRelease>(defaultRelease);

  const handleDelete = async (item: IRelease) => {
    setLoading(true);
    try {
      await deleteRelease(item);
      successToast('Release has been deleted.');
      setLoading(false);
      fetchModuleReleases();
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  return (
    <>
      <DeleteModal
        headerTitle={`Delete Release`}
        item={selected}
        handleDelete={handleDelete}
      />
      <div
        className={`card card-custom card-flush ${
          loading ? 'overlay overlay-block' : ''
        }`}
      >
        <div className="pt-8 card-header">
          <h3 className="card-title">Module Releases</h3>
          <div className="card-toolbar">
            <Link
              className="btn btn-sm btn-light"
              to={`/module-management/releases/create`}
            >
              {`Create Release`}
            </Link>
          </div>
        </div>
        <div className={`card-body py-5 ${loading ? 'overlay-wrapper' : ''}`}>
          <table className="table table-row-gray-300 gy-7">
            <thead>
              <tr className="fw-bolder fs-6 text-gray-800">
                <th>Version</th>
                <th>Link</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {releases.map((element, idx) => (
                <tr key={idx} className="align-middle">
                  <td>{element.version_number}</td>
                  <td>{element.link}</td>
                  <td className="text-end">
                    {/* begin::Dropdown */}
                    <ActionsDropdown>
                      <div className="menu-item px-3">
                        <Link
                          to={`/module-management/releases/${element.id}/update`}
                          className="menu-link px-3"
                        >
                          Update
                        </Link>
                      </div>
                      <div className="menu-item px-3">
                        <a
                          onClick={() => setSelected(element)}
                          href="#"
                          className="menu-link px-3 text-danger"
                          data-bs-toggle="modal"
                          data-bs-target="#custom_delete_kt_modal_1"
                        >
                          Delete
                        </a>
                      </div>
                      <div className="separator mt-3 opacity-75"></div>
                    </ActionsDropdown>
                    {/* end::Dropdown */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {loading && (
            <div className="overlay-layer rounded bg-dark bg-opacity-5">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
        {/* <div className="card-footer">
          <Pagination
            loading={loading}
            pagination={paginatedValue}
            handlePaginateState={handlePaginateState}
          />
        </div> */}
      </div>
    </>
  );
};
