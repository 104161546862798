import { FormImageFieldModal } from '../../../../media-management/components/form-image/FormImageFieldModal';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { IElement } from '../../../models/Element';
import { ContactsHeaderContent } from './ContactsHeaderContent';
import { ContactsHeaderFigure } from './ContactsHeaderFigure';
import { ContactsHeader } from './ContactsHeader';

type ContactsHeaderFCProps = {
  index: number;
  data: ContactsHeader;
  updateData: (element: IElement, index: number) => void;
};

export const ContactsHeaderFC = ({
  index,
  data,
  updateData,
}: ContactsHeaderFCProps) => {
  const update = (fieldsToUpdate: Partial<ContactsHeader>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  return (
    <>
      <FormImageFieldModal
        modalId={`${data.name}-${data.id}`}
        name={`image`}
        altText={`image_alt`}
        update={update}
      />

      <ElementWrapper elementIndex={index}>
        <div className="contacts-header">
          <ContactsHeaderContent data={data} update={update} />
          <ContactsHeaderFigure data={data} update={update} />
        </div>
      </ElementWrapper>
    </>
  );
};
