import { toAbsoluteUrl } from '../../../../../../_start/helpers';
import { FormImageFieldModal } from '../../../../media-management/components/form-image/FormImageFieldModal';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { InlineEdit } from '../../../components/InlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { IElement } from '../../../models/Element';
import { AboutUsShowcaseWithTextRight } from './AboutUsShowcaseWithTextRight';

type AboutUsShowcaseWithTextRightFCProps = {
  index: number;
  data: AboutUsShowcaseWithTextRight;
  updateData: (body: IElement, index: number) => void;
};

export const AboutUsShowcaseWithTextRightFC = ({
  index,
  data,
  updateData,
}: AboutUsShowcaseWithTextRightFCProps) => {
  const update = (fieldsToUpdate: Partial<AboutUsShowcaseWithTextRight>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  return (
    <>
      <FormImageFieldModal
        modalId={`${data.name}-${data.id}`}
        name={`image_preview`}
        altText={`image_preview_alt`}
        update={update}
      />

      <ElementWrapper elementIndex={index}>
        <div className="about-us-showcase-with-text-right body-my-8">
          <div className="showcase__figure">
            {data.image_preview ? (
              <div className="overlay">
                <div className="overlay-wrapper">
                  <img alt="Uploaded" src={data.image_preview} />
                </div>
                <div className="overlay-layer bg-dark bg-opacity-10 flex-column">
                  <div>
                    <button
                      name="image_preview"
                      className="btn btn-primary btn-shadow"
                      onClick={() =>
                        update({
                          image_preview: '',
                          image_preview_alt: '',
                        })
                      }
                    >
                      Remove
                    </button>
                    <label
                      className="btn btn-light-primary btn-shadow ms-2"
                      data-bs-toggle="modal"
                      data-bs-target={`#${data.name}-${data.id}`}
                    >
                      Change
                    </label>
                  </div>

                  <input
                    placeholder="Alt attribute"
                    className="w-50 mt-2 form-control form-control-lg form-control-solid"
                    type="text"
                    value={data.image_preview_alt ?? ''}
                    onChange={(event) =>
                      update({ image_preview_alt: event.target.value })
                    }
                  />
                </div>
              </div>
            ) : (
              <>
                <label
                  data-bs-toggle="modal"
                  data-bs-target={`#${data.name}-${data.id}`}
                >
                  <img
                    src={toAbsoluteUrl('/assets/images/900x540.png')}
                    alt="Placeholder"
                  />
                </label>
              </>
            )}
          </div>

          <div className="showcase__content">
            <div className="content-inner">
              {/* begin::Title */}
              <InlineEdit
                name="title"
                className="content-title"
                value={data.title}
                setValue={update}
                activeVerticalPadding={`py-0`}
              />
              {/* end::Title */}

              {/* begin::Description */}
              <TextAreaEdit
                name="description"
                className="content-description"
                value={data.description}
                setValue={update}
              />
              {/* end::Description */}
            </div>
          </div>
        </div>
      </ElementWrapper>
    </>
  );
};
