import { ElementType, IElement } from '../../../models/Element';

export type AboutUsFeaturedContentOne = IElement & {
  title: string;
  description: string;
  button_title: string;
  button_url: string;
};

export const defaultAboutUsFeaturedContentOne: AboutUsFeaturedContentOne = {
  id: 0,
  name: ElementType.ABOUT_US_FEATURED_CONTENT_ONE,
  title: 'Oploskoffie',
  description:
    'Wie wil dat nou niet. Een heerlijk bakje oploskoffie. Als organisatie die staat voor locatie onafhankelijk werk, ontkom je er soms niet aan. Toch is een bakje oploskoffie zo verkeerd nog niet. Samen zitten, vraagstuk op tafel en problemen oplossen. Onder het genot van een bakje. Op die manier klink oploskoffie ons helemaal niet zo verkeerd in de oren. Kom jij een bakje* doen?',
  button_title: 'Neem contact met ons op',
  button_url: '',
};
