import { defaultModule, IModule } from './Module';

export interface IRelease {
  id: number;
  link: string;
  version_number: string;
  change_log: string;
  documentation_url: string;
  download_url: string;
  module_id: number;
  module: IModule;
}

export const defaultRelease: IRelease = {
  id: 0,
  link: '',
  version_number: '',
  change_log: '',
  documentation_url: '',
  download_url: '',
  module_id: 0,
  module: defaultModule,
};
