import { Link } from 'react-router-dom';
import { RoleNames } from '../../../../app/modules/role-and-permission/pages/roles/Role';
import { AuthMenu } from './AuthMenu';
import { MenuItem } from './MenuItem';

export function MenuInner() {
  return (
    <>
      <div className="row">
        <div className="col-sm-3">
          <h3 className="fw-bolder mb-5">Dashboard</h3>
          <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
            <li className="menu-item">
              <MenuItem to="/dashboard" title="Home" />
            </li>
          </ul>
        </div>

        <AuthMenu
          requiredRoles={[
            RoleNames.SUPER_ADMIN,
            RoleNames.ADMIN,
            RoleNames.MODERATOR,
          ]}
        >
          <div className="col-sm-3">
            <h3 className="fw-bolder mb-5">Paginabeheer</h3>
            <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
              <li className="menu-item">
                <MenuItem to="/page-management/pages" title="Pages" />
              </li>
              <li className="menu-item">
                <MenuItem
                  to="/page-management/case-details"
                  title="Case Details"
                />
              </li>
              <li className="menu-item">
                <MenuItem
                  to="/page-management/case-categories"
                  title="Case Categories"
                />
              </li>
              <li className="menu-item">
                <MenuItem to="/page-management/faqs" title="FAQs" />
              </li>
              <li className="menu-item">
                <MenuItem
                  to="/page-management/faq-categories"
                  title="FAQ Categories"
                />
              </li>
            </ul>
          </div>

          <div className="col-sm-3">
            <h3 className="fw-bolder mb-5">Berichtbeheer</h3>
            <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
              <li className="menu-item">
                <MenuItem to="/post-management/posts" title="Berichten" />
              </li>
              <li className="menu-item">
                <MenuItem
                  to="/post-management/categories"
                  title="Categorieën"
                />
              </li>
              <li className="menu-item">
                <MenuItem to="/post-management/tags" title="Tags" />
              </li>
            </ul>
          </div>

          <div className="col-sm-3">
            <h3 className="fw-bolder mb-5">Manuals</h3>
            <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
              <li className="menu-item">
                <MenuItem
                  to="/tutorial-management/tutorials"
                  title="Tutorials"
                />
              </li>
              <li className="menu-item">
                <MenuItem
                  to="/tutorial-management/categories"
                  title="Categorieën"
                />
              </li>
            </ul>
          </div>

          <div className="col-sm-3">
            <h3 className="fw-bolder mb-5">Module Management</h3>
            <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
              <li className="menu-item">
                <MenuItem to="/module-management/plugins" title="Plugins" />
              </li>
              <li className="menu-item">
                <MenuItem to="/module-management/modules" title="Modules" />
              </li>
              <li className="menu-item">
                <MenuItem to="/module-management/releases" title="Releases" />
              </li>
              <li className="menu-item">
                <MenuItem to="/module-management/packages" title="Packages" />
              </li>
              <li className="menu-item">
                <MenuItem to="/module-management/features" title="Features" />
              </li>
              {/* <li className="menu-item">
                <MenuItem to="/module-management/tags" title="Tags" />
              </li>
              <li className="menu-item">
                <MenuItem to="/module-management/licenses" title="Licenses" />
              </li> */}
            </ul>
          </div>

          <div className="col-sm-3">
            <h3 className="fw-bolder mb-5">File Management</h3>
            <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
              <li className="menu-item">
                <MenuItem to="/media-management/media" title="Media" />
              </li>
              <li className="menu-item">
                <MenuItem to="/media-management/files" title="Files" />
              </li>
            </ul>
          </div>
        </AuthMenu>

        <AuthMenu requiredRoles={[RoleNames.SUPER_ADMIN, RoleNames.ADMIN]}>
          <div className="col-sm-3">
            <h3 className="fw-bolder mb-5">Lay-outbeheer</h3>
            <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
              <li className="menu-item">
                <MenuItem to="/layout-management/menus" title="Menus" />
              </li>
            </ul>
          </div>

          <div className="col-sm-3">
            <h3 className="fw-bolder mb-5">Site instellingen</h3>
            <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
              <li className="menu-item">
                <MenuItem to="/settings/seo" title="SEO" />
              </li>
            </ul>
          </div>

          <div className="col-sm-3">
            <h3 className="fw-bolder mb-5">Personeelsbeheer</h3>
            <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
              <li className="menu-item">
                <MenuItem
                  to="/employee-management/employees"
                  title="Employees"
                />
                <MenuItem
                  to="/employee-management/departments"
                  title="Departments"
                />
              </li>
            </ul>
          </div>

          <div className="col-sm-3">
            <h3 className="fw-bolder mb-5">Klantbeheer</h3>
            <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
              <li className="menu-item">
                <MenuItem
                  to="/customer-management/customers"
                  title="Customers"
                />
              </li>
            </ul>
          </div>
        </AuthMenu>

        <AuthMenu requiredRoles={[RoleNames.SUPER_ADMIN]}>
          <div className="col-sm-3">
            <h3 className="fw-bolder mb-5">Gebruikersbeheer</h3>
            <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
              <li className="menu-item">
                <MenuItem to="/user-management/members" title="Members" />
              </li>
            </ul>
          </div>

          <div className="col-sm-3">
            <h3 className="fw-bolder mb-5">Rol & Permissie</h3>
            <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
              <li className="menu-item">
                <MenuItem to="/role-and-permission/roles" title="Roles" />
              </li>
              <li className="menu-item">
                <MenuItem
                  to="/role-and-permission/permissions"
                  title="Permissions"
                />
              </li>
            </ul>
          </div>
        </AuthMenu>

        {/* <div className="col-sm-3">
          <h3 className="fw-bolder mb-5">Formulier beheer</h3>
          <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
            <li className="menu-item">
              <MenuItem to="/form-management/dynamic-forms" title="Forms" />
            </li>
          </ul>
        </div> */}

        <div className="col-sm-3">
          <h3 className="fw-bolder mb-5">Profiel</h3>
          <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
            <li className="menu-item">
              <Link className="menu-link ps-0 py-2" to="/profile/overview">
                Overview
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/profile/account" className="menu-link ps-0 py-2">
                Account
              </Link>
            </li>
            <li className="menu-item">
              <Link className="menu-link ps-0 py-2" to="/profile/settings">
                Settings
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {/* <div className="row">
				<div className="col-sm-4">
					<h3 className="fw-bolder mb-5">Profile</h3>
					<ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
						<li className="menu-item">
							<Link
								className="menu-link ps-0 py-2"
								to="/profile/overview"
							>
								Overview
							</Link>
						</li>
						<li className="menu-item">
							<Link
								to="/profile/account"
								className="menu-link ps-0 py-2"
							>
								Account
							</Link>
						</li>
						<li className="menu-item">
							<Link
								className="menu-link ps-0 py-2"
								to="/profile/settings"
							>
								Settings
							</Link>
						</li>
					</ul>
				</div>
				<div className="col-sm-4">
					<h3 className="fw-bolder mb-5">Resources</h3>
					<ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
						<li className="menu-item">
							<Link
								className="menu-link ps-0 py-2"
								to="/docs/getting-started"
							>
								Documentation
							</Link>
						</li>
						<li className="menu-item">
							<Link className="menu-link ps-0 py-2" to="/builder">
								Layout Builder
							</Link>
						</li>
						<li className="menu-item">
							<Link
								className="menu-link ps-0 py-2"
								to="/docs/changelog"
							>
								Changelog
							</Link>
						</li>
					</ul>
				</div>
			</div> */}
    </>
  );
}
