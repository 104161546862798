import { ElementType, IElement } from "../../../models/Element";

export type AboutUsCenteredParagraph = IElement & {
  content: string;
};

export const defaultAboutUsCenteredParagraph: AboutUsCenteredParagraph = {
  id: 0,
  name: ElementType.ABOUT_US_CENTERED_PARAGRAPH,
  content:
    '<p>Inmiddels bestaat het team uit 10 enthousiaste medewerkers. Door onze platte en open organisatie kennen we geen bazen en managers. Communiceren doen we horizontaal en hierdoor kunnen we snel schakelen. Tussendoor is inmiddels onderdeel van TOV. Benieuwd naar onze openstande vacatures of bedrijfscultuur? Door naar <a href="https://tussendoor.nl/basing/tussendoor/index.html">TOV.team</a></p>',
};
