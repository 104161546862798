import React from 'react';
import { FormImageFieldModal } from '../../../../media-management/components/form-image/FormImageFieldModal';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { IElement } from '../../../models/Element';
import { PluginHighlightRightImageInfo } from './PluginHighlightRightImageInfo';
import { PluginHighlightRightImageInfoContent } from './PluginHighlightRightImageInfoContent';
import { PluginHighlightRightImageInfoFigure } from './PluginHighlightRightImageInfoFigure';

type Props = {
  index: number;
  data: PluginHighlightRightImageInfo;
  updateData: (element: IElement, index: number) => void;
};

export const PluginHighlightRightImageInfoFC: React.FC<Props> = ({
  index,
  data,
  updateData,
}) => {
  const update = (fieldsToUpdate: Partial<PluginHighlightRightImageInfo>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };
  return (
    <>
      <FormImageFieldModal
        modalId={`${data.name}-${data.id}`}
        name={`image`}
        altText={`image_alt`}
        update={update}
      />

      <ElementWrapper elementIndex={index}>
        <div className="plugin-highlight-right-image-info">
          <div className="showcase">
            <PluginHighlightRightImageInfoContent data={data} update={update} />
            <PluginHighlightRightImageInfoFigure data={data} update={update} />
          </div>
        </div>
      </ElementWrapper>
    </>
  );
};
