import { ElementType, IElement } from "../../../models/Element";

export type HomeHeader = IElement & {
  image: string;
  image_alt: string;
  title: string;
  heading: string;
  description: string;
  button_url: string;
  button_title: string;
};

export const defaultHomeHeader: HomeHeader = {
  id: 0,
  name: ElementType.HOME_HEADER,
  image: '',
  image_alt: '',
  title: 'Wordpress specialist',
  heading: 'Dé WordPress specialist van Nederland',
  description:
    'Tussendoor combineert techniek en marketing in één samenhanngende oplossing.',
  button_url: '',
  button_title: 'Meer weten?',
};
