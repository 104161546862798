import React from 'react';
import { ButtonTooltip } from '../../../components/ButtonTooltip';
import { InlineEdit } from '../../../components/InlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { PluginRightImageHighlightInfo } from './PluginRightImageHighlightInfo';

type Props = {
  data: PluginRightImageHighlightInfo;
  update: (fieldsToUpdate: Partial<PluginRightImageHighlightInfo>) => void;
};

export const PluginRightImageHighlightInfoContent: React.FC<Props> = ({
  data,
  update,
}) => {
  return (
    <div className="showcase__content">
      <div>
        {/* begin::Heading */}
        <TextAreaEdit
          name="heading"
          className="heading"
          value={data.heading}
          setValue={update}
        />
        {/* end::Heading */}
      </div>

      {/* begin::Description */}
      <TextAreaEdit
        name="description"
        className="description"
        value={data.description}
        setValue={update}
      />
      {/* end::Description */}
    </div>
  );
};
