/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";

type InlineEditProps = {
  name: string;
  value: string;
  setValue: (fieldsToUpdate: Partial<unknown>) => void;
  className?: string;
  style?: React.CSSProperties;
  activeVerticalPadding?: string;
  dynamicWidth?: boolean;
};

export const InlineEdit: React.FC<InlineEditProps> = ({
  name,
  value,
  setValue,
  className,
  style,
  activeVerticalPadding = "py-2",
  dynamicWidth = false,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [editingValue, setEditingValue] = useState(value);

  useEffect(() => {
    if (value !== editingValue) {
      setEditingValue(value);
    }

    if (dynamicWidth) {
      if (inputRef) {
        inputRef.current!.style.width = value.length + "ch";

        if (value.length < 6 || value === "") {
          inputRef.current!.style.width = "60px";
        }
      }
    }
  }, [value]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // dynamic width
    if (dynamicWidth) {
      event.currentTarget.style.width = event.target.value.length + "ch";

      if (event.target.value.length < 6 || event.target.value === "") {
        event.currentTarget.style.width = "60px";
      }
    }

    setEditingValue(event.target.value);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    if (event.key === "Enter" || event.key === "Escape") {
      target.blur();
    }
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value.trim() === "" || event.target.value === value) {
      inputRef.current!.style.width = value.length + "ch";
      setEditingValue(value);
    } else {
      setValue({ [event.target.name]: event.target.value });
    }
    inputRef.current?.classList.remove(activeVerticalPadding);
  };

  const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    inputRef.current?.classList.add(activeVerticalPadding);
  };

  const allClassNames = `inline-edit-input ${className}`;

  return (
    <input
      name={name}
      ref={inputRef}
      className={allClassNames}
      style={style}
      {...rest}
      type="text"
      aria-label="Field name"
      value={editingValue}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
};
