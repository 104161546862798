import { ButtonTooltip } from '../../../components/ButtonTooltip';
import { InlineEdit } from '../../../components/InlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { AboutUsHeader } from './AboutUsHeader';

type AboutUsHeaderContentProps = {
  data: AboutUsHeader;
  update: (fieldsToUpdate: Partial<AboutUsHeader>) => void;
};

export const AboutUsHeaderContent = ({
  data,
  update,
}: AboutUsHeaderContentProps) => {
  return (
    <div className="about-us-header__content">
      {/* begin::Title */}
      <InlineEdit
        name="title"
        className="content-title"
        value={data.title}
        setValue={update}
        activeVerticalPadding={`py-0`}
      />
      {/* end::Title */}

      {/* begin::Heading */}
      <TextAreaEdit
        name="heading"
        className="content-heading"
        value={data.heading}
        setValue={update}
      />
      {/* end::Heading */}

      {/* begin::Description */}
      <TextAreaEdit
        name="description"
        className="content-description"
        value={data.description}
        setValue={update}
      />
      {/* end::Description */}

      <div className="content-action">
        <ButtonTooltip
          name={`button_url`}
          className="content-action__button"
          value={data.button_url}
          setValue={update}
        >
          <button className="btn btn-success rounded-pill">
            <InlineEdit
              name="button_title"
              className="px-1 text-white text-center"
              value={data.button_title}
              setValue={update}
              activeVerticalPadding={'py-1'}
              dynamicWidth
              style={{
                maxWidth: '22rem',
              }}
            />
          </button>
        </ButtonTooltip>
      </div>
    </div>
  );
};
