import React from 'react';
import { ButtonTooltip } from '../../../components/ButtonTooltip';
import { InlineEdit } from '../../../components/InlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { SpecializationHeader } from './SpecializationHeader';

interface Props {
  data: SpecializationHeader;
  update: (fieldsToUpdate: Partial<SpecializationHeader>) => void;
}

export const SpecializationHeaderContent: React.FC<Props> = ({
  data,
  update,
}) => {
  return (
    <div className="specializations-header__content">
      {/* begin::Title */}
      <InlineEdit
        name="title"
        className="content-title"
        value={data.title}
        setValue={update}
        activeVerticalPadding={`py-0`}
      />
      {/* end::Title */}

      {/* begin::Heading */}
      <TextAreaEdit
        name="heading"
        className="content-heading"
        value={data.heading}
        setValue={update}
      />
      {/* end::Heading */}

      {/* begin::Description */}
      <TextAreaEdit
        name="description"
        className="content-description"
        value={data.description}
        setValue={update}
      />
      {/* end::Description */}

      <div className="content-action-container">
        <div className="content-action">
          {/*  start::Primary Button  */}
          <ButtonTooltip
            name={`button_primary_url`}
            className="content-action__button"
            value={data.button_primary_url}
            setValue={update}
          >
            <button className="btn btn-success rounded-pill">
              <InlineEdit
                name="button_primary_title"
                className="px-1 text-white text-center"
                value={data.button_primary_title}
                setValue={update}
                activeVerticalPadding={'py-1'}
                dynamicWidth
                style={{
                  maxWidth: '22rem',
                }}
              />
            </button>
          </ButtonTooltip>
          {/*  end::Primary Button  */}
        </div>

        <div className="content-action">
          {/*  start::Secondary Button  */}
          <ButtonTooltip
            name={`button_secondary_url`}
            className="content-action__button"
            value={data.button_secondary_url}
            setValue={update}
          >
            <button className="btn btn-success rounded-pill">
              <InlineEdit
                name="button__secondary_title"
                className="px-1 text-white text-center"
                value={data.button_secondary_title}
                setValue={update}
                activeVerticalPadding={'py-1'}
                dynamicWidth
                style={{
                  maxWidth: '22rem',
                }}
              />
            </button>
          </ButtonTooltip>
          {/*  end::Secondary Button  */}
        </div>
      </div>
    </div>
  );
};
