import { Redirect, Route, Switch } from 'react-router-dom';
import { PageTitle } from '../../../_start/layout/core';
import { CreateDepartmentPage } from './pages/departments/CreateDepartmentPage';
import { DepartmentsPage } from './pages/departments/DepartmentsPage';
import { UpdateDepartmentPage } from './pages/departments/UpdateDepartmentPage';
import { CreateEmployeePage } from './pages/employees/CreateEmployeePage';
import { EmployeesPage } from './pages/employees/EmployeesPage';
import { UpdateEmployeePage } from './pages/employees/UpdateEmployeePage';

export const EmployeeManagementPage = () => {
  return (
    <Switch>
      {/* begin::Employee Routes */}
      <Route exact={true} path="/employee-management/employees">
        <>
          <PageTitle>Employees</PageTitle>
          <EmployeesPage />
        </>
      </Route>
      <Route exact={true} path="/employee-management/employees/create">
        <>
          <PageTitle>Employees</PageTitle>
          <CreateEmployeePage />
        </>
      </Route>
      <Route
        exact={true}
        path="/employee-management/employees/:employee_id/update"
      >
        <>
          <PageTitle>Employees</PageTitle>
          <UpdateEmployeePage />
        </>
      </Route>
      {/* end::Employee Routes */}

      {/* begin::Employee Routes */}
      <Route exact={true} path="/employee-management/departments">
        <>
          <PageTitle>Departments</PageTitle>
          <DepartmentsPage />
        </>
      </Route>
      <Route exact={true} path="/employee-management/departments/create">
        <>
          <PageTitle>Departments</PageTitle>
          <CreateDepartmentPage />
        </>
      </Route>
      <Route
        exact={true}
        path="/employee-management/departments/:department_id/update"
      >
        <>
          <PageTitle>Departments</PageTitle>
          <UpdateDepartmentPage />
        </>
      </Route>
      {/* end::Employee Routes */}

      <Redirect
        from="/employee-management"
        exact={true}
        to="/employee-management/employees"
      />
      <Redirect to="/employee-management/employees" />
    </Switch>
  );
};
