import { ElementType, IElement } from "../../../models/Element";

export type PluginIcon = {
  name: string;
  path: string;
};

export const defaultPluginIcon: PluginIcon = {
  name: 'icon-av',
  path: '/assets/icons/icon-av.svg',
};

export type Plugin = {
  title: string;
  description: string;
  url_link: string;
  icon: PluginIcon;
};

export const defaultPlugin: Plugin = {
  title: 'Wordpress Makelaarsplugin geschikt voor RealWorks',
  description: 'Realworks Koppeling geschikt voor WordPress ',
  url_link: '',
  icon: defaultPluginIcon,
};

export type HomePlugins = IElement & {
  title: string;
  description: string;
  button_url: string;
  button_title: string;
  plugins: Array<Plugin>;
};

export const defaultHomePlugins: HomePlugins = {
  id: 0,
  name: ElementType.HOME_PLUGINS,
  title: 'Plugins',
  description:
    'In-house ontwikkeld. Voor jouw automatisering en gemak. Gebruik jij onze plugins al?',
  button_title: 'Alle plugins',
  button_url: '',
  plugins: [defaultPlugin, defaultPlugin, defaultPlugin],
};
