import {
  IActionUnion,
  makeBaseAction,
  makeParamsAction,
  makePayloadAction,
} from '../../../../setup/redux/utils';
import { PaginatedValue } from '../../../support/utils';
import { IDepartment } from '../pages/departments/Department';
import { DepartmentEvents } from './DepartmentEvents';

// CREATE --
export const createDepartmentRequest = makeParamsAction<
  DepartmentEvents.CREATE_DEPARTMENT_REQUESTED,
  { model: IDepartment; callback?: () => void }
>(DepartmentEvents.CREATE_DEPARTMENT_REQUESTED);

export const createDepartmentLoad = makePayloadAction<
  DepartmentEvents.CREATE_DEPARTMENT_LOADED,
  IDepartment
>(DepartmentEvents.CREATE_DEPARTMENT_LOADED);

export const createDepartmentFailed =
  makeBaseAction<DepartmentEvents.CREATE_DEPARTMENT_FAILED>(
    DepartmentEvents.CREATE_DEPARTMENT_FAILED
  );

// VIEW --
export const viewDepartmentRequest = makeParamsAction<
  DepartmentEvents.VIEW_DEPARTMENT_REQUESTED,
  { model_id: string | number }
>(DepartmentEvents.VIEW_DEPARTMENT_REQUESTED);

export const viewDepartmentLoad = makePayloadAction<
  DepartmentEvents.VIEW_DEPARTMENT_LOADED,
  IDepartment
>(DepartmentEvents.VIEW_DEPARTMENT_LOADED);

export const viewDepartmentFailed =
  makeBaseAction<DepartmentEvents.VIEW_DEPARTMENT_FAILED>(
    DepartmentEvents.VIEW_DEPARTMENT_FAILED
  );

// UPDATE --
export const updateDepartmentRequest = makeParamsAction<
  DepartmentEvents.UPDATE_DEPARTMENT_REQUESTED,
  { model: IDepartment; callback?: () => void }
>(DepartmentEvents.UPDATE_DEPARTMENT_REQUESTED);

export const updateDepartmentLoad = makePayloadAction<
  DepartmentEvents.UPDATE_DEPARTMENT_LOADED,
  IDepartment
>(DepartmentEvents.UPDATE_DEPARTMENT_LOADED);

export const updateDepartmentFailed =
  makeBaseAction<DepartmentEvents.UPDATE_DEPARTMENT_FAILED>(
    DepartmentEvents.UPDATE_DEPARTMENT_FAILED
  );

// DELETE --
export const deleteDepartmentRequest = makeParamsAction<
  DepartmentEvents.DELETE_DEPARTMENT_REQUESTED,
  { model: IDepartment }
>(DepartmentEvents.DELETE_DEPARTMENT_REQUESTED);

export const deleteDepartmentLoad = makePayloadAction<
  DepartmentEvents.DELETE_DEPARTMENT_LOADED,
  IDepartment
>(DepartmentEvents.DELETE_DEPARTMENT_LOADED);

export const deleteDepartmentFailed =
  makeBaseAction<DepartmentEvents.DELETE_DEPARTMENT_FAILED>(
    DepartmentEvents.DELETE_DEPARTMENT_FAILED
  );

// LIST --
export const listDepartmentRequest =
  makeBaseAction<DepartmentEvents.LIST_DEPARTMENT_REQUESTED>(
    DepartmentEvents.LIST_DEPARTMENT_REQUESTED
  );

export const listDepartmentLoad = makePayloadAction<
  DepartmentEvents.LIST_DEPARTMENT_LOADED,
  PaginatedValue<IDepartment>
>(DepartmentEvents.LIST_DEPARTMENT_LOADED);

export const listDepartmentFailed =
  makeBaseAction<DepartmentEvents.LIST_DEPARTMENT_FAILED>(
    DepartmentEvents.LIST_DEPARTMENT_FAILED
  );

export const resetCreateDepartment =
  makeBaseAction<DepartmentEvents.RESET_CREATE_DEPARTMENT>(
    DepartmentEvents.RESET_CREATE_DEPARTMENT
  );

// SEARCH
export const searchDepartmentRequest = makeParamsAction<
  DepartmentEvents.SEARCH_DEPARTMENT_REQUESTED,
  { search: string }
>(DepartmentEvents.SEARCH_DEPARTMENT_REQUESTED);

export const searchDepartmentLoad = makePayloadAction<
  DepartmentEvents.SEARCH_DEPARTMENT_LOADED,
  PaginatedValue<IDepartment>
>(DepartmentEvents.SEARCH_DEPARTMENT_LOADED);

export const searchDepartmentFailed =
  makeBaseAction<DepartmentEvents.SEARCH_DEPARTMENT_FAILED>(
    DepartmentEvents.SEARCH_DEPARTMENT_FAILED
  );

export const actions = {
  createDepartmentRequest,
  createDepartmentLoad,
  createDepartmentFailed,
  viewDepartmentRequest,
  viewDepartmentLoad,
  viewDepartmentFailed,
  updateDepartmentRequest,
  updateDepartmentLoad,
  updateDepartmentFailed,
  deleteDepartmentRequest,
  deleteDepartmentLoad,
  deleteDepartmentFailed,
  listDepartmentRequest,
  listDepartmentLoad,
  listDepartmentFailed,
  resetCreateDepartment,
  searchDepartmentRequest,
  searchDepartmentLoad,
  searchDepartmentFailed,
};

export type DepartmentActions = IActionUnion<typeof actions>;
