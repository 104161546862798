import {
  defaultTutorialPlaceholder,
  ITutorial,
} from '../../../../tutorial-management/models/Tutorial';
import { ElementType, IElement } from '../../../models/Element';

export type PluginsFeaturedTutorials = IElement & {
  heading: string;
  tutorials: Array<ITutorial>;
};

export const defaultPluginFeaturedTutorials: PluginsFeaturedTutorials = {
  id: 0,
  name: ElementType.PLUGIN_FEATURED_TUTORIALS,
  heading: 'Handleidingen en snippets',
  tutorials: [defaultTutorialPlaceholder],
};
