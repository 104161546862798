import { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { FallbackView } from '../../_start/partials';
import { ExtendedDashboardWrapper } from '../pages/dashboards/extended-dashboard/ExtendedDashboardWrapper';
import { LightDashboardWrapper } from '../pages/dashboards/light-dashboard/LightDashboardWrapper';
import { CompactDashboardWrapper } from '../pages/dashboards/compact-dashboard/CompactDashboardWrapper';
import { StartDashboardWrapper } from '../pages/dashboards/start-dashboard/StartDashboardWrapper';
import { MenuTestPage } from '../pages/MenuTestPage';
import { MyPage } from '../pages/MyPage';
import { UserManagementWrapper } from '../modules/user-management/UserManagementWrapper';
import { RoleAndPermissionWrapper } from '../modules/role-and-permission/RoleAndPermissionWrapper';
import { PageManagementWrapper } from '../modules/page-management/PageManagementWrapper';
import { CustomerManagementWrapper } from '../modules/customer-management/CustomerManagementWrapper';
import { EmployeeManagementWrapper } from '../modules/employee-management/EmployeeManagementWrapper';
import { MediaManagementWrapper } from '../modules/media-management/MediaManagementWrapper';
import { LayoutManagementWrapper } from '../modules/layout-management/LayoutManagementWrapper';
import { PostManagementWrapper } from '../modules/post-management/PostManagementWrapper';
import { SettingsWrapper } from '../modules/settings/SettingsWrapper';
import { TutorialManagementWrapper } from '../modules/tutorial-management/TutorialManagementWrapper';
import { ModuleManagementWrapper } from '../modules/module-management/ModuleManagementWrapper';
import { AuthRoute } from './AuthRoute';
import { RoleNames } from '../modules/role-and-permission/pages/roles/Role';

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(
    () => import('../pages/layout-builder/BuilderPageWrapper')
  );
  const ProfilePageWrapper = lazy(
    () => import('../modules/profile/ProfilePageWrapper')
  );
  const ChagePage = lazy(() => import('../modules/apps/chat/ChatPageWrapper'));
  const ShopPageWrapper = lazy(
    () => import('../modules/apps/shop/ShopPageWrapper')
  );
  const GeneralPageWrapper = lazy(
    () => import('../modules/general/GeneralPageWrapper')
  );
  const DocsPageWrapper = lazy(() => import('../modules/docs/DocsPageWrapper'));

  // const UserManagementWrapper = lazy(
  // 	() => import("../modules/user-management/UserManagementWrapper")
  // );

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path="/dashboard" component={StartDashboardWrapper} />
        <AuthRoute
          path={`/page-management`}
          Component={PageManagementWrapper}
          requiredRoles={[
            RoleNames.SUPER_ADMIN,
            RoleNames.ADMIN,
            RoleNames.MODERATOR,
          ]}
        />
        <AuthRoute
          path={`/post-management`}
          Component={PostManagementWrapper}
          requiredRoles={[
            RoleNames.SUPER_ADMIN,
            RoleNames.ADMIN,
            RoleNames.MODERATOR,
          ]}
        />
        <AuthRoute
          path={`/tutorial-management`}
          Component={TutorialManagementWrapper}
          requiredRoles={[
            RoleNames.SUPER_ADMIN,
            RoleNames.ADMIN,
            RoleNames.MODERATOR,
          ]}
        />
        <AuthRoute
          path={`/module-management`}
          Component={ModuleManagementWrapper}
          requiredRoles={[
            RoleNames.SUPER_ADMIN,
            RoleNames.ADMIN,
            RoleNames.MODERATOR,
          ]}
        />
        <AuthRoute
          path={`/media-management`}
          Component={MediaManagementWrapper}
          requiredRoles={[
            RoleNames.SUPER_ADMIN,
            RoleNames.ADMIN,
            RoleNames.MODERATOR,
          ]}
        />

        <AuthRoute
          path={`/customer-management`}
          Component={CustomerManagementWrapper}
          requiredRoles={[RoleNames.SUPER_ADMIN, RoleNames.ADMIN]}
        />
        <AuthRoute
          path={`/employee-management`}
          Component={EmployeeManagementWrapper}
          requiredRoles={[RoleNames.SUPER_ADMIN, RoleNames.ADMIN]}
        />
        <AuthRoute
          path={`/layout-management`}
          Component={LayoutManagementWrapper}
          requiredRoles={[RoleNames.SUPER_ADMIN, RoleNames.ADMIN]}
        />

        <AuthRoute
          path={`/settings`}
          Component={SettingsWrapper}
          requiredRoles={[RoleNames.SUPER_ADMIN, RoleNames.ADMIN]}
        />

        <AuthRoute
          path={`/user-management`}
          Component={UserManagementWrapper}
          requiredRoles={[RoleNames.SUPER_ADMIN]}
        />
        <AuthRoute
          path={`/role-and-permission`}
          Component={RoleAndPermissionWrapper}
          requiredRoles={[RoleNames.SUPER_ADMIN]}
        />

        {/* <Route path="/form-management" component={FormManagementWrapper} /> */}

        <Route path="/extended" component={ExtendedDashboardWrapper} />
        <Route path="/light" component={LightDashboardWrapper} />
        <Route path="/compact" component={CompactDashboardWrapper} />
        <Route path="/builder" component={BuilderPageWrapper} />
        <Route path="/general" component={GeneralPageWrapper} />
        <Route path="/chat" component={ChagePage} />
        <Route path="/shop" component={ShopPageWrapper} />
        <Route path="/profile" component={ProfilePageWrapper} />
        <Route path="/menu-test" component={MenuTestPage} />
        <Route path="/docs" component={DocsPageWrapper} />
        <Route path="/my-page" component={MyPage} />
        {/* <Redirect from="/auth" to="/user-management" />
        <Redirect exact from="/" to="/user-management" /> */}
        <Redirect from="/auth" to="/dashboard" />
        <Redirect exact from="/" to="/dashboard" />
        <Redirect to="error/404" />
      </Switch>
    </Suspense>
  );
}
