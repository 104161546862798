import React, { useRef } from 'react';
import updateHelper from 'immutability-helper';
import { IElement } from '../../../models/Element';
import { generateListItemId } from '../../../../../support/helpers';
import {
  defaultHeaderAndDescription,
  IHeaderAndDescription,
  PluginArrayHeaderAndDescription,
} from './PluginArrayHeaderAndDescription';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { HeaderAndDescription } from './HeaderAndDescription';
import { KTSVG } from '../../../../../../_start/helpers';

type Props = {
  index: number;
  data: PluginArrayHeaderAndDescription;
  updateData: (element: IElement, index: number) => void;
};

export const PluginArrayHeaderAndDescriptionFC: React.FC<Props> = ({
  index,
  data,
  updateData,
}) => {
  const update = (fieldsToUpdate: Partial<PluginArrayHeaderAndDescription>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  const addItemRef = useRef<HTMLLIElement>(null);

  const onMouseEnter = (event: React.MouseEvent<HTMLUListElement>) => {
    addItemRef.current?.classList.remove('d-none');

    if (event.currentTarget === event.target) {
      event.stopPropagation();
    }

    event.currentTarget.style.cursor = 'pointer';
  };

  const onMouseLeave = (event: React.MouseEvent<HTMLUListElement>) => {
    addItemRef.current?.classList.add('d-none');
    event.currentTarget.style.cursor = 'auto';
  };

  const updateItem = (idx: number, item: IHeaderAndDescription) => {
    let items = [...data.item_list];
    items[idx] = item;
    const updatedData = { ...data, item_list: items };
    updateData(updatedData, index);
  };

  const addListItem = () => {
    const item_list = updateHelper(data.item_list, {
      $push: [
        {
          ...defaultHeaderAndDescription,
          id: generateListItemId(data.item_list),
        },
      ],
    });

    const updatedData = { ...data, item_list };
    updateData(updatedData, index);
  };

  const removeListItem = (itemIndex: number) => {
    let item_list = updateHelper(data.item_list, {
      $splice: [[itemIndex, 1]],
    });

    const updatedData = { ...data, item_list };
    updateData(updatedData, index);
  };

  const moveListItem = (dragIndex: number, hoverIndex: number) => {
    let item_list = updateHelper(data.item_list, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, data.item_list[dragIndex]],
      ],
    });

    const updatedData = { ...data, item_list };
    updateData(updatedData, index);
  };
  return (
    <ElementWrapper elementIndex={index}>
      <div style={{ marginTop: '50px' }}>
        <ul
          className="plugin-array-header-and-description"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {data.item_list.map((headerAndDescription, idx) => (
            <HeaderAndDescription
              key={idx}
              index={idx}
              headerAndDescription={headerAndDescription}
              pluginArray={data}
              updateProcess={updateItem}
              removeListItem={removeListItem}
              moveListItem={moveListItem}
            />
          ))}

          <li
            ref={addItemRef}
            onClick={addListItem}
            style={{
              minHeight: '263px',
            }}
            className="d-none w-100 h-100 d-flex rounded border-light-400 border-dashed border-2 opacity-75-hover"
          >
            <div className="m-auto d-flex flex-column" style={{ zIndex: 1 }}>
              <KTSVG
                path="/media/icons/duotone/Interface/Plus-Square.svg"
                className="svg-icon-3hx text-center"
              />
              <span className="mt-4">Add new item</span>
            </div>
          </li>
        </ul>
      </div>
    </ElementWrapper>
  );
};
