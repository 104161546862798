/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { RootState } from '../../../../../setup';
import {
  updateDepartmentRequest,
  viewDepartmentRequest,
} from '../../redux/DepartmentActions';
import { defaultDepartment, IDepartment } from './Department';

export const UpdateDepartmentPage = () => {
  const { department_id } = useParams<{ department_id: string }>();

  const dispatch = useDispatch();

  const handleFetch = (id: string) => {
    dispatch(viewDepartmentRequest({ model_id: id }));
  };

  useEffect(() => {
    handleFetch(department_id);
  }, []);

  const loading = useSelector(
    (state: RootState) => state.departmentManagement.loading
  );
  const viewedCustomer = useSelector(
    (state: RootState) => state.departmentManagement.viewedDepartment
  );

  const [data, setData] = useState<IDepartment>({
    ...defaultDepartment,
    name: '',
  });

  useEffect(() => {
    if (viewedCustomer.id !== 0) {
      setData(viewedCustomer);
    }
  }, [viewedCustomer]);

  const updateData = (fieldsToUpdate: Partial<IDepartment>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    dispatch(updateDepartmentRequest({ model: data }));
  };

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Update Department</h3>
      </div>

      {/* begin::Form */}
      <form onSubmit={handleSubmit} className="form d-flex flex-center">
        <div className="card-body mw-800px py-20">
          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Name</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter name"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.name}
                  onChange={(e) => updateData({ name: e.target.value })}
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row">
            <label className="col-lg-3 col-form-label"></label>
            <div className="col-lg-9">
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary fw-bolder px-6 py-3 me-3"
              >
                {!loading && <span className="indicator-label">Save</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Please wait...{' '}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
              <Link
                className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3"
                to="/employee-management/departments"
              >
                Cancel
              </Link>
            </div>
          </div>
          {/* end::Form row */}
        </div>
      </form>
      {/* end::Form */}
    </div>
  );
};
