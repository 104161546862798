import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PageTitle } from '../../../_start/layout/core';
import { CreateMember } from './pages/members/CreateMember';
import MembersPage from './pages/members/MembersPage';
import { UpdateMember } from './pages/members/UpdateMember';

export const UserManagementPage = () => {
  return (
    <Switch>
      <Route exact={true} path="/user-management/members">
        <>
          <PageTitle>Members</PageTitle>
          <MembersPage />
        </>
      </Route>
      <Route exact={true} path="/user-management/members/:member_id/update">
        <>
          <PageTitle>Members</PageTitle>
          <UpdateMember />
        </>
      </Route>
      <Route exact={true} path="/user-management/members/create">
        <>
          <PageTitle>Members</PageTitle>
          <CreateMember />
        </>
      </Route>

      <Redirect
        from="/user-management"
        exact={true}
        to="/user-management/members"
      />
      <Redirect to="/user-management/members" />
    </Switch>
  );
};
