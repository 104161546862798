import { KTSVG } from '../../../../../../_start/helpers';
import { ButtonTooltip } from '../../../components/ButtonTooltip';
import { InlineEdit } from '../../../components/InlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { HomePlugins } from './HomePlugins';

type HomePluginsHeaderProps = {
  data: HomePlugins;
  update: (fieldsToUpdate: Partial<HomePlugins>) => void;
};

export const HomePluginsHeader = ({ data, update }: HomePluginsHeaderProps) => {
  return (
    <div className="home-plugins__header">
      {/* begin::Title */}
      <div className="header-title">
        <KTSVG
          path="/assets/icons/icon-tree-structure.svg"
          className="me-3 svg-icon-4hx svg-icon-muted"
        />
        <InlineEdit
          name="title"
          className="header-title_input"
          value={data.title}
          setValue={update}
          activeVerticalPadding={`py-0`}
          dynamicWidth
        />
      </div>
      {/* end::Title */}

      {/* begin::Description */}
      <div className="header-description">
        <TextAreaEdit
          name="description"
          className="w-100"
          value={data.description}
          setValue={update}
        />
      </div>
      {/* end::Description */}

      {/* begin::Action Button */}
      <div className="header-button">
        <ButtonTooltip
          name={`button_url`}
          value={data.button_url}
          setValue={update}
        >
          <button className="btn btn-success rounded-pill">
            <InlineEdit
              name="button_title"
              className="px-1 text-white text-center"
              value={data.button_title}
              setValue={update}
              activeVerticalPadding={'py-1'}
              dynamicWidth
              style={{
                maxWidth: '180px',
              }}
            />
          </button>
        </ButtonTooltip>
        {/* end::Action Button */}
      </div>
    </div>
  );
};
