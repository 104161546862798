import { ElementType, IElement } from '../../../models/Element';

export type PluginRightImageInfo = IElement & {
  image: string;
  image_alt: string;
  heading: string;
  description: string;
  button1_title: string;
  button1_url: string;
  button2_title: string;
  button2_url: string;
};

export const defaultPluginRightImageInfo: PluginRightImageInfo = {
  id: 0,
  name: ElementType.PLUGIN_RIGHT_IMAGE_INFO,
  image: '',
  image_alt: '',
  heading: 'Werking van de plugin.',
  description:
    'Voor makelaars is het van groot belang dat objecten (woningen en BOG) goed vindbaar zijn voor potentiële kopers. Dit kan tegenwoordig via Funda, Jaap en andere woningsites, maar ook de eigen website van de makelaar is hierin van essentieel belang.',
  button1_title: 'Bestel',
  button1_url: '',
  button2_title: 'Bekijk Whitepaper',
  button2_url: '',
};
