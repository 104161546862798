import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useFormik } from 'formik';
import * as auth from '../redux/AuthRedux';
import axios, { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';
import { API_URL } from '../../../support/api';
import { ErrorResponse } from '../../../../setup/axios/SetupAxios';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  otp: Yup.string().required('OTP is required'),
});

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

type OtpPayload = {
  email: string;
  otp: string;
};

const otp = async (payload: OtpPayload) => {
  const { data } = await axios.post<{ value: {access_token: string} }>(
    `${API_URL}/v1/auth/otp`,
    payload
  );

  return data.value.access_token;
};

export function LoginPartial() {
  const { email } = useParams<{ email: string }>();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const initialValues = {
    email,
    otp: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      setLoading(true);
      try {
        const payload: OtpPayload = {
          email: values.email,
          otp: values.otp,
        };
        const response = await otp(payload);
        setLoading(false);
        setSubmitting(true);
        dispatch(auth.actions.login(response));
      } catch (error) {
        const err = error as AxiosError;
        const { message } = err.response?.data as ErrorResponse;
        setLoading(false);
        setSubmitting(false);
        setStatus(message ? message : 'The login detail is incorrect');
      }
    },
  });

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      {/* begin::Title */}
      <div className="pb-lg-15">
        <h3 className="fw-bolder text-dark display-6">Google Authenticator</h3>
        {/* <div className="text-muted fw-bold fs-3">
					New Here?{" "}
					<Link
						to="/auth/registration"
						className="text-primary fw-bolder"
						id="kt_login_signin_form_singup_button"
					>
						Create Account
					</Link>
				</div> */}
      </div>
      {/* begin::Title */}

      {formik.status && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      )}

      {/* {formik.status ? (
				<div className="mb-lg-15 alert alert-danger">
					<div className="alert-text font-weight-bold">
						{formik.status}
					</div>
				</div>
			) : (
				<div className="mb-lg-15 alert alert-info">
					<div className="alert-text ">
						Use credentials <strong>admin@demo.com</strong> and{" "}
						<strong>demo</strong> to sign in.
					</div>
				</div>
			)} */}

      {/* begin::Form group */}
      <div className="v-row mb-10 fv-plugins-icon-container">
        <label className="form-label fs-6 fw-bolder text-dark">Email</label>
        <input
          placeholder="Email"
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.email && formik.errors.email,
            },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type="email"
          name="email"
          autoComplete="off"
          disabled
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik.errors.email}</div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="fv-row mb-10 fv-plugins-icon-container">
        <div className="d-flex justify-content-between mt-n5">
          <label className="form-label fs-6 fw-bolder text-dark pt-5">
            OTP
          </label>

          {/* <Link
						to="/auth/forgot-password"
						className="text-primary fs-6 fw-bolder text-hover-primary pt-5"
						id="kt_login_signin_form_password_reset_button"
					>
						Forgot Password ?
					</Link> */}
        </div>
        <input
          placeholder="OTP"
          type="text"
          autoComplete="off"
          {...formik.getFieldProps('otp')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.otp && formik.errors.otp,
            },
            {
              'is-valid': formik.touched.otp && !formik.errors.otp,
            }
          )}
        />
        {formik.touched.otp && formik.errors.otp && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik.errors.otp}</div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className="pb-lg-0 pb-5">
        <button
          type="submit"
          id="kt_login_signin_form_submit_button"
          className="btn btn-primary fw-bolder fs-6 px-8 py-4 my-3 me-3"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className="indicator-label">Proceed</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: 'block' }}>
              Please wait...{' '}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        {/* <button
					type="button"
					className="btn btn-light-primary fw-bolder px-8 py-4 my-3 fs-6 mr-3"
				>
					<img
						src={toAbsoluteUrl(
							"/media/svg/brand-logos/google-icon.svg"
						)}
						className="w-20px h-20px me-3"
						alt=""
					/>
					Sign in with Google
				</button> */}
      </div>
      {/* end::Action */}
    </form>
  );
}
