/* eslint-disable jsx-a11y/anchor-is-valid */
import { toAbsoluteUrl } from '../../../../../../_start/helpers';
import { FormImageFieldModal } from '../../../../media-management/components/form-image/FormImageFieldModal';
import { ButtonTooltip } from '../../../components/ButtonTooltip';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { InlineEdit } from '../../../components/InlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { IElement } from '../../../models/Element';
import { ShowcaseFeatured } from './ShowcaseFeatured';

type ShowcaseFeaturedFCProps = {
  index: number;
  data: ShowcaseFeatured;
  updateData: (body: IElement, index: number) => void;
};

export const ShowcaseFeaturedFC = ({
  index,
  data,
  updateData,
}: ShowcaseFeaturedFCProps) => {
  const update = (fieldsToUpdate: Partial<ShowcaseFeatured>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  return (
    <>
      <FormImageFieldModal
        modalId={`${data.name}-${data.id}-desktop`}
        name={`image_preview_desktop`}
        altText={`image_preview_desktop_alt`}
        update={update}
      />

      <FormImageFieldModal
        modalId={`${data.name}-${data.id}-mobile`}
        name={`image_preview_mobile`}
        altText={`image_preview_mobile_alt`}
        update={update}
      />

      <ElementWrapper elementIndex={index}>
        <div className="case-showcase-featured body-my-8">
          <div className="case-showcase-featured__container">
            {/* begin::Content */}
            <div className="container__content">
              <TextAreaEdit
                name="title"
                className="content-title"
                value={data.title}
                setValue={update}
              />

              <TextAreaEdit
                name="description"
                className="content-description"
                value={data.description}
                setValue={update}
              />

              <div className="content-action">
                <ButtonTooltip
                  name={`button_url`}
                  value={data.button_url}
                  setValue={update}
                >
                  <a>
                    <InlineEdit
                      className="action-text"
                      name="button_title"
                      value={data.button_title}
                      setValue={update}
                      activeVerticalPadding={'py-0'}
                      dynamicWidth
                      style={{
                        maxWidth: '22rem',
                      }}
                    />
                  </a>
                </ButtonTooltip>
              </div>
            </div>
            {/* end::Content */}

            {/* begin::Figure */}
            <div className="container__figure">
              {/* begin::Screenshot */}
              {data.image_preview_desktop ? (
                <div className="mt-5 position-relative">
                  <div className="overlay">
                    <div className="overlay-wrapper">
                      <img
                        alt="dummy"
                        src={data.image_preview_desktop}
                        style={{
                          width: '100%',
                          height: '525px',
                          objectFit: 'cover',
                          borderRadius: '1.25rem',
                        }}
                      />
                    </div>
                    <div className="overlay-layer bg-dark bg-opacity-10 flex-column justify-content-end pb-12">
                      <div>
                        <button
                          name="image_preview_desktop"
                          className="btn btn-primary btn-shadow"
                          onClick={() =>
                            update({
                              image_preview_desktop: '',
                              image_preview_desktop_alt: '',
                            })
                          }
                        >
                          Remove
                        </button>
                        <label
                          className="btn btn-light-primary btn-shadow ms-2"
                          data-bs-toggle="modal"
                          data-bs-target={`#${data.name}-${data.id}-desktop`}
                        >
                          Change
                        </label>
                      </div>

                      <input
                        placeholder="Alt attribute"
                        className="w-50 mt-2 form-control form-control-lg form-control-solid"
                        type="text"
                        value={data.image_preview_desktop_alt ?? ''}
                        onChange={(event) =>
                          update({
                            image_preview_desktop_alt: event.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  {data.image_preview_mobile ? (
                    <>
                      <div className="overlay position-absolute top-0 start-0 ms-10 mt-n8 smartphone smartphone--dark smartphone--inside smartphone--scale">
                        <div className="overlay-wrapper">
                          <img
                            alt="dummy"
                            src={data.image_preview_mobile}
                            style={{
                              width: '100%',
                              height: '380px',
                              objectFit: 'cover',
                            }}
                          />
                        </div>
                        <div className="overlay-layer bg-dark bg-opacity-10 flex-column">
                          <div>
                            <button
                              name="image_preview_mobile"
                              className="btn btn-primary btn-shadow"
                              onClick={() =>
                                update({
                                  image_preview_mobile: '',
                                  image_preview_mobile_alt: '',
                                })
                              }
                            >
                              Remove
                            </button>
                            <label
                              className="btn btn-light-primary btn-shadow ms-2"
                              data-bs-toggle="modal"
                              data-bs-target={`#${data.name}-${data.id}-mobile`}
                            >
                              Change
                            </label>
                          </div>

                          <input
                            placeholder="Alt attribute"
                            className="w-75 mt-2 form-control form-control-lg form-control-solid"
                            type="text"
                            value={data.image_preview_mobile_alt ?? ''}
                            onChange={(event) =>
                              update({
                                image_preview_mobile_alt: event.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <label
                      className="position-absolute top-0 start-0 ms-10 mt-n8 smartphone smartphone--dark smartphone--inside smartphone--scale"
                      data-bs-toggle="modal"
                      data-bs-target={`#${data.name}-${data.id}-mobile`}
                    >
                      <img
                        src={toAbsoluteUrl('/assets/images/400x679.png')}
                        alt=""
                        style={{
                          width: '100%',
                          height: '380px',
                          objectFit: 'cover',
                        }}
                      />
                    </label>
                  )}
                </div>
              ) : (
                <>
                  <label
                    className="position-relative"
                    data-bs-toggle="modal"
                    data-bs-target={`#${data.name}-${data.id}-desktop`}
                  >
                    <img
                      alt=""
                      src={toAbsoluteUrl('/assets/images/900x540.png')}
                      style={{
                        width: '100%',
                        height: '525px',
                        objectFit: 'cover',
                        borderRadius: '1.25rem',
                      }}
                    />

                    <label className="position-absolute top-0 start-0 ms-10 mt-n8 smartphone smartphone--dark smartphone--inside smartphone--scale">
                      <img
                        src={toAbsoluteUrl('/assets/images/400x679.png')}
                        alt=""
                        style={{
                          width: '100%',
                          height: '380px',
                          objectFit: 'cover',
                        }}
                      />
                    </label>
                  </label>
                </>
              )}
              {/* begin::Screenshot */}
            </div>
            {/* end::Figure */}
          </div>
        </div>
      </ElementWrapper>
    </>
  );
};
