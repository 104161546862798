/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../setup';
import { KTSVG } from '../../../../../_start/helpers';
import { Pagination } from '../../../../components/Pagination';
import { useDebounce } from '../../../../hooks/useDebounce';
import { failureToast, handlePaginate } from '../../../../support/utils';
import { defaultMedia, IMedia } from '../../models/Media';
import { mediaLibraryActions } from '../../redux/MediaLibraryAction';
import { listMedia } from '../../redux/MediaLibraryCRUD';
import { UploadMedia } from './UploadMedia';

type FormImageFieldModalProps = {
  modalId: string;
  name: string;
  altText?: string;
  captionText?: string;
  update: (fieldsToUpdate: Partial<any>) => void;
};

export const FormImageFieldModal = ({
  modalId,
  name,
  altText = '',
  captionText = '',
  update,
}: FormImageFieldModalProps) => {
  const dispatch = useDispatch();

  const initialized = useSelector(
    (state: RootState) => state.mediaLibrary.initialized
  );

  const [loading, setLoading] = useState(false);

  /**
   * SEARCH
   */
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce<string>(search, 500);

  const handleSearch = async () => {
    setLoading(true);
    try {
      const result = await listMedia(search);
      dispatch(mediaLibraryActions.setSearchPaginatedValue(result));
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (initialized) {
      handleSearch();
    }
  }, [debouncedSearch]);

  /**
   * PAGINATION
   */
  const paginatedValue = useSelector(
    (state: RootState) => state.mediaLibrary.paginatedValue
  );

  const handlePaginateState = async (page_url: string) => {
    setLoading(true);
    try {
      const result = await handlePaginate<IMedia>(page_url);
      dispatch(mediaLibraryActions.setPaginatedValue(result));
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  /**
   * INITIALIZATION
   */
  const handleFetchList = async () => {
    setLoading(true);
    try {
      const result = await listMedia();
      dispatch(mediaLibraryActions.setPaginatedValue(result));
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!initialized) {
      handleFetchList();
    }
  }, []);

  const closeRef = useRef<HTMLButtonElement>(null);

  const [selected, setSelected] = useState<IMedia>(defaultMedia);

  const handleConfirm = () => {
    if (captionText) {
      update({
        [name]: selected.original_url,
        [altText]: selected.custom_properties.alt_text ?? '',
        [captionText]: selected.custom_properties.caption ?? '',
      });
    } else if (altText) {
      update({
        [name]: selected.original_url,
        [altText]: selected.custom_properties.alt_text ?? '',
      });
    } else {
      update({ [name]: selected.original_url });
    }
    closeRef.current?.click();
    handleClose();
  };

  const handleClose = () => {
    setSearch('');
  };

  return (
    <div className="modal bg-white fade" tabIndex={-1} id={modalId}>
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content shadow-none">
          <div className="modal-header">
            <h5 className="modal-title">Select Media</h5>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <KTSVG
                path="/media/icons/duotone/Navigation/Close.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-lg-4">
                <UploadMedia
                  loading={loading}
                  handleFetchList={handleFetchList}
                />
              </div>

              <div className="col-lg-8">
                <div
                  className={`card card-custom card-flush ${
                    loading ? 'overlay overlay-block' : ''
                  }`}
                >
                  <div className="pt-8 card-header">
                    <div className="d-flex flex-center border py-1 px-4 bg-white rounded">
                      <KTSVG
                        path="/media/icons/duotone/General/Search.svg"
                        className="svg-icon-1 svg-icon-primary"
                      />
                      <input
                        value={search}
                        onChange={(event) => setSearch(event.target.value)}
                        type="text"
                        className={`form-control border-0 fw-bold ps-2 ${
                          '' ? 'w-xxl-600px' : 'w-xxl-350px'
                        }`}
                        placeholder="Zoeken"
                      />
                    </div>
                  </div>
                  <div
                    className={`card-body py-5 ${
                      loading ? 'overlay-wrapper' : ''
                    }`}
                  >
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
                        gap: '2rem',
                      }}
                    >
                      {paginatedValue.data.map((element, idx) => (
                        <a
                          key={idx}
                          className={`rounded text-center ${
                            element.id === selected.id
                              ? 'border border-4 border-gray-400'
                              : ''
                          }`}
                          onClick={() => setSelected(element)}
                        >
                          {element.mime_type === 'application/pdf' ||
                          element.mime_type === 'application/zip' ? (
                            <div className="h-100 d-flex justify-content-center align-items-center">
                              <KTSVG
                                path="/media/icons/duotone/Files/File.svg"
                                className="svg-icon-5tx"
                              />
                            </div>
                          ) : (
                            <img
                              alt={element.name}
                              src={element.original_url as string}
                              style={{
                                width: '150px',
                                height: '150px',
                                objectFit: 'cover',
                              }}
                            />
                          )}
                        </a>
                      ))}
                    </div>
                    {loading && (
                      <div className="overlay-layer rounded bg-dark bg-opacity-5">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="card-footer">
                    <Pagination
                      loading={loading}
                      pagination={paginatedValue}
                      handlePaginateState={handlePaginateState}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer justify-content-between">
            <div className="d-block">
              <p>
                <span>Selected: </span>
                <span className="fw-bold underline">
                  {selected.id !== 0 ? selected.name : 'None'}
                </span>
              </p>
            </div>
            <div className="d-flex gap-5">
              <button
                ref={closeRef}
                onClick={handleClose}
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                disabled={selected.id === 0}
                onClick={handleConfirm}
                type="button"
                className="btn btn-primary"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
