import React from "react";
import { AsideMenuItem } from "./AsideMenuItem";

export function AsideMenuMain() {
	return (
		<>
			{" "}
			<>
				<>
					<div className="menu-item">
						<h4 className="menu-content text-muted mb-0 fs-6 fw-bold text-uppercase">
							Dashboard
						</h4>
					</div>
					<AsideMenuItem to="/dashboard" title="Home" />
					{/* <AsideMenuItem to="/extended" title="Extended" />
					<AsideMenuItem to="/light" title="Light" />
					<AsideMenuItem to="/compact" title="Compact" />
					<AsideMenuItem to="/my-page" title="My Page" /> */}
				</>

				<>
					<div className="menu-item mt-10">
						<h4 className="menu-content text-muted mb-0 fs-6 fw-bold text-uppercase">
							Gebruikersbeheer
						</h4>
					</div>
					<AsideMenuItem to="/user-management/members" title="Members" />
				</>

				<>
					<div className="menu-item mt-10">
						<h4 className="menu-content text-muted mb-0 fs-6 fw-bold text-uppercase">
							Rol & Permissies
						</h4>
					</div>
					<AsideMenuItem to="/role-and-permission/roles" title="Roles" />
					<AsideMenuItem to="/role-and-permission/permissions" title="Permissions" />
				</>

				<>
					<div className="menu-item mt-10">
						<h4 className="menu-content text-muted mb-0 fs-6 fw-bold text-uppercase">
							Paginabeheer
						</h4>
					</div>
					<AsideMenuItem to="/page-management/pages" title="Pages" />
					<AsideMenuItem to="/page-management/post-types" title="Post Types" />
					<AsideMenuItem to="/page-management/categories" title="Categories" />
				</>

				<>
					<div className="menu-item mt-10">
						<h4 className="menu-content text-muted mb-0 fs-6 fw-bold text-uppercase">
							Profiel
						</h4>
					</div>
					<AsideMenuItem to="/profile/overview" title="Overview" />
					<AsideMenuItem to="/profile/account" title="Account" />
					<AsideMenuItem to="/profile/settings" title="Settings" />
				</>

				{/* <>
					<div className="menu-item mt-10">
						<h4 className="menu-content text-muted mb-0 fs-6 fw-bold text-uppercase">
							Apps
						</h4>
					</div>
					<AsideMenuItem to="/chat" title="Chat" />
					<AsideMenuItem to="/shop/shop-1" title="Shop 1" />
					<AsideMenuItem to="/shop/shop-2" title="Shop 2" />
					<AsideMenuItem to="/shop/product/1" title="Shop Product" />
				</>

				<>
					<div className="menu-item mt-10">
						<h4 className="menu-content text-muted mb-0 fs-6 fw-bold text-uppercase">
							General
						</h4>
					</div>
					<AsideMenuItem to="/general/faq" title="FAQ" />
					<AsideMenuItem to="/general/pricing" title="Pricing" />
					<AsideMenuItem to="/general/invoice" title="Inovice" />
					<AsideMenuItem to="/auth/login" title="Login" />
					<AsideMenuItem to="/general/wizard" title="Wizard" />
					<AsideMenuItem to="/error/404" title="Error" />
				</>

				<>
					<div className="menu-item mt-10">
						<h4 className="menu-content text-muted mb-0 fs-6 fw-bold text-uppercase">
							Resources
						</h4>
					</div>
					<AsideMenuItem to="/docs" title="Documentation" />
					<AsideMenuItem to="/builder" title="Layout Builder" />
					<AsideMenuItem to="/docs/change-log" title="Change Log" />
				</> */}
			</>
		</>
	);
}
