import { toAbsoluteUrl } from '../../../../../../_start/helpers';
import { FormImageFieldModal } from '../../../../media-management/components/form-image/FormImageFieldModal';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { StaticWidthInlineEdit } from '../../../components/StaticWidthInlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { IElement } from '../../../models/Element';
import { ShowcaseBigBanner } from './ShowcaseBigBanner';

type ShowcaseBigBannerFCProps = {
  index: number;
  data: ShowcaseBigBanner;
  updateData: (body: IElement, index: number) => void;
};

export const ShowcaseBigBannerFC = ({
  index,
  data,
  updateData,
}: ShowcaseBigBannerFCProps) => {
  const update = (fieldsToUpdate: Partial<ShowcaseBigBanner>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  return (
    <>
      <FormImageFieldModal
        modalId={`${data.name}-${data.id}`}
        name={`image_preview`}
        altText={`image_preview_alt`}
        captionText={`image_caption`}
        update={update}
      />

      <ElementWrapper elementIndex={index}>
        <div className="case-showcase-big-banner body-my-8">
          {/* begin::Content */}
          <div className="case-showcase-big-banner__content">
            <StaticWidthInlineEdit
              name="title"
              className="content-title"
              value={data.title}
              setValue={update}
            />

            <TextAreaEdit
              name="description"
              className="content-description"
              value={data.description}
              setValue={update}
            />
          </div>
          {/* end::Content */}

          {/* begin::Figure */}
          <div className="case-showcase-big-banner__figure">
            {data.image_preview ? (
              <div className="overlay">
                <div className="overlay-wrapper">
                  <img
                    alt="dummy"
                    src={data.image_preview}
                    style={{
                      width: '100%',
                      height: '500px',
                      objectFit: 'cover',
                    }}
                  />
                </div>
                <div className="overlay-layer bg-dark bg-opacity-10 flex-column">
                  <div>
                    <button
                      name="image_preview"
                      className="btn btn-primary btn-shadow"
                      onClick={() =>
                        update({
                          image_preview: '',
                          image_preview_alt: '',
                          image_caption: 'Image caption',
                        })
                      }
                    >
                      Remove
                    </button>
                    <label
                      className="btn btn-light-primary btn-shadow ms-2"
                      data-bs-toggle="modal"
                      data-bs-target={`#${data.name}-${data.id}`}
                    >
                      Change
                    </label>
                  </div>

                  <input
                    placeholder="Alt attribute"
                    className="w-50 mt-2 form-control form-control-lg form-control-solid"
                    type="text"
                    value={data.image_preview_alt ?? ''}
                    onChange={(event) =>
                      update({ image_preview_alt: event.target.value })
                    }
                  />
                </div>
              </div>
            ) : (
              <>
                <label
                  data-bs-toggle="modal"
                  data-bs-target={`#${data.name}-${data.id}`}
                >
                  <img
                    src={toAbsoluteUrl('/assets/images/1160x522.png')}
                    style={{
                      width: '100%',
                      height: '500px',
                      objectFit: 'cover',
                    }}
                    alt=""
                  />
                </label>
              </>
            )}
          </div>
          {/* end::Figure */}

          {/* begin::Image Caption */}
          <div className="case-showcase-big-banner__caption">
            <StaticWidthInlineEdit
              name="image_caption"
              value={data.image_caption}
              setValue={update}
            />
          </div>
          {/* end::Image Caption */}
        </div>
      </ElementWrapper>
    </>
  );
};
