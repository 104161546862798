import React from 'react';
import { InlineEdit } from '../../../components/InlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { SpecializationsModalSteps } from './SpecializationsModalSteps';

interface ModelStepsHeadingProps {
  data: SpecializationsModalSteps;
  update: (fieldsToUpdate: Partial<SpecializationsModalSteps>) => void;
}

export const SpecializationsModalStepsHeading: React.FC<
  ModelStepsHeadingProps
> = ({ data, update }) => {
  return (
    <>
      <InlineEdit
        value={data.heading}
        setValue={update}
        name="heading"
        className="specializations-modal-steps__heading"
        dynamicWidth
      />
      <p>
        <TextAreaEdit
          value={data.description}
          setValue={update}
          name="description"
          className="specializations-modal-steps__description"
        />
      </p>
    </>
  );
};
