import React from 'react';
import { toAbsoluteUrl } from '../../../../../../_start/helpers';
import {
  SpecializationsCases,
  SpecializationsCasesItem,
} from './SpecializationsCases';

interface SpecializationsCasesFigureProps {
  data: SpecializationsCasesItem;
  update: (fieldsToUpdate: Partial<SpecializationsCases>) => void;
}

export const SpecializationsCasesFigure: React.FC<
  SpecializationsCasesFigureProps
> = ({ data, update }) => {
  return (
    <div className="specializations-cases__figure">
      {/* begin::Content Image Preview */}
      <div className="content-image">
        {data.case_detail.image_preview ? (
          <div className="overlay">
            <div className="overlay-wrapper">
              <img
                alt="dummy"
                src={data.case_detail.image_preview}
                style={{
                  width: '100%',
                  height: '320px',
                  objectFit: 'cover',
                }}
              />
            </div>
          </div>
        ) : (
          <>
            <label htmlFor={`specializations-cases-input-${data.id}`}>
              <img src={toAbsoluteUrl('/assets/images/400x500.png')} alt="" />
            </label>
          </>
        )}
      </div>
      {/* end::Content Image Preview */}
    </div>
  );
};
