export const handleFileChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  update: (fieldsToUpdate: Partial<any>) => void
) => {
  const files = event.target.files;

  if (!files?.length) return;

  const reader = new FileReader();

  reader.onload = (ev) => {
    const result = ev.target?.result;
    update({
      [event.target.name]: result,
    });
  };

  reader.onerror = function () {
    console.log(reader.result);
  };

  reader.readAsDataURL(files[files.length - 1]);

  // this line right below will reset the
  // input field so if you removed it you can re-add the same file
  event.target.value = '';
};

/**
 * Convert string to slug
 * @param value Input string
 * @returns slug of the input
 */
export const convertToSlug = (value: string) => {
  return value
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
};

export const generateListItemId = (items: Array<{ id: number }>) => {
  if (items.length === 0) {
    return 0;
  }

  let sortedItems = [...items].sort((a, b) => {
    return a.id - b.id;
  });

  return sortedItems[sortedItems.length - 1].id + 1;
};
