import { defaultPartner, PluginPartners } from './PluginPartners';
import { PluginPartnersCarousel } from './PluginPartnersCarousel';
import { PluginPartnersContent } from './PluginPartnersContent';

import immutabilityHelper from 'immutability-helper';
import { IElement } from '../../../models/Element';
import { ElementWrapper } from '../../../components/ElementWrapper';

type HomeEmployeesFCProps = {
  index: number;
  data: PluginPartners;
  updateData: (body: IElement, index: number) => void;
};

export const PluginPartnersFC = ({
  index,
  data,
  updateData,
}: HomeEmployeesFCProps) => {
  const update = (fieldsToUpdate: Partial<PluginPartners>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  const addPartner = () => {
    const partners = immutabilityHelper(data.partners, {
      $push: [defaultPartner],
    });

    const updatedData = { ...data, partners };
    updateData(updatedData, index);
  };

  const removePartner = (itemIndex: number) => {
    let partners = immutabilityHelper(data.partners, {
      $splice: [[itemIndex, 1]],
    });

    const updatedData = { ...data, partners };
    updateData(updatedData, index);
  };

  return (
    <ElementWrapper elementIndex={index}>
      <div className="plugin-partners body-my-8">
        <PluginPartnersContent data={data} update={update} />
        <PluginPartnersCarousel
          data={data}
          update={update}
          addPartner={addPartner}
          removePartner={removePartner}
        />
      </div>
    </ElementWrapper>
  );
};
