/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { failureToast, successToast } from '../../../../support/utils';

import { updateMember, viewMember } from '../../redux/UserManagementCRUD';

import { defaultMember, IMember } from '../../models/Member';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../setup';
import { actions } from '../../redux/UserManagementRedux';

export const UpdateMember = () => {
  const history = useHistory();

  const { member_id } = useParams<{ member_id: string }>();

  const dispatch = useDispatch();

  const roles = useSelector((state: RootState) => state.userManagement.roles);

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<IMember>(defaultMember);

  const updateData = (fieldsToUpdate: Partial<IMember>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  const handleGetMember = async (id: number | string) => {
    setLoading(true);
    try {
      await dispatch(actions.requestRoles());
      const {
        data: { value },
      } = await viewMember(id);
      setData({
        ...data,
        ...value,
        role_id: value.roles![0].id,
      });
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetMember(member_id);
  }, [member_id]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    console.log(data);

    setLoading(true);
    try {
      await updateMember(data);
      successToast('Member has been updated.');
      setLoading(false);
      history.goBack();
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Update Member</h3>
      </div>
      {/* begin::Form */}
      <form onSubmit={handleSubmit} className="form d-flex flex-center">
        <div className="card-body mw-800px py-20">
          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Name</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter name"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.name}
                  onChange={(e) => updateData({ name: e.target.value })}
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Email Address</label>
            <div className="col-lg-9">
              <div className="input-group input-group-lg input-group-solid">
                <span className="input-group-text pe-0">
                  <i className="la la-at fs-4"></i>
                </span>
                <input
                  disabled
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  placeholder="Email"
                  value={data.email}
                  onChange={(e) => updateData({ email: e.target.value })}
                />
              </div>
              <div className="form-text">
                Email will not be publicly displayed.{' '}
                <a href="#" className="fw-bold">
                  Learn more
                </a>
                .
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Role</label>
            <div className="col-lg-9">
              <select
                className="form-select form-select-lg form-select-solid text-capitalize"
                data-control="select2"
                data-placeholder="Select Language..."
                value={data.role_id}
                onChange={(e) =>
                  updateData({
                    role_id: parseInt(e.target.value),
                  })
                }
              >
                {roles?.map((role, idx) => {
                  if (role.id === 1) return '';
                  return (
                    <option
                      value={role.id}
                      key={idx}
                      className="text-capitalize"
                    >
                      {role.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row">
            <label className="col-lg-3 col-form-label"></label>
            <div className="col-lg-9">
              <button
                type="submit"
                className="btn btn-primary fw-bolder px-6 py-3 me-3"
              >
                {!loading && <span className="indicator-label">Save</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Please wait...{' '}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
              <Link
                className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3"
                to="members"
              >
                Cancel
              </Link>
            </div>
          </div>
          {/* end::Form row */}
        </div>
      </form>
      {/* end::Form */}
    </div>
  );
};
