import { ElementWrapper } from '../../../components/ElementWrapper';
import { IElement } from '../../../models/Element';
import { WebsiteScreenshot } from './WebsiteScreenshot';
import { WebsiteScreenshotDesktop } from './WebsiteScreenshotDesktop';
import { WebsiteScreenshotMobile } from './WebsiteScreenshotMobile';

type WebsiteScreenshotFCProps = {
  index: number;
  data: WebsiteScreenshot;
  updateData: (body: IElement, index: number) => void;
};

export const WebsiteScreenshotFC = ({
  index,
  data,
  updateData,
}: WebsiteScreenshotFCProps) => {
  const update = (fieldsToUpdate: Partial<WebsiteScreenshot>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  return (
    <ElementWrapper elementIndex={index}>
      <div className="case-header-website-screenshot body-my-8">
        <div className="case-header-website-screenshot__content">
          <WebsiteScreenshotDesktop data={data} update={update} />
          <WebsiteScreenshotMobile data={data} update={update} />
        </div>
      </div>
    </ElementWrapper>
  );
};
