import { toAbsoluteUrl } from '../../../../../../_start/helpers';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { InlineEdit } from '../../../components/InlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { IElement } from '../../../models/Element';
import { AboutUsEmployees } from './AboutUsEmployees';

type AboutUsEmployeesFCProps = {
  index: number;
  data: AboutUsEmployees;
  updateData: (body: IElement, index: number) => void;
};

export const AboutUsEmployeesFC = ({
  index,
  data,
  updateData,
}: AboutUsEmployeesFCProps) => {
  const update = (fieldsToUpdate: Partial<AboutUsEmployees>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  return (
    <ElementWrapper elementIndex={index}>
      <div className="about-us-employees">
        <div className="about-us-employees__content body-my-8">
          {/* begin::Title */}
          <InlineEdit
            name="title"
            className="content-title"
            value={data.title}
            setValue={update}
            activeVerticalPadding={`py-0`}
          />
          {/* end::Title */}

          {/* begin::Heading */}
          <TextAreaEdit
            name="heading"
            className="content-heading"
            value={data.heading}
            setValue={update}
          />
          {/* end::Heading */}

          {/* begin::Description */}
          <TextAreaEdit
            name="description"
            className="content-description"
            value={data.description}
            setValue={update}
          />
          {/* end::Description */}
        </div>
        <div className="about-us-employees__department">
          <div className="department department--active">
            <span>Iedereen (12)</span>
          </div>
          <div className="department">
            <span>Design (3)</span>
          </div>
          <div className="department">
            <span>Marketing (3)</span>
          </div>
          <div className="department">
            <span>Development (3)</span>
          </div>
          <div className="department">
            <span>Strategy (3)</span>
          </div>
        </div>

        <div className="about-us-employees__list">
          <div className="item">
            <img
              className="item-figure"
              src={toAbsoluteUrl('/assets/images/400x500.png')}
              alt=""
              style={{
                width: '100%',
                height: '400px',
                objectFit: 'cover',
              }}
            />

            <div className="item-content">
              <span className="item-content__name">John Doe</span>
              <span className="item-content__department">Department</span>
            </div>
          </div>

          <div className="item">
            <img
              className="item-figure"
              src={toAbsoluteUrl('/assets/images/400x500.png')}
              alt=""
              style={{
                width: '100%',
                height: '400px',
                objectFit: 'cover',
              }}
            />

            <div className="item-content">
              <span className="item-content__name">John Doe</span>
              <span className="item-content__department">Department</span>
            </div>
          </div>

          <div className="item">
            <img
              className="item-figure"
              src={toAbsoluteUrl('/assets/images/400x500.png')}
              alt=""
              style={{
                width: '100%',
                height: '400px',
                objectFit: 'cover',
              }}
            />

            <div className="item-content">
              <span className="item-content__name">John Doe</span>
              <span className="item-content__department">Department</span>
            </div>
          </div>

          <div className="item">
            <img
              className="item-figure"
              src={toAbsoluteUrl('/assets/images/400x500.png')}
              alt=""
              style={{
                width: '100%',
                height: '400px',
                objectFit: 'cover',
              }}
            />

            <div className="item-content">
              <span className="item-content__name">John Doe</span>
              <span className="item-content__department">Department</span>
            </div>
          </div>
        </div>
      </div>
    </ElementWrapper>
  );
};
