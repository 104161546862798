import {
  defaultCustomer,
  ICustomer,
} from '../../../../customer-management/models/Customer';
import { ElementType, IElement } from '../../../models/Element';

export type PersonHighlightExperience = IElement & {
  customer: ICustomer;
  title: string;
  heading: string;
  description: string;
  button_url: string;
  button_title: string;
};

export const defaultPersonHighlightExperience: PersonHighlightExperience = {
  id: 0,
  name: ElementType.PERSON_HIGHLIGHT_EXPERIENCE,
  customer: {
    ...defaultCustomer,
    name: 'John Doe',
    business_name: 'Company ABC',
  },
  title: 'Klant aan het woord',
  heading: 'Een geweldig resultaat!',
  description:
    "Voor DS covers heeft Tussendoor een geweldige goed werkende configurator gerealiseerd. For athletes, high altitude produces two contradictory effects on performance. For explosive events the reduction in atmospheric pressure means there is less resistance from the atmosphere and the athlete's performance will generally be better at high altitude.",
  button_url: '',
  button_title: 'Live bekijken',
};
