import { PageLink } from '../../../_start/layout/core';

export const pageManagementSubmenu: Array<PageLink> = [
  {
    title: 'Pages',
    path: '/page-management/pages',
    isActive: true,
  },
  {
    title: 'Case Details',
    path: '/page-management/case-details',
    isActive: true,
  },
  {
    title: 'Case Categories',
    path: '/page-management/case-categories',
    isActive: true,
  },
  {
    title: 'Tags',
    path: '/page-management/tags',
    isActive: true,
  },
  {
    title: 'FAQs',
    path: '/page-management/faqs',
    isActive: true,
  },
  {
    title: 'FAQ Categories',
    path: '/page-management/faq-categories',
    isActive: true,
  },
];
