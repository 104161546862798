import { ElementType, IElement } from '../../../models/Element';

export type ShowcaseWithTextRight = IElement & {
  title: string;
  description: string;
  image_preview: string;
  image_preview_alt: string;
  image_caption: string;
};

export const defaultShowcaseWithTextRight: ShowcaseWithTextRight = {
  id: 0,
  name: ElementType.SHOWCASE_WITH_TEXT_RIGHT,
  title: 'Documentatie, whitepaper & inbouwservice!',
  description:
    'Twijfel je of de "WordPress Makelaars plugin" (geschikt voor Realworks) wel is wat je zoekt? Benieuwd naar alle mogelijkheden? Download dan onze PDF met meer informatie. Alle specificaties, voorbeelden en een demo vind je in dit document. Hierin vind je ook direct een klein deel van de uitgebreide handleiding die wordt geleverd bij aanschaf van de plugin.',
  image_preview: '',
  image_preview_alt: '',
  image_caption: 'Foto door: Olaf the Great',
};
