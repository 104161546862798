import { useRef, useState } from 'react';

import { MediaLibraryCollection } from '@spatie/media-library-pro-react-collection';

import { MediaLibrary } from '@spatie/media-library-pro-core/dist/types';
import { MediaLibrary as MediaLibraryClass } from '@spatie/media-library-pro-core/dist';
import axios from 'axios';
import { API_URL, WEB_URL } from '../../../../support/api';

type UploadMediaProps = {
  loading: boolean;
  handleFetchList: () => void;
};

export const UploadMedia = ({ loading, handleFetchList }: UploadMediaProps) => {
  const [value, setValue] = useState<{
    name: string;
    media: {
      [uuid: string]: MediaLibrary.MediaAttributes;
    };
  }>({
    name: '',
    media: {},
  });

  const [validationErrors, setValidationErrors] = useState<
    MediaLibrary.State['validationErrors']
  >({});
  // const [isUploadSuccess, setIsUploadSuccess] = useState(false);

  const mediaLibrary = useRef<MediaLibraryClass | null>(null);

  const handleSubmit = async () => {
    // setIsUploadSuccess(false);
    setValidationErrors({});

    try {
      const result = await axios.post(
        `${API_URL}/v1/media-library/store`,
        value
      );

      if (result.data.value.success) {
        // setIsUploadSuccess(true);
        setValue({ name: '', media: {} });

        mediaLibrary.current?.changeState((state) => {
          state.media = [];
        });
      }

      handleFetchList();
    } catch (error) {
      const resultError: any = error;

      console.error(resultError);

      if (resultError && resultError.response && resultError.response.data) {
        setValidationErrors(resultError.response.data.errors);
      }
    }
  };

  return (
    <div
      className={`card card-custom card-flush ${
        loading ? 'overlay overlay-block' : ''
      }`}
    >
      <div className="pt-8 card-header">
        <h3 className="card-title">Upload media</h3>
      </div>

      <div className={`card-body py-5 ${loading ? 'overlay-wrapper' : ''}`}>
        <div className="grid gap-8 justify-content-start">
          <div className="w-100">
            <MediaLibraryCollection
              name="media"
              routePrefix={`tussendoor-media`}
              uploadDomain={WEB_URL}
              validationRules={{
                accept: ['image/png', 'image/jpeg', 'image/webp', 'image/gif'],
              }}
              validationErrors={validationErrors}
              setMediaLibrary={(mediaLib) => (mediaLibrary.current = mediaLib)}
              onChange={(media) =>
                setValue((value) => ({
                  ...value,
                  media,
                }))
              }
              fieldsView={({
                getCustomPropertyInputProps,
                getCustomPropertyInputErrors,
                getNameInputErrors,
                getNameInputProps,
              }) => (
                <div className="media-library-properties">
                  <div className="media-library-field">
                    <label className="media-library-label">Name</label>
                    <input
                      className="media-library-input"
                      {...getNameInputProps()}
                      {...{
                        dusk: 'media-library-field-name',
                      }}
                    />

                    {getNameInputErrors().map((error) => (
                      <p key={error} className="media-library-text-error">
                        {error}
                      </p>
                    ))}
                  </div>

                  <div className="media-library-field">
                    <label className="media-library-label">Alt text</label>
                    <input
                      className="media-library-input"
                      {...getCustomPropertyInputProps('alt_text')}
                      {...{
                        dusk: 'media-library-extra-field',
                      }}
                    />

                    {getCustomPropertyInputErrors('alt_text').map(
                      (error: string) => (
                        <p key={error} className="media-library-text-error">
                          {error}
                        </p>
                      )
                    )}
                  </div>

                  <div className="media-library-field">
                    <label className="media-library-label">Caption</label>
                    <input
                      className="media-library-input"
                      {...getCustomPropertyInputProps('caption')}
                      {...{
                        dusk: 'media-library-extra-field',
                      }}
                    />

                    {getCustomPropertyInputErrors('caption').map(
                      (error: string) => (
                        <p key={error} className="media-library-text-error">
                          {error}
                        </p>
                      )
                    )}
                  </div>
                </div>
              )}
            ></MediaLibraryCollection>
          </div>

          <button
            type="button"
            className="mt-5 btn btn-info btn-sm"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};
