import React from 'react';
import { FormImageFieldModal } from '../../../../media-management/components/form-image/FormImageFieldModal';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { IElement } from '../../../models/Element';
import { PluginLeftImageHighlightInfo } from './PluginLeftImageHighlightInfo';
import { PluginLeftImageHighlightInfoContent } from './PluginLeftImageHighlightInfoContent';
import { PluginLeftImageHighlightInfoFigure } from './PluginLeftImageHighlightInfoFigure';

type Props = {
  index: number;
  data: PluginLeftImageHighlightInfo;
  updateData: (element: IElement, index: number) => void;
};

export const PluginLeftImageHighlightInfoFC: React.FC<Props> = ({
  index,
  data,
  updateData,
}) => {
  const update = (fieldsToUpdate: Partial<PluginLeftImageHighlightInfo>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };
  return (
    <>
      <FormImageFieldModal
        modalId={`${data.name}-${data.id}`}
        name={`image`}
        altText={`image_alt`}
        update={update}
      />

      <ElementWrapper elementIndex={index}>
        <div className="plugin-left-image-highlight-info">
          <div className="background" />
          <div className="showcase">
            <PluginLeftImageHighlightInfoContent data={data} update={update} />
            <PluginLeftImageHighlightInfoFigure data={data} update={update} />
          </div>
        </div>
      </ElementWrapper>
    </>
  );
};
