/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { failureToast, successToast } from '../../../../support/utils';
import { FileField } from '../../../media-management/components/file/FileField';
import { IModule } from '../../models/Module';
import { defaultRelease, IRelease } from '../../models/Release';
import { getAllModule } from '../../redux/ModuleCRUD';
import { createRelease } from '../../redux/ReleaseCRUD';

export const CreateRelease = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<IRelease>(defaultRelease);

  const updateData = (fieldsToUpdate: Partial<IRelease>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  const [modules, setModules] = useState<Array<IModule>>([]);

  const handleInitialization = async () => {
    setLoading(true);

    try {
      const [modulesResult] = await Promise.all([getAllModule()]);

      if (modulesResult.length) {
        updateData({
          module_id: modulesResult[0].id,
        });
      }

      setModules(modulesResult);

      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleInitialization();
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);

    try {
      await createRelease(data);
      successToast('Release has been created.');
      setData(defaultRelease);
      history.goBack();
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Create Release</h3>
      </div>
      {/* begin::Form */}
      <form onSubmit={handleSubmit} className="form d-flex flex-center">
        <div className="card-body mw-800px py-20">
          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Module</label>
            <div className="col-lg-9">
              <select
                className="form-select form-select-lg form-select-solid text-capitalize"
                data-control="select2"
                data-placeholder="Select Language..."
                value={data.module_id}
                onChange={(e) =>
                  updateData({
                    module_id: parseInt(e.target.value),
                  })
                }
              >
                {modules.map((element, idx) => (
                  <option value={element.id} key={idx}>
                    {element.title}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Link</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="https://example.com"
                  className="form-control form-control-lg form-control-solid"
                  type="url"
                  value={data.link}
                  onChange={(e) =>
                    updateData({
                      link: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Version Number</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter version number"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.version_number}
                  onChange={(e) =>
                    updateData({
                      version_number: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Change Log</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <textarea
                  rows={2}
                  placeholder="Enter change log"
                  className="form-control form-control-lg form-control-solid"
                  value={data.change_log}
                  onChange={(e) =>
                    updateData({
                      change_log: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-20">
            <label className="col-lg-3 col-form-label">Documentation URL</label>
            <div className="col-lg-9">
              <FileField
                name={`documentation_url`}
                src={data.documentation_url}
                update={updateData}
                modalId={`documentation_url_modal`}
              />
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-20">
            <label className="col-lg-3 col-form-label">Download URL</label>
            <div className="col-lg-9">
              <FileField
                name={`download_url`}
                src={data.download_url}
                update={updateData}
                modalId={`download_url_modal`}
              />
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row">
            <label className="col-lg-3 col-form-label"></label>
            <div className="col-lg-9">
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary fw-bolder px-6 py-3 me-3"
              >
                {!loading && <span className="indicator-label">Save</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Please wait...{' '}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
              <Link
                className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3"
                to={`/module-management/releases`}
              >
                Cancel
              </Link>
            </div>
          </div>
          {/* end::Form row */}
        </div>
      </form>
      {/* end::Form */}
    </div>
  );
};
