/* eslint-disable jsx-a11y/anchor-is-valid */
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toAbsoluteUrl } from '../../../../../../_start/helpers';
import { FormImageFieldModal } from '../../../../media-management/components/form-image/FormImageFieldModal';
import { ButtonTooltip } from '../../../components/ButtonTooltip';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { InlineEdit } from '../../../components/InlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { IElement } from '../../../models/Element';
import { PluginRightScreenshotsInfo } from './PluginRightScreenshotsInfo';

type WebsiteScreenshotWithTextRightFCProps = {
  index: number;
  data: PluginRightScreenshotsInfo;
  updateData: (body: IElement, index: number) => void;
};

export const PluginRightScreenshotsInfoFC = ({
  index,
  data,
  updateData,
}: WebsiteScreenshotWithTextRightFCProps) => {
  const update = (fieldsToUpdate: Partial<PluginRightScreenshotsInfo>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  const webFigure = () => {
    return (
      <div className="screen screen--dark">
        {data.image_preview ? (
          <div className="overlay">
            <div className="overlay-wrapper">
              <img
                alt="dummy"
                src={data.image_preview}
                style={{
                  width: '100%',

                  objectFit: 'cover',
                }}
              />
            </div>
            <div className="overlay-layer bg-dark bg-opacity-10 flex-column">
              <div>
                <button
                  name="image_preview"
                  className="btn btn-primary btn-shadow"
                  onClick={() =>
                    update({
                      image_preview: '',
                      image_preview_alt: '',
                    })
                  }
                >
                  Remove
                </button>
                <label
                  className="btn btn-light-primary btn-shadow ms-2"
                  data-bs-toggle="modal"
                  data-bs-target={`#${data.name}-${data.id}-web`}
                >
                  Change
                </label>
              </div>

              <input
                placeholder="Alt attribute"
                className="w-50 mt-2 form-control form-control-lg form-control-solid"
                type="text"
                value={data.image_preview_alt ?? ''}
                onChange={(event) =>
                  update({ image_preview_alt: event.target.value })
                }
              />
            </div>
          </div>
        ) : (
          <>
            <label
              data-bs-toggle="modal"
              data-bs-target={`#${data.name}-${data.id}-web`}
            >
              <img
                src={toAbsoluteUrl('/assets/images/900x630.png')}
                alt=""
                style={{
                  width: '100%',

                  objectFit: 'cover',
                }}
              />
            </label>
          </>
        )}
      </div>
    );
  };

  const phoneFigure = () => {
    return (
      <div
        className="smartphone smartphone--dark smartphone--inside smartphone--scale"
        style={{
          bottom: '-30px',
          right: 0,
          width: '35%',
          height: '75%',
        }}
      >
        {data.phone_preview ? (
          <div className="overlay" style={{ height: '100%' }}>
            <div
              className="overlay-wrapper"
              style={{ width: '100%', height: '100%' }}
            >
              <img
                alt="dummy"
                src={data.phone_preview}
                style={{
                  objectFit: 'cover',
                }}
              />
            </div>
            <div className="overlay-layer bg-dark bg-opacity-10 flex-column">
              <div>
                <button
                  name="phone_preview"
                  className="btn btn-primary btn-shadow"
                  onClick={() =>
                    update({
                      phone_preview: '',
                      phone_preview_alt: '',
                    })
                  }
                >
                  Remove
                </button>
                <label
                  className="btn btn-light-primary btn-shadow ms-2"
                  data-bs-toggle="modal"
                  data-bs-target={`#${data.name}-${data.id}-phone`}
                >
                  Change
                </label>
              </div>

              <input
                placeholder="Alt attribute"
                className="w-50 mt-2 form-control form-control-lg form-control-solid"
                type="text"
                value={data.image_preview_alt ?? ''}
                onChange={(event) =>
                  update({ image_preview_alt: event.target.value })
                }
              />
            </div>
          </div>
        ) : (
          <>
            <label
              data-bs-toggle="modal"
              data-bs-target={`#${data.name}-${data.id}-phone`}
            >
              <img
                src={toAbsoluteUrl('/assets/images/400x679.png')}
                alt=""
                style={{
                  width: '100%',
                  objectFit: 'cover',
                }}
              />
            </label>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <FormImageFieldModal
        modalId={`${data.name}-${data.id}-web`}
        name={`image_preview`}
        altText={`image_preview_alt`}
        update={update}
      />

      <FormImageFieldModal
        modalId={`${data.name}-${data.id}-phone`}
        name={`phone_preview`}
        altText={`phone_preview_alt`}
        update={update}
      />

      <ElementWrapper elementIndex={index}>
        <div className="plugin-right-screenshots-info">
          <div className="showcase">
            {/* begin::Content */}
            <div className="plugin-left-screenshots-info__content">
              <TextAreaEdit
                name="title"
                className="content-title"
                value={data.title}
                setValue={update}
              />

              <CKEditor
                className="content-description"
                editor={ClassicEditor}
                data={data.description}
                // @ts-ignore
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  // console.log('Editor is ready to use!', editor);
                }}
                // @ts-ignore
                onChange={(event, editor) => {
                  const editorData = editor.getData();

                  update({ description: editorData });
                }}
                // @ts-ignore
                onBlur={(event, editor) => {
                  //   console.log('Blur.', editor);
                }}
                // @ts-ignore
                onFocus={(event, editor) => {
                  //   console.log('Focus.', editor);
                }}
              />
              {/* begin::Actions */}
              <div className="buttons">
                <ButtonTooltip
                  name={`button_url`}
                  className="content-action__button"
                  value={data.button_url}
                  setValue={update}
                >
                  <button className="btn btn-success rounded-pill">
                    <InlineEdit
                      name="button_title"
                      className="px-1 text-white text-center"
                      value={data.button_title}
                      setValue={update}
                      activeVerticalPadding={'py-1'}
                      dynamicWidth
                      style={{
                        maxWidth: '200px',
                      }}
                    />
                  </button>
                </ButtonTooltip>
              </div>
              {/* end::Actions */}
            </div>
            {/* end::Content */}

            {/* begin::Figure */}
            <div className="plugin-left-screenshots-info__figure">
              {webFigure()}
              {phoneFigure()}
            </div>
            {/* end::Figure */}
          </div>
        </div>
      </ElementWrapper>
    </>
  );
};
