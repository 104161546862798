/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { RootState } from '../../../../setup';
import { FormImageField } from '../../media-management/components/form-image/FormImageField';
import { defaultCustomer, ICustomer } from '../models/Customer';
import * as customerActions from '../redux/CustomerActions';
import { checkImageInput } from './CreateCustomerPage';

export const UpdateCustomerPage = () => {
  const { customer_id } = useParams<{ customer_id: string }>();

  const dispatch = useDispatch();

  const handleFetch = (id: string) => {
    dispatch(customerActions.viewCustomerRequest({ customer_id: id }));
  };

  useEffect(() => {
    handleFetch(customer_id);
  }, []);

  const loading = useSelector(
    (state: RootState) => state.customerManagement.loading
  );
  const viewedCustomer = useSelector(
    (state: RootState) => state.customerManagement.viewedCustomer
  );

  const [data, setData] = useState<ICustomer>(defaultCustomer);

  useEffect(() => {
    if (viewedCustomer.id !== 0) {
      setData(viewedCustomer);
    }
  }, [viewedCustomer]);

  const updateData = (fieldsToUpdate: Partial<ICustomer>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (checkImageInput(data)) {
      return;
    }

    dispatch(customerActions.updateCustomerRequest({ customer: data }));
  };

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Update Customer</h3>
      </div>

      {/* begin::Form */}
      <form onSubmit={handleSubmit} className="form d-flex flex-center">
        <div className="card-body mw-800px py-20">
          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Name</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter name"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.name}
                  onChange={(e) => updateData({ name: e.target.value })}
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Business name</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter business name"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.business_name}
                  onChange={(e) =>
                    updateData({ business_name: e.target.value })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-20">
            <label className="col-lg-3 col-form-label">Person Image</label>
            <div className="col-lg-9">
              <FormImageField
                name={`person_image`}
                src={data.person_image}
                update={updateData}
                modalId={`person_image_modal`}
                imageWidth={`auto`}
                imageHeight={`auto`}
              />
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-20">
            <label className="col-lg-3 col-form-label">Logo</label>
            <div className="col-lg-9">
              <FormImageField
                name={`logo`}
                src={data.logo}
                update={updateData}
                modalId={`logo_modal`}
                imageWidth={`auto`}
                imageHeight={`auto`}
              />
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-20">
            <label className="col-lg-3 col-form-label">Branding Image</label>
            <div className="col-lg-9">
              <FormImageField
                name={`branding_image`}
                src={data.branding_image}
                update={updateData}
                modalId={`branding_image_modal`}
                imageWidth={`100%`}
                imageHeight={`auto`}
              />
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row">
            <label className="col-lg-3 col-form-label"></label>
            <div className="col-lg-9">
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary fw-bolder px-6 py-3 me-3"
              >
                {!loading && <span className="indicator-label">Save</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Please wait...{' '}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
              <Link
                className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3"
                to="/customer-management/customers"
              >
                Cancel
              </Link>
            </div>
          </div>
          {/* end::Form row */}
        </div>
      </form>
      {/* end::Form */}
    </div>
  );
};
