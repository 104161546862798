import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import Select, { MultiValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import {
  customFailureToast,
  failureToast,
  successToast,
} from '../../../../support/utils';

import { convertToSlug } from '../../../../support/helpers';

import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { FormImageField } from '../../../media-management/components/form-image/FormImageField';
import {
  defaultTutorial,
  ITutorial,
  SchemaArticleType,
  SchemaPageType,
  StatusType,
} from '../../models/Tutorial';
import { ITutorialCategory } from '../../models/TutorialCategory';
import {
  updateTutorial,
  UpdateTutorialPayload,
  viewTutorial,
} from '../../redux/TutorialsCRUD';
import { getAllCategory } from '../../redux/TutorialCategoriesCRUD';
import {
  defaultRobotTypeOption,
  defaultSEOTitleTypeOption,
  RobotTypeOption,
  SEOTitleTypeOption,
} from '../../../post-management/pages/Post/CreatePost';
// import { FormImageField } from "../../../media-management/components/form-image/FormImageField";

export const UpdateTutorial = () => {
  const { id } = useParams<{ id: string }>();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<ITutorial>(defaultTutorial);

  const updateData = (fieldsToUpdate: Partial<ITutorial>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  const [categories, setCategories] = useState<Array<ITutorialCategory>>([]);
  const [selectedCategories, setSelectedCategories] = useState<
    MultiValue<ITutorialCategory>
  >([]);

  const seoTitles: Array<SEOTitleTypeOption> = defaultSEOTitleTypeOption;
  const [selectedSeoTitles, setSelectedSeoTitles] = useState<
    MultiValue<SEOTitleTypeOption>
  >(defaultSEOTitleTypeOption);

  const robots: Array<RobotTypeOption> = defaultRobotTypeOption;
  const [selectedRobots, setSelectedRobots] = useState<
    MultiValue<RobotTypeOption>
  >([]);

  const handleFetch = async (id: string) => {
    setLoading(true);

    try {
      const [categories, tutorial] = await Promise.all([
        getAllCategory(),
        viewTutorial(id),
      ]);

      setCategories(categories);
      setData({ ...tutorial, publish_date: new Date(tutorial.publish_date) });

      setSelectedCategories(tutorial.categories);
      setSelectedSeoTitles(
        tutorial.seo_title.map((seoTitle) => ({
          value: seoTitle,
          label: seoTitle,
        }))
      );
      setSelectedRobots(
        tutorial.robots.map((robot) => ({
          value: robot,
          label: robot,
        }))
      );

      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      handleFetch(id);
    }
  }, [id]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);

    if (!selectedCategories) {
      customFailureToast({
        title: 'The given data is invalid',
        message: 'Category is required',
      });
      setLoading(false);
      return;
    }

    try {
      const payload: UpdateTutorialPayload = {
        ...data,
        tutorial_categories: selectedCategories.map((i) => i.id),
        publish_date: format(data.publish_date as Date, 'yyyy-MM-dd HH:mm:ss'),
        seo_title: selectedSeoTitles.map((seoTitle) => seoTitle.value),
        robots: selectedRobots.map((robot) => robot.value),
      };

      await updateTutorial(payload);
      successToast('Tutorial has been updated.');
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Update Post</h3>
      </div>
      {/* begin::Form */}
      <form onSubmit={handleSubmit} className="form d-flex flex-center">
        <div className="card-body mw-800px py-20">
          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Title</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter name"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.title}
                  onChange={(e) =>
                    updateData({
                      title: e.target.value,
                      slug: convertToSlug(e.target.value),
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Slug</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter slug"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.slug}
                  onChange={(e) => updateData({ slug: e.target.value })}
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Status</label>
            <div className="col-lg-9">
              <select
                className="form-select form-select-lg form-select-solid text-capitalize"
                data-control="select2"
                data-placeholder="Select Status..."
                value={data.status}
                onChange={(e) =>
                  updateData({
                    status: e.target.value as StatusType,
                  })
                }
              >
                <option value={StatusType.DRAFT}>Draft</option>
                <option value={StatusType.PUBLISHED}>Published</option>
                <option value={StatusType.FUTURE}>Future</option>
              </select>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Publish Date</label>
            <div className="col-lg-9">
              <DatePicker
                selected={data.publish_date as Date}
                onChange={(date: Date) => updateData({ publish_date: date })}
                showTimeSelect
                timeFormat="HH:mm"
                dateFormat="MMMM d, yyyy HH:mm"
                className="w-100 form-control form-control-lg form-control-solid"
              />
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Category</label>
            <div className="col-lg-9">
              <Select
                placeholder={`Select categories...`}
                value={selectedCategories}
                onChange={setSelectedCategories}
                getOptionValue={(model: ITutorialCategory) =>
                  model.id.toString()
                }
                getOptionLabel={(model: ITutorialCategory) => model.name}
                options={categories}
                isSearchable
                isMulti
                isClearable
              />
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-20">
            <label className="col-lg-3 col-form-label">Image Preview</label>
            <div className="col-lg-9">
              <FormImageField
                name={`image_preview`}
                altText={`image_preview_alt_text`}
                src={data.image_preview}
                update={updateData}
                modalId={`image_preview_modal`}
              />
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">
              Image Preview Alt Attribute
            </label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter alt attribute"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.image_preview_alt_text}
                  onChange={(e) =>
                    updateData({
                      image_preview_alt_text: e.target.value,
                    })
                  }
                  required={data.image_preview !== ''}
                  disabled={data.image_preview === ''}
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Description</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <textarea
                  rows={2}
                  placeholder="Enter description"
                  className="form-control form-control-lg form-control-solid"
                  value={data.description}
                  onChange={(e) =>
                    updateData({
                      description: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          <div className={`separator my-10`}></div>

          <div className="row mb-8">
            <div className="col-lg-12 col-form-label text-center">SEO</div>
          </div>

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">SEO Title</label>
            <div className="col-lg-9">
              <CreatableSelect
                placeholder={`Select SEO title/s ...`}
                value={selectedSeoTitles}
                onChange={setSelectedSeoTitles}
                options={seoTitles}
                isMulti
                isSearchable
                isClearable
              />
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Robots</label>
            <div className="col-lg-9">
              <Select
                closeMenuOnSelect={false}
                placeholder={`Select robot/s ...`}
                value={selectedRobots}
                onChange={setSelectedRobots}
                options={robots}
                isMulti
                isSearchable
                isClearable
              />
            </div>
          </div>
          {/* end::Form row */}

          <div className={`separator my-10`}></div>

          <div className="row mb-8">
            <div className="col-lg-12 col-form-label text-center">SCHEMA</div>
          </div>

          {/* begin::Form row */}
          <div className={`row mb-8`}>
            <label className="col-lg-3 col-form-label">Page Type</label>
            <div className="col-lg-9">
              <select
                className="form-select form-select-lg form-select-solid"
                data-control="select2"
                data-placeholder="Select Package..."
                value={data.schema_page_type}
                onChange={(e) =>
                  updateData({
                    schema_page_type: e.target.value as SchemaPageType,
                  })
                }
              >
                <option value={SchemaPageType.WEB_PAGE}>
                  {SchemaPageType.WEB_PAGE}
                </option>
                <option value={SchemaPageType.ITEM_PAGE}>
                  {SchemaPageType.ITEM_PAGE}
                </option>
                <option value={SchemaPageType.ABOUT_PAGE}>
                  {SchemaPageType.ABOUT_PAGE}
                </option>
                <option value={SchemaPageType.FAQ_PAGE}>
                  {SchemaPageType.FAQ_PAGE}
                </option>
                <option value={SchemaPageType.QA_PAGE}>
                  {SchemaPageType.QA_PAGE}
                </option>
                <option value={SchemaPageType.PROFILE_PAGE}>
                  {SchemaPageType.PROFILE_PAGE}
                </option>
                <option value={SchemaPageType.CONTACT_PAGE}>
                  {SchemaPageType.CONTACT_PAGE}
                </option>
                <option value={SchemaPageType.MEDICAL_WEB_PAGE}>
                  {SchemaPageType.MEDICAL_WEB_PAGE}
                </option>
                <option value={SchemaPageType.COLLECTION_PAGE}>
                  {SchemaPageType.COLLECTION_PAGE}
                </option>
                <option value={SchemaPageType.CHECKOUT_PAGE}>
                  {SchemaPageType.CHECKOUT_PAGE}
                </option>
                <option value={SchemaPageType.REAL_ESTATE_LISTING}>
                  {SchemaPageType.REAL_ESTATE_LISTING}
                </option>
                <option value={SchemaPageType.SEARCH_RESULT_PAGE}>
                  {SchemaPageType.SEARCH_RESULT_PAGE}
                </option>
              </select>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className={`row mb-8`}>
            <label className="col-lg-3 col-form-label">Article Type</label>
            <div className="col-lg-9">
              <select
                className="form-select form-select-lg form-select-solid"
                data-control="select2"
                data-placeholder="Select Package..."
                value={data.schema_article_type}
                onChange={(e) =>
                  updateData({
                    schema_article_type: e.target.value as SchemaArticleType,
                  })
                }
              >
                <option value={SchemaArticleType.ARTICLE}>
                  {SchemaArticleType.ARTICLE}
                </option>
                <option value={SchemaArticleType.BLOG_POST}>
                  {SchemaArticleType.BLOG_POST}
                </option>
                <option value={SchemaArticleType.SOCIAL_MEDIA_POSTING}>
                  {SchemaArticleType.SOCIAL_MEDIA_POSTING}
                </option>
                <option value={SchemaArticleType.NEWS_ARTICLE}>
                  {SchemaArticleType.NEWS_ARTICLE}
                </option>
                <option value={SchemaArticleType.ADVISER_CONTENT_ARTICLE}>
                  {SchemaArticleType.ADVISER_CONTENT_ARTICLE}
                </option>
                <option value={SchemaArticleType.SATIRICAL_ARTICLE}>
                  {SchemaArticleType.SATIRICAL_ARTICLE}
                </option>
                <option value={SchemaArticleType.SCHOLARLY_ARTICLE}>
                  {SchemaArticleType.SCHOLARLY_ARTICLE}
                </option>
                <option value={SchemaArticleType.TECH_ARTICLE}>
                  {SchemaArticleType.TECH_ARTICLE}
                </option>
                <option value={SchemaArticleType.REPORT}>
                  {SchemaArticleType.REPORT}
                </option>
                <option value={SchemaArticleType.NONE}>
                  {SchemaArticleType.NONE}
                </option>
              </select>
            </div>
          </div>
          {/* end::Form row */}

          <div className={`separator my-10`}></div>

          <div className="row mb-8">
            <div className="col-lg-12 col-form-label text-center">
              Social - Facebook
            </div>
          </div>

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Facebook Title</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter facebook title"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.facebook_title}
                  onChange={(e) =>
                    updateData({
                      facebook_title: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">
              Facebook Description
            </label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <textarea
                  rows={2}
                  placeholder="Enter facebook description"
                  className="form-control form-control-lg form-control-solid"
                  value={data.facebook_description}
                  onChange={(e) =>
                    updateData({
                      facebook_description: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-20">
            <label className="col-lg-3 col-form-label">Facebook Image</label>
            <div className="col-lg-9">
              <FormImageField
                name={`facebook_image`}
                src={data.facebook_image}
                update={updateData}
                modalId={`facebook_image_modal`}
              />
            </div>
          </div>
          {/* end::Form row */}

          <div className={`separator my-10`}></div>

          <div className="row mb-8">
            <div className="col-lg-12 col-form-label text-center">
              Social - Twitter
            </div>
          </div>

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Twitter Title</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter twitter title"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.twitter_title}
                  onChange={(e) =>
                    updateData({
                      twitter_title: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">
              Twitter Description
            </label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <textarea
                  rows={2}
                  placeholder="Enter twitter description"
                  className="form-control form-control-lg form-control-solid"
                  value={data.twitter_description}
                  onChange={(e) =>
                    updateData({
                      twitter_description: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-20">
            <label className="col-lg-3 col-form-label">Twitter Image</label>
            <div className="col-lg-9">
              <FormImageField
                name={`twitter_image`}
                src={data.twitter_image}
                update={updateData}
                modalId={`twitter_image_modal`}
              />
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row">
            <label className="col-lg-3 col-form-label"></label>
            <div className="col-lg-9">
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary fw-bolder px-6 py-3 me-3"
              >
                {!loading && <span className="indicator-label">Save</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Please wait...{' '}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
              <Link
                className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3"
                to={`/tutorial-management/tutorials`}
              >
                Cancel
              </Link>
            </div>
          </div>
          {/* end::Form row */}
        </div>
      </form>
      {/* end::Form */}
    </div>
  );
};
