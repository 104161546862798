import axios from 'axios';
import { API_URL } from '../../../support/api';
import { PaginatedValue } from '../../../support/utils';
import { ICategory } from '../../post-management/models/Category';

export const BASE_CATEGORY_URL = `${API_URL}/v1/management/page-management/categories`;
export const GET_ALL_CATEGORY_URL = `${API_URL}/v1/management/page-management/categories/get-all`;
export const CREATE_CATEGORY_URL = `${API_URL}/v1/management/page-management/categories/create`;

export const listCategory = async (search = '', direction = 'asc') => {
  const URL = `${BASE_CATEGORY_URL}/list?search=${search}&direction=${direction}`;
  const {
    data: { value },
  } = await axios.get<{ value: PaginatedValue<ICategory> }>(URL);

  return value;
};

export const createCategory = async (payload: ICategory) => {
  const {
    data: { value },
  } = await axios.post<{ value: ICategory }>(CREATE_CATEGORY_URL, payload);

  return value;
};

export const viewCategory = async (id: number | string) => {
  const {
    data: { value },
  } = await axios.get<{ value: ICategory }>(`${BASE_CATEGORY_URL}/${id}/get`);

  return value;
};

export const updateCategory = async (payload: ICategory) => {
  const {
    data: { value },
  } = await axios.post<{ value: ICategory }>(
    `${BASE_CATEGORY_URL}/${payload.id}/update`,
    payload
  );

  return value;
};

export const deleteCategory = async (payload: ICategory) => {
  const {
    data: { value },
  } = await axios.post<{ value: ICategory }>(
    `${BASE_CATEGORY_URL}/${payload.id}/delete`,
    payload
  );

  return value;
};

// unpaginated categories
export const getAllCategory = async () => {
  const {
    data: { value },
  } = await axios.get<{ value: Array<ICategory> }>(GET_ALL_CATEGORY_URL);

  return value;
};
