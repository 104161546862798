/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { IFAQ } from '../../../../page-management/models/FAQ';
import {
  defaultFAQNarrowItem,
  FAQSelectNarrow,
  getAllFAQs,
} from './FAQSelectNarrow';
import { FAQSelectNarrowListItem } from './FAQSelectNarrowListItem';

interface FAQListProps {
  data: FAQSelectNarrow;
  update: (fieldsToUpdate: Partial<FAQSelectNarrow>) => void;
}

export const FAQSelectNarrowList: React.FC<FAQListProps> = ({
  data,
  update,
}) => {
  const updateData = (fieldsToUpdate: Partial<IFAQ>, index: number) => {
    const items = [...data.item_list];
    items[index] = { ...items[index], ...fieldsToUpdate };
    update({ ...data, item_list: items });
  };

  const [faqs, setFaqs] = useState<Array<IFAQ>>([]);
  const [loading, setLoading] = useState(true);

  const fetchFAQs = async () => {
    setLoading(true);
    const dataF = await getAllFAQs();
    setFaqs(
      dataF.filter((f) => data.item_list.map((i) => i.id).indexOf(f.id) !== -1)
    );
    setLoading(false);
  };

  useEffect(() => {
    fetchFAQs();
  }, []);

  const addItemRef = useRef<HTMLDivElement>(null);

  const addListItem = () => {
    const items = [...data.item_list];
    items.push(defaultFAQNarrowItem);
    update({ ...data, item_list: items });
  };

  const remove = (index: number) => {
    const items = [...data.item_list];
    items.splice(index, 1);
    update({ ...data, item_list: items });
  };

  const onMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    // removeButtonRef.current?.classList.remove('d-none');
    // addItemRef.current?.classList.remove("d-none");

    if (event.currentTarget === event.target) {
      event.stopPropagation();
    }

    event.currentTarget.style.cursor = 'pointer';
  };

  const onMouseLeave = (event: React.MouseEvent<HTMLDivElement>) => {
    // removeButtonRef.current?.classList.add('d-none');
    // addItemRef.current?.classList.add("d-none");
    event.currentTarget.style.cursor = 'auto';
  };

  if (loading) {
    return (
      <div className="w-100 h-100 d-flex rounded border-gray-400 border-dashed border-2">
        <div className="m-auto d-flex flex-column">Loading...</div>
      </div>
    );
  }
  return (
    <div
      className="specializations-faq__list"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {data.item_list.map((item, index) => (
        <div>
          <FAQSelectNarrowListItem
            index={index}
            data={item}
            update={updateData}
            remove={remove}
            faqs={faqs}
          />
        </div>
      ))}
      {/* <div
        ref={addItemRef}
        onClick={addListItem}
        style={{
          minHeight: "68px",
        }}
        className="w-100 h-100 d-flex rounded border-gray-400 border-dashed border-2"
      >
        <div className="m-auto d-flex flex-column">
          <KTSVG
            path="/media/icons/duotone/Interface/Plus-Square.svg"
            className="svg-icon-muted svg-icon-3hx text-center"
          />
        </div>
      </div> */}
    </div>
  );
};
