/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Select, { MultiValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { convertToSlug } from '../../../support/helpers';
import { failureToast, successToast } from '../../../support/utils';

import { defaultPage, IPage, PageStatusType } from '../models/Page';
import {
  getAllParentPages,
  updatePage,
  UpdatePagePayload,
  viewPage,
} from '../redux/PageCRUD';
import { format } from 'date-fns';
import {
  SchemaArticleType,
  SchemaPageType,
} from '../../post-management/models/Post';
import {
  defaultRobotTypeOption,
  defaultSEOTitleTypeOption,
  RobotTypeOption,
  SEOTitleTypeOption,
} from '../../post-management/pages/Post/CreatePost';
import { FormImageField } from '../../media-management/components/form-image/FormImageField';

export const UpdatePage = () => {
  const { id } = useParams<{ id: string }>();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<IPage>(defaultPage);

  const [parentPages, setParentPages] = useState<IPage[]>([]);

  const [isParent, setIsParent] = useState(data.is_parent);

  const updateData = (fieldsToUpdate: Partial<IPage>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  const fetchParentPages = async () => {
    try {
      setLoading(true);
      const pages = await getAllParentPages();
      setParentPages(pages);
      setLoading(false);
    } catch (e) {
      failureToast(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchParentPages();
  }, []);

  useEffect(() => {
    if (isParent) {
      updateData({ parent_id: null });
    }
  }, [isParent]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);
    try {
      let payload: UpdatePagePayload = {
        ...data,
        publish_date: format(data.publish_date as Date, 'yyyy-MM-dd HH:mm:ss'),
        // @ts-ignore
        page_featured_posts: data.page_featured_posts.map((post) => post.id),
        // @ts-ignore
        page_featured_case_details: data.page_featured_case_details.map(
          (detail) => detail.id
        ),
      };

      await updatePage(payload);
      successToast('Page has been updated.');
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  const seoTitles: Array<SEOTitleTypeOption> = defaultSEOTitleTypeOption;
  const [selectedSeoTitles, setSelectedSeoTitles] = useState<
    MultiValue<SEOTitleTypeOption>
  >(defaultSEOTitleTypeOption);

  const robots: Array<RobotTypeOption> = defaultRobotTypeOption;
  const [selectedRobots, setSelectedRobots] = useState<
    MultiValue<RobotTypeOption>
  >([]);

  const handleFetch = async (id: string) => {
    setLoading(true);
    try {
      const result = await viewPage(id);
      setData({
        ...data,
        ...result,
        publish_date: new Date(result.publish_date),
      });

      setSelectedSeoTitles(
        result.seo_title.map((seoTitle) => ({
          value: seoTitle,
          label: seoTitle,
        }))
      );
      setSelectedRobots(
        result.robots.map((robot) => ({
          value: robot,
          label: robot,
        }))
      );

      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      handleFetch(id);
    }
  }, [id]);

  const formSubmitButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Update Page</h3>
        <div className="card-toolbar gap-5">
          <Link
            className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3"
            to={`/page-management/pages`}
          >
            Cancel
          </Link>
          <button
            type="button"
            onClick={() => formSubmitButtonRef.current?.click()}
            disabled={loading}
            className="btn btn-primary fw-bolder px-6 py-3"
          >
            {!loading && <span className="indicator-label">Save</span>}
            {loading && (
              <span className="indicator-progress" style={{ display: 'block' }}>
                Please wait...{' '}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </div>
      <form onSubmit={handleSubmit} className="form d-flex flex-center">
        <div className="card-body mw-800px py-20">
          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Is Parent?</label>
            <div className="col-lg-9">
              <select
                className="form-select form-select-lg form-select-solid text-capitalize"
                data-control="select2"
                data-placeholder="Select State..."
                value={isParent ? 'YES' : 'NO'}
                onChange={(e) => {
                  setIsParent(e.target.value === 'YES' ? true : false);
                  updateData({
                    is_parent: e.target.value === 'YES' ? true : false,
                  });
                }}
              >
                <option value={'YES'}>YES</option>
                <option value={'NO'}>NO</option>
              </select>
            </div>
          </div>
          {/* end::Form row */}

          {!data.is_parent && (
            <div className="row mb-8">
              <label className="col-lg-3 col-form-label">Parent Page</label>
              <div className="col-lg-9">
                <select
                  className="form-select form-select-lg form-select-solid text-capitalize"
                  data-control="select2"
                  data-placeholder="Select Page..."
                  value={data.parent_id === null ? undefined : data.parent_id!}
                  onChange={(e) =>
                    updateData({
                      parent_id: Number(e.target.value),
                    })
                  }
                >
                  <option value={undefined}>Select Page...</option>
                  {parentPages.map((i, idx) => (
                    <option key={idx} value={i.id}>
                      {i.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Title</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter name"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.title}
                  onChange={(e) =>
                    updateData({
                      title: e.target.value,
                      slug: convertToSlug(e.target.value),
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Slug</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter slug"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.slug}
                  onChange={(e) => updateData({ slug: e.target.value })}
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Status</label>
            <div className="col-lg-9">
              <select
                className="form-select form-select-lg form-select-solid text-capitalize"
                data-control="select2"
                data-placeholder="Select Status..."
                value={data.status}
                onChange={(e) =>
                  updateData({
                    status: e.target.value as PageStatusType,
                  })
                }
              >
                <option value={PageStatusType.DRAFT}>Draft</option>
                <option value={PageStatusType.PUBLISHED}>Published</option>
                <option value={PageStatusType.FUTURE}>Future</option>
              </select>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Publish Date</label>
            <div className="col-lg-9">
              <DatePicker
                selected={data.publish_date as Date}
                onChange={(date: Date) => updateData({ publish_date: date })}
                showTimeSelect
                timeFormat="HH:mm"
                dateFormat="MMMM d, yyyy HH:mm"
                className="w-100 form-control form-control-lg form-control-solid"
              />
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Description</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <textarea
                  rows={2}
                  placeholder="Enter description"
                  className="form-control form-control-lg form-control-solid"
                  value={data.description}
                  onChange={(e) =>
                    updateData({
                      description: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          <div className={`separator my-10`}></div>

          <div className="row mb-8">
            <div className="col-lg-12 col-form-label text-center">SEO</div>
          </div>

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">SEO Title</label>
            <div className="col-lg-9">
              <CreatableSelect
                placeholder={`Select SEO title/s ...`}
                value={selectedSeoTitles}
                onChange={setSelectedSeoTitles}
                options={seoTitles}
                isMulti
                isSearchable
                isClearable
              />
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Robots</label>
            <div className="col-lg-9">
              <Select
                closeMenuOnSelect={false}
                placeholder={`Select robot/s ...`}
                value={selectedRobots}
                onChange={setSelectedRobots}
                options={robots}
                isMulti
                isSearchable
                isClearable
              />
            </div>
          </div>
          {/* end::Form row */}

          <div className={`separator my-10`}></div>

          <div className="row mb-8">
            <div className="col-lg-12 col-form-label text-center">SCHEMA</div>
          </div>

          {/* begin::Form row */}
          <div className={`row mb-8`}>
            <label className="col-lg-3 col-form-label">Page Type</label>
            <div className="col-lg-9">
              <select
                className="form-select form-select-lg form-select-solid"
                data-control="select2"
                data-placeholder="Select Package..."
                value={data.schema_page_type}
                onChange={(e) =>
                  updateData({
                    schema_page_type: e.target.value as SchemaPageType,
                  })
                }
              >
                <option value={SchemaPageType.WEB_PAGE}>
                  {SchemaPageType.WEB_PAGE}
                </option>
                <option value={SchemaPageType.ITEM_PAGE}>
                  {SchemaPageType.ITEM_PAGE}
                </option>
                <option value={SchemaPageType.ABOUT_PAGE}>
                  {SchemaPageType.ABOUT_PAGE}
                </option>
                <option value={SchemaPageType.FAQ_PAGE}>
                  {SchemaPageType.FAQ_PAGE}
                </option>
                <option value={SchemaPageType.QA_PAGE}>
                  {SchemaPageType.QA_PAGE}
                </option>
                <option value={SchemaPageType.PROFILE_PAGE}>
                  {SchemaPageType.PROFILE_PAGE}
                </option>
                <option value={SchemaPageType.CONTACT_PAGE}>
                  {SchemaPageType.CONTACT_PAGE}
                </option>
                <option value={SchemaPageType.MEDICAL_WEB_PAGE}>
                  {SchemaPageType.MEDICAL_WEB_PAGE}
                </option>
                <option value={SchemaPageType.COLLECTION_PAGE}>
                  {SchemaPageType.COLLECTION_PAGE}
                </option>
                <option value={SchemaPageType.CHECKOUT_PAGE}>
                  {SchemaPageType.CHECKOUT_PAGE}
                </option>
                <option value={SchemaPageType.REAL_ESTATE_LISTING}>
                  {SchemaPageType.REAL_ESTATE_LISTING}
                </option>
                <option value={SchemaPageType.SEARCH_RESULT_PAGE}>
                  {SchemaPageType.SEARCH_RESULT_PAGE}
                </option>
              </select>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className={`row mb-8`}>
            <label className="col-lg-3 col-form-label">Article Type</label>
            <div className="col-lg-9">
              <select
                className="form-select form-select-lg form-select-solid"
                data-control="select2"
                data-placeholder="Select Package..."
                value={data.schema_article_type}
                onChange={(e) =>
                  updateData({
                    schema_article_type: e.target.value as SchemaArticleType,
                  })
                }
              >
                <option value={SchemaArticleType.ARTICLE}>
                  {SchemaArticleType.ARTICLE}
                </option>
                <option value={SchemaArticleType.BLOG_POST}>
                  {SchemaArticleType.BLOG_POST}
                </option>
                <option value={SchemaArticleType.SOCIAL_MEDIA_POSTING}>
                  {SchemaArticleType.SOCIAL_MEDIA_POSTING}
                </option>
                <option value={SchemaArticleType.NEWS_ARTICLE}>
                  {SchemaArticleType.NEWS_ARTICLE}
                </option>
                <option value={SchemaArticleType.ADVISER_CONTENT_ARTICLE}>
                  {SchemaArticleType.ADVISER_CONTENT_ARTICLE}
                </option>
                <option value={SchemaArticleType.SATIRICAL_ARTICLE}>
                  {SchemaArticleType.SATIRICAL_ARTICLE}
                </option>
                <option value={SchemaArticleType.SCHOLARLY_ARTICLE}>
                  {SchemaArticleType.SCHOLARLY_ARTICLE}
                </option>
                <option value={SchemaArticleType.TECH_ARTICLE}>
                  {SchemaArticleType.TECH_ARTICLE}
                </option>
                <option value={SchemaArticleType.REPORT}>
                  {SchemaArticleType.REPORT}
                </option>
                <option value={SchemaArticleType.NONE}>
                  {SchemaArticleType.NONE}
                </option>
              </select>
            </div>
          </div>
          {/* end::Form row */}

          <div className={`separator my-10`}></div>

          <div className="row mb-8">
            <div className="col-lg-12 col-form-label text-center">
              Social - Facebook
            </div>
          </div>

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Facebook Title</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter facebook title"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.facebook_title}
                  onChange={(e) =>
                    updateData({
                      facebook_title: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">
              Facebook Description
            </label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <textarea
                  rows={2}
                  placeholder="Enter facebook description"
                  className="form-control form-control-lg form-control-solid"
                  value={data.facebook_description}
                  onChange={(e) =>
                    updateData({
                      facebook_description: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-20">
            <label className="col-lg-3 col-form-label">Facebook Image</label>
            <div className="col-lg-9">
              <FormImageField
                name={`facebook_image`}
                src={data.facebook_image}
                update={updateData}
                modalId={`facebook_image_modal`}
              />
            </div>
          </div>
          {/* end::Form row */}

          <div className={`separator my-10`}></div>

          <div className="row mb-8">
            <div className="col-lg-12 col-form-label text-center">
              Social - Twitter
            </div>
          </div>

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Twitter Title</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter twitter title"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.twitter_title}
                  onChange={(e) =>
                    updateData({
                      twitter_title: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">
              Twitter Description
            </label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <textarea
                  rows={2}
                  placeholder="Enter twitter description"
                  className="form-control form-control-lg form-control-solid"
                  value={data.twitter_description}
                  onChange={(e) =>
                    updateData({
                      twitter_description: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-20">
            <label className="col-lg-3 col-form-label">Twitter Image</label>
            <div className="col-lg-9">
              <FormImageField
                name={`twitter_image`}
                src={data.twitter_image}
                update={updateData}
                modalId={`twitter_image_modal`}
              />
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row">
            <label className="col-lg-3 col-form-label"></label>
            <div className="col-lg-9">
              <button
                ref={formSubmitButtonRef}
                type="submit"
                disabled={loading}
                className="btn btn-primary fw-bolder px-6 py-3 me-3"
              >
                {!loading && <span className="indicator-label">Save</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Please wait...{' '}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
              <Link
                className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3"
                to={`/page-management/pages`}
              >
                Cancel
              </Link>
            </div>
          </div>
          {/* end::Form row */}
        </div>
      </form>
      {/* end::Form */}
    </div>
  );
};
