import React from 'react';
import { toAbsoluteUrl } from '../../../../../../_start/helpers';
import { PluginHeader } from './PluginHeader';

type Props = {
  data: PluginHeader;
  update: (fieldsToUpdate: Partial<PluginHeader>) => void;
};

export const PluginHeaderFigure: React.FC<Props> = ({ data, update }) => {
  return (
    <div className="showcase__figure">
      {data.image ? (
        <div className="mt-5 overlay">
          <div className="overlay-wrapper">
            <img
              alt="dummy"
              src={data.image}
              style={{
                width: '100%',
                borderRadius: '20px',
                objectFit: 'cover',
              }}
            />
          </div>
          <div className="overlay-layer bg-dark bg-opacity-10 flex-column justify-items-center align-items-center">
            <div>
              <button
                name="image"
                className="btn btn-primary btn-shadow"
                onClick={() =>
                  update({
                    image: '',
                    image_alt: '',
                  })
                }
              >
                Remove
              </button>
              <label
                className="btn btn-light-primary btn-shadow ms-2"
                data-bs-toggle="modal"
                data-bs-target={`#${data.name}-${data.id}`}
              >
                Change
              </label>
            </div>

            <input
              placeholder="Alt attribute"
              className="w-25 mt-2 form-control form-control-lg form-control-solid"
              type="text"
              value={data.image_alt ?? ''}
              onChange={(event) => update({ image_alt: event.target.value })}
            />
          </div>
        </div>
      ) : (
        <>
          <label
            className="mt-5"
            data-bs-toggle="modal"
            data-bs-target={`#${data.name}-${data.id}`}
          >
            <img
              src={toAbsoluteUrl('/assets/images/900x774.webp')}
              style={{
                width: '100%',
                borderRadius: '20px',
                objectFit: 'cover',
              }}
              alt=""
            />
          </label>
        </>
      )}
    </div>
  );
};
