import Carousel from 'react-elastic-carousel';
import { KTSVG } from '../../../../../../_start/helpers';
import { IEmployee } from '../../../../employee-management/pages/employees/Employee';
import { PluginPartnerItem } from './PluginPartnersItem';
import { IPartner, PluginPartners } from './PluginPartners';
import { PluginPartnersCarouselItemWrapper } from './PluginPartnersCarouselItemWrapper';

type HomeEmployeesCarouselProps = {
  data: PluginPartners;
  update: (fieldsToUpdate: Partial<PluginPartners>) => void;
  addPartner: () => void;
  removePartner: (itemIndex: number) => void;
};

export const PluginPartnersCarousel = ({
  data,
  update,
  addPartner,
  removePartner,
}: HomeEmployeesCarouselProps) => {
  const updatePartnerSelected = (idx: number, partner: IPartner) => {
    let partners = [...data.partners];

    const prevPartner = partners[idx];
    partners[idx] = {
      ...prevPartner,
      image: partner.image,
      image_alt: partner.image_alt,
    };

    const updatedData: PluginPartners = {
      ...data,
      partners,
    };

    update(updatedData);
  };

  return (
    <div className="plugin-partners__carousel">
      <Carousel
        itemsToShow={3}
        isRTL={false}
        pagination={true}
        enableSwipe={false}
        enableMouseSwipe={false}
      >
        {data.partners.map((partner, idx) => (
          <PluginPartnerItem
            updatePartner={updatePartnerSelected}
            pluginPartner={data}
            partner={partner}
            partnerIndex={idx}
            removePartner={removePartner}
          />
        ))}

        <div onClick={addPartner} className="add-item">
          <div className="m-auto d-flex flex-column">
            <KTSVG
              path="/media/icons/duotone/Interface/Plus-Square.svg"
              className="svg-icon-muted svg-icon-3hx text-center"
            />
            <span className="mt-4 text-gray-500">Add new item</span>
          </div>
        </div>
      </Carousel>
    </div>
  );
};
