import {
  RobotType,
  SchemaArticleType,
  SchemaPageType,
  SEOTitleType,
} from '../../post-management/models/Post';
import { IElement } from './Element';

export interface IContent {
  id: number;

  content: Array<IElement>;

  seo_title: Array<SEOTitleType>;
  robots: Array<RobotType>;

  schema_page_type: SchemaPageType;
  schema_article_type: SchemaArticleType;

  facebook_image: string;
  facebook_title: string;
  facebook_description: string;

  twitter_image: string;
  twitter_title: string;
  twitter_description: string;

  image_preview: string;
  image_preview_alt_text: string;
}

export const defaultContent: IContent = {
  id: 0,

  content: [],

  seo_title: [],
  robots: [],

  schema_page_type: SchemaPageType.WEB_PAGE,
  schema_article_type: SchemaArticleType.ARTICLE,

  facebook_image: '',
  facebook_title: '',
  facebook_description: '',

  twitter_image: '',
  twitter_title: '',
  twitter_description: '',

  image_preview: '',
  image_preview_alt_text: '',
};
