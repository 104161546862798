import { defaultContent, IContent } from '../../edit-content/models/Content';
import { PageStatusType } from '../../page-management/models/Page';
import { ITag } from '../../post-management/models/Tag';
import { ITutorial } from '../../tutorial-management/models/Tutorial';
import { defaultModule, IModule } from './Module';

export interface IPlugin {
  id: number;
  title: string;
  slug: string;
  publish_date: string | Date;
  status: PageStatusType;
  description: string;
  content: IContent;
  featured_plugin_details: Array<IPlugin>;
  tutorials: Array<ITutorial>;

  module_id: number;
  module: IModule;

  tags: Array<ITag>;
}

export const defaultPlugin: IPlugin = {
  id: 0,
  title: '',
  slug: '',
  publish_date: new Date(),
  status: PageStatusType.DRAFT,
  description: '',
  content: defaultContent,
  featured_plugin_details: [],
  tutorials: [],

  module_id: 0,
  module: defaultModule,

  tags: [],
};
