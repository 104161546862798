import { ElementType, IElement } from '../../../models/Element';

export interface IServiceItem {
  id: number;
  title: string;
  description: string;
  url_title: string;
  url_link: string;
  icon?: string;
}

const icon_sword = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35"><path fill="currentColor" d="M17.94 26.78a1.89 1.89 0 0 1-1.2-3.34L31.2 11.5V3.77h-7.73L11.56 18.26a1.89 1.89 0 0 1-2.91-2.4L21.16.72A1.9 1.9 0 0 1 22.61 0h10.5a2.07 2.07 0 0 1 1.34.55A1.93 1.93 0 0 1 35 1.89v10.5a1.93 1.93 0 0 1-.69 1.45L19.14 26.35a1.88 1.88 0 0 1-1.2.43Z" /><path d="M25.23 9.77a1.88 1.88 0 0 0-2.67 0L14 18.31l-5-5a3.27 3.27 0 0 0-4.52 0l-2.05 2.11a3.2 3.2 0 0 0 0 4.53l3 3-4.5 4.57a3.16 3.16 0 0 0-.69 1 3.25 3.25 0 0 0 0 2.48 3.18 3.18 0 0 0 .7 1l2 2a3.31 3.31 0 0 0 1 .69 3.25 3.25 0 0 0 2.45 0 3.4 3.4 0 0 0 1-.7L12 29.54l3 3a3.28 3.28 0 0 0 4.53 0l2.09-2.09a3.21 3.21 0 0 0 0-4.52l-5-5 8.54-8.55a1.87 1.87 0 0 0 .07-2.61Zm-7.92 19.74-3-3a3.28 3.28 0 0 0-4.53 0L5.22 31 4 29.78l4.53-4.53a3.15 3.15 0 0 0 .69-1A3 3 0 0 0 9.47 23a3.08 3.08 0 0 0-.25-1.23 3.15 3.15 0 0 0-.69-1l-3-3 1.25-1.37 5.91 5.91 5.91 5.91Z" /></svg>`;

export const defaultServiceItem: IServiceItem = {
  id: 0,
  title: 'Strategie',
  description:
    'Van WordPress website en web winkel ontwikkeling tot maatwerk plugins en white-label.',
  url_title: 'Lees meer',
  url_link: '',
  icon: icon_sword,
};

export type HomeServices = IElement & {
  title: string;
  description: string;
  button_url: string;
  button_title: string;
  image_preview: string;
  image_preview_alt: string;
  services: Array<IServiceItem>;
};

export const defaultHomeServices: HomeServices = {
  id: 1,
  name: ElementType.HOME_SERVICES,
  title: 'Binnen WordPress',
  description: 'is niks ons te gek',
  button_url: '',
  button_title: 'Ontdek onze kracht',
  image_preview: '',
  image_preview_alt: '',
  services: [
    defaultServiceItem,
    { ...defaultServiceItem, title: 'Web development' },
    { ...defaultServiceItem, title: 'Design' },
    { ...defaultServiceItem, title: 'Marketing' },
  ],
};
