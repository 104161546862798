import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import type { XYCoord, Identifier } from 'dnd-core';

import { EditContentItemType, ElementType, IElement } from '../models/Element';
import { MarginTopElementFC } from './utility/margin-top-element/MarginTopElementFC';
import { MarginTopElement } from './utility/margin-top-element/MarginTopElement';
import { MarginBottomElementFC } from './utility/margin-bottom-element/MarginBottomFC';
import { MarginBottomElement } from './utility/margin-bottom-element/MarginBottomElement';
import { BlogHeaderFC } from './blog/blog-header/BlogHeaderFC';
import { BlogHeader } from './blog/blog-header/BlogHeader';
import { BlogFeaturedFC } from './blog/blog-featured/BlogFeaturedFC';
import { BlogFeatured } from './blog/blog-featured/BlogFeatured';
import { BlogContentHeadingFC } from './blog/blog-content-heading/BlogContentHeadingFC';
import { BlogContentHeading } from './blog/blog-content-heading/BlogContentHeading';
import { BlogContentImageFC } from './blog/blog-content-image/BlogContentImageFC';
import { BlogContentImage } from './blog/blog-content-image/BlogContentImage';
import { BlogContentDescriptionFC } from './blog/blog-content-description/BlogContentDescriptionFC';
import { BlogContentDescription } from './blog/blog-content-description/BlogContentDescription';
import { BlogContentDescriptionWithInfoFC } from './blog/blog-content-description-with-info/BlogContentDescriptionWithInfoFC';
import { BlogContentDescriptionWithInfo } from './blog/blog-content-description-with-info/BlogContentDescriptionWithInfo';
import { BlogContentWideDescriptionTwoColumnFC } from './blog/blog-content-wide-description-two-column/BlogContentWideDescriptionTwoColumnFC';
import { BlogContentWideDescriptionTwoColumn } from './blog/blog-content-wide-description-two-column/BlogContentWideDescriptionTwoColumn';
import { BlogContentInfoPanelFC } from './blog/blog-content-info-panel/BlogContentInfoPanelFC';
import { BlogContentInfoPanel } from './blog/blog-content-info-panel/BlogContentInfoPanel';
import { BlogContentWideDescriptionFC } from './blog/blog-content-wide-description/BlogContentWideDescriptionFC';
import { BlogContentWideDescription } from './blog/blog-content-wide-description/BlogContentWideDescription';
import { BlogAuthorHeaderFC } from './blog/blog-author-header/BlogAuthorHeaderFC';
import { BlogAuthorHeader } from './blog/blog-author-header/BlogAuthorHeader';
import { BlogAuthorFooterFC } from './blog/blog-author-footer/BlogAuthorFooterFC';
import { BlogAuthorFooter } from './blog/blog-author-footer/BlogAuthorFooter';
import { AboutUsHeaderFC } from './about-us/about-us-header/AboutUsHeaderFC';
import { AboutUsHeader } from './about-us/about-us-header/AboutUsHeader';
import { AboutUsIntroductionFC } from './about-us/text-heading-with-justify-center-paragraph/AboutUsIntroductionFC';
import { AboutUsIntroduction } from './about-us/text-heading-with-justify-center-paragraph/AboutUsIntroduction';
import { AboutUsUniqueSellingPointFC } from './about-us/icon-with-description/AboutUsUniqueSellingPointFC';
import { AboutUsUniqueSellingPoint } from './about-us/icon-with-description/AboutUsUniqueSellingPoint';
import { AboutUsImageBannerFC } from './about-us/image-banner/AboutUsImageBannerFC';
import { AboutUsImageBanner } from './about-us/image-banner/AboutUsImageBanner';
import { AboutUsFeaturedContentOneFC } from './about-us/dark-background-with-white-text-and-a-blue-button/AboutUsFeaturedContentOneFC';
import { AboutUsFeaturedContentOne } from './about-us/dark-background-with-white-text-and-a-blue-button/AboutUsFeaturedContentOne';
import { AboutUsEmployeesFC } from './about-us/employees-grid/AboutUsEmployeesFC';
import { AboutUsEmployees } from './about-us/employees-grid/AboutUsEmployees';
import { AboutUsContentHeadingFC } from './about-us/justified-center-text-with-heading/AboutUsContentHeadingFC';
import { AboutUsContentHeading } from './about-us/justified-center-text-with-heading/AboutUsContentHeading';
import { AboutUsContentDescriptionFC } from './about-us/rounded-border-with-white-background-and-dark-text-with-button/AboutUsContentDescriptionFC';
import { AboutUsContentDescription } from './about-us/rounded-border-with-white-background-and-dark-text-with-button/AboutUsContentDescription';
import { AboutUsShowcaseWithTextRightFC } from './about-us/image-with-text-right/AboutUsShowcaseWithTextRightFC';
import { AboutUsShowcaseWithTextRight } from './about-us/image-with-text-right/AboutUsShowcaseWithTextRight';
import { AboutUsShowcaseWithTextLeftFC } from './about-us/image-with-text-left/AboutUsShowcaseWithTextLeftFC';
import { AboutUsShowcaseWithTextLeft } from './about-us/image-with-text-left/AboutUsShowcaseWithTextLeft';
import { AboutUsCenteredParagraphFC } from './about-us/centered-text-with-padding/AboutUsCenteredParagraphFC';
import { AboutUsCenteredParagraph } from './about-us/centered-text-with-padding/AboutUsCenteredParagraph';
import { AboutUsFeaturedContentTwoFC } from './about-us/dark-background-with-white-text-and-a-white-button/AboutUsFeaturedContentTwoFC';
import { AboutUsFeaturedContentTwo } from './about-us/dark-background-with-white-text-and-a-white-button/AboutUsFeaturedContentTwo';
import { HomeHeaderFC } from './home/home-header/HomeHeaderFC';
import { HomeHeader } from './home/home-header/HomeHeader';
import { HomeFeaturedProjectsFC } from './home/home-featured-projects/HomeFeaturedProjectsFC';
import { HomeFeaturedProjects } from './home/home-featured-projects/HomeFeaturedProjects';
import { HomeEmployeesFC } from './home/home-employees/HomeEmployeesFC';
import { HomeEmployees } from './home/home-employees/HomeEmployees';
import { HomePluginsFC } from './home/home-plugins/HomePluginsFC';
import { HomePlugins } from './home/home-plugins/HomePlugins';
import { HomeServicesFC } from './home/home-services/HomeServicesFC';
import { HomeServices } from './home/home-services/HomeServices';
import { SpecializationsModalStepsFC } from './specializations/dark-background-with-text-and-multiple-modal-steps/SpecializationsModalStepsFC';
import { SpecializationsModalSteps } from './specializations/dark-background-with-text-and-multiple-modal-steps/SpecializationsModalSteps';
import { SpecializationsFullTextFC } from './specializations/full-text/SpecializationsFullTextFC';
import { SpecializationsFullText } from './specializations/full-text/SpecializationsFullText';
import { SpecializationsFloatingRightModalFC } from './specializations/image-with-floating-right-modal/SpecializationsFloatingRightModalFC';
import { SpecializationFloatingRightModal } from './specializations/image-with-floating-right-modal/SpecializationsFloatingRightModal';
import { SpecializationsFloatingLeftModalFC } from './specializations/image-with-floating-left-modal/SpecializationsFloatingLeftModalFC';
import { SpecializationFloatingLeftModal } from './specializations/image-with-floating-left-modal/SpecializationsFloatingLeftModal';
import { SpecializationsCasesFC } from './specializations/text-with-a-single-button-with-three-cases/SpecializationsCasesFC';
import { SpecializationsCases } from './specializations/text-with-a-single-button-with-three-cases/SpecializationsCases';
import { CaseHeaderFC } from './case-detail/case-header/CaseHeaderFC';
import { CaseHeader } from './case-detail/case-header/CaseHeader';
import { WebsiteScreenshotFC } from './case-detail/website-screenshot/WebsiteScreenshotFC';
import { WebsiteScreenshot } from './case-detail/website-screenshot/WebsiteScreenshot';
import { CustomerQuestionFC } from './case-detail/customer-question/CustomerQuestionFC';
import { CustomerQuestion } from './case-detail/customer-question/CustomerQuestion';
import { ProjectHighlightsFC } from './case-detail/project-highlights/ProjectHighlightsFC';
import { ProjectHighlights } from './case-detail/project-highlights/ProjectHighlights';
import { ShowcaseBigBannerFC } from './case-detail/showcase-big-banner/ShowcaseBigBannerFC';
import { ShowcaseBigBanner } from './case-detail/showcase-big-banner/ShowcaseBigBanner';
import { WebsiteScreenshotWithTextRightFC } from './case-detail/website-screenshot-with-text-right/WebsiteScreenshotWithTextRightFC';
import { WebsiteScreenshotWithTextRight } from './case-detail/website-screenshot-with-text-right/WebsiteScreenshotWithTextRight';
import { WebsiteScreenshotWithTextLeftFC } from './case-detail/website-screenshot-with-text-left/WebsiteScreenshotWithTextLeftFC';
import { WebsiteScreenshotWithTextLeft } from './case-detail/website-screenshot-with-text-left/WebsiteScreenshotWithTextLeft';
import { ShowcaseFeaturedFC } from './case-detail/showcase-featured/ShowcaseFeaturedFC';
import { ShowcaseFeatured } from './case-detail/showcase-featured/ShowcaseFeatured';
import { ShowcaseWithTextRightFC } from './case-detail/showcase-with-text-right/ShowcaseWithTextRightFC';
import { ShowcaseWithTextRight } from './case-detail/showcase-with-text-right/ShowcaseWithTextRight';
import { ShowcaseWithTextLeftFC } from './case-detail/showcase-with-text-left/ShowcaseWithTextLeftFC';
import { ShowcaseWithTextLeft } from './case-detail/showcase-with-text-left/ShowcaseWithTextLeft';
import { ProcessHighlightFC } from './case-detail/process-highlight/ProcessHighlightFC';
import { ProcessHighlight } from './case-detail/process-highlight/ProcessHighlight';
import { PersonHighlightExperienceFC } from './case-detail/person-highlight-experience/PersonHighlightExperienceFC';
import { PersonHighlightExperience } from './case-detail/person-highlight-experience/PersonHighlightExperience';
import { FeaturedUsedPluginsFC } from './case-detail/featured-used-plugins/FeaturedUsedPluginsFC';
import { FeaturedUsedPlugins } from './case-detail/featured-used-plugins/FeaturedUsedPlugins';
import { FeaturedOtherCasesFC } from './case-detail/featured-other-cases/FeaturedOtherCasesFC';
import { FeaturedOtherCases } from './case-detail/featured-other-cases/FeaturedOtherCases';
import { ContactsHeaderFC } from './contacts/contacts-header/ContactsHeaderFC';
import { ContactsHeader } from './contacts/contacts-header/ContactsHeader';
import { TitleElement } from './utility/title-element/TitleElement';
import { TitleElementFC } from './utility/title-element/TitleElementFC';
import { SpecializationFeaturePostsFC } from './specializations/specializations-featured-posts/SpecializationsFeaturedPostsFC';
import { SpecializationFeaturedPosts } from './specializations/specializations-featured-posts/SpecializationsFeaturedPosts';
import { SpecializationFeatureCasesFC } from './specializations/specializations-featured-cases/SpecializationsFeaturedCasesFC';
import { SpecializationFeaturedCases } from './specializations/specializations-featured-cases/SpecializationsFeaturedCases';
import { FAQSelectFC } from './faq/faq-select/FAQSelectFC';
import { FAQSelect } from './faq/faq-select/FAQSelect';
import { ReviewsElementFC } from './reviews/reviews-element/ReviewsElementFC';
import { ReviewsElement } from './reviews/reviews-element/ReviewsElement';
import { FAQSelectNarrowFC } from './faq/faq-select-narrow/FAQSelectNarrowFC';
import { FAQSelectNarrow } from './faq/faq-select-narrow/FAQSelectNarrow';
import { PluginHeaderFC } from './plugin/plugin-header/PluginHeaderFC';
import { PluginHeader } from './plugin/plugin-header/PluginHeader';
import { PluginLeftImageInfoFC } from './plugin/plugin-left-image-info/PluginLeftImageInfoFC';
import { PluginLeftImageInfo } from './plugin/plugin-left-image-info/PluginLeftImageInfo';
import { PluginRightImageInfoFC } from './plugin/plugin-right-image-info/PluginRightImageInfoFC';
import { PluginRightImageInfo } from './plugin/plugin-right-image-info/PluginRightImageInfo';
import { PluginRightImageHighlightInfoFC } from './plugin/plugin-right-image-highlight-info/PluginRightImageHighlightInfoFC';
import { PluginRightImageHighlightInfo } from './plugin/plugin-right-image-highlight-info/PluginRightImageHighlightInfo';
import { PluginLeftImageHighlightInfoFC } from './plugin/plugin-left-image-highlight-info/PluginLeftImageHighlightInfoFC';
import { PluginLeftImageHighlightInfo } from './plugin/plugin-left-image-highlight-info/PluginLeftImageHighlightInfo';
import { PluginLeftImageInfoNoButtons } from './plugin/plugin-left-image-info-no-buttons/PluginLeftImageInfoNoButtons';
import { PluginLeftImageInfoNoButtonsFC } from './plugin/plugin-left-image-info-no-buttons/PluginLeftImageInfoNoButtonsFC';
import { PluginRightImageInfoNoButtonsFC } from './plugin/plugin-right-image-info-no-buttons/PluginRightImageInfoNoButtonsFC';
import { PluginRightImageInfoNoButtons } from './plugin/plugin-right-image-info-no-buttons/PluginRightImageInfoNoButtons';
import { PluginLeftScreenshotInfoNoButtonsFC } from './plugin/plugin-left-screenshot-info-no-buttons/PluginLeftScreenshotInfoNoButtonsFC';
import { PluginLeftScreenshotInfoNoButtons } from './plugin/plugin-left-screenshot-info-no-buttons/PluginLeftScreenshotInfoNoButtons';
import { PluginRightScreenshotInfoNoButtonsFC } from './plugin/plugin-right-screenshot-info-no-buttons/PluginRightScreenshotInfoNoButtonsFC';
import { PluginRightScreenshotInfoNoButtons } from './plugin/plugin-right-screenshot-info-no-buttons/PluginRightScreenshotInfoNoButtons';
import { PluginHighlightLeftImageInfoFC } from './plugin/plugin-highlight-left-image-info/PluginHighlightLeftImageInfoFC';
import { PluginHighlightLeftImageInfo } from './plugin/plugin-highlight-left-image-info/PluginHighlightLeftImageInfo';
import { PluginHighlightRightImageInfoFC } from './plugin/plugin-highlight-right-image-info/PluginHighlightRightImageInfoFC';
import { PluginHighlightRightImageInfo } from './plugin/plugin-highlight-right-image-info/PluginHighlightRightImageInfo';
import { PluginNoHighlightLeftImageInfoFC } from './plugin/plugin-no-highlight-left-image-info/PluginNoHighlightLeftImageInfoFC';
import { PluginNoHighlightLeftImageInfo } from './plugin/plugin-no-highlight-left-image-info/PluginNoHighlightLeftImageInfo';
import { PluginNoHighlightRightImageInfoFC } from './plugin/plugin-no-highlight-right-image-info/PluginNoHighlightRightImageInfoFC';
import { PluginNoHighlightRightImageInfo } from './plugin/plugin-no-highlight-right-image-info/PluginNoHighlightRightImageInfo';
import { PluginLeftScreenshotsInfoFC } from './plugin/plugin-left-screenshots-info/PluginLeftScreenshotsInfoFC';
import { PluginLeftScreenshotsInfo } from './plugin/plugin-left-screenshots-info/PluginLeftScreenshotsInfo';
import { PluginRightScreenshotsInfoFC } from './plugin/plugin-right-screenshots-info/PluginRightScreenshotsInfoFC';
import { PluginRightScreenshotsInfo } from './plugin/plugin-right-screenshots-info/PluginRightScreenshotsInfo';
import { PluginArrayHeaderAndDescriptionFC } from './plugin/plugin-array-header-and-description/PluginArrayHeaderAndDescriptionFC';
import { PluginArrayHeaderAndDescription } from './plugin/plugin-array-header-and-description/PluginArrayHeaderAndDescription';
import { PluginPartnersFC } from './plugin/plugin-partners/PluginPartnersFC';
import { PluginPartners } from './plugin/plugin-partners/PluginPartners';
import { PluginFeaturedPluginsFC } from './plugin/plugin-featured-plugins/PluginFeaturedPluginsFC';
import { PluginsFeaturedPlugins } from './plugin/plugin-featured-plugins/PluginFeaturedPlugins';
import { PluginFeaturedTutorialsFC } from './plugin/plugin-featured-tutorials/PluginFeaturedTutorialsFC';
import { PluginsFeaturedTutorials } from './plugin/plugin-featured-tutorials/PluginFeaturedTutorials';
import { SpecializationsHeaderFC } from './specializations/specialization-header/SpecializationsHeaderFC';
import { SpecializationHeader } from './specializations/specialization-header/SpecializationHeader';

interface DragItem {
  index: number;
  id: string;
  type: string;
}

type ElementDragProps = {
  component: IElement;
  index: number;
  move: (dragIndex: number, hoverIndex: number) => void;
  remove: (index: number) => void;
  updateData: (body: IElement, index: number) => void;
};

export const ElementDrag = ({
  component,
  index,
  move,
  remove,
  updateData,
}: ElementDragProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: EditContentItemType.ELEMENT_MOVE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      move(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: EditContentItemType.ELEMENT_MOVE,
    item: () => {
      return { id: component.id, index: index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  const renderElement = (element: IElement) => {
    switch (element.name) {
      // UTILITY
      case ElementType.TITLE:
        return (
          <TitleElementFC
            index={index}
            data={component as TitleElement}
            updateData={updateData}
          />
        );

      case ElementType.UTILITY_MARGIN_TOP:
        return (
          <MarginTopElementFC
            index={index}
            data={component as MarginTopElement}
            updateData={updateData}
          />
        );

      case ElementType.UTILITY_MARGIN_BOTTOM:
        return (
          <MarginBottomElementFC
            index={index}
            data={component as MarginBottomElement}
            updateData={updateData}
          />
        );

      // BLOG
      case ElementType.BLOG_HEADER:
        return (
          <BlogHeaderFC
            index={index}
            data={component as BlogHeader}
            updateData={updateData}
          />
        );

      case ElementType.BLOG_AUTHOR_HEADER:
        return (
          <BlogAuthorHeaderFC
            index={index}
            data={component as BlogAuthorHeader}
            updateData={updateData}
          />
        );

      case ElementType.BLOG_CONTENT_HEADING:
        return (
          <BlogContentHeadingFC
            index={index}
            data={component as BlogContentHeading}
            updateData={updateData}
          />
        );

      case ElementType.BLOG_CONTENT_DESCRIPTION:
        return (
          <BlogContentDescriptionFC
            index={index}
            data={component as BlogContentDescription}
            updateData={updateData}
          />
        );

      case ElementType.BLOG_CONTENT_IMAGE:
        return (
          <BlogContentImageFC
            index={index}
            data={component as BlogContentImage}
            updateData={updateData}
          />
        );

      case ElementType.BLOG_CONTENT_DESCRIPTION_WITH_INFO:
        return (
          <BlogContentDescriptionWithInfoFC
            index={index}
            data={component as BlogContentDescriptionWithInfo}
            updateData={updateData}
          />
        );

      case ElementType.BLOG_CONTENT_WIDE_DESCRIPTION_TWO_COLUMN:
        return (
          <BlogContentWideDescriptionTwoColumnFC
            index={index}
            data={component as BlogContentWideDescriptionTwoColumn}
            updateData={updateData}
          />
        );

      case ElementType.BLOG_CONTENT_INFO_PANEL:
        return (
          <BlogContentInfoPanelFC
            index={index}
            data={component as BlogContentInfoPanel}
            updateData={updateData}
          />
        );

      case ElementType.BLOG_CONTENT_WIDE_DESCRIPTION:
        return (
          <BlogContentWideDescriptionFC
            index={index}
            data={component as BlogContentWideDescription}
            updateData={updateData}
          />
        );

      case ElementType.BLOG_AUTHOR_FOOTER:
        return (
          <BlogAuthorFooterFC
            index={index}
            data={component as BlogAuthorFooter}
            updateData={updateData}
          />
        );

      case ElementType.BLOG_FEATURED:
        return (
          <BlogFeaturedFC
            index={index}
            data={component as BlogFeatured}
            updateData={updateData}
          />
        );

      // CASE DETAIL
      case ElementType.CASE_HEADER:
        return (
          <CaseHeaderFC
            index={index}
            data={component as CaseHeader}
            updateData={updateData}
          />
        );

      case ElementType.WEBSITE_SCREENSHOT:
        return (
          <WebsiteScreenshotFC
            index={index}
            data={component as WebsiteScreenshot}
            updateData={updateData}
          />
        );

      case ElementType.CUSTOMER_QUESTION:
        return (
          <CustomerQuestionFC
            index={index}
            data={component as CustomerQuestion}
            updateData={updateData}
          />
        );

      case ElementType.PROJECT_HIGHLIGHTS:
        return (
          <ProjectHighlightsFC
            index={index}
            data={component as ProjectHighlights}
            updateData={updateData}
          />
        );

      case ElementType.SHOWCASE_BIG_BANNER:
        return (
          <ShowcaseBigBannerFC
            index={index}
            data={component as ShowcaseBigBanner}
            updateData={updateData}
          />
        );

      case ElementType.WEBSITE_SCREENSHOT_WITH_TEXT_RIGHT:
        return (
          <WebsiteScreenshotWithTextRightFC
            index={index}
            data={component as WebsiteScreenshotWithTextRight}
            updateData={updateData}
          />
        );

      case ElementType.WEBSITE_SCREENSHOT_WITH_TEXT_LEFT:
        return (
          <WebsiteScreenshotWithTextLeftFC
            index={index}
            data={component as WebsiteScreenshotWithTextLeft}
            updateData={updateData}
          />
        );

      case ElementType.SHOWCASE_FEATURED:
        return (
          <ShowcaseFeaturedFC
            index={index}
            data={component as ShowcaseFeatured}
            updateData={updateData}
          />
        );

      case ElementType.SHOWCASE_WITH_TEXT_RIGHT:
        return (
          <ShowcaseWithTextRightFC
            index={index}
            data={component as ShowcaseWithTextRight}
            updateData={updateData}
          />
        );

      case ElementType.SHOWCASE_WITH_TEXT_LEFT:
        return (
          <ShowcaseWithTextLeftFC
            index={index}
            data={component as ShowcaseWithTextLeft}
            updateData={updateData}
          />
        );

      case ElementType.PROCESS_HIGHLIGHT:
        return (
          <ProcessHighlightFC
            index={index}
            data={component as ProcessHighlight}
            updateData={updateData}
          />
        );

      case ElementType.PERSON_HIGHLIGHT_EXPERIENCE:
        return (
          <PersonHighlightExperienceFC
            index={index}
            data={component as PersonHighlightExperience}
            updateData={updateData}
          />
        );

      case ElementType.FEATURED_USED_PLUGINS:
        return (
          <FeaturedUsedPluginsFC
            index={index}
            data={component as FeaturedUsedPlugins}
            updateData={updateData}
          />
        );

      case ElementType.FEATURED_OTHER_CASES:
        return (
          <FeaturedOtherCasesFC
            index={index}
            data={component as FeaturedOtherCases}
            updateData={updateData}
          />
        );

      // ABOUT US
      case ElementType.ABOUT_US_HEADER:
        return (
          <AboutUsHeaderFC
            index={index}
            data={component as AboutUsHeader}
            updateData={updateData}
          />
        );

      case ElementType.ABOUT_US_INTRODUCTION:
        return (
          <AboutUsIntroductionFC
            index={index}
            data={component as AboutUsIntroduction}
            updateData={updateData}
          />
        );

      case ElementType.ABOUT_US_UNIQUE_SELLING_POINT:
        return (
          <AboutUsUniqueSellingPointFC
            index={index}
            data={component as AboutUsUniqueSellingPoint}
            updateData={updateData}
          />
        );

      case ElementType.ABOUT_US_IMAGE_BANNER:
        return (
          <AboutUsImageBannerFC
            index={index}
            data={component as AboutUsImageBanner}
            updateData={updateData}
          />
        );

      case ElementType.ABOUT_US_FEATURED_CONTENT_ONE:
        return (
          <AboutUsFeaturedContentOneFC
            index={index}
            data={component as AboutUsFeaturedContentOne}
            updateData={updateData}
          />
        );

      case ElementType.ABOUT_US_EMPLOYEES:
        return (
          <AboutUsEmployeesFC
            index={index}
            data={component as AboutUsEmployees}
            updateData={updateData}
          />
        );

      case ElementType.ABOUT_US_CONTENT_HEADING:
        return (
          <AboutUsContentHeadingFC
            index={index}
            data={component as AboutUsContentHeading}
            updateData={updateData}
          />
        );

      case ElementType.ABOUT_US_CONTENT_DESCRIPTION:
        return (
          <AboutUsContentDescriptionFC
            index={index}
            data={component as AboutUsContentDescription}
            updateData={updateData}
          />
        );

      case ElementType.ABOUT_US_SHOWCASE_WITH_TEXT_RIGHT:
        return (
          <AboutUsShowcaseWithTextRightFC
            index={index}
            data={component as AboutUsShowcaseWithTextRight}
            updateData={updateData}
          />
        );

      case ElementType.ABOUT_US_SHOWCASE_WITH_TEXT_LEFT:
        return (
          <AboutUsShowcaseWithTextLeftFC
            index={index}
            data={component as AboutUsShowcaseWithTextLeft}
            updateData={updateData}
          />
        );

      case ElementType.ABOUT_US_CENTERED_PARAGRAPH:
        return (
          <AboutUsCenteredParagraphFC
            index={index}
            data={component as AboutUsCenteredParagraph}
            updateData={updateData}
          />
        );

      case ElementType.ABOUT_US_FEATURED_CONTENT_TWO:
        return (
          <AboutUsFeaturedContentTwoFC
            index={index}
            data={component as AboutUsFeaturedContentTwo}
            updateData={updateData}
          />
        );

      // HOME
      case ElementType.HOME_HEADER:
        return (
          <HomeHeaderFC
            index={index}
            data={component as HomeHeader}
            updateData={updateData}
          />
        );

      case ElementType.HOME_FEATURED_PROJECTS:
        return (
          <HomeFeaturedProjectsFC
            index={index}
            data={component as HomeFeaturedProjects}
            updateData={updateData}
          />
        );

      case ElementType.HOME_EMPLOYEES:
        return (
          <HomeEmployeesFC
            index={index}
            data={component as HomeEmployees}
            updateData={updateData}
          />
        );

      case ElementType.HOME_PLUGINS:
        return (
          <HomePluginsFC
            index={index}
            data={component as HomePlugins}
            updateData={updateData}
          />
        );

      case ElementType.HOME_SERVICES:
        return (
          <HomeServicesFC
            index={index}
            data={component as HomeServices}
            updateData={updateData}
          />
        );

      // Specializations
      case ElementType.SPECIALIZATION_HEADER:
        return (
          <SpecializationsHeaderFC
            index={index}
            data={component as SpecializationHeader}
            updateData={updateData}
          />
        );

      case ElementType.SPECIALIZATION_MODAL_STEPS:
        return (
          <SpecializationsModalStepsFC
            index={index}
            data={component as SpecializationsModalSteps}
            updateData={updateData}
          />
        );

      case ElementType.SPECIALIZATION_FULL_TEXT:
        return (
          <SpecializationsFullTextFC
            index={index}
            data={component as SpecializationsFullText}
            updateData={updateData}
          />
        );

      case ElementType.SPECIALIZATION_FLOATING_RIGHT_MODAL:
        return (
          <SpecializationsFloatingRightModalFC
            index={index}
            data={component as SpecializationFloatingRightModal}
            updateData={updateData}
          />
        );

      case ElementType.SPECIALIZATION_FLOATING_LEFT_MODAL:
        return (
          <SpecializationsFloatingLeftModalFC
            index={index}
            data={component as SpecializationFloatingLeftModal}
            updateData={updateData}
          />
        );

      case ElementType.SPECIALIZATION_CASES:
        return (
          <SpecializationsCasesFC
            index={index}
            data={component as SpecializationsCases}
            updateData={updateData}
          />
        );

      // case ElementType.SPECIALIZATION_BLOGPOSTS:
      //   return (
      //     <SpecializationsBlogPostsFC
      //       index={index}
      //       data={component as SpecializationsBlogPosts}
      //       updateData={updateData}
      //     />
      //   );
      case ElementType.SPECIALIZATION_FEATURED_POSTS:
        return (
          <SpecializationFeaturePostsFC
            index={index}
            data={component as SpecializationFeaturedPosts}
            updateData={updateData}
          />
        );

      case ElementType.SPECIALIZATION_FEATURED_CASES:
        return (
          <SpecializationFeatureCasesFC
            index={index}
            data={component as SpecializationFeaturedCases}
            updateData={updateData}
          />
        );

      case ElementType.SPECIALIZATION_FAQ:
        return (
          <FAQSelectFC
            index={index}
            data={component as FAQSelect}
            updateData={updateData}
          />
        );

      // Contacts
      case ElementType.CONTACTS_HEADER:
        return (
          <ContactsHeaderFC
            index={index}
            data={component as ContactsHeader}
            updateData={updateData}
          />
        );

      // Reviews
      case ElementType.REVIEWS_ELEMENT:
        return (
          <ReviewsElementFC
            index={index}
            data={component as ReviewsElement}
            updateData={updateData}
          />
        );

      // FAQ
      // case ElementType.FAQ:
      //   return (
      //     <FAQFC
      //       index={index}
      //       data={component as FAQ}
      //       updateData={updateData}
      //     />
      //   );

      // case ElementType.FAQ2:
      //   return (
      //     <FAQ2FC
      //       index={index}
      //       data={component as FAQ2}
      //       updateData={updateData}
      //     />
      //   );

      case ElementType.FAQSELECT:
        return (
          <FAQSelectFC
            index={index}
            data={component as FAQSelect}
            updateData={updateData}
          />
        );

      case ElementType.FAQSELECT_NARROW:
        return (
          <FAQSelectNarrowFC
            index={index}
            data={component as FAQSelectNarrow}
            updateData={updateData}
          />
        );

      // Plugins
      case ElementType.PLUGIN_HEADER:
        return (
          <PluginHeaderFC
            index={index}
            data={component as PluginHeader}
            updateData={updateData}
          />
        );

      case ElementType.PLUGIN_LEFT_IMAGE_INFO:
        return (
          <PluginLeftImageInfoFC
            index={index}
            data={component as PluginLeftImageInfo}
            updateData={updateData}
          />
        );

      case ElementType.PLUGIN_LEFT_IMAGE_INFO_NO_BUTTONS:
        return (
          <PluginLeftImageInfoNoButtonsFC
            index={index}
            data={component as PluginLeftImageInfoNoButtons}
            updateData={updateData}
          />
        );

      case ElementType.PLUGIN_RIGHT_IMAGE_INFO:
        return (
          <PluginRightImageInfoFC
            index={index}
            data={component as PluginRightImageInfo}
            updateData={updateData}
          />
        );

      case ElementType.PLUGIN_RIGHT_IMAGE_INFO_NO_BUTTONS:
        return (
          <PluginRightImageInfoNoButtonsFC
            index={index}
            data={component as PluginRightImageInfoNoButtons}
            updateData={updateData}
          />
        );

      case ElementType.PLUGIN_RIGHT_IMAGE_HIGHLIGHT_INFO:
        return (
          <PluginRightImageHighlightInfoFC
            index={index}
            data={component as PluginRightImageHighlightInfo}
            updateData={updateData}
          />
        );

      case ElementType.PLUGIN_LEFT_IMAGE_HIGHLIGHT_INFO:
        return (
          <PluginLeftImageHighlightInfoFC
            index={index}
            data={component as PluginLeftImageHighlightInfo}
            updateData={updateData}
          />
        );

      case ElementType.PLUGIN_HIGHLIGHT_LEFT_IMAGE_INFO:
        return (
          <PluginHighlightLeftImageInfoFC
            index={index}
            data={component as PluginHighlightLeftImageInfo}
            updateData={updateData}
          />
        );

      case ElementType.PLUGIN_NO_HIGHLIGHT_LEFT_IMAGE_INFO:
        return (
          <PluginNoHighlightLeftImageInfoFC
            index={index}
            data={component as PluginNoHighlightLeftImageInfo}
            updateData={updateData}
          />
        );

      case ElementType.PLUGIN_HIGHLIGHT_RIGHT_IMAGE_INFO:
        return (
          <PluginHighlightRightImageInfoFC
            index={index}
            data={component as PluginHighlightRightImageInfo}
            updateData={updateData}
          />
        );

      case ElementType.PLUGIN_NO_HIGHLIGHT_RIGHT_IMAGE_INFO:
        return (
          <PluginNoHighlightRightImageInfoFC
            index={index}
            data={component as PluginNoHighlightRightImageInfo}
            updateData={updateData}
          />
        );

      case ElementType.PLUGIN_LEFT_SCREENSHOT_INFO_NO_BUTTONS:
        return (
          <PluginLeftScreenshotInfoNoButtonsFC
            index={index}
            data={component as PluginLeftScreenshotInfoNoButtons}
            updateData={updateData}
          />
        );

      case ElementType.PLUGIN_LEFT_SCREENSHOTS_INFO:
        return (
          <PluginLeftScreenshotsInfoFC
            index={index}
            data={component as PluginLeftScreenshotsInfo}
            updateData={updateData}
          />
        );

      case ElementType.PLUGIN_RIGHT_SCREENSHOTS_INFO:
        return (
          <PluginRightScreenshotsInfoFC
            index={index}
            data={component as PluginRightScreenshotsInfo}
            updateData={updateData}
          />
        );

      case ElementType.PLUGIN_RIGHT_SCREENSHOT_INFO_NO_BUTTONS:
        return (
          <PluginRightScreenshotInfoNoButtonsFC
            index={index}
            data={component as PluginRightScreenshotInfoNoButtons}
            updateData={updateData}
          />
        );

      case ElementType.PLUGIN_ARRAY_HEADER_AND_DESCRIPTION:
        return (
          <PluginArrayHeaderAndDescriptionFC
            index={index}
            data={component as PluginArrayHeaderAndDescription}
            updateData={updateData}
          />
        );

      case ElementType.PLUGIN_PARTNERS:
        return (
          <PluginPartnersFC
            index={index}
            data={component as PluginPartners}
            updateData={updateData}
          />
        );

      case ElementType.PLUGIN_FEATURED_PLUGINS:
        return (
          <PluginFeaturedPluginsFC
            index={index}
            data={component as PluginsFeaturedPlugins}
            updateData={updateData}
          />
        );

      case ElementType.PLUGIN_FEATURED_TUTORIALS:
        return (
          <PluginFeaturedTutorialsFC
            index={index}
            data={component as PluginsFeaturedTutorials}
            updateData={updateData}
          />
        );
      default:
        return <div>Something went wrong.</div>;
    }
  };

  return (
    <div ref={ref} style={{ opacity }} data-handler-id={handlerId}>
      {renderElement(component)}
    </div>
  );
};
