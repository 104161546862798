import React from 'react';
import { toAbsoluteUrl } from '../../../../../../_start/helpers';
import { FormImageFieldModal } from '../../../../media-management/components/form-image/FormImageFieldModal';
import { WebsiteScreenshot } from './WebsiteScreenshot';

type WebsiteScreenshotDesktopProps = {
  data: WebsiteScreenshot;
  update: (fieldsToUpdate: Partial<WebsiteScreenshot>) => void;
};

export const WebsiteScreenshotDesktop = ({
  data,
  update,
}: WebsiteScreenshotDesktopProps) => {
  return (
    <>
      <FormImageFieldModal
        modalId={`${data.name}-${data.id}-desktop`}
        name={`image_preview_desktop`}
        altText={`image_preview_desktop_alt`}
        update={update}
      />

      {/* begin::Website Screenshot Desktop */}
      <div className="screen screen--white u-grow">
        {data.image_preview_desktop ? (
          <div className="overlay">
            <div className="overlay-wrapper">
              <img
                style={{
                  width: '100%',
                  height: '496px',
                  objectFit: 'cover',
                }}
                alt="dummy"
                src={data.image_preview_desktop}
              />
            </div>
            <div className="overlay-layer bg-dark bg-opacity-10 flex-column">
              <div>
                <button
                  name="image_preview_desktop"
                  className="btn btn-primary btn-shadow"
                  onClick={() =>
                    update({
                      image_preview_desktop: '',
                      image_preview_desktop_alt: '',
                    })
                  }
                >
                  Remove
                </button>
                <label
                  className="btn btn-light-primary btn-shadow ms-2"
                  data-bs-toggle="modal"
                  data-bs-target={`#${data.name}-${data.id}-desktop`}
                >
                  Change
                </label>
              </div>

              <input
                placeholder="Alt attribute"
                className="w-50 mt-2 form-control form-control-lg form-control-solid"
                type="text"
                value={data.image_preview_desktop_alt ?? ''}
                onChange={(event) =>
                  update({ image_preview_desktop_alt: event.target.value })
                }
              />
            </div>
          </div>
        ) : (
          <>
            <label
              data-bs-toggle="modal"
              data-bs-target={`#${data.name}-${data.id}-desktop`}
            >
              <img
                src={toAbsoluteUrl('/assets/images/960x665.webp')}
                alt=""
                style={{
                  width: '100%',
                  height: '500px',
                  objectFit: 'cover',
                }}
              />
            </label>
          </>
        )}
      </div>
      {/* end::Website Screenshot Desktop */}
    </>
  );
};
