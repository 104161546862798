import { PageLink } from '../../../_start/layout/core';

export const moduleManagementSubmenu: Array<PageLink> = [
  {
    title: 'Plugins',
    path: '/module-management/plugins',
    isActive: true,
  },
  {
    title: 'Modules',
    path: '/module-management/modules',
    isActive: true,
  },
  {
    title: 'Releases',
    path: '/module-management/releases',
    isActive: true,
  },
  {
    title: 'Packages',
    path: '/module-management/packages',
    isActive: true,
  },
  {
    title: 'Features',
    path: '/module-management/features',
    isActive: true,
  },
  {
    title: 'Tags',
    path: '/module-management/tags',
    isActive: true,
  },
];
