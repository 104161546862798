import { ITag } from '../../../../post-management/models/Tag';
import { ElementType, IElement } from '../../../models/Element';

export type CaseHeader = IElement & {
  title: string;
  heading: string;
  description: string;
  tags: Array<ITag>;
  button_url: string;
  button_title: string;
};

export const defaultCaseHeader: CaseHeader = {
  id: 1,
  name: ElementType.CASE_HEADER,
  title: 'Noorderlijk Film Festival',
  heading: 'De focus op gemak en maatwerk',
  description:
    'Van WordPress website en webwinkel ontwikkeling tot maatwerk plugins en white-label outsourcing.',
  tags: [],
  button_url: '',
  button_title: 'Live bekijken',
};
