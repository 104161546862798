import { PageLink } from '../../../_start/layout/core';

export const employeeManagementSubmenu: Array<PageLink> = [
  {
    title: 'Employees',
    path: '/employee-management/employees',
    isActive: true,
  },

  {
    title: 'Departments',
    path: '/employee-management/departments',
    isActive: true,
  },
];
