import { defaultDepartment, IDepartment } from '../departments/Department';

export interface IEmployee {
  id: number;
  name: string;
  profile_image: string;
  gif: string;
  department_id: number;
  department: IDepartment;
}

export const defaultEmployee: IEmployee = {
  id: 0,
  name: 'John Doe',
  profile_image: '',
  gif: '',
  department_id: 0,
  department: defaultDepartment,
};

export const defaultAuthorPlaceholder: IEmployee = {
	...defaultEmployee,
	name: "John Doe",
	department: { ...defaultDepartment, name: "Department" },
};
