import { SpecializationHeader } from './SpecializationHeader';
import { SpecializationHeaderContent } from './SpecializationHeaderContent';
import { SpecializatiosHeaderFigure } from './SpecializatiosHeaderFigure';
import { FormImageFieldModal } from '../../../../media-management/components/form-image/FormImageFieldModal';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { IElement } from '../../../models/Element';

type SpecializationsHeaderFCProps = {
  index: number;
  data: SpecializationHeader;
  updateData: (element: IElement, index: number) => void;
};

export const SpecializationsHeaderFC = ({
  index,
  data,
  updateData,
}: SpecializationsHeaderFCProps) => {
  const update = (fieldsToUpdate: Partial<SpecializationHeader>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  return (
    <>
      <FormImageFieldModal
        modalId={`${data.name}-${data.id}`}
        name={`image`}
        altText={`image_alt`}
        update={update}
      />

      <ElementWrapper elementIndex={index}>
        <div className="specializations-header">
          <SpecializationHeaderContent data={data} update={update} />
          <SpecializatiosHeaderFigure data={data} update={update} />
        </div>
      </ElementWrapper>
    </>
  );
};
