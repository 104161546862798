import {
  IActionUnion,
  makeParamsAction,
  makePayloadAction,
} from '../../../../setup/redux/utils';
import { ICustomer } from '../../customer-management/models/Customer';
import { IEmployee } from '../../employee-management/pages/employees/Employee';
import { IPlugin } from '../../module-management/models/Plugin';
import { ICaseDetail } from '../../page-management/models/CaseDetail';
import { IPage } from '../../page-management/models/Page';
import { IPost } from '../../post-management/models/Post';
import { ITutorial } from '../../tutorial-management/models/Tutorial';
import { IElement } from '../models/Element';
import { EditContentEvents } from './EditContentEvents';

export const insertElement = makeParamsAction<
  EditContentEvents.INSERT_ELEMENT,
  { element: IElement }
>(EditContentEvents.INSERT_ELEMENT);

export const moveElement = makeParamsAction<
  EditContentEvents.MOVE_ELEMENT,
  { dragIndex: number; hoverIndex: number }
>(EditContentEvents.MOVE_ELEMENT);

export const updateElement = makeParamsAction<
  EditContentEvents.UPDATE_ELEMENT,
  { element: IElement; index: number }
>(EditContentEvents.UPDATE_ELEMENT);

export const removeElement = makeParamsAction<
  EditContentEvents.REMOVE_ELEMENT,
  { index: number }
>(EditContentEvents.REMOVE_ELEMENT);

export const setContent = makePayloadAction<
  EditContentEvents.SET_CONTENT,
  Array<IElement>
>(EditContentEvents.SET_CONTENT);

export const setPost = makePayloadAction<EditContentEvents.SET_POST, IPost>(
  EditContentEvents.SET_POST
);

export const setPage = makePayloadAction<EditContentEvents.SET_PAGE, IPage>(
  EditContentEvents.SET_PAGE
);

export const setCaseDetail = makePayloadAction<
  EditContentEvents.SET_CASE_DETAIL,
  ICaseDetail
>(EditContentEvents.SET_CASE_DETAIL);

export const setTutorial = makePayloadAction<
  EditContentEvents.SET_TUTORIAL,
  ITutorial
>(EditContentEvents.SET_TUTORIAL);

export const setPlugin = makePayloadAction<
  EditContentEvents.SET_PLUGIN,
  IPlugin
>(EditContentEvents.SET_PLUGIN);

export const setAllPosts = makePayloadAction<
  EditContentEvents.SET_ALL_POSTS,
  Array<IPost>
>(EditContentEvents.SET_ALL_POSTS);

export const setAllCaseDetails = makePayloadAction<
  EditContentEvents.SET_ALL_CASE_DETAIL,
  Array<ICaseDetail>
>(EditContentEvents.SET_ALL_CASE_DETAIL);

export const setAllTutorials = makePayloadAction<
  EditContentEvents.SET_ALL_TUTORIALS,
  Array<ITutorial>
>(EditContentEvents.SET_ALL_TUTORIALS);

export const setAllPlugins = makePayloadAction<
  EditContentEvents.SET_ALL_PLUGINS,
  Array<IPlugin>
>(EditContentEvents.SET_ALL_PLUGINS);

export const setAllEmployees = makePayloadAction<
  EditContentEvents.SET_ALL_EMPLOYEES,
  Array<IEmployee>
>(EditContentEvents.SET_ALL_EMPLOYEES);

export const setAllCustomers = makePayloadAction<
  EditContentEvents.SET_ALL_CUSTOMERS,
  Array<ICustomer>
>(EditContentEvents.SET_ALL_CUSTOMERS);

export const setFeaturedCaseDetails = makePayloadAction<
  EditContentEvents.SET_FEATURED_CASE_DETAILS,
  Array<ICaseDetail>
>(EditContentEvents.SET_FEATURED_CASE_DETAILS);

export const setFeaturedPosts = makePayloadAction<
  EditContentEvents.SET_FEATURED_POSTS,
  Array<IPost>
>(EditContentEvents.SET_FEATURED_POSTS);

export const setAuthor = makePayloadAction<
  EditContentEvents.SET_AUTHOR,
  IEmployee
>(EditContentEvents.SET_AUTHOR);

export const editContentActions = {
  insertElement,
  moveElement,
  updateElement,
  removeElement,
  setContent,
  setPost,
  setPage,
  setCaseDetail,
  setTutorial,
  setPlugin,
  setAllPosts,
  setAllCaseDetails,
  setAllTutorials,
  setAllEmployees,
  setAllCustomers,
  setAllPlugins,
  setFeaturedCaseDetails,
  setFeaturedPosts,
  setAuthor,
};

export type EditContentActions = IActionUnion<typeof editContentActions>;
