import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { FailureToast } from '../components/FailureToast';
import { SuccessToast } from '../components/SuccessToast';
import { generateSitemap } from '../modules/settings/redux/SettingsCRUD';

type ErrorResponse = {
  message: string;
  errors: { [key: string]: Array<string> };
};

export const successToast = (message: string, title?: string): void => {
  toast(SuccessToast({ message, title }));
};

export const failureToast = (e: any): void => {
  const error = e as AxiosError<ErrorResponse>;

  // for CORS issue
  if (error.response === undefined) {
    toast(FailureToast({ message: error.message }));
    return;
  }

  const { message } = error.response?.data as ErrorResponse;
  const { errors } = error.response?.data as ErrorResponse;

  // this is for custom exception thrown from the backend
  if (errors) {
    toast(
      FailureToast({
        message: errors[Object.keys(errors)[0]][0],
        title: message,
      })
    );
    return;
  }

  if (typeof errors === 'string') {
    toast(FailureToast({ message: errors, title: message }));
    return;
  }

  toast(FailureToast({ title: message }));
};

type CustomFailureToastProp = {
  title?: string;
  message: string;
};
export const customFailureToast = ({
  title = 'Something went wrong.',
  message,
}: CustomFailureToastProp) => {
  toast(
    FailureToast({
      title,
      message,
    })
  );
};

export const handlePaginate = async <T>(page_url: string) => {
  const response = await axios.get<{ value: PaginatedValue<T> }>(page_url, {
    baseURL: '',
  });
  return response.data.value;
};

export class PaginatedValue<T = any> {
  data: Array<T> = [];
  current_page: number = 0;
  first_page_url: string = '';
  from: number = 0;
  last_page: number = 0;
  last_page_url: string = '';
  links: Array<PaginateLink> = [];
  next_page_url: string | null = null;
  path: string = '';
  per_page: number = 0;
  prev_page_url: string | null = null;
  to: number = 0;
  total: number = 0;
}

export class PaginateLink {
  active: boolean = false;
  label: string = '';
  url: string | null = null;
}

export const defaultPaginatedValue: PaginatedValue = {
  ...new PaginatedValue(),
  current_page: 1,
  links: [
    new PaginateLink(),
    { ...new PaginateLink(), label: '1', active: true },
    new PaginateLink(),
  ],
};

export const handleGenerateSitemap = async (
  loading: boolean,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setLoading(true);

  try {
    const result = await generateSitemap();
    successToast('Sitemap has been generated.');
    window.open(result, '_blank')?.focus();
    setLoading(false);
  } catch (error) {
    failureToast(error);
    setLoading(false);
  }
};
