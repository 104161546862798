import immutabilityHelper from 'immutability-helper';
import Carousel from 'react-elastic-carousel';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../setup';
import { KTSVG, toAbsoluteUrl } from '../../../../../../_start/helpers';
import {
  defaultPlugin,
  IPlugin,
} from '../../../../module-management/models/Plugin';
import {
  defaultTutorialPlaceholder,
  ITutorial,
} from '../../../../tutorial-management/models/Tutorial';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { IElement } from '../../../models/Element';
import { setPlugin } from '../../../redux/EditContentActions';
import { PluginsFeaturedTutorials } from './PluginFeaturedTutorials';
import { PluginFeaturedTutorialsItemWrapper } from './PluginFeaturedTutorialsItemWrapper';

interface Props {
  index: number;
  data: PluginsFeaturedTutorials;
  updateData: (element: IElement, index: number) => void;
}

export const PluginFeaturedTutorialsFC: React.FC<Props> = ({
  index,
  data,
  updateData,
}) => {
  const dispatch = useDispatch();

  const plugin = useSelector((state: RootState) => state.editContent.plugin);

  // initialize base from the Post model featured_posts
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if (plugin.id !== 0 && !initialized) {
      // initialize CONTENT featured post image preview
      const tempTutorials = plugin.tutorials.map((item, idx) => ({
        ...item,
        // image_preview: data.featured_posts[idx].image_preview,
        // image_preview_alt: data.featured_posts[idx].image_preview_alt,
      }));

      update({
        tutorials: tempTutorials,
      });

      setInitialized(true);
    }
  }, [plugin]);

  const update = (fieldsToUpdate: Partial<PluginsFeaturedTutorials>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);

    // update the featured_posts on post data
    dispatch(
      setPlugin({
        ...plugin,
        tutorials: updatedData.tutorials,
      })
    );
  };

  const updateFeaturedTutorial = (idx: number, tutorial: ITutorial) => {
    let featured_tutorials = [...data.tutorials];
    featured_tutorials[idx] = tutorial;

    const updatedData: PluginsFeaturedTutorials = {
      ...data,
      tutorials: featured_tutorials,
    };

    update(updatedData);
  };

  const addTutorial = () => {
    const tutorials = immutabilityHelper(data.tutorials, {
      $push: [defaultTutorialPlaceholder],
    });

    const updatedData = { ...data, tutorials };
    update(updatedData);
  };

  const removeTutorial = (itemIndex: number) => {
    let tutorials = immutabilityHelper(data.tutorials, {
      $splice: [[itemIndex, 1]],
    });

    const updatedData = { ...data, tutorials };
    update(updatedData);
  };

  const allTutorials = useSelector(
    (state: RootState) => state.editContent.allTutorials
  );

  const [selectedTutorialsMap, setSelectedTutorialsMap] = useState<
    Map<number, ITutorial>
  >(new Map());

  useEffect(() => {
    if (allTutorials.length) {
      const iterable: Array<[number, ITutorial]> = plugin.tutorials.map(
        (plugin, idx) => [plugin.id, plugin]
      );

      setSelectedTutorialsMap(new Map(iterable));
    }
  }, [allTutorials, plugin]);

  return (
    <ElementWrapper elementIndex={index}>
      <div className="plugin-featured-tutorials">
        <div className="plugin-featured-tutorials__content">
          {/** begin:: Heading */}
          <TextAreaEdit
            name={'heading'}
            value={data.heading}
            setValue={update}
            className="heading"
          />
          {/** end:: Heading */}
        </div>

        <div className="plugin-featured-tutorials__items">
          <Carousel
            itemsToShow={4}
            isRTL={false}
            pagination={true}
            enableSwipe={false}
            enableMouseSwipe={false}
            itemPadding={[10]}
          >
            {data.tutorials.map((element, idx) => (
              <PluginFeaturedTutorialsItemWrapper
                key={idx}
                itemIndex={idx}
                tutorial={element}
                updateSelected={updateFeaturedTutorial}
                selectedMap={selectedTutorialsMap}
              >
                <div className="card">
                  <span
                    onClick={() => removeTutorial(idx)}
                    className="position-absolute z-index-2 top-0 end-0 me-2 mt-2 opacity-75-hover"
                  >
                    <KTSVG
                      path="/media/icons/duotone/Interface/Minus-Square.svg"
                      className="svg-icon-danger svg-icon-1"
                    />
                  </span>
                  <div className="card__figure" />

                  <div className="card__content">
                    <div className="heading">{element.title}</div>

                    <div className="description">{element.description}</div>

                    <div className="link">
                      <div className="text">Lees meer</div>
                    </div>
                  </div>
                </div>
              </PluginFeaturedTutorialsItemWrapper>
            ))}

            <div onClick={addTutorial} className="add-item">
              <div className="add-item__inner">
                <div className="m-auto d-flex flex-column">
                  <KTSVG
                    path="/media/icons/duotone/Interface/Plus-Square.svg"
                    className="svg-icon-muted svg-icon-3hx text-center"
                  />
                  <span className="mt-4 text-gray-500">Add new item</span>
                </div>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </ElementWrapper>
  );
};
