import axios from 'axios';
import { API_URL } from '../../../support/api';
import { IRole } from '../../role-and-permission/pages/roles/Role';
import { AuthModel } from '../models/AuthModel';

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/auth/get-user`;
export const LOGIN_URL = `${API_URL}/v1/auth/login`;
export const REGISTER_URL = `${API_URL}/auth/register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`;

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, { email, password });
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string
) {
  return axios.post<AuthModel>(REGISTER_URL, {
    email,
    firstname,
    lastname,
    password,
  });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, { email });
}

export async function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  const response = await axios.get<{
    value: {
      id: number;
      email: string;
      name: string;
      google_two_factor_enabled: number;
      roles: Array<IRole>;
    };
  }>(GET_USER_BY_ACCESSTOKEN_URL);
  return response.data.value;
}
