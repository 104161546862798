import React from 'react';
import { toAbsoluteUrl } from '../../../../../../_start/helpers';
import { SpecializationFloatingLeftModal } from './SpecializationsFloatingLeftModal';

interface Props {
  data: SpecializationFloatingLeftModal;
  update: (fieldsToUpdate: Partial<SpecializationFloatingLeftModal>) => void;
}

export const SpecializationsFloatingLeftModalFigure: React.FC<Props> = ({
  data,
  update,
}) => {
  return (
    <div className="specializations-left-modal__figure">
      {/* begin::Content Image Preview */}
      <div className="content-image">
        {data.image_preview ? (
          <div className="overlay">
            <div className="overlay-wrapper">
              <img
                alt="dummy"
                src={data.image_preview}
                style={{
                  width: '100%',
                  height: '320px',
                  objectFit: 'cover',
                }}
              />
            </div>
            <div className="overlay-layer bg-dark bg-opacity-10 flex-column">
              <div>
                <button
                  name="image_preview"
                  className="btn btn-primary btn-shadow"
                  onClick={(event) =>
                    update({
                      [event.currentTarget.name]: '',
                      image_preview_alt: '',
                    })
                  }
                >
                  Remove
                </button>
                <label
                  data-bs-toggle="modal"
                  data-bs-target={`#${data.name}-${data.id}`}
                  className="btn btn-light-primary btn-shadow ms-2"
                >
                  Change
                </label>
              </div>

              <input
                placeholder="Alt attribute"
                className="w-50 mt-2 form-control form-control-lg form-control-solid"
                type="text"
                value={data.image_preview_alt ?? ''}
                onChange={(event) =>
                  update({ image_preview_alt: event.target.value })
                }
              />
            </div>
          </div>
        ) : (
          <>
            <label
              className="mt-5"
              data-bs-toggle="modal"
              data-bs-target={`#${data.name}-${data.id}`}
            >
              <img src={toAbsoluteUrl('/assets/images/700x420.png')} alt="" />
            </label>
          </>
        )}
      </div>
      {/* end::Content Image Preview */}
    </div>
  );
};
