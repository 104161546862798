/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { KTSVG } from '../../../../../_start/helpers';
import {
  failureToast,
  handlePaginate,
  PaginatedValue,
  successToast,
} from '../../../../support/utils';
import { defaultMember, IMember } from '../../models/Member';
import { DeleteModal } from '../../../../components/modals/DeleteModal';
import { deleteMember, getMembers } from '../../redux/UserManagementCRUD';
import { Pagination } from '../../../../components/Pagination';
import { useDebounce } from '../../../../hooks/useDebounce';
import { ActionsDropdown } from '../../../../components/dropdown/ActionsDropdown';

const MembersPage = () => {
  const [loading, setLoading] = useState(false);

  const [paginatedValue, setPaginatedValue] = useState<PaginatedValue<IMember>>(
    new PaginatedValue()
  );

  const handlePaginateState = async (page_url: string) => {
    setLoading(true);
    try {
      const result = await handlePaginate<IMember>(page_url);
      setPaginatedValue(result);
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  const handleFetchList = async () => {
    setLoading(true);
    try {
      const result = await getMembers();
      setPaginatedValue(result);
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetchList();
  }, []);

  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce<string>(search, 750);

  const handleSearch = async () => {
    setLoading(true);
    try {
      const result = await getMembers(search);
      setPaginatedValue(result);
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleSearch();
  }, [debouncedSearch]);

  const [selected, setSelected] = useState<IMember>(defaultMember);
  const [password, setPassword] = useState('');

  const handleDelete = async (member: IMember) => {
    setLoading(true);
    try {
      const payload = {
        ...member,
        password,
      };

      await deleteMember(payload);
      successToast('Member has been deleted.');
      setLoading(false);
      setPassword("");
			handleFetchList();
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  return (
    <>
      {/* begin::Delete Modal */}
      <DeleteModal
        headerTitle={`Delete Member`}
        item={selected}
        handleDelete={handleDelete}
      >
        <>
          <p>{`Are you sure to delete this employee? This action is irreversible.`}</p>
          <p className="my-8 d-block text-center fw-bold">{selected.name}</p>

          <div className="row">
            <label className="col-lg-3 col-form-label">Password</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  type="password"
                  className="form-control form-control-lg form-control-solid"
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
          </div>
        </>
      </DeleteModal>
      {/* end::Delete Modal */}

      <div
        className={`card card-custom card-flush ${
          loading ? 'overlay overlay-block' : ''
        }`}
      >
        <div className="pt-8 card-header">
          <div className="d-flex flex-center border py-1 px-4 bg-white rounded">
            <KTSVG
              path="/media/icons/duotone/General/Search.svg"
              className="svg-icon-1 svg-icon-primary"
            />
            <input
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              type="text"
              className={`form-control border-0 fw-bold ps-2 ${
                '' ? 'w-xxl-600px' : 'w-xxl-350px'
              }`}
              placeholder="Search"
            />
          </div>
          <div className="card-toolbar">
            <Link className="btn btn-sm btn-light" to="members/create">
              Create Member
            </Link>
          </div>
        </div>
        <div className={`card-body py-5 ${loading ? 'overlay-wrapper' : ''}`}>
          <table className="table table-row-gray-300 gy-7">
            <thead>
              <tr className="fw-bolder fs-6 text-gray-800">
                <th>Name</th>
                <th>Email</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {paginatedValue.data.map((member, idx) => (
                <tr key={idx} className="align-middle">
                  <td>{member.name}</td>
                  <td>{member.email}</td>
                  <td className="text-end">
                    {/* begin::Dropdown */}
                    <ActionsDropdown>
                      <div className="menu-item px-3">
                        <Link
                          to={`members/${member.id}/update`}
                          className="menu-link px-3"
                        >
                          Update
                        </Link>
                      </div>
                      <div className="menu-item px-3">
                        <a
                          onClick={() => setSelected(member)}
                          href="#"
                          className="menu-link px-3 text-danger"
                          data-bs-toggle="modal"
                          data-bs-target="#custom_delete_kt_modal_1"
                        >
                          Delete
                        </a>
                      </div>
                      <div className="separator mt-3 opacity-75"></div>
                    </ActionsDropdown>
                    {/* end::Dropdown */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {loading && (
            <div className="overlay-layer rounded bg-dark bg-opacity-5">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
        <div className="card-footer">
          <Pagination
            loading={loading}
            pagination={paginatedValue}
            handlePaginateState={handlePaginateState}
          />
        </div>
      </div>
    </>
  );
};

export default MembersPage;
