import React, { useRef } from 'react';
import { KTSVG, toAbsoluteUrl } from '../../../../../../_start/helpers';
import { IEmployee } from '../../../../employee-management/pages/employees/Employee';
import { FormImageFieldModal } from '../../../../media-management/components/form-image/FormImageFieldModal';

import { IPartner, PluginPartners } from './PluginPartners';

type HomeEmployeeItemProps = {
  pluginPartner: PluginPartners;
  partner: IPartner;
  partnerIndex: number;
  updatePartner: (index: number, partner: IPartner) => void;
  removePartner: (itemIndex: number) => void;
};

export const PluginPartnerItem = ({
  partner,
  pluginPartner,
  partnerIndex,
  updatePartner,
  removePartner,
}: HomeEmployeeItemProps) => {
  const removeItemRef = useRef<HTMLSpanElement>(null);

  const handleRemove = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    event.preventDefault();
    removePartner(partnerIndex);
  };

  const update = (to: Partial<IPartner>) => {
    const updatedPartner = { ...partner, ...to };

    updatePartner(partnerIndex, updatedPartner);
  };

  return (
    <>
      <FormImageFieldModal
        modalId={`${pluginPartner.name}-${pluginPartner.id}-${partnerIndex}`}
        name={`image`}
        altText={`image_alt`}
        update={update}
      />

      <div className="item">
        {/* begin::Delete Item */}
        {pluginPartner.partners.length > 2 && (
          <span
            ref={removeItemRef}
            onClick={handleRemove}
            className="position-absolute z-index-2 top-0 end-0 me-2 mt-2 opacity-75-hover"
          >
            <KTSVG
              path="/media/icons/duotone/Interface/Minus-Square.svg"
              className="svg-icon-danger svg-icon-1"
            />
          </span>
        )}
        {/* end::Delete Item */}

        {/* begin::Employee Image */}
        {partner.image ? (
          <div className="mt-5 overlay" style={{ height: '100%' }}>
            <div className="overlay-wrapper" style={{ height: '100%' }}>
              <img
                alt={partner.image_alt}
                src={partner.image}
                style={{
                  width: '100%',
                  borderRadius: '20px',
                  objectFit: 'cover',
                }}
              />
            </div>
            <div className="overlay-layer bg-dark bg-opacity-10 flex-column justify-items-center align-items-center">
              <div>
                <button
                  name="image"
                  className="btn btn-primary btn-shadow"
                  onClick={() =>
                    update({
                      image: '',
                      image_alt: '',
                    })
                  }
                >
                  Remove
                </button>
                <label
                  className="btn btn-light-primary btn-shadow ms-2"
                  data-bs-toggle="modal"
                  data-bs-target={`#${pluginPartner.name}-${pluginPartner.id}-${partnerIndex}`}
                >
                  Change
                </label>
              </div>

              <input
                placeholder="Alt attribute"
                className="w-25 mt-2 form-control form-control-lg form-control-solid"
                type="text"
                value={partner.image_alt ?? ''}
                onChange={(event) => update({ image_alt: event.target.value })}
              />
            </div>
          </div>
        ) : (
          <>
            <label
              className="mt-5"
              data-bs-toggle="modal"
              data-bs-target={`#${pluginPartner.name}-${pluginPartner.id}-${partnerIndex}`}
            >
              <img
                src={toAbsoluteUrl('/assets/images/900x774.webp')}
                style={{
                  width: '100%',
                  borderRadius: '20px',
                  objectFit: 'cover',
                }}
                alt=""
              />
            </label>
          </>
        )}
        {/* end::Employee Image */}
      </div>
    </>
  );
};
