import { ElementType, IElement } from '../../../models/Element';

export type AboutUsContentDescription = IElement & {
  title: string;
  description: string;
  button_title: string;
  button_url: string;
};

export const defaultAboutUsContentDescription: AboutUsContentDescription = {
  id: 0,
  name: ElementType.ABOUT_US_CONTENT_DESCRIPTION,
  title: 'Directe lijnen',
  description:
    'Tussendoor werkt graag samen met klanten uit het MKB-segment. Geen tussenpersonen maar korte, directe lijnen. Voor zowel realisatie, marketing als onderhoud op WordPress gebied kan je bij Tussendoor terecht. Wij staan je graag bij om jouw bedrijf tot een online-succes te maken en via de online kanalen diverse verkopen en/of leads te scoren',
  button_title: 'Intresse?',
  button_url: '',
};
