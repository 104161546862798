import { ElementType, IElement } from '../../../models/Element';

export type AboutUsEmployees = IElement & {
  title: string;
  heading: string;
  description: string;
};

export const defaultAboutUsEmployees: AboutUsEmployees = {
  id: 0,
  name: ElementType.ABOUT_US_EMPLOYEES,
  title: 'Hier zijn we',
  heading: 'Jouw helden.',
  description:
    'Wij zijn Tussendoor en we geloven graag dat we jouw helden zijn. Omdat we je helpen oplossingen, waar iedereen blij van wordt. Jij, je klanten en dus wij. Aangenaam kennis te maken, we zien je snel.',
};
