import { PageDataContainer, PageLink } from '../../../_start/layout/core';
import { tutorialManagementSubmenu } from './TutorialManagementData';
import { TutorialManagementPage } from './TutorialManagementPage';

const breadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/',
    isActive: false,
  },
  {
    title: 'Manuals',
    path: '/tutorial-management',
    isActive: false,
  },
];

export const TutorialManagementWrapper = () => {
  return (
    <>
      <PageDataContainer
        breadcrumbs={breadcrumbs}
        submenu={tutorialManagementSubmenu}
      />
      <TutorialManagementPage />
    </>
  );
};
