export interface ICustomer {
  id: number;
  name: string;
  business_name: string;
  person_image: string;
  logo: string;
  branding_image: string;
}

export const defaultCustomer: ICustomer = {
  id: 0,
  name: '',
  business_name: '',
  person_image: '',
  logo: '',
  branding_image: '',
};
