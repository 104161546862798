import { PageLink } from '../../../_start/layout/core';

export const tutorialManagementSubmenu: Array<PageLink> = [
  {
    title: 'Tutorials',
    path: '/tutorial-management/tutorials',
    isActive: true,
  },
  {
    title: 'Categorieën',
    path: '/tutorial-management/categories',
    isActive: true,
  },
];
