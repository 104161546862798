import { IFAQ } from './FAQ';

export interface IFAQCategory {
  id: number;
  title: string;
  description: string;
  faqs?: Array<IFAQ>;
}

export interface CreateFAQCategoryPayload {
  title: string;
  description: string;
  faqs?: Array<number>;
}

export type UpdateFAQCategoryPayload = CreateFAQCategoryPayload;

export const defaultFAQCategory: IFAQCategory = {
  id: 0,
  title: '',
  description: '',
  faqs: [],
};
