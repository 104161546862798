/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../setup";
import { KTSVG } from "../../../../../../_start/helpers";
import { TextAreaEdit } from "../../../components/TextAreaEdit";
import { ElementType, IElement } from "../../../models/Element";
import { BlogHeader } from "./BlogHeader";
// import { BlogHeaderPostTypesDropdown } from './components/BlogHeaderPostTypesDropdown';

type BlogHeaderContentProps = {
	data: BlogHeader;
	update: (fieldsToUpdate: Partial<BlogHeader>) => void;
};

const filterOverviewLink = (body: Array<IElement>) => {
	let links: Array<IElement> = [];

	body.forEach((element) => {
		switch (element.name) {
			case ElementType.BLOG_CONTENT_HEADING:
				links.push(element);
				break;

			case ElementType.BLOG_CONTENT_DESCRIPTION:
				links.push(element);
				break;

			case ElementType.BLOG_CONTENT_DESCRIPTION_WITH_INFO:
				links.push(element);
				break;

			case ElementType.BLOG_CONTENT_WIDE_DESCRIPTION_TWO_COLUMN:
				links.push(element);
				break;

			case ElementType.BLOG_CONTENT_WIDE_DESCRIPTION:
				links.push(element);
				break;

			default:
				break;
		}
	});

	return links;
};

export const BlogHeaderContent = ({ data, update }: BlogHeaderContentProps) => {
	const post = useSelector((state: RootState) => state.editContent.post);

	const [overviewLink, setOverviewLink] = useState<
		Array<IElement & { title: string }>
	>([]);

	const content = useSelector(
		(state: RootState) => state.editContent.content
	);

	useEffect(() => {
		setOverviewLink(filterOverviewLink(content) as typeof overviewLink);
	}, [content]);

	return (
		<div className="blog-header__content">
			{/* <BlogHeaderPostTypesDropdown /> */}

			{/* begin::Tags */}
			<div className="content-post-types">
				{post.tags.map((element, idx) => (
					<a key={idx} className="post-type-category">
						{element.name}
					</a>
				))}
			</div>
			{/* end::Tags */}

			<TextAreaEdit
				name="title"
				className="content-title"
				value={data.title}
				setValue={update}
			/>

			<span className="content-reading-time">{`Reading time: Automatically calculated`}</span>

			<div className="content-overview">
				<span className="overview-heading">Overzicht</span>

				<div className="overview-list">
					{overviewLink.map((link, idx) => (
						<a key={idx} className="overview-list-item">
							<span>{link.title}</span>
							<KTSVG
								path="/assets/icons/icon-arrow.svg"
								className="ms-10 svg-icon-2"
								svgClassName="overview-list-item__icon"
							/>
						</a>
					))}
				</div>
			</div>
		</div>
	);
};
