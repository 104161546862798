import { FormImageFieldModal } from '../../../../media-management/components/form-image/FormImageFieldModal';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { IElement } from '../../../models/Element';
import { HomeContent } from './HomeContent';
import { HomeFigure } from './HomeFigure';
import { HomeHeader } from './HomeHeader';

type HomeHeaderFCProps = {
  index: number;
  data: HomeHeader;
  updateData: (element: IElement, index: number) => void;
};

export const HomeHeaderFC = ({
  index,
  data,
  updateData,
}: HomeHeaderFCProps) => {
  const update = (fieldsToUpdate: Partial<HomeHeader>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  return (
    <>
      <FormImageFieldModal
        modalId={`${data.name}-${data.id}`}
        name={`image`}
        altText={`image_alt`}
        update={update}
      />

      <ElementWrapper elementIndex={index}>
        <div className="home-header">
          <HomeContent data={data} update={update} />
          <HomeFigure data={data} update={update} />
        </div>
      </ElementWrapper>
    </>
  );
};
