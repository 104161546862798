/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { KTSVG } from '../../../../../../_start/helpers';
import { ButtonTooltip } from '../../../components/ButtonTooltip';
import { InlineEdit } from '../../../components/InlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import {
  HeaderAndDescriptionItemTypes,
  IHeaderAndDescription,
  PluginArrayHeaderAndDescription,
} from './PluginArrayHeaderAndDescription';

type Props = {
  index: number;
  headerAndDescription: IHeaderAndDescription;
  pluginArray: PluginArrayHeaderAndDescription;
  updateProcess: (
    idx: number,
    headerAndDescription: IHeaderAndDescription
  ) => void;
  removeListItem: (itemIndex: number) => void;
  moveListItem: (dragIndex: number, hoverIndex: number) => void;
};

export const HeaderAndDescription = ({
  index,
  headerAndDescription,
  pluginArray,
  updateProcess,
  removeListItem,
  moveListItem,
}: Props) => {
  const [data, setData] = useState(headerAndDescription);

  const update = (fieldsToUpdate: Partial<IHeaderAndDescription>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
    updateProcess(index, updatedData);
  };

  useEffect(() => {
    setData(headerAndDescription);
  }, [process]);

  const ref = useRef<HTMLLIElement>(null);
  const removeItemRef = useRef<HTMLSpanElement>(null);

  const onMouseEnter = (event: React.MouseEvent<HTMLLIElement>) => {
    removeItemRef.current?.classList.remove('d-none');
  };

  const onMouseLeave = (event: React.MouseEvent<HTMLLIElement>) => {
    removeItemRef.current?.classList.add('d-none');
  };

  const [{ isDragging }, drag] = useDrag(() => ({
    type: `${HeaderAndDescriptionItemTypes.HEADER_AND_DESCRIPTION}-${pluginArray.id}`,
    item: () => ({ id: data.id, index: index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const [{ isOver, canDrop, handlerId }, drop] = useDrop({
    accept: `${HeaderAndDescriptionItemTypes.HEADER_AND_DESCRIPTION}-${pluginArray.id}`,
    drop: (item: { id: number; index: number }) => {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      moveListItem(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      handlerId: monitor.getHandlerId(),
    }),
  });

  drop(drag(ref));

  const opacity = isDragging ? 0.3 : 1;

  return (
    <li
      ref={ref}
      data-handler-id={handlerId}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{ opacity }}
      className={`card ${canDrop ? 'can-drop-highlight' : 'bg-white'} ${
        isOver ? 'is-over-highlight' : ''
      }`}
    >
      {/* begin::Delete Item */}
      {pluginArray.item_list.length > 1 && (
        <span
          ref={removeItemRef}
          onClick={() => removeListItem(index)}
          className="position-absolute top-0 end-0 d-none mt-n3 me-n3 opacity-75-hover"
        >
          <KTSVG
            path="/media/icons/duotone/Interface/Minus-Square.svg"
            className="svg-icon-danger svg-icon-1"
          />
        </span>
      )}
      {/* end::Delete Item */}

      {/* begin::Heading */}
      <TextAreaEdit
        name="heading"
        value={data.heading}
        setValue={update}
        className="heading"
      />
      {/* end::Title */}

      {/* begin::Description */}
      <TextAreaEdit
        name="description"
        value={data.description}
        setValue={update}
        className="description"
      />
      {/* end::Description */}

      {/* begin::Action*/}
      <div className="button">
        <ButtonTooltip
          name={`button_url`}
          value={data.button_url}
          setValue={update}
        >
          <a>
            <InlineEdit
              className="button__text"
              name="button_title"
              value={data.button_title}
              setValue={update}
              activeVerticalPadding={'py-0'}
              dynamicWidth
              style={{
                maxWidth: '22rem',
              }}
            />
          </a>
        </ButtonTooltip>
      </div>
      {/* end::Action*/}
    </li>
  );
};
