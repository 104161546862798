import { ElementType, IElement } from '../../../models/Element';

export type PluginHeader = IElement & {
  image: string;
  image_alt: string;
  title: string;
  heading: string;
  heading_description: string;
  description: string;
  button1_title: string;
  button1_url: string;
  button2_title: string;
  button2_url: string;
};

export const defaultPluginHeader: PluginHeader = {
  id: 0,
  name: ElementType.PLUGIN_HEADER,
  image: '',
  image_alt: '',
  title: 'Wordpress Plugin',
  heading: 'Wordpress Makelaars plugin',
  heading_description: 'Geschikt voor Realworks',
  description:
    'De ideale koppeling geschikt voor Realworks om automatisch objecten en woningen te beheren. Lees meer over deze WordPress koppeling',
  button1_title: 'Kopen',
  button1_url: '',
  button2_title: 'Bekijk Whitepaper',
  button2_url: '',
};
