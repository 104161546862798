export enum DepartmentEvents {
  CREATE_DEPARTMENT_REQUESTED = '[Request Create DEPARTMENT] Action',
  CREATE_DEPARTMENT_LOADED = '[Load Created DEPARTMENT] API',
  CREATE_DEPARTMENT_FAILED = '[Create DEPARTMENT Failed] API',

  VIEW_DEPARTMENT_REQUESTED = '[Request View DEPARTMENT] Action',
  VIEW_DEPARTMENT_LOADED = '[Load Viewed DEPARTMENT] API',
  VIEW_DEPARTMENT_FAILED = '[View DEPARTMENT Failed] API',

  UPDATE_DEPARTMENT_REQUESTED = '[Request Update DEPARTMENT] Action',
  UPDATE_DEPARTMENT_LOADED = '[Load Updated DEPARTMENT] API',
  UPDATE_DEPARTMENT_FAILED = '[Update DEPARTMENT Failed] API',

  DELETE_DEPARTMENT_REQUESTED = '[Request Delete DEPARTMENT] Action',
  DELETE_DEPARTMENT_LOADED = '[Load Deleted DEPARTMENT] API',
  DELETE_DEPARTMENT_FAILED = '[Delete DEPARTMENT Failed] API',

  LIST_DEPARTMENT_REQUESTED = '[Request List DEPARTMENT] Action',
  LIST_DEPARTMENT_LOADED = '[Load List DEPARTMENT] API',
  LIST_DEPARTMENT_FAILED = '[List DEPARTMENT Failed] API',

  RESET_CREATE_DEPARTMENT = '[Reset Create DEPARTMENT] Action',

  SEARCH_DEPARTMENT_REQUESTED = '[Request Search DEPARTMENT] Action',
  SEARCH_DEPARTMENT_LOADED = '[Load Search DEPARTMENT] API',
  SEARCH_DEPARTMENT_FAILED = '[Search DEPARTMENT Failed] API',
}
