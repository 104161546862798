import { ElementType, IElement } from '../../../models/Element';

export type PluginRightScreenshotsInfo = IElement & {
  title: string;
  description: string;
  button_title: string;
  button_url: string;
  image_preview: string;
  image_preview_alt: string;
  phone_preview: string;
  phone_preview_alt: string;
};

export const defaultPluginRightScreenshotsInfo: PluginRightScreenshotsInfo = {
  id: 0,
  name: ElementType.PLUGIN_RIGHT_SCREENSHOTS_INFO,
  title: 'Zoekmachine vriendelijk',
  description:
    'Het CRM-pakket van Realworks is bij uitstek geschikt voor het beheren van objecten, projecten en woningen. De WordPress Makelaars plugin maakt een ijzersterke combinatie.',
  button_title: 'Contact',
  button_url: '',
  image_preview: '',
  image_preview_alt: '',
  phone_preview: '',
  phone_preview_alt: '',
};
