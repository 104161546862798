import { ElementType, IElement } from "../../../models/Element";

export type WebsiteScreenshotWithTextRight = IElement & {
  title: string;
  description: string;
  image_preview: string;
  image_preview_alt: string;
  button_url: string;
  button_title: string;
};

export const defaultWebsiteScreenshotWithTextRight: WebsiteScreenshotWithTextRight =
  {
    id: 0,
    name: ElementType.WEBSITE_SCREENSHOT_WITH_TEXT_RIGHT,
    title: 'Meerdere kleurencombinaties',
    description:
      'Productprijzen worden automatisch berekend op basis van de gewenste huurperiode daarnaast kunnen producten online verlengd worden.',
    image_preview: '',
    image_preview_alt: '',
    button_url: '',
    button_title: 'Contact',
  };
