import { ICaseDetail } from '../../../../page-management/models/CaseDetail';
import { ElementType, IElement } from '../../../models/Element';

export type HomeFeaturedProjects = IElement & {
  title: string;
  description: string;
  button_url: string;
  button_title: string;
  featured_case_details: Array<ICaseDetail>;
};

export const defaultHomeFeaturedProjects: HomeFeaturedProjects = {
  id: 0,
  name: ElementType.HOME_FEATURED_PROJECTS,
  title: 'Projecten',
  description:
    'WodPress websites, WooCommerce webwikels & doelgerichte marketing.',
  button_url: '',
  button_title: 'Alle projecten',
  featured_case_details: [],
};
