import axios from 'axios';
import { API_URL } from '../../../support/api';
import { PaginatedValue } from '../../../support/utils';
import { ITutorialCategory } from '../models/TutorialCategory';

export const BASE_CATEGORY_URL = `${API_URL}/v1/management/tutorial-management/categories`;
export const GET_ALL_CATEGORY_URL = `${API_URL}/v1/management/tutorial-management/categories/get-all`;
export const CREATE_CATEGORY_URL = `${API_URL}/v1/management/tutorial-management/categories/create`;

export const listCategory = async (search = '', direction = 'asc') => {
  const URL = `${BASE_CATEGORY_URL}/list?search=${search}&direction=${direction}`;
  const {
    data: { value },
  } = await axios.get<{ value: PaginatedValue<ITutorialCategory> }>(URL);

  return value;
};

export const createCategory = async (payload: ITutorialCategory) => {
  const {
    data: { value },
  } = await axios.post<{ value: ITutorialCategory }>(
    CREATE_CATEGORY_URL,
    payload
  );

  return value;
};

export const viewCategory = async (id: number | string) => {
  const {
    data: { value },
  } = await axios.get<{ value: ITutorialCategory }>(
    `${BASE_CATEGORY_URL}/${id}/get`
  );

  return value;
};

export const updateCategory = async (payload: ITutorialCategory) => {
  const {
    data: { value },
  } = await axios.post<{ value: ITutorialCategory }>(
    `${BASE_CATEGORY_URL}/${payload.id}/update`,
    payload
  );

  return value;
};

export const deleteCategory = async (payload: ITutorialCategory) => {
  const {
    data: { value },
  } = await axios.post<{ value: ITutorialCategory }>(
    `${BASE_CATEGORY_URL}/${payload.id}/delete`,
    payload
  );

  return value;
};

// unpaginated categories
export const getAllCategory = async () => {
  const {
    data: { value },
  } = await axios.get<{ value: Array<ITutorialCategory> }>(
    GET_ALL_CATEGORY_URL
  );

  return value;
};
