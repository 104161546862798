import Carousel from 'react-elastic-carousel';
import { KTSVG } from '../../../../../../_start/helpers';
import { IEmployee } from '../../../../employee-management/pages/employees/Employee';
import { HomeEmployeeItem } from './HomeEmployeeItem';
import { HomeEmployees } from './HomeEmployees';
import { HomeEmployeesCarouselItemWrapper } from './HomeEmployeesCarouselItemWrapper';

type HomeEmployeesCarouselProps = {
  data: HomeEmployees;
  update: (fieldsToUpdate: Partial<HomeEmployees>) => void;
  addEmployee: () => void;
  removeEmployee: (itemIndex: number) => void;
};

export const HomeEmployeesCarousel = ({
  data,
  update,
  addEmployee,
  removeEmployee,
}: HomeEmployeesCarouselProps) => {
  const updateEmployeeSelected = (idx: number, employee: IEmployee) => {
    let employees = [...data.employees];

    const prevEmployee = employees[idx];
    employees[idx] = {
      ...prevEmployee,
      name: employee.name,
      gif: employee.gif,
      department: employee.department,
    };

    const updatedData: HomeEmployees = {
      ...data,
      employees,
    };

    update(updatedData);
  };

  return (
    <div className="home-employees__carousel">
      <Carousel
        itemsToShow={3}
        isRTL={false}
        pagination={true}
        enableSwipe={false}
        enableMouseSwipe={false}
      >
        {data.employees.map((employee, idx) => (
          <HomeEmployeesCarouselItemWrapper
            key={idx}
            employee={employee}
            employeeIndex={idx}
            updateEmployeeSelected={updateEmployeeSelected}
          >
            <HomeEmployeeItem
              homeEmployee={data}
              employee={employee}
              employeeIndex={idx}
              removeEmployee={removeEmployee}
            />
          </HomeEmployeesCarouselItemWrapper>
        ))}

        <div onClick={addEmployee} className="add-item">
          <div className="m-auto d-flex flex-column">
            <KTSVG
              path="/media/icons/duotone/Interface/Plus-Square.svg"
              className="svg-icon-muted svg-icon-3hx text-center"
            />
            <span className="mt-4 text-gray-500">Add new item</span>
          </div>
        </div>
      </Carousel>
    </div>
  );
};
