import axios from 'axios';
import { API_URL } from '../../../support/api';
import { PaginatedValue } from '../../../support/utils';
import { ICustomer } from '../models/Customer';

const CUSTOMER_URL = `${API_URL}/v1/management/customer-management`;

export const createCustomer = async (payload: ICustomer) => {
  const response = await axios.post<{ value: ICustomer }>(
    `${CUSTOMER_URL}/create`,
    payload
  );

  return response.data.value;
};

export const viewCustomer = async (customer_id: string | number) => {
  const response = await axios.get<{ value: ICustomer }>(
    `${CUSTOMER_URL}/${customer_id}/get`
  );

  return response.data.value;
};

export const updateCustomer = async (payload: ICustomer) => {
  const response = await axios.post<{ value: ICustomer }>(
    `${CUSTOMER_URL}/${payload.id}/update`,
    payload
  );

  return response.data.value;
};

export const deleteCustomer = async (payload: ICustomer) => {
  const response = await axios.post<{ value: ICustomer }>(
    `${CUSTOMER_URL}/${payload.id}/delete`,
    payload
  );

  return response.data.value;
};

export const listCustomer = async (search = '', direction = 'asc') => {
  const URL = `${CUSTOMER_URL}/list?search=${search}&direction=${direction}`;
  const response = await axios.get<{ value: PaginatedValue<ICustomer> }>(URL);

  return response.data.value;
};

export const getAllCustomer = async () => {
  const response = await axios.get<{ value: Array<ICustomer> }>(
    `${CUSTOMER_URL}/get-all`
  );

  return response.data.value;
};
