import axios from 'axios';
import { API_URL } from '../../../support/api';
import { PaginatedValue } from '../../../support/utils';
import { IPlugin } from '../models/Plugin';

export const BASE_PLUGIN_URL = `${API_URL}/v1/management/module-management/plugin-details`;
export const GET_ALL_PLUGIN_URL = `${API_URL}/v1/management/module-management/plugin-details/get-all`;
export const CREATE_PLUGIN_URL = `${API_URL}/v1/management/module-management/plugin-details/create`;

export type CreatePluginPayload = IPlugin & {
  post_category_id: number;
  tags: Array<number>;
  featured_posts: Array<number>;
  featured_plugin_details: Array<number>;
  tutorials: Array<number>;
};

export const listPlugin = async (search = '', direction = 'asc') => {
  const URL = `${BASE_PLUGIN_URL}/list?search=${search}&direction=${direction}`;
  const {
    data: { value },
  } = await axios.get<{ value: PaginatedValue<IPlugin> }>(URL);

  return value;
};

export const createPlugin = async (payload: IPlugin) => {
  const modifiedPayload = {
    ...payload,
    ...payload.content,
  };

  console.log(modifiedPayload);

  const {
    data: { value },
  } = await axios.post<{ value: IPlugin }>(CREATE_PLUGIN_URL, modifiedPayload);

  return value;
};

export const viewPlugin = async (id: number | string) => {
  const {
    data: { value },
  } = await axios.get<{ value: IPlugin }>(`${BASE_PLUGIN_URL}/${id}/get`);

  return value;
};

export const updatePlugin = async (payload: CreatePluginPayload) => {
  const modifiedPayload = {
    ...payload,
    ...payload.content,
  };

  const {
    data: { value },
  } = await axios.post<{ value: IPlugin }>(
    `${BASE_PLUGIN_URL}/${payload.id}/update`,
    modifiedPayload
  );

  return value;
};

export const deletePlugin = async (payload: IPlugin) => {
  const {
    data: { value },
  } = await axios.post<{ value: IPlugin }>(
    `${BASE_PLUGIN_URL}/${payload.id}/delete`,
    payload
  );

  return value;
};

// unpaginated categories
export const getAllPlugin = async () => {
  const {
    data: { value },
  } = await axios.get<{ value: Array<IPlugin> }>(GET_ALL_PLUGIN_URL);

  return value;
};

export const publishPlugin = async (payload: IPlugin) => {
  const {
    data: { value },
  } = await axios.post<{ value: IPlugin }>(
    `${BASE_PLUGIN_URL}/${payload.id}/publish`,
    payload
  );

  return value;
};
