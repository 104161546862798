import { ElementWrapper } from "../../../components/ElementWrapper";
import {
	getHeaderClassName,
	HeaderTypeSelect,
} from "../../../components/HeaderTypeSelect";
import { TextAreaEdit } from "../../../components/TextAreaEdit";
import { IElement } from "../../../models/Element";
import { BlogContentHeading } from "./BlogContentHeading";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

type BlogContentHeadingFCProps = {
	index: number;
	data: BlogContentHeading;
	updateData: (body: IElement, index: number) => void;
};

export const BlogContentHeadingFC = ({
	index,
	data,
	updateData,
}: BlogContentHeadingFCProps) => {
	const update = (fieldsToUpdate: Partial<BlogContentHeading>) => {
		const updatedData = { ...data, ...fieldsToUpdate };
		updateData(updatedData, index);
	};

	return (
		<ElementWrapper elementIndex={index}>
			<div className="blog-content-heading my-10">
				<div className="blog-content">
					<div className="position-relative">
						<TextAreaEdit
							name="title"
							className={`blog-content__title`}
							value={data.title}
							setValue={update}
						/>
						{/* <HeaderTypeSelect
							name={`title_type`}
							value={data.title_type}
							setValue={update}
						/> */}
					</div>

					<div className="position-relative">
						<TextAreaEdit
							name="description"
							className={`blog-content__description ${getHeaderClassName(
								data.description_type
							)}`}
							value={data.description}
							setValue={update}
						/>
						<HeaderTypeSelect
							name={`description_type`}
							value={data.description_type}
							setValue={update}
						/>
					</div>

					<div className="blog-content__editor">
						<CKEditor
							editor={ClassicEditor}
							data={data.content}
							// @ts-ignore
							onReady={(editor) => {
								// You can store the "editor" and use when it is needed.
								// console.log('Editor is ready to use!', editor);
							}}
							// @ts-ignore
							onChange={(event, editor) => {
								const editorData = editor.getData();

								update({ content: editorData });
							}}
							// @ts-ignore
							onBlur={(event, editor) => {
								// console.log('Blur.', editor);
							}}
							// @ts-ignore
							onFocus={(event, editor) => {
								// console.log('Focus.', editor);
							}}
						/>
					</div>
				</div>
			</div>
		</ElementWrapper>
	);
};
