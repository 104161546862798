export type MenuLink = {
  link_title: string;
  link_url: string;
};
export const defaultMenuLink: MenuLink = {
  link_title: '',
  link_url: '',
};

export type MenuContentGroup = {
  group_title: string;
  links: Array<MenuLink>;
};
export const defaultMenuContentGroup: MenuContentGroup = {
  group_title: '',
  links: [],
};

export type MenuSidebar = {
  links: Array<MenuLink>;
};

export interface IMenuSidebar {
  heading: string;
  description: string;
  link_title: string;
  link_url: string;
}

export const defaultMenuSidebar: IMenuSidebar = {
  heading: '',
  description: '',
  link_title: '',
  link_url: '',
};
export interface IMenu {
  id: number;

  name: string;

  heading: string;
  description: string;
  button_title: string;
  button_url: string;

  expand: number;
  url: string;

  content: Array<MenuContentGroup>;
  sidebar: IMenuSidebar;

  package_id: number;
}

export const defaultMenu: IMenu = {
  id: 0,

  name: '',

  heading: '',
  description: '',
  button_title: '',
  button_url: '',

  expand: 0,
  url: '',

  content: [],
  sidebar: defaultMenuSidebar,

  package_id: 0,
};
