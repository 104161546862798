import { KTSVG } from '../../../../../../_start/helpers';
import { ButtonTooltip } from '../../../components/ButtonTooltip';
import { InlineEdit } from '../../../components/InlineEdit';
import { StaticWidthInlineEdit } from '../../../components/StaticWidthInlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { HomeHeader } from './HomeHeader';

type HomeContentProps = {
  data: HomeHeader;
  update: (fieldsToUpdate: Partial<HomeHeader>) => void;
};

export const HomeContent = ({ data, update }: HomeContentProps) => {
  return (
    <div className="home-header__content">
      {/* begin::Title */}
      <StaticWidthInlineEdit
        name="title"
        className="content-title"
        value={data.title}
        setValue={update}
        activeVerticalPadding={`py-0`}
      />
      {/* end::Title */}

      {/* begin::Heading */}
      <TextAreaEdit
        name="heading"
        className="content-heading"
        value={data.heading}
        setValue={update}
      />
      {/* end::Heading */}

      {/* begin::Description */}
      <TextAreaEdit
        name="description"
        className="content-description"
        value={data.description}
        setValue={update}
      />
      {/* end::Description */}

      {/* begin::Actions */}
      <div className="content-action">
        <ButtonTooltip
          name={`button_url`}
          className="content-action__button"
          value={data.button_url}
          setValue={update}
        >
          <button className="btn btn-success rounded-pill">
            <InlineEdit
              name="button_title"
              className="px-1 text-white text-center"
              value={data.button_title}
              setValue={update}
              activeVerticalPadding={'py-1'}
              dynamicWidth
              style={{
                maxWidth: '200px',
              }}
            />
          </button>
        </ButtonTooltip>

        <div className="content-action__icons">
          <span>
            <KTSVG
              path="/assets/icons/icon-code.svg"
              className="svg-icon-2hx fill-green1 color-green5"
            />
          </span>

          <span>
            <KTSVG
              path="/assets/icons/icon-sword.svg"
              className="svg-icon-2hx fill-purple1 color-purple5"
            />
          </span>

          <span>
            <KTSVG
              path="/assets/icons/icon-pencil-line.svg"
              className="svg-icon-2hx fill-yellow1 color-yellow5"
            />
          </span>

          <span>
            <KTSVG
              path="/assets/icons/icon-target.svg"
              className="svg-icon-2hx fill-pink1 color-pink5"
            />
          </span>
        </div>
      </div>
      {/* end::Actions */}
    </div>
  );
};
