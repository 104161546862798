/* eslint-disable jsx-a11y/anchor-is-valid */

import { KTSVG } from "../../../../../../_start/helpers";
import { ButtonTooltip } from "../../../components/ButtonTooltip";
import { InlineEdit } from "../../../components/InlineEdit";
import { StaticWidthInlineEdit } from "../../../components/StaticWidthInlineEdit";
import { TextAreaEdit } from "../../../components/TextAreaEdit";
import { FeaturedUsedPlugins } from "./FeaturedUsedPlugins";

type FeaturedUsedPluginsHeaderProps = {
  data: FeaturedUsedPlugins;
  update: (fieldsToUpdate: Partial<FeaturedUsedPlugins>) => void;
};

export const FeaturedUsedPluginsHeader = ({
  data,
  update,
}: FeaturedUsedPluginsHeaderProps) => {
  return (
    <div className="case-featured-used-plugins__header">
      {/* begin::Title */}
      <div className="header-title">
        <KTSVG
          path="/assets/icons/icon-tree-structure.svg"
          className="me-3 svg-icon-4hx svg-icon-muted"
        />
        <StaticWidthInlineEdit
          name="title"
          className="header-title_input"
          value={data.title}
          setValue={update}
        />
      </div>
      {/* end::Title */}

      {/* begin::Description */}
      <TextAreaEdit
        name="description"
        className="header-description"
        value={data.description}
        setValue={update}
      />
      {/* end::Description */}

      {/* begin::Action Button */}
      <ButtonTooltip
        name={`button_url`}
        className="header-action"
        value={data.button_url}
        setValue={update}
      >
        <a>
          <InlineEdit
            className="action-text"
            name="button_title"
            value={data.button_title}
            setValue={update}
            activeVerticalPadding={'py-0'}
            dynamicWidth
            style={{
              maxWidth: '22rem',
            }}
          />
        </a>
      </ButtonTooltip>
      {/* end::Action Button */}
    </div>
  );
};
