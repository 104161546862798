import React from 'react';
import { ButtonTooltip } from '../../../components/ButtonTooltip';
import { InlineEdit } from '../../../components/InlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { SpecializationsCases } from './SpecializationsCases';

interface Props {
  data: SpecializationsCases;
  update: (fieldsToUpdate: Partial<SpecializationsCases>) => void;
}

export const SpecializationsCasesContent: React.FC<Props> = ({
  data,
  update,
}) => {
  return (
    <div className="inner">
      {/* begin::Heading */}
      <TextAreaEdit
        name="heading"
        className="content-heading"
        value={data.heading}
        setValue={update}
      />
      {/* end::Heading */}

      {/*begin::Description*/}
      <TextAreaEdit
        name="description"
        className="content-description"
        value={data.description}
        setValue={update}
      />
      {/*end::Description*/}

      {/* begin::Action Button with Editable Link Tooltip */}
      <ButtonTooltip
        name={`button_url`}
        className="content-action"
        value={data.button_url}
        setValue={update}
      >
        <button className="btn btn-success rounded-pill">
          <InlineEdit
            name="button_title"
            className="px-1 text-white text-center"
            value={data.button_title}
            setValue={update}
            activeVerticalPadding={'py-1'}
            dynamicWidth
            style={{
              maxWidth: '200px',
            }}
          />
        </button>
      </ButtonTooltip>
      {/* end::Action Button with Editable Link Tooltip */}
    </div>
  );
};
