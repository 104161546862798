import { Redirect, Route, Switch } from 'react-router-dom';
import { PageTitle } from '../../../_start/layout/core';
import { CreateCustomerPage } from './pages/CreateCustomerPage';
import { CustomersPage } from './pages/CustomersPage';
import { UpdateCustomerPage } from './pages/UpdateCustomerPage';

export const CustomerManagementPage = () => {
  return (
    <Switch>
      <Route exact={true} path="/customer-management/customers">
        <>
          <PageTitle>Customers</PageTitle>
          <CustomersPage />
        </>
      </Route>
      <Route exact={true} path="/customer-management/customers/create">
        <>
          <PageTitle>Customers</PageTitle>
          <CreateCustomerPage />
        </>
      </Route>
      <Route
        exact={true}
        path="/customer-management/customers/:customer_id/update"
      >
        <>
          <PageTitle>Customers</PageTitle>
          <UpdateCustomerPage />
        </>
      </Route>

      <Redirect
        from="/customer-management"
        exact={true}
        to="/customer-management/customers"
      />
      <Redirect to="/customer-management/customers" />
    </Switch>
  );
};
