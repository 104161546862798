import { HomeEmployees } from './HomeEmployees';
import { HomeEmployeesCarousel } from './HomeEmployeesCarousel';
import { HomeEmployeesContent } from './HomeEmployeesContent';

import immutabilityHelper from 'immutability-helper';
import { IElement } from '../../../models/Element';
import { defaultEmployee } from '../../../../employee-management/pages/employees/Employee';
import { ElementWrapper } from '../../../components/ElementWrapper';

type HomeEmployeesFCProps = {
  index: number;
  data: HomeEmployees;
  updateData: (body: IElement, index: number) => void;
};

export const HomeEmployeesFC = ({
  index,
  data,
  updateData,
}: HomeEmployeesFCProps) => {
  const update = (fieldsToUpdate: Partial<HomeEmployees>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  const addEmployee = () => {
    const employees = immutabilityHelper(data.employees, {
      $push: [defaultEmployee],
    });

    const updatedData = { ...data, employees };
    updateData(updatedData, index);
  };

  const removeEmployee = (itemIndex: number) => {
    let employees = immutabilityHelper(data.employees, {
      $splice: [[itemIndex, 1]],
    });

    const updatedData = { ...data, employees };
    updateData(updatedData, index);
  };

  return (
    <ElementWrapper elementIndex={index}>
      <div className="home-employees body-my-8">
        <HomeEmployeesContent data={data} update={update} />
        <HomeEmployeesCarousel
          data={data}
          update={update}
          addEmployee={addEmployee}
          removeEmployee={removeEmployee}
        />
      </div>
    </ElementWrapper>
  );
};
