import { ButtonTooltip } from '../../../components/ButtonTooltip';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { InlineEdit } from '../../../components/InlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { IElement } from '../../../models/Element';
import { AboutUsContentDescription } from './AboutUsContentDescription';

type AboutUsContentDescriptionFCProps = {
  index: number;
  data: AboutUsContentDescription;
  updateData: (body: IElement, index: number) => void;
};

export const AboutUsContentDescriptionFC = ({
  index,
  data,
  updateData,
}: AboutUsContentDescriptionFCProps) => {
  const update = (fieldsToUpdate: Partial<AboutUsContentDescription>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  return (
    <ElementWrapper elementIndex={index}>
      <div className="about-us-content-description body-my-8">
        <div className="description__content">
          {/* begin::Title */}
          <InlineEdit
            name="title"
            className="content-title"
            value={data.title}
            setValue={update}
            activeVerticalPadding={`py-0`}
          />
          {/* end::Title */}

          {/* begin::Description */}
          <TextAreaEdit
            name="description"
            className="content-description"
            value={data.description}
            setValue={update}
          />
          {/* end::Description */}

          <div className="content-action">
            <ButtonTooltip
              name={`button_url`}
              className="content-action__button"
              value={data.button_url}
              setValue={update}
            >
              <button className="btn btn-success rounded-pill">
                <InlineEdit
                  name="button_title"
                  className="px-1 text-white text-center"
                  value={data.button_title}
                  setValue={update}
                  activeVerticalPadding={'py-1'}
                  dynamicWidth
                  style={{
                    maxWidth: '22rem',
                  }}
                />
              </button>
            </ButtonTooltip>
          </div>
        </div>
      </div>
    </ElementWrapper>
  );
};
