import { call, put, takeLatest } from 'redux-saga/effects';
import {
  defaultPaginatedValue,
  failureToast,
  PaginatedValue,
  successToast,
} from '../../../support/utils';
import { defaultCustomer, ICustomer } from '../models/Customer';
import {
  createCustomer,
  deleteCustomer,
  listCustomer,
  updateCustomer,
  viewCustomer,
} from './CustomerCRUD';
import { CustomerEvents } from './CustomerEvents';

import * as customerActions from './CustomerActions';

interface ICustomerManagementState {
  loading: boolean;
  createdCustomer: ICustomer;
  viewedCustomer: ICustomer;
  updatedCustomer: ICustomer;
  deletedCustomer: ICustomer;
  paginatedCustomers: PaginatedValue<ICustomer>;
}

const defaultCustomerManagementState: ICustomerManagementState = {
  loading: false,
  createdCustomer: defaultCustomer,
  viewedCustomer: defaultCustomer,
  updatedCustomer: defaultCustomer,
  deletedCustomer: defaultCustomer,
  paginatedCustomers: defaultPaginatedValue,
};

export const reducer = (
  state: ICustomerManagementState = defaultCustomerManagementState,
  action: customerActions.CustomerActions
): ICustomerManagementState => {
  switch (action.type) {
    // CREATE
    case CustomerEvents.CREATE_CUSTOMER_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case CustomerEvents.CREATE_CUSTOMER_LOADED:
      return {
        ...state,
        loading: false,
        createdCustomer: action.payload,
      };

    case CustomerEvents.CREATE_CUSTOMER_FAILED:
      return {
        ...state,
        loading: false,
      };

    // VIEW
    case CustomerEvents.VIEW_CUSTOMER_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case CustomerEvents.VIEW_CUSTOMER_LOADED:
      return {
        ...state,
        loading: false,
        viewedCustomer: action.payload,
      };

    case CustomerEvents.VIEW_CUSTOMER_FAILED:
      return {
        ...state,
        loading: false,
      };

    // UPDATE
    case CustomerEvents.UPDATE_CUSTOMER_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case CustomerEvents.UPDATE_CUSTOMER_LOADED:
      return {
        ...state,
        loading: false,
        updatedCustomer: action.payload,
      };

    case CustomerEvents.UPDATE_CUSTOMER_FAILED:
      return {
        ...state,
        loading: false,
      };

    // DELETE
    case CustomerEvents.DELETE_CUSTOMER_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case CustomerEvents.DELETE_CUSTOMER_LOADED:
      return {
        ...state,
        loading: false,
        deletedCustomer: action.payload,
      };

    case CustomerEvents.DELETE_CUSTOMER_FAILED:
      return {
        ...state,
        loading: false,
      };

    // LIST
    case CustomerEvents.LIST_CUSTOMER_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case CustomerEvents.LIST_CUSTOMER_LOADED:
      return {
        ...state,
        loading: false,
        paginatedCustomers: action.payload,
      };

    case CustomerEvents.LIST_CUSTOMER_FAILED:
      return {
        ...state,
        loading: false,
      };

    case CustomerEvents.RESET_CREATE_CUSTOMER:
      return {
        ...state,
        createdCustomer: defaultCustomer,
      };

    // SEARCH
    case CustomerEvents.SEARCH_CUSTOMER_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case CustomerEvents.SEARCH_CUSTOMER_LOADED:
      return {
        ...state,
        loading: false,
        paginatedCustomers: action.payload,
      };

    case CustomerEvents.SEARCH_CUSTOMER_FAILED:
      return {
        ...state,
        loading: false,
      };

    default:
      return {
        ...state,
      };
  }
};

export function* saga() {
  yield takeLatest(
    CustomerEvents.CREATE_CUSTOMER_REQUESTED,
    function* createCustomerRequest(
      action: ReturnType<typeof customerActions.createCustomerRequest>
    ) {
      try {
        const customer: ICustomer = yield call(createCustomer, action.customer);

        yield put(customerActions.createCustomerLoad(customer));

        yield call(successToast, 'Customer has been created.');
      } catch (error) {
        yield put(customerActions.createCustomerFailed());
        yield call(failureToast, error);
      }
    }
  );

  yield takeLatest(
    CustomerEvents.VIEW_CUSTOMER_REQUESTED,
    function* viewCustomerRequest(
      action: ReturnType<typeof customerActions.viewCustomerRequest>
    ) {
      try {
        const customer: ICustomer = yield call(
          viewCustomer,
          action.customer_id
        );

        yield put(customerActions.viewCustomerLoad(customer));
      } catch (error) {
        yield put(customerActions.viewCustomerFailed());
        yield call(failureToast, error);
      }
    }
  );

  yield takeLatest(
    CustomerEvents.UPDATE_CUSTOMER_REQUESTED,
    function* updateCustomerRequest(
      action: ReturnType<typeof customerActions.updateCustomerRequest>
    ) {
      try {
        const customer: ICustomer = yield call(updateCustomer, action.customer);

        yield put(customerActions.updateCustomerLoad(customer));
        yield call(successToast, 'Customer has been updated.');
      } catch (error) {
        yield put(customerActions.updateCustomerFailed());
        yield call(failureToast, error);
      }
    }
  );

  yield takeLatest(
    CustomerEvents.DELETE_CUSTOMER_REQUESTED,
    function* deleteCustomerRequest(
      action: ReturnType<typeof customerActions.deleteCustomerRequest>
    ) {
      try {
        const customer: ICustomer = yield call(deleteCustomer, action.customer);

        yield put(customerActions.deleteCustomerLoad(customer));
        yield call(successToast, 'Customer has been deleted.');

        yield put(customerActions.listCustomerRequest());
      } catch (error) {
        yield put(customerActions.deleteCustomerFailed());
        yield call(failureToast, error);
      }
    }
  );

  yield takeLatest(
    CustomerEvents.LIST_CUSTOMER_REQUESTED,
    function* listCustomerRequest() {
      try {
        const paginatedCustomers: PaginatedValue<ICustomer> = yield call(
          listCustomer
        );

        yield put(customerActions.listCustomerLoad(paginatedCustomers));
      } catch (error) {
        yield put(customerActions.listCustomerFailed());
        yield call(failureToast, error);
      }
    }
  );

  yield takeLatest(
    CustomerEvents.SEARCH_CUSTOMER_REQUESTED,
    function* searchCustomerRequest(
      action: ReturnType<typeof customerActions.searchCustomerRequest>
    ) {
      try {
        const paginatedCustomers: PaginatedValue<ICustomer> = yield call(
          listCustomer,
          action.search
        );

        yield put(customerActions.searchCustomerLoad(paginatedCustomers));
      } catch (error) {
        yield put(customerActions.searchCustomerFailed());
        yield call(failureToast, error);
      }
    }
  );
}
