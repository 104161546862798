import { defaultAuthorPlaceholder, IEmployee } from "../../../../employee-management/pages/employees/Employee";
import { ElementType, IElement } from "../../../models/Element";


export type BlogAuthorFooter = IElement & {
	content: string;
	button_title: string;
	button_url: string;
	author: IEmployee;
};

export const defaultBlogAuthorFooter: BlogAuthorFooter = {
	id: 0,
	name: ElementType.BLOG_AUTHOR_FOOTER,
	content:
		"<p>Je las een artikel over het adverteren op Facebook. Heb je nog <strong>vragen?</strong></p>",
	button_title: "Contact met",
	button_url: "",
	author: defaultAuthorPlaceholder,
};
