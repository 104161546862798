/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../../../../setup';
import { ScrollComponent } from '../../../../../_start/assets/ts/components';
import {
  getConfig,
  IThemeConfig,
  useTheme,
} from '../../../../../_start/layout/core';
import { WEB_URL } from '../../../../support/api';
import {
  failureToast,
  handleGenerateSitemap,
  successToast,
} from '../../../../support/utils';
import { getAllCustomer } from '../../../customer-management/redux/CustomerCRUD';
import { ElementDropContainer } from '../../../edit-content/elements/ElementDropContainer';
import {
  setPost,
  setContent,
  setAllPosts,
  setAllEmployees,
  setAllCustomers,
  setAllCaseDetails,
  setAllTutorials,
  setTutorial,
} from '../../../edit-content/redux/EditContentActions';
import { getAllEmployee } from '../../../employee-management/redux/EmployeeCRUD';
import { getAllCaseDetail } from '../../../page-management/redux/CaseDetailCRUD';
import { getAllPost } from '../../../post-management/redux/PostsCRUD';
import { StatusType } from '../../models/Tutorial';
import {
  CreateTutorialPayload,
  getAllTutorial,
  publishTutorial,
  updateTutorial,
  viewTutorial,
} from '../../redux/TutorialsCRUD';

const defaultPageConfig = getConfig();
const pageConfig: Partial<IThemeConfig> = {
  sidebar: {
    ...defaultPageConfig.sidebar,
    display: true,
    content: 'edit-content',
    bgColor: 'bg-white',
  },
};

export const TutorialEditContent = () => {
  const { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();

  const tutorial = useSelector(
    (state: RootState) => state.editContent.tutorial
  );

  const content = useSelector((state: RootState) => state.editContent.content);

  const [loading, setLoading] = useState(false);

  // SUBMIT
  const handleSave = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);

    const publish_date = format(
      new Date(tutorial.publish_date),
      'yyyy-MM-dd HH:mm:ss'
    );

    try {
      const payload: CreateTutorialPayload = {
        ...tutorial,
        tutorial_categories: tutorial.categories.map((i) => i.id),
        content: content,
        publish_date,
      };

      await updateTutorial(payload);
      successToast('Content has been updated.');
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  // INITIALIZATION
  const handleFetch = async (id: string) => {
    setLoading(true);

    try {
      dispatch(setContent([]));

      const [
        tutorial,
        allTutorials,
        allPosts,
        allCaseDetails,
        allEmployees,
        allCustomers,
      ] = await Promise.all([
        viewTutorial(id),
        getAllTutorial(),
        getAllPost(),
        getAllCaseDetail(),
        getAllEmployee(),
        getAllCustomer(),
      ]);

      // set ALL TUTORIAL data
      dispatch(setAllTutorials(allTutorials));

      // set ALL POST data
      dispatch(setAllPosts(allPosts));

      // set ALL CASE DETAIL data
      dispatch(setAllCaseDetails(allCaseDetails));

      // set ALL EMPLOYEE data
      dispatch(setAllEmployees(allEmployees));

      // set ALL CUSTOMER data
      dispatch(setAllCustomers(allCustomers));

      dispatch(setTutorial(tutorial)); // set POST data
      dispatch(setContent(tutorial.content)); // set CONTENT data

      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  const { setTheme } = useTheme();
  // Refresh UI after config updates
  useEffect(() => {
    setTheme(pageConfig);
    setTimeout(() => {
      ScrollComponent.reinitialization();
    }, 100);

    if (id) {
      handleFetch(id);
    }

    return () => {
      setTheme(defaultPageConfig);
    };
  }, [id]);

  const handlePublish = async () => {
    setLoading(true);

    try {
      const result = await publishTutorial(tutorial);
      dispatch(setTutorial(result));
      successToast('Post has been published.');
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        {/* begin::Page Title */}
        <div className="align-items-center">
          <h2>{tutorial.title}</h2>
        </div>
        {/* end::Page Title */}

        {/* begin::Actions */}
        <div className="d-inline-flex align-items-center">
          <form onSubmit={handleSave} className="me-8">
            <a
              aria-disabled
              className={`btn btn-active-light-info`}
              onClick={() => handleGenerateSitemap(loading, setLoading)}
            >
              Generate Sitemap
            </a>
            {tutorial.status === StatusType.DRAFT && (
              <button
                type={'button'}
                className="btn btn-active-light-warning"
                disabled={loading}
                onClick={handlePublish}
              >
                Publish
              </button>
            )}
            <a
              aria-disabled
              className={`btn btn-active-light-success`}
              href={`${WEB_URL}/tutorial/${tutorial.categories[0]?.slug}/${tutorial.slug}`}
              target="_blank"
              rel="noreferrer"
            >
              Preview
            </a>
            <button
              type="submit"
              disabled={loading}
              className="btn btn-active-light-primary"
            >
              Save
            </button>
          </form>

          {/* begin::Toggle Sidebar */}
          {/* <button
						onClick={toggleSidebarDisplay}
						className="btn btn-icon btn-sm btn-white btn-active-primary"
						title="Toggle Sidebar"
					>
						{config.sidebar.display ? (
							<KTSVG
								path="/media/icons/duotone/Navigation/Right-2.svg"
								className="svg-icon-1 svg-icon-dark"
							/>
						) : (
							<KTSVG
								path="/media/icons/duotone/Navigation/Left-2.svg"
								className="svg-icon-1 svg-icon-dark"
							/>
						)}
					</button> */}
          {/* end::Toggle Sidebar */}
        </div>
        {/* end::Actions */}
      </div>

      {/* begin::Card */}
      <div className={`mt-3 card ${loading ? 'overlay overlay-block' : ''}`}>
        {/* begin::Card Body */}
        <div className={`card-body p-20 ${loading ? 'overlay-wrapper' : ''}`}>
          {/* begin::Element Drop Container */}
          <ElementDropContainer />

          {/* end::Element Drop Container */}

          {/* begin::Overlay Layer */}
          {loading && (
            <div className="overlay-layer rounded bg-dark bg-opacity-5">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
          {/* begin::Overlay Layer */}
        </div>
        {/* end::Card Body */}
      </div>
      {/* end::Card */}
    </>
  );
};
