import React from 'react';
import { v4 as uuid } from 'uuid';
import { KTSVG } from '../../../../_start/helpers';

interface Props {
  svgIcon: string;
  update: (newIcon: string) => void;
  className?: string;
  containerClassName?: string;
  svgClassName?: string;
  style?: React.CSSProperties;
}

export const SVGIconEdit: React.FC<Props> = ({
  svgIcon,
  update,
  className,
  containerClassName,
  svgClassName,
  style,
}) => {
  const id = uuid();

  const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files!;
    let fileContent: string | ArrayBuffer | null = '';

    const fr = new FileReader();
    fr.onload = () => {
      fileContent = fr.result;
      update(
        // @ts-ignore
        fileContent
      );
    };

    fr.readAsText(fileList[0]);
  };
  return (
    <div className={className} style={style}>
      <div className="overlay">
        <div className="overlay-layer bg-dark bg-opacity-10">
          <label htmlFor={id} className="btn btn-light-primary btn-shadow">
            Change
          </label>
        </div>
        <div>
          <KTSVG
            className={containerClassName}
            path={svgIcon ?? ''}
            svgClassName={svgClassName}
          />
        </div>
      </div>
      <input
        id={id}
        type="file"
        accept=".svg"
        name="svgIcon"
        style={{ display: 'none' }}
        onChange={(event) => {
          handleFile(event);
        }}
      />
    </div>
  );
};
