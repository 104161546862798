import { format } from 'date-fns';
import { ICustomer } from '../../../customer-management/models/Customer';
import { IEmployee } from '../../../employee-management/pages/employees/Employee';
import { IPostType } from '../post-types/PostType';
import { ITag } from '../tags/Tag';
import { IBody } from './edit-content/components/body/Body';
import { IHeader } from './edit-content/components/header/Header';

export enum PageStatusTypes {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  FUTURE = 'future',
}

export enum PageTypes {
  HOME = 'home',
  ABOUT_US = 'about-us',
  SPECIALIZATIONS = 'specializations',
  CASE = 'case',
  PLUGIN = 'plugin',
  BLOG = 'blog',
}

export type PageContent = {
  header: IHeader | null;
  body: Array<IBody>;
};
export interface IPage {
  id: number;
  image_preview: string;
  mobile_preview: string;
  title: string;
  slug: string;
  status: string;
  publish_date: string;
  description: string;
  page_type: PageTypes;
  post_type_id: number | string | null;
  post_type?: IPostType;
  created_at?: string;
  updated_at?: string;
  content: PageContent;

  author?: IEmployee | null;
  author_id: number | null;

  customer?: ICustomer | null;
  customer_id: number | null;

  tags: Array<ITag> | Array<number>;

  follow: boolean;
}

export const defaultPage: IPage = {
  id: 0,
  image_preview: '',
  mobile_preview: '',
  title: '',
  slug: '',
  status: 'draft',
  publish_date: format(new Date(), 'yyyy-MM-dd'),
  description: '',
  post_type_id: null,
  page_type: PageTypes.CASE,
  content: {
    header: null,
    body: [],
  },

  author_id: 0,
  customer_id: 0,

  tags: [],

  follow: true,
};
