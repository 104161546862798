import { ElementType, IElement } from '../../../models/Element';

export type SpecializationFloatingRightModal = IElement & {
  heading: string;
  description: string;
  button_url: string;
  button_title: string;
  image_preview: string;
  image_preview_alt: string;
};

export const defaultSpecializationFloatingRightModal: SpecializationFloatingRightModal =
  {
    id: 0,
    name: ElementType.SPECIALIZATION_FLOATING_RIGHT_MODAL,
    heading: 'Search Engine Optimalisation',
    description:
      'Van WordPress website en webwinkel ontwikkeling tot maatwerk plugins en white-label outsourcing.',
    button_url: '',
    button_title: 'Contact',
    image_preview: '',
    image_preview_alt: '',
  };
