import axios from "axios";
import { API_URL } from "../../../support/api";
import { PaginatedValue } from "../../../support/utils";
import { IMember } from "../../user-management/models/Member";
import { IPost } from "../models/Post";

export const BASE_POST_URL = `${API_URL}/v1/management/post-management/posts`;
export const GET_ALL_POST_URL = `${API_URL}/v1/management/post-management/posts/get-all`;
export const CREATE_POST_URL = `${API_URL}/v1/management/post-management/posts/create`;

export type CreatePostPayload = IPost & {
  post_category_id: number;
  tags: Array<number>;
  featured_posts: Array<number>;
};

export const listPost = async (search = "", direction = "asc") => {
  const URL = `${BASE_POST_URL}/list?search=${search}&direction=${direction}`;
  const {
    data: { value },
  } = await axios.get<{ value: PaginatedValue<IPost> }>(URL);

  return value;
};

export const getCurrentUser = async () => {
  const URL = `${API_URL}/users/me`;
  const {
    data: { user },
  } = await axios.get<{ user: IMember }>(URL);

  return user;
};

export const createPost = async (payload: CreatePostPayload) => {
  const {
    data: { value },
  } = await axios.post<{ value: IPost }>(CREATE_POST_URL, payload);

  return value;
};

export const viewPost = async (id: number | string) => {
  const {
    data: { value },
  } = await axios.get<{ value: IPost }>(`${BASE_POST_URL}/${id}/get`);

  return value;
};

export const updatePost = async (payload: IPost) => {
  const {
    data: { value },
  } = await axios.post<{ value: IPost }>(
    `${BASE_POST_URL}/${payload.id}/update`,
    payload
  );

  return value;
};

export const deletePost = async (payload: IPost) => {
  const {
    data: { value },
  } = await axios.post<{ value: IPost }>(
    `${BASE_POST_URL}/${payload.id}/delete`,
    payload
  );

  return value;
};

// unpaginated categories
export const getAllPost = async () => {
  const {
    data: { value },
  } = await axios.get<{ value: Array<IPost> }>(GET_ALL_POST_URL);

  return value;
};

export const publishPost = async (payload: IPost) => {
  const {
    data: { value },
  } = await axios.post<{ value: IPost }>(
    `${BASE_POST_URL}/${payload.id}/publish`,
    payload
  );

  return value;
};
