/* eslint-disable jsx-a11y/anchor-is-valid */
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toAbsoluteUrl } from '../../../../../../_start/helpers';
import { FormImageFieldModal } from '../../../../media-management/components/form-image/FormImageFieldModal';
import { ButtonTooltip } from '../../../components/ButtonTooltip';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { InlineEdit } from '../../../components/InlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { IElement } from '../../../models/Element';
import { PluginRightScreenshotInfoNoButtons } from './PluginRightScreenshotInfoNoButtons';

type WebsiteScreenshotWithTextRightFCProps = {
  index: number;
  data: PluginRightScreenshotInfoNoButtons;
  updateData: (body: IElement, index: number) => void;
};

export const PluginRightScreenshotInfoNoButtonsFC = ({
  index,
  data,
  updateData,
}: WebsiteScreenshotWithTextRightFCProps) => {
  const update = (
    fieldsToUpdate: Partial<PluginRightScreenshotInfoNoButtons>
  ) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  return (
    <>
      <FormImageFieldModal
        modalId={`${data.name}-${data.id}`}
        name={`image_preview`}
        altText={`image_preview_alt`}
        update={update}
      />

      <ElementWrapper elementIndex={index}>
        <div className="plugin-right-screenshot-info-no-buttons">
          <div className="showcase">
            {/* begin::Content */}
            <div className="plugin-right-screenshot-info-no-buttons__content">
              <TextAreaEdit
                name="title"
                className="content-title"
                value={data.title}
                setValue={update}
              />

              <CKEditor
                className="content-description"
                editor={ClassicEditor}
                data={data.description}
                // @ts-ignore
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  // console.log('Editor is ready to use!', editor);
                }}
                // @ts-ignore
                onChange={(event, editor) => {
                  const editorData = editor.getData();

                  update({ description: editorData });
                }}
                // @ts-ignore
                onBlur={(event, editor) => {
                  //   console.log('Blur.', editor);
                }}
                // @ts-ignore
                onFocus={(event, editor) => {
                  //   console.log('Focus.', editor);
                }}
              />
            </div>
            {/* end::Content */}

            {/* begin::Figure */}
            <div className="plugin-right-screenshot-info-no-buttons__figure">
              <div className="screen screen--dark">
                {data.image_preview ? (
                  <div className="overlay">
                    <div className="overlay-wrapper">
                      <img
                        alt="dummy"
                        src={data.image_preview}
                        style={{
                          width: '100%',

                          objectFit: 'cover',
                        }}
                      />
                    </div>
                    <div className="overlay-layer bg-dark bg-opacity-10 flex-column">
                      <div>
                        <button
                          name="image_preview"
                          className="btn btn-primary btn-shadow"
                          onClick={() =>
                            update({
                              image_preview: '',
                              image_preview_alt: '',
                            })
                          }
                        >
                          Remove
                        </button>
                        <label
                          className="btn btn-light-primary btn-shadow ms-2"
                          data-bs-toggle="modal"
                          data-bs-target={`#${data.name}-${data.id}`}
                        >
                          Change
                        </label>
                      </div>

                      <input
                        placeholder="Alt attribute"
                        className="w-50 mt-2 form-control form-control-lg form-control-solid"
                        type="text"
                        value={data.image_preview_alt ?? ''}
                        onChange={(event) =>
                          update({ image_preview_alt: event.target.value })
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <label
                      data-bs-toggle="modal"
                      data-bs-target={`#${data.name}-${data.id}`}
                    >
                      <img
                        src={toAbsoluteUrl('/assets/images/900x630.png')}
                        alt=""
                        style={{
                          width: '100%',

                          objectFit: 'cover',
                        }}
                      />
                    </label>
                  </>
                )}
              </div>
            </div>
            {/* end::Figure */}
          </div>
        </div>
      </ElementWrapper>
    </>
  );
};
