import { ElementType, IElement } from "../../../models/Element";

export type AboutUsImageBanner = IElement & {
  image: string;
  image_alt: string;
};

export const defaultAboutUsImageBanner: AboutUsImageBanner = {
  id: 0,
  name: ElementType.ABOUT_US_IMAGE_BANNER,
  image: '',
  image_alt: '',
};
