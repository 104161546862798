import {
  IActionUnion,
  makeBaseAction,
  makeParamsAction,
  makePayloadAction,
} from '../../../../../setup/redux/utils';
import { ICustomer } from '../../../customer-management/models/Customer';
import { IEmployee } from '../../../employee-management/pages/employees/Employee';
import { IBody } from '../../pages/Pages/edit-content/components/body/Body';
import { IHeader } from '../../pages/Pages/edit-content/components/header/Header';
import { IPage } from '../../pages/Pages/Page';
import { IPostType } from '../../pages/post-types/PostType';
import { PageEvents } from './PageEvents';

export const editPageContentRequest = makeParamsAction<
  PageEvents.EDIT_PAGE_CONTENT_REQUESTED,
  { page_id: string | number }
>(PageEvents.EDIT_PAGE_CONTENT_REQUESTED);
export const editPageContentLoaded =
  makeBaseAction<PageEvents.EDIT_PAGE_CONTENT_LOADED>(
    PageEvents.EDIT_PAGE_CONTENT_LOADED
  );
export const editPageContentFailed =
  makeBaseAction<PageEvents.EDIT_PAGE_CONTENT_FAILED>(
    PageEvents.EDIT_PAGE_CONTENT_FAILED
  );

export const setPostTypes = makePayloadAction<
  PageEvents.SET_POST_TYPES,
  Array<IPostType>
>(PageEvents.SET_POST_TYPES);

export const setCasePages = makePayloadAction<
  PageEvents.SET_CASE_PAGES,
  Array<IPage>
>(PageEvents.SET_CASE_PAGES);

export const setBlogPages = makePayloadAction<
  PageEvents.SET_BLOG_PAGES,
  Array<IPage>
>(PageEvents.SET_BLOG_PAGES);

export const setCustomers = makePayloadAction<
  PageEvents.SET_CUSTOMERS,
  Array<ICustomer>
>(PageEvents.SET_CUSTOMERS);

export const setEmployees = makePayloadAction<
  PageEvents.SET_EMPLOYEES,
  Array<IEmployee>
>(PageEvents.SET_EMPLOYEES);

export const setPage = makePayloadAction<PageEvents.SET_PAGE, IPage>(
  PageEvents.SET_PAGE
);

export const insertHeader = makeParamsAction<
  PageEvents.INSERT_HEADER,
  { header: IHeader }
>(PageEvents.INSERT_HEADER);
export const updateHeader = makePayloadAction<
  PageEvents.UPDATE_HEADER,
  IHeader
>(PageEvents.UPDATE_HEADER);
export const removeHeader = makeBaseAction<PageEvents.REMOVE_HEADER>(
  PageEvents.REMOVE_HEADER
);

export const insertBody = makeParamsAction<
  PageEvents.INSERT_BODY,
  { body: IBody }
>(PageEvents.INSERT_BODY);

export const moveBody = makeParamsAction<
  PageEvents.MOVE_BODY,
  { dragIndex: number; hoverIndex: number }
>(PageEvents.MOVE_BODY);

export const updateBody = makeParamsAction<
  PageEvents.UPDATE_BODY,
  { body: IBody; index: number }
>(PageEvents.UPDATE_BODY);

export const removeBody = makeParamsAction<
  PageEvents.REMOVE_BODY,
  { index: number }
>(PageEvents.REMOVE_BODY);

export const savePageRequest = makeParamsAction<
  PageEvents.SAVE_PAGE_REQUESTED,
  { page: IPage }
>(PageEvents.SAVE_PAGE_REQUESTED);

export const savePageLoaded = makeBaseAction<PageEvents.SAVE_PAGE_LOADED>(
  PageEvents.SAVE_PAGE_LOADED
);
export const savePageFailed = makeBaseAction<PageEvents.SAVE_PAGE_FAILED>(
  PageEvents.SAVE_PAGE_FAILED
);

export const setWasInitiallySaved = makePayloadAction<
  PageEvents.SET_WAS_INITIALLY_SAVED,
  boolean
>(PageEvents.SET_WAS_INITIALLY_SAVED);

const actions = {
  editPageContentRequest,
  editPageContentLoaded,
  editPageContentFailed,
  setPostTypes,
  setCasePages,
  setBlogPages,
  setCustomers,
  setEmployees,
  setPage,
  insertHeader,
  updateHeader,
  removeHeader,
  insertBody,
  moveBody,
  updateBody,
  removeBody,
  savePageRequest,
  savePageLoaded,
  savePageFailed,
  setWasInitiallySaved,
};

export type PageActions = IActionUnion<typeof actions>;
