import { IFeature } from './Feature';

export interface IPackage {
  id: number;
  name: string;
  price: string;
  features: Array<IFeature>;
}

export const defaultPackage: IPackage = {
  id: 0,
  name: '',
  price: '',
  features: [],
};
