import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { RootState } from '../../setup';
import { RoleNames } from '../modules/role-and-permission/pages/roles/Role';

type AuthRouteProps = {
  Component: React.FC<RouteComponentProps>;
  path: string;
  exact?: boolean;
  requiredRoles: Array<RoleNames | undefined>;
};

export const AuthRoute = ({
  Component,
  path,
  exact = false,
  requiredRoles,
}: AuthRouteProps) => {
  const user = useSelector((state: RootState) => state.auth.user);

  const hasRequiredRole = requiredRoles.includes(user?.roles[0].name);

  return (
    <Route
      exact={exact}
      path={path}
      render={(props: RouteComponentProps) => {
        if (hasRequiredRole) {
          return <Component {...props} />;
        }

        return <Redirect to={`/dashboard`} />;
      }}
    />
  );
};
