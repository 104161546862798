/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../setup';
import { KTSVG, toAbsoluteUrl } from '../../../../../../_start/helpers';
import { ButtonTooltip } from '../../../components/ButtonTooltip';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { InlineEdit } from '../../../components/InlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { IElement } from '../../../models/Element';
import { SpecializationFeaturedCases } from './SpecializationsFeaturedCases';

import immutabilityHelper from 'immutability-helper';

import { SpecializationFeaturedCaseWrapper } from './SpecializationFeaturedCasesWrapper';
import { setPage } from '../../../redux/EditContentActions';
import { useEffect, useState } from 'react';
import {
  defaultCaseDetailPlaceholder,
  ICaseDetail,
} from '../../../../page-management/models/CaseDetail';
// import { BlogFeaturedFigure } from "./BlogFeaturedFigure";

type SpecializationsFeaturedCasesProps = {
  index: number;
  data: SpecializationFeaturedCases;
  updateData: (element: IElement, index: number) => void;
};

export const SpecializationFeatureCasesFC = ({
  index,
  data,
  updateData,
}: SpecializationsFeaturedCasesProps) => {
  const dispatch = useDispatch();

  const page = useSelector((state: RootState) => state.editContent.page);

  // initialize base from the Post model featured_posts
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if (page.id !== 0 && !initialized) {
      // initialize CONTENT featured post image preview
      const tempPosts = page.page_featured_case_details.map((item, idx) => ({
        ...item,
        // image_preview: data.featured_posts[idx].image_preview,
        // image_preview_alt: data.featured_posts[idx].image_preview_alt,
      }));

      update({
        featured_cases: tempPosts,
      });

      setInitialized(true);
    }
  }, [page]);

  const update = (fieldsToUpdate: Partial<SpecializationFeaturedCases>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);

    // update the featured_posts on post data
    dispatch(
      setPage({
        ...page,
        page_featured_case_details: updatedData.featured_cases,
      })
    );
  };

  const updateFeaturedPost = (idx: number, cases: ICaseDetail) => {
    let featured_cases = [...data.featured_cases];
    featured_cases[idx] = cases;

    const updatedData: SpecializationFeaturedCases = {
      ...data,
      featured_cases: featured_cases,
    };

    update(updatedData);
  };

  const addCase = () => {
    const featured_cases = immutabilityHelper(data.featured_cases, {
      $push: [defaultCaseDetailPlaceholder],
    });

    const updatedData = { ...data, featured_cases: featured_cases };
    update(updatedData);
  };

  const removeCase = (itemIndex: number) => {
    let featured_cases = immutabilityHelper(data.featured_cases, {
      $splice: [[itemIndex, 1]],
    });

    const updatedData = { ...data, featured_cases: featured_cases };
    update(updatedData);
  };

  const allPosts = useSelector(
    (state: RootState) => state.editContent.allPosts
  );

  const [selectedCasesMap, setSelectedCasesMap] = useState<
    Map<number, ICaseDetail>
  >(new Map());

  useEffect(() => {
    if (allPosts.length) {
      const iterable: Array<[number, ICaseDetail]> =
        page.page_featured_case_details.map((post, idx) => [post.id, post]);

      setSelectedCasesMap(new Map(iterable));
    }
  }, [allPosts, page]);

  return (
    <ElementWrapper elementIndex={index}>
      <div className={`specializations-cases`}>
        <div className="specializations-cases__content">
          <TextAreaEdit
            className={`content-heading`}
            name="title"
            value={data.title}
            setValue={update}
          />

          <TextAreaEdit
            className={`content-description`}
            name="description"
            value={data.description}
            setValue={update}
          />

          <ButtonTooltip
            name={`button_url`}
            value={data.button_url}
            setValue={update}
          >
            <button className="btn btn-success rounded-pill">
              <InlineEdit
                className="px-1 text-white text-center"
                name="button_title"
                value={data.button_title}
                setValue={update}
                activeVerticalPadding={'py-0'}
                dynamicWidth
                style={{
                  maxWidth: '22rem',
                }}
              />
            </button>
          </ButtonTooltip>
        </div>

        <div className="specializations-cases__list">
          {data.featured_cases.map((element, idx) => (
            <SpecializationFeaturedCaseWrapper
              key={idx}
              itemIndex={idx}
              featuredCase={element}
              updateSelected={updateFeaturedPost}
              selectedMap={selectedCasesMap}
            >
              <div className="card">
                <span
                  onClick={() => removeCase(idx)}
                  className="position-absolute z-index-2 top-0 end-0 me-2 mt-2 opacity-75-hover"
                >
                  <KTSVG
                    path="/media/icons/duotone/Interface/Minus-Square.svg"
                    className="svg-icon-danger svg-icon-1"
                  />
                </span>

                <div className="specializations-cases__figure">
                  {element.image_preview ? (
                    <img src={element.image_preview} alt="Placeholder" />
                  ) : (
                    <img
                      src={toAbsoluteUrl('/assets/images/400x500.png')}
                      alt="Placeholder"
                    />
                  )}
                </div>

                {/* <BlogFeaturedFigure
									dataIndex={idx}
									post={element}
									updateFeaturedPost={updateFeaturedPost}
									blogFeatured={data}
								/> */}
                <div className="card__content">
                  <div className="card__tags">
                    {
                      // @ts-ignore
                      element.tags.map(
                        // @ts-ignore
                        (tag: ITag, idx) => (
                          <a key={idx} className="card__tag tag-green">
                            {tag.name}
                          </a>
                        )
                      )
                    }
                  </div>

                  <div className="item-title">{element.title}</div>
                </div>
              </div>
            </SpecializationFeaturedCaseWrapper>
          ))}

          <div onClick={addCase} className="add-item">
            <div className="add-item__inner">
              <div className="m-auto d-flex flex-column">
                <KTSVG
                  path="/media/icons/duotone/Interface/Plus-Square.svg"
                  className="svg-icon-muted svg-icon-3hx text-center"
                />
                <span className="mt-4 text-gray-500">Add new item</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ElementWrapper>
  );
};
