/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { RootState } from '../../../../../setup';
import { FormImageField } from '../../../media-management/components/form-image/FormImageField';
import {
  createEmployeeRequest,
  initializeEmployeeRequest,
  resetCreateEmployee,
} from '../../redux/EmployeeAction';
import { defaultEmployee, IEmployee } from './Employee';

export const CreateEmployeePage = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const loading = useSelector(
    (state: RootState) => state.employeeManagement.loading
  );

  const departments = useSelector(
    (state: RootState) => state.employeeManagement.allDepartments
  );

  const [data, setData] = useState<IEmployee>({
    ...defaultEmployee,
    name: '',
  });

  useEffect(() => {
    // initialize if departments is empty
    // otherwise set the department id of the data to the first element id of departments
    if (departments.length === 0) {
      dispatch(initializeEmployeeRequest());
    } else {
      setData((prev) => ({ ...prev, department_id: departments[0].id }));
    }
  }, [departments]);

  const updateData = (fieldsToUpdate: Partial<IEmployee>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  const successCallback = () => {
    history.goBack();
    dispatch(resetCreateEmployee());
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    dispatch(createEmployeeRequest({ model: data, callback: successCallback }));
  };

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Create Employee</h3>
      </div>
      {/* begin::Form */}
      <form onSubmit={handleSubmit} className="form d-flex flex-center">
        <div className="card-body mw-800px py-20">
          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Name</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter name"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.name}
                  onChange={(e) => updateData({ name: e.target.value })}
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Department</label>
            <div className="col-lg-9">
              <select
                className="form-select form-select-lg form-select-solid"
                data-control="select2"
                data-placeholder="Select Status..."
                value={data.department_id}
                onChange={(e) =>
                  updateData({
                    department_id: parseInt(e.target.value),
                  })
                }
              >
                {departments.map((department, idx) => (
                  <option value={department.id} key={idx}>
                    {department.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-20">
            <label className="col-lg-3 col-form-label">Profile Image</label>
            <div className="col-lg-9">
              <FormImageField
                name={`profile_image`}
                src={data.profile_image}
                update={updateData}
                modalId={`profile_image_modal`}
                imageWidth={`auto`}
                imageHeight={`auto`}
              />
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-20">
            <label className="col-lg-3 col-form-label">GIF Image</label>
            <div className="col-lg-9">
              <FormImageField
                name={`gif`}
                src={data.gif}
                update={updateData}
                imageHeight={`100%`}
                imageWidth={`100%`}
                modalId={`gif_modal`}
              />
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row">
            <label className="col-lg-3 col-form-label"></label>
            <div className="col-lg-9">
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary fw-bolder px-6 py-3 me-3"
              >
                {!loading && <span className="indicator-label">Save</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Please wait...{' '}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
              <Link
                className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3"
                to="/employee-management/employees"
              >
                Cancel
              </Link>
            </div>
          </div>
          {/* end::Form row */}
        </div>
      </form>
      {/* end::Form */}
    </div>
  );
};
