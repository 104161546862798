import { toAbsoluteUrl } from '../../../../../../_start/helpers';
import { FormImageFieldModal } from '../../../../media-management/components/form-image/FormImageFieldModal';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { IElement } from '../../../models/Element';
import { AboutUsImageBanner } from './AboutUsImageBanner';

type AboutUsImageBannerFCProps = {
  index: number;
  data: AboutUsImageBanner;
  updateData: (body: IElement, index: number) => void;
};

export const AboutUsImageBannerFC = ({
  index,
  data,
  updateData,
}: AboutUsImageBannerFCProps) => {
  const update = (fieldsToUpdate: Partial<AboutUsImageBanner>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  return (
    <>
      <FormImageFieldModal
        modalId={`${data.name}-${data.id}`}
        name={`image`}
        altText={`image_alt`}
        update={update}
      />
      <ElementWrapper elementIndex={index}>
        <div className="about-us-image-banner body-my-8">
          {data.image ? (
            <div className="overlay">
              <div className="overlay-wrapper">
                <img alt="Uploaded" src={data.image} />
              </div>
              <div className="overlay-layer bg-dark bg-opacity-10 flex-column">
                <div>
                  <button
                    name="image"
                    className="btn btn-primary btn-shadow"
                    onClick={() =>
                      update({
                        image: '',
                        image_alt: '',
                      })
                    }
                  >
                    Remove
                  </button>
                  <label
                    className="btn btn-light-primary btn-shadow ms-2"
                    data-bs-toggle="modal"
                    data-bs-target={`#${data.name}-${data.id}`}
                  >
                    Change
                  </label>
                </div>

                <input
                  placeholder="Alt attribute"
                  className="w-50 mt-2 form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.image_alt ?? ''}
                  onChange={(event) =>
                    update({ image_alt: event.target.value })
                  }
                />
              </div>
            </div>
          ) : (
            <>
              <label
                data-bs-toggle="modal"
                data-bs-target={`#${data.name}-${data.id}`}
              >
                <img
                  src={toAbsoluteUrl('/assets/images/1160x522.png')}
                  alt="Placeholder"
                />
              </label>
            </>
          )}
        </div>
      </ElementWrapper>
    </>
  );
};
