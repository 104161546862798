import React from 'react';
import {
  SpecializationsCases,
  SpecializationsCasesItem,
} from './SpecializationsCases';
import { SpecializationsCasesListItem } from './SpecializationsCasesListItem';

interface Props {
  data: SpecializationsCases;
  update: (fieldsToUpdate: Partial<SpecializationsCases>) => void;
}

export const SpecializationsCasesList: React.FC<Props> = ({ data, update }) => {
  const updateList = (
    fieldsToUpdate: Partial<SpecializationsCasesItem>,
    index: number
  ) => {
    const item_list = [...data.item_list];
    item_list[index] = { ...item_list[index], ...fieldsToUpdate };
    update({ item_list });
  };
  return (
    <div className="inner">
      {data.item_list.map((item, index) => (
        <SpecializationsCasesListItem
          key={index}
          index={index}
          data={item}
          update={(fields) => {
            updateList(fields, index);
          }}
        />
      ))}
    </div>
  );
};
