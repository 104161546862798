// import { FormImageFieldModal } from "../../../../media-management/components/form-image/FormImageFieldModal";
import { FormImageFieldModal } from "../../../../media-management/components/form-image/FormImageFieldModal";
import { ElementWrapper } from "../../../components/ElementWrapper";
import { IElement } from "../../../models/Element";
import { BlogHeader } from "./BlogHeader";
import { BlogHeaderContent } from "./BlogHeaderContent";
import { BlogHeaderFigure } from "./BlogHeaderFigure";

type BlogHeaderFCProps = {
	index: number;
	data: BlogHeader;
	updateData: (element: IElement, index: number) => void;
};

export const BlogHeaderFC = ({
	index,
	data,
	updateData,
}: BlogHeaderFCProps) => {
	const update = (fieldsToUpdate: Partial<BlogHeader>) => {
		const updatedData = { ...data, ...fieldsToUpdate };
		updateData(updatedData, index);
	};

	return (
		<>
			<FormImageFieldModal
				modalId={`${data.name}-${data.id}`}
				name={`image`}
				altText={`image_alt`}
				update={update}
			/>

			<ElementWrapper elementIndex={index}>
				<div className="blog-header">
					<BlogHeaderFigure data={data} update={update} />
					<BlogHeaderContent data={data} update={update} />
				</div>
			</ElementWrapper>
		</>
	);
};
