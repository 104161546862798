import { ElementType, IElement } from '../../../models/Element';

export type PluginLeftScreenshotInfoNoButtons = IElement & {
  title: string;
  description: string;
  image_preview: string;
  image_preview_alt: string;
};

export const defaultPluginLeftScreenshotInfoNoButtons: PluginLeftScreenshotInfoNoButtons =
  {
    id: 0,
    name: ElementType.PLUGIN_LEFT_SCREENSHOT_INFO_NO_BUTTONS,
    title: 'Zoekmachine vriendelijk',
    description:
      'Het CRM-pakket van Realworks is bij uitstek geschikt voor het beheren van objecten, projecten en woningen. De WordPress Makelaars plugin maakt een ijzersterke combinatie.',
    image_preview: '',
    image_preview_alt: '',
  };
