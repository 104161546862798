import React from 'react';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { InlineEdit } from '../../../components/InlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { IElement } from '../../../models/Element';
import { AboutUsIntroduction } from './AboutUsIntroduction';

type AboutUsIntroductionFCProps = {
  index: number;
	data: AboutUsIntroduction;
	updateData: (body: IElement, index: number) => void;
};

export const AboutUsIntroductionFC = ({
  index,
  data,
  updateData,
}: AboutUsIntroductionFCProps) => {
  const update = (fieldsToUpdate: Partial<AboutUsIntroduction>) => {
		const updatedData = { ...data, ...fieldsToUpdate };
		updateData(updatedData, index);
	};

  return (
    <ElementWrapper elementIndex={index}>
      <div className="about-us-introduction body-my-8">
        {/* begin::Heading */}
        <div className="about-us-introduction__heading">
          <InlineEdit
            name="heading"
            className="text-heading"
            value={data.heading}
            setValue={update}
          />
        </div>
        {/* end::Heading */}

        <div className="about-us-introduction__message">
          {/* begin::Message */}
          <TextAreaEdit
            name="message"
            className="text-message"
            value={data.message}
            setValue={update}
          />
          {/* end::Message */}
        </div>
      </div>
    </ElementWrapper>
  );
};
