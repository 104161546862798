import { defaultAboutUsHeader } from '../elements/about-us/about-us-header/AboutUsHeader';
import { defaultAboutUsCenteredParagraph } from '../elements/about-us/centered-text-with-padding/AboutUsCenteredParagraph';
import { defaultAboutUsFeaturedContentOne } from '../elements/about-us/dark-background-with-white-text-and-a-blue-button/AboutUsFeaturedContentOne';
import { defaultAboutUsFeaturedContentTwo } from '../elements/about-us/dark-background-with-white-text-and-a-white-button/AboutUsFeaturedContentTwo';
import { defaultAboutUsEmployees } from '../elements/about-us/employees-grid/AboutUsEmployees';
import { defaultAboutUsUniqueSellingPoint } from '../elements/about-us/icon-with-description/AboutUsUniqueSellingPoint';
import { defaultAboutUsImageBanner } from '../elements/about-us/image-banner/AboutUsImageBanner';
import { defaultAboutUsShowcaseWithTextLeft } from '../elements/about-us/image-with-text-left/AboutUsShowcaseWithTextLeft';
import { defaultAboutUsShowcaseWithTextRight } from '../elements/about-us/image-with-text-right/AboutUsShowcaseWithTextRight';
import { defaultAboutUsContentHeading } from '../elements/about-us/justified-center-text-with-heading/AboutUsContentHeading';
import { defaultAboutUsContentDescription } from '../elements/about-us/rounded-border-with-white-background-and-dark-text-with-button/AboutUsContentDescription';
import { defaultAboutUsIntroduction } from '../elements/about-us/text-heading-with-justify-center-paragraph/AboutUsIntroduction';
import { defaultBlogAuthorFooter } from '../elements/blog/blog-author-footer/BlogAuthorFooter';
import { defaultBlogAuthorHeader } from '../elements/blog/blog-author-header/BlogAuthorHeader';
import { defaultBlogContentDescriptionWithInfo } from '../elements/blog/blog-content-description-with-info/BlogContentDescriptionWithInfo';
import { defaultBlogContentDescription } from '../elements/blog/blog-content-description/BlogContentDescription';
import { defaultBlogContentHeading } from '../elements/blog/blog-content-heading/BlogContentHeading';
import { defaultBlogContentImage } from '../elements/blog/blog-content-image/BlogContentImage';
import { defaultBlogContentInfoPanel } from '../elements/blog/blog-content-info-panel/BlogContentInfoPanel';
import { defaultBlogContentWideDescriptionTwoColumn } from '../elements/blog/blog-content-wide-description-two-column/BlogContentWideDescriptionTwoColumn';
import { defaultBlogContentWideDescription } from '../elements/blog/blog-content-wide-description/BlogContentWideDescription';
import { defaultBlogFeatured } from '../elements/blog/blog-featured/BlogFeatured';
import { defaultBlogHeader } from '../elements/blog/blog-header/BlogHeader';
import { defaultCaseHeader } from '../elements/case-detail/case-header/CaseHeader';
import { defaultCustomerQuestion } from '../elements/case-detail/customer-question/CustomerQuestion';
import { defaultFeaturedOtherCases } from '../elements/case-detail/featured-other-cases/FeaturedOtherCases';
import { defaultFeaturedUsedPlugins } from '../elements/case-detail/featured-used-plugins/FeaturedUsedPlugins';
import { defaultPersonHighlightExperience } from '../elements/case-detail/person-highlight-experience/PersonHighlightExperience';
import { defaultProcessHighlight } from '../elements/case-detail/process-highlight/ProcessHighlight';
import { defaultProjectHighlights } from '../elements/case-detail/project-highlights/ProjectHighlights';
import { defaultShowcaseBigBanner } from '../elements/case-detail/showcase-big-banner/ShowcaseBigBanner';
import { defaultShowcaseFeatured } from '../elements/case-detail/showcase-featured/ShowcaseFeatured';
import { defaultShowcaseWithTextLeft } from '../elements/case-detail/showcase-with-text-left/ShowcaseWithTextLeft';
import { defaultShowcaseWithTextRight } from '../elements/case-detail/showcase-with-text-right/ShowcaseWithTextRight';
import { defaultWebsiteScreenshotWithTextLeft } from '../elements/case-detail/website-screenshot-with-text-left/WebsiteScreenshotWithTextLeft';
import { defaultWebsiteScreenshotWithTextRight } from '../elements/case-detail/website-screenshot-with-text-right/WebsiteScreenshotWithTextRight';
import { defaultWebsiteScreenshot } from '../elements/case-detail/website-screenshot/WebsiteScreenshot';
import { defaultContactsHeader } from '../elements/contacts/contacts-header/ContactsHeader';
import { defaultFAQSelectNarrow } from '../elements/faq/faq-select-narrow/FAQSelectNarrow';
import { defaultFAQSelect } from '../elements/faq/faq-select/FAQSelect';
import { defaultHomeEmployees } from '../elements/home/home-employees/HomeEmployees';
import { defaultHomeFeaturedProjects } from '../elements/home/home-featured-projects/HomeFeaturedProjects';
import { defaultHomeHeader } from '../elements/home/home-header/HomeHeader';
import { defaultHomePlugins } from '../elements/home/home-plugins/HomePlugins';
import { defaultHomeServices } from '../elements/home/home-services/HomeServices';
import { defaultPluginArrayHeaderAndDescription } from '../elements/plugin/plugin-array-header-and-description/PluginArrayHeaderAndDescription';
import { defaultPluginFeaturedPlugins } from '../elements/plugin/plugin-featured-plugins/PluginFeaturedPlugins';
import { defaultPluginFeaturedTutorials } from '../elements/plugin/plugin-featured-tutorials/PluginFeaturedTutorials';
import { defaultPluginHeader } from '../elements/plugin/plugin-header/PluginHeader';
import { defaultPluginHighlightLeftImageInfo } from '../elements/plugin/plugin-highlight-left-image-info/PluginHighlightLeftImageInfo';
import { defaultPluginHighlightRightImageInfo } from '../elements/plugin/plugin-highlight-right-image-info/PluginHighlightRightImageInfo';
import { defaultPluginLeftImageHighlightInfo } from '../elements/plugin/plugin-left-image-highlight-info/PluginLeftImageHighlightInfo';
import { defaultPluginLeftImageInfoNoButtons } from '../elements/plugin/plugin-left-image-info-no-buttons/PluginLeftImageInfoNoButtons';
import { defaultPluginLeftImageInfo } from '../elements/plugin/plugin-left-image-info/PluginLeftImageInfo';
import { defaultPluginLeftScreenshotInfoNoButtons } from '../elements/plugin/plugin-left-screenshot-info-no-buttons/PluginLeftScreenshotInfoNoButtons';
import { defaultPluginLeftScreenshotsInfo } from '../elements/plugin/plugin-left-screenshots-info/PluginLeftScreenshotsInfo';
import { defaultPluginNoHighlightLeftImageInfo } from '../elements/plugin/plugin-no-highlight-left-image-info/PluginNoHighlightLeftImageInfo';
import { defaultPluginNoHighlightRightImageInfo } from '../elements/plugin/plugin-no-highlight-right-image-info/PluginNoHighlightRightImageInfo';
import { defaultPluginPartners } from '../elements/plugin/plugin-partners/PluginPartners';
import { defaultPluginRightImageHighlightInfo } from '../elements/plugin/plugin-right-image-highlight-info/PluginRightImageHighlightInfo';
import { defaultPluginRightImageInfoNoButtons } from '../elements/plugin/plugin-right-image-info-no-buttons/PluginRightImageInfoNoButtons';
import { defaultPluginRightImageInfo } from '../elements/plugin/plugin-right-image-info/PluginRightImageInfo';
import { defaultPluginRightScreenshotInfoNoButtons } from '../elements/plugin/plugin-right-screenshot-info-no-buttons/PluginRightScreenshotInfoNoButtons';
import { defaultPluginRightScreenshotsInfo } from '../elements/plugin/plugin-right-screenshots-info/PluginRightScreenshotsInfo';
import { defaultReviewsElement } from '../elements/reviews/reviews-element/ReviewsElement';
import { defaultSpecializationsModalSteps } from '../elements/specializations/dark-background-with-text-and-multiple-modal-steps/SpecializationsModalSteps';
import { defaultSpecializationsFullText } from '../elements/specializations/full-text/SpecializationsFullText';
import { defaultSpecializationFloatingLeftModal } from '../elements/specializations/image-with-floating-left-modal/SpecializationsFloatingLeftModal';
import { defaultSpecializationFloatingRightModal } from '../elements/specializations/image-with-floating-right-modal/SpecializationsFloatingRightModal';
import { defaultSpecializationHeader } from '../elements/specializations/specialization-header/SpecializationHeader';
import { defaultSpecializationFeaturedCases } from '../elements/specializations/specializations-featured-cases/SpecializationsFeaturedCases';
import { defaultSpecializationFeaturedPosts } from '../elements/specializations/specializations-featured-posts/SpecializationsFeaturedPosts';
import { defaultSpecializationFAQ } from '../elements/specializations/text-with-frequently-asked-questions/SpecializationsFAQ';

import { defaultMarginBottomElement } from '../elements/utility/margin-bottom-element/MarginBottomElement';
import { defaultMarginTopElement } from '../elements/utility/margin-top-element/MarginTopElement';
import { defaultTitleElement } from '../elements/utility/title-element/TitleElement';
import { IElement } from '../models/Element';
import { SidebarElementDrag } from './SidebarElementDrag';

const utilityElements: Array<IElement> = [
  defaultTitleElement,
  defaultMarginTopElement,
  defaultMarginBottomElement,
];

const blogElements: Array<IElement> = [
  defaultBlogHeader,
  defaultBlogAuthorHeader,
  defaultBlogContentHeading,
  defaultBlogContentDescription,
  defaultBlogContentImage,
  defaultBlogContentDescriptionWithInfo,
  defaultBlogContentWideDescriptionTwoColumn,
  defaultBlogContentInfoPanel,
  defaultBlogContentWideDescription,
  defaultBlogAuthorFooter,
  defaultBlogFeatured,
];

const caseDetailElements: Array<IElement> = [
  defaultCaseHeader,
  defaultWebsiteScreenshot,
  defaultCustomerQuestion,
  defaultProjectHighlights,
  defaultShowcaseBigBanner,
  defaultWebsiteScreenshotWithTextRight,
  defaultWebsiteScreenshotWithTextLeft,
  defaultShowcaseFeatured,
  defaultShowcaseWithTextRight,
  defaultShowcaseWithTextLeft,
  defaultProcessHighlight,
  defaultPersonHighlightExperience,
  defaultFeaturedUsedPlugins,
  defaultFeaturedOtherCases,
];

const aboutUsElements: Array<IElement> = [
  defaultAboutUsHeader,
  defaultAboutUsIntroduction,
  defaultAboutUsUniqueSellingPoint,
  defaultAboutUsImageBanner,
  defaultAboutUsFeaturedContentOne,
  defaultAboutUsEmployees,
  defaultAboutUsContentHeading,
  defaultAboutUsContentDescription,
  defaultAboutUsShowcaseWithTextLeft,
  defaultAboutUsShowcaseWithTextRight,
  defaultAboutUsCenteredParagraph,
  defaultAboutUsFeaturedContentTwo,
];

const homeElements: Array<IElement> = [
  defaultHomeHeader,
  defaultHomeFeaturedProjects,
  defaultHomeEmployees,
  defaultHomePlugins,
  defaultHomeServices,
];

const specializationElements: Array<IElement> = [
  defaultSpecializationHeader,
  defaultSpecializationFloatingRightModal,
  defaultSpecializationFloatingLeftModal,
  defaultSpecializationsFullText,
  defaultSpecializationsModalSteps,
  defaultSpecializationFeaturedCases,
  defaultSpecializationFeaturedPosts,
  defaultSpecializationFAQ,
];

const contactsElements: Array<IElement> = [
  // defaultContactsImageRightDescriptionElement,
  // defaultContactsImageLeftDescriptionElement,
  // defaultContactsImageLeftDescriptionFloatingWindowIconsElement,
  // defaultContactsOtherInfoWithIconsElement,
  defaultContactsHeader,
];

const pluginElements: Array<IElement> = [
  defaultPluginHeader,
  defaultPluginLeftImageInfo,
  defaultPluginRightImageInfo,
  defaultPluginRightImageHighlightInfo,
  defaultPluginLeftImageHighlightInfo,
  defaultPluginLeftImageInfoNoButtons,
  defaultPluginRightImageInfoNoButtons,
  defaultPluginLeftScreenshotInfoNoButtons,
  defaultPluginRightScreenshotInfoNoButtons,
  defaultPluginHighlightLeftImageInfo,
  defaultPluginHighlightRightImageInfo,
  defaultPluginNoHighlightLeftImageInfo,
  defaultPluginNoHighlightRightImageInfo,
  defaultPluginLeftScreenshotsInfo,
  defaultPluginRightScreenshotsInfo,
  defaultPluginArrayHeaderAndDescription,
  defaultPluginPartners,
  defaultPluginFeaturedPlugins,
  defaultPluginFeaturedTutorials,
];

const FAQElements: Array<IElement> = [defaultFAQSelect, defaultFAQSelectNarrow];

const reviewElements: Array<IElement> = [defaultReviewsElement];

export const SidebarEditContent = () => {
  return (
    <div id="kt_sidebar_content" className="py-10 px-7 px-lg-12">
      <div
        className="hover-scroll-y me-lg-n7 pe-lg-5"
        data-kt-scroll="true"
        data-kt-scroll-height="auto"
        data-kt-scroll-offset="10px"
        data-kt-scroll-wrappers="#kt_sidebar_content"
      >
        <div>
          {/*begin::Page Components*/}
          <div className="form">
            {/*begin::Utility*/}
            <div className="mb-11">
              <label className="fs-2 fw-bolder text-dark mb-4">Utility</label>
              <div className="d-flex flex-column">
                {utilityElements.map((element, idx) => (
                  <SidebarElementDrag key={idx} element={element} />
                ))}
              </div>
            </div>
            {/*end::Utility*/}

            {/*begin::Blog*/}
            <div className="mb-11">
              <label className="fs-2 fw-bolder text-dark mb-4">Blog</label>
              <div className="d-flex flex-column">
                {blogElements.map((element, idx) => (
                  <SidebarElementDrag key={idx} element={element} />
                ))}
              </div>
            </div>
            {/*end::Blog*/}

            {/*begin::Case Detail*/}
            <div className="mb-11">
              <label className="fs-2 fw-bolder text-dark mb-4">
                Case Detail
              </label>
              <div className="d-flex flex-column">
                {caseDetailElements.map((element, idx) => (
                  <SidebarElementDrag key={idx} element={element} />
                ))}
              </div>
            </div>
            {/*end::Case Detail*/}

            {/*begin::About Us*/}
            <div className="mb-11">
              <label className="fs-2 fw-bolder text-dark mb-4">About Us</label>
              <div className="d-flex flex-column">
                {aboutUsElements.map((element, idx) => (
                  <SidebarElementDrag key={idx} element={element} />
                ))}
              </div>
            </div>
            {/*end::About Us*/}

            {/*begin::Home*/}
            <div className="mb-11">
              <label className="fs-2 fw-bolder text-dark mb-4">Home</label>
              <div className="d-flex flex-column">
                {homeElements.map((element, idx) => (
                  <SidebarElementDrag key={idx} element={element} />
                ))}
              </div>
            </div>
            {/*end::Home*/}

            {/*begin::Specializations*/}
            <div className="mb-11">
              <label className="fs-2 fw-bolder text-dark mb-4">
                Specializations
              </label>
              <div className="d-flex flex-column">
                {specializationElements.map((element, idx) => (
                  <SidebarElementDrag key={idx} element={element} />
                ))}
              </div>
            </div>
            {/*end::Specializations*/}

            {/*begin::Contacts*/}
            <div className="mb-11">
              <label className="fs-2 fw-bolder text-dark mb-4">Contacts</label>
              <div className="d-flex flex-column">
                {contactsElements.map((element, idx) => (
                  <SidebarElementDrag key={idx} element={element} />
                ))}
              </div>
            </div>
            {/*end::Contacts*/}

            {/*begin::FAQ*/}
            <div className="mb-11">
              <label className="fs-2 fw-bolder text-dark mb-4">FAQs</label>
              <div className="d-flex flex-column">
                {FAQElements.map((element, idx) => (
                  <SidebarElementDrag key={idx} element={element} />
                ))}
              </div>
            </div>
            {/*end::FAQ*/}

            {/*begin::Reviews*/}
            <div className="mb-11">
              <label className="fs-2 fw-bolder text-dark mb-4">Reviews</label>
              <div className="d-flex flex-column">
                {reviewElements.map((element, idx) => (
                  <SidebarElementDrag key={idx} element={element} />
                ))}
              </div>
            </div>
            {/*end::Reviews*/}

            {/*begin::Reviews*/}
            <div className="mb-11">
              <label className="fs-2 fw-bolder text-dark mb-4">Plugins</label>
              <div className="d-flex flex-column">
                {pluginElements.map((element, idx) => (
                  <SidebarElementDrag key={idx} element={element} />
                ))}
              </div>
            </div>
            {/*end::Reviews*/}
          </div>
          {/*end::Page Components*/}
        </div>
      </div>
    </div>
  );
};
