import React from 'react';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { IElement } from '../../../models/Element';
import { SpecializationsModalSteps } from './SpecializationsModalSteps';
import { SpecializationsModalStepsHeading } from './SpecializationsModalStepsHeading';
import { SpecializationsModalStepsList } from './SpecializationsModalStepsList';

interface SpecializationsModalStepsProps {
  index: number;
  data: SpecializationsModalSteps;
  updateData: (element: IElement, index: number) => void;
}

export const SpecializationsModalStepsFC: React.FC<
  SpecializationsModalStepsProps
> = ({ index, data, updateData }) => {
  const update = (fieldsToUpdate: Partial<SpecializationsModalSteps>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };
  return (
    <ElementWrapper elementIndex={index}>
      <div className="specializations-modal-steps body-my-8">
        <div className="inner">
          <SpecializationsModalStepsHeading data={data} update={update} />
          <SpecializationsModalStepsList data={data} update={update} />
        </div>
      </div>
    </ElementWrapper>
  );
};
