import { Redirect, Route, Switch } from 'react-router-dom';
import { PageTitle } from '../../../_start/layout/core';
import { FileLibraryPage } from './pages/FileLibraryPage';
import { MediaLibraryPage } from './pages/MediaLibraryPage';

export const MediaManagementPage = () => {
  return (
    <Switch>
      {/* begin::MEDIA */}
      <Route exact={true} path="/media-management/media">
        <>
          <PageTitle>Media</PageTitle>
          <MediaLibraryPage />
        </>
      </Route>
      {/* end::MEDIA */}

      {/* begin::FILE */}
      <Route exact={true} path="/media-management/files">
        <>
          <PageTitle>Files</PageTitle>
          <FileLibraryPage />
        </>
      </Route>
      {/* end::FILE */}

      <Redirect
        from="/media-management"
        exact={true}
        to="/media-management/media"
      />

      <Redirect to="/media-management/media" />
    </Switch>
  );
};
