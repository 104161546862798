/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";

import TextareaAutosize from "react-textarea-autosize";

type TextAreaEditProps = {
  name: string;
  value: string;
  setValue: (fieldsToUpdate: Partial<any>) => void;
  cacheMeasurements?: boolean;
  className?: string;
};

export const TextAreaEdit = ({
  name,
  value,
  setValue,
  cacheMeasurements = false,
  className = "",
}: TextAreaEditProps) => {
  const [editingValue, setEditingValue] = useState(value);

  useEffect(() => {
    if (value !== editingValue) {
      setEditingValue(value);
    }
  }, [value]);

  const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const target = event.target as HTMLTextAreaElement;
    if (event.key === "Escape") {
      target.blur();
    }
    // textareaRef.current?.classList.remove('p-4');
  };

  const onBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    // if (event.target.value === value) {
    //   setEditingValue(value);
    // } else if (event.target.value.trim() === "") {
    //   setEditingValue("");
    // } else {
    //   setValue({ [event.target.name]: event.target.value });
    // }
    setValue({ [event.target.name]: event.target.value });
  };

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const textAreaClassName = `${className}`;

  return (
    <TextareaAutosize
      cacheMeasurements={cacheMeasurements}
      ref={textAreaRef}
      name={name}
      className={textAreaClassName}
      value={editingValue}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      onChange={(ev) => setEditingValue(ev.target.value)}
      style={{
        resize: "none",
        backgroundColor: "transparent",
        border: "0",
        overflow: "hidden",
      }}
    />
  );
};
