import axios from "axios";
import { API_URL } from "../../../support/api";
import { ISetting } from "../models/Setting";

export const BASE_SETTING_URL = `${API_URL}/v1/management/settings`;

export const viewSetting = async (id: number | string) => {
	const {
		data: { value },
	} = await axios.get<{ value: ISetting }>(`${BASE_SETTING_URL}/${id}/get`);

	return value;
};

export const updateSetting = async (payload: ISetting) => {
	const {
		data: { value },
	} = await axios.post<{ value: ISetting }>(
		`${BASE_SETTING_URL}/${payload.id}/modify`,
		payload
	);

	return value;
};

export const generateSitemap = async () => {
	const {
		data: { value },
	} = await axios.get<{ value: { path: string } }>(
		`${BASE_SETTING_URL}/generate-sitemap`
	);

  return value.path;
};
