/* eslint-disable react-hooks/exhaustive-deps */
import { useRef } from 'react';

import updateHelper from 'immutability-helper';
import {
  defaultHighlight,
  IHighlight,
  ProjectHighlights,
} from './ProjectHighlights';
import { Highlight } from './Highlight';
import { IElement } from '../../../models/Element';
import { generateListItemId } from '../../../../../support/helpers';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { StaticWidthInlineEdit } from '../../../components/StaticWidthInlineEdit';
import { KTSVG } from '../../../../../../_start/helpers';

type ProjectHighlightsFCProps = {
  index: number; // index of this body component inside the page.content.body array
  data: ProjectHighlights;
  updateData: (body: IElement, index: number) => void;
};

export const ProjectHighlightsFC = ({
  index,
  data,
  updateData,
}: ProjectHighlightsFCProps) => {
  const update = (fieldsToUpdate: Partial<ProjectHighlights>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  const addItemRef = useRef<HTMLLIElement>(null);

  const onMouseEnter = (event: React.MouseEvent<HTMLUListElement>) => {
    addItemRef.current?.classList.remove('d-none');

    if (event.currentTarget === event.target) {
      event.stopPropagation();
    }

    event.currentTarget.style.cursor = 'pointer';
  };

  const onMouseLeave = (event: React.MouseEvent<HTMLUListElement>) => {
    addItemRef.current?.classList.add('d-none');
    event.currentTarget.style.cursor = 'auto';
  };

  const updateItem = (idx: number, highlight: IHighlight) => {
    let highlights = [...data.highlights];
    highlights[idx] = highlight;
    const updatedData = { ...data, highlights };
    updateData(updatedData, index);
  };

  const addListItem = () => {
    const highlights = updateHelper(data.highlights, {
      $push: [{ ...defaultHighlight, id: generateListItemId(data.highlights) }],
    });

    const updatedData = { ...data, highlights };
    updateData(updatedData, index);
  };

  const removeListItem = (itemIndex: number) => {
    let highlights = updateHelper(data.highlights, {
      $splice: [[itemIndex, 1]],
    });

    const updatedData = { ...data, highlights };
    updateData(updatedData, index);
  };

  const moveListItem = (dragIndex: number, hoverIndex: number) => {
    let highlights = updateHelper(data.highlights, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, data.highlights[dragIndex]],
      ],
    });

    const updatedData = { ...data, highlights };
    updateData(updatedData, index);
  };

  return (
    <ElementWrapper elementIndex={index}>
      <div className="case-project-highlights body-my-8">
        <div className="case-customer-question__content">
          <StaticWidthInlineEdit
            name="title"
            className="content-title"
            value={data.title}
            setValue={update}
          />

          <ul
            className="content-list"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            {data.highlights.map((highlight, idx) => (
              <Highlight
                key={idx}
                index={idx}
                highlight={highlight}
                projectHighlight={data}
                updateProjectHighlight={updateItem}
                removeListItem={removeListItem}
                moveListItem={moveListItem}
              />
            ))}

            <li
              ref={addItemRef}
              onClick={addListItem}
              style={{
                minHeight: '170px',
              }}
              className="d-none w-100 h-100 d-flex rounded border-dashed border-2 opacity-75-hover"
            >
              <div className="m-auto d-flex flex-column">
                <KTSVG
                  path="/media/icons/duotone/Interface/Plus-Square.svg"
                  className="svg-icon-muted svg-icon-3hx text-center"
                />
                <span className="mt-4 text-gray-500">Add new item</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </ElementWrapper>
  );
};
