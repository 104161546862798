import axios from 'axios';
import { API_URL } from '../../../support/api';
import { PaginatedValue } from '../../../support/utils';
import { IMember } from '../../user-management/models/Member';
import { ITutorial } from '../models/Tutorial';

export const BASE_POST_URL = `${API_URL}/v1/management/tutorial-management/tutorials`;
export const GET_ALL_POST_URL = `${API_URL}/v1/management/tutorial-management/tutorials/get-all`;
export const CREATE_POST_URL = `${API_URL}/v1/management/tutorial-management/tutorials/create`;

export type CreateTutorialPayload = ITutorial;

export type UpdateTutorialPayload = CreateTutorialPayload;

export const listTutorial = async (search = '', direction = 'asc') => {
  const URL = `${BASE_POST_URL}/list?search=${search}&direction=${direction}`;
  const {
    data: { value },
  } = await axios.get<{ value: PaginatedValue<ITutorial> }>(URL);

  return value;
};

export const getCurrentUser = async () => {
  const URL = `${API_URL}/users/me`;
  const {
    data: { user },
  } = await axios.get<{ user: IMember }>(URL);

  return user;
};

export const createTutorial = async (payload: CreateTutorialPayload) => {
  const {
    data: { value },
  } = await axios.post<{ value: ITutorial }>(CREATE_POST_URL, payload);

  return value;
};

export const viewTutorial = async (id: number | string) => {
  const {
    data: { value },
  } = await axios.get<{ value: ITutorial }>(`${BASE_POST_URL}/${id}/get`);

  return value;
};

export const updateTutorial = async (payload: UpdateTutorialPayload) => {
  const {
    data: { value },
  } = await axios.post<{ value: ITutorial }>(
    `${BASE_POST_URL}/${payload.id}/update`,
    payload
  );

  return value;
};

export const deleteTutorial = async (payload: ITutorial) => {
  const {
    data: { value },
  } = await axios.post<{ value: ITutorial }>(
    `${BASE_POST_URL}/${payload.id}/delete`,
    payload
  );

  return value;
};

// unpaginated categories
export const getAllTutorial = async () => {
  const {
    data: { value },
  } = await axios.get<{ value: Array<ITutorial> }>(GET_ALL_POST_URL);

  return value;
};

export const publishTutorial = async (payload: ITutorial) => {
  const {
    data: { value },
  } = await axios.post<{ value: ITutorial }>(
    `${BASE_POST_URL}/${payload.id}/publish`,
    payload
  );

  return value;
};
