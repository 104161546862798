import { call, put, takeLatest } from 'redux-saga/effects';
import {
  defaultPaginatedValue,
  failureToast,
  PaginatedValue,
  successToast,
} from '../../../support/utils';
import { IDepartment } from '../pages/departments/Department';
import { defaultEmployee, IEmployee } from '../pages/employees/Employee';
import { getAllDepartment } from './DepartmentCRUD';
import * as employeeActions from './EmployeeAction';
import {
  createEmployee,
  deleteEmployee,
  listEmployee,
  updateEmployee,
  viewEmployee,
} from './EmployeeCRUD';
import { EmployeeEvents } from './EmployeeEvents';

interface IEmployeeManagementState {
  loading: boolean;
  createdEmployee: IEmployee;
  viewedEmployee: IEmployee;
  updatedEmployee: IEmployee;
  deletedEmployee: IEmployee;
  paginatedEmployees: PaginatedValue<IEmployee>;
  allDepartments: Array<IDepartment>;
}

const defaultEmployeeManagementState: IEmployeeManagementState = {
  loading: false,
  createdEmployee: defaultEmployee,
  viewedEmployee: defaultEmployee,
  updatedEmployee: defaultEmployee,
  deletedEmployee: defaultEmployee,
  paginatedEmployees: defaultPaginatedValue,
  allDepartments: [],
};

export const reducer = (
  state: IEmployeeManagementState = defaultEmployeeManagementState,
  action: employeeActions.EmployeeActions
): IEmployeeManagementState => {
  switch (action.type) {
    // INITIALIZATION
    case EmployeeEvents.INITIALIZATION_EMPLOYEE_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case EmployeeEvents.INITIALIZATION_EMPLOYEE_LOADED:
      return {
        ...state,
        loading: false,
        allDepartments: action.payload,
      };

    case EmployeeEvents.INITIALIZATION_EMPLOYEE_FAILED:
      return {
        ...state,
        loading: false,
      };

    // CREATE
    case EmployeeEvents.CREATE_EMPLOYEE_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case EmployeeEvents.CREATE_EMPLOYEE_LOADED:
      return {
        ...state,
        loading: false,
        createdEmployee: action.payload,
      };

    case EmployeeEvents.CREATE_EMPLOYEE_FAILED:
      return {
        ...state,
        loading: false,
      };

    // VIEW
    case EmployeeEvents.VIEW_EMPLOYEE_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case EmployeeEvents.VIEW_EMPLOYEE_LOADED:
      return {
        ...state,
        loading: false,
        viewedEmployee: action.payload,
      };

    case EmployeeEvents.VIEW_EMPLOYEE_FAILED:
      return {
        ...state,
        loading: false,
      };

    // UPDATE
    case EmployeeEvents.UPDATE_EMPLOYEE_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case EmployeeEvents.UPDATE_EMPLOYEE_LOADED:
      return {
        ...state,
        loading: false,
        updatedEmployee: action.payload,
      };

    case EmployeeEvents.UPDATE_EMPLOYEE_FAILED:
      return {
        ...state,
        loading: false,
      };

    // DELETE
    case EmployeeEvents.DELETE_EMPLOYEE_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case EmployeeEvents.DELETE_EMPLOYEE_LOADED:
      return {
        ...state,
        loading: false,
        deletedEmployee: action.payload,
      };

    case EmployeeEvents.DELETE_EMPLOYEE_FAILED:
      return {
        ...state,
        loading: false,
      };

    // LIST
    case EmployeeEvents.LIST_EMPLOYEE_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case EmployeeEvents.LIST_EMPLOYEE_LOADED:
      return {
        ...state,
        loading: false,
        paginatedEmployees: action.payload,
      };

    case EmployeeEvents.LIST_EMPLOYEE_FAILED:
      return {
        ...state,
        loading: false,
      };

    case EmployeeEvents.RESET_CREATE_EMPLOYEE:
      return {
        ...state,
        createdEmployee: defaultEmployee,
      };

    // SEARCH
    case EmployeeEvents.SEARCH_EMPLOYEE_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case EmployeeEvents.SEARCH_EMPLOYEE_LOADED:
      return {
        ...state,
        loading: false,
        paginatedEmployees: action.payload,
      };

    case EmployeeEvents.SEARCH_EMPLOYEE_FAILED:
      return {
        ...state,
        loading: false,
      };

    default:
      return {
        ...state,
      };
  }
};

export function* saga() {
  yield takeLatest(
    EmployeeEvents.INITIALIZATION_EMPLOYEE_REQUESTED,
    function* initializeEmployeeRequest() {
      try {
        const response: Array<IDepartment> = yield call(getAllDepartment);
        yield put(employeeActions.initializeEmployeeLoad(response));
      } catch (error) {
        yield put(employeeActions.initializeEmployeeFailed());
      }
    }
  );

  yield takeLatest(
    EmployeeEvents.CREATE_EMPLOYEE_REQUESTED,
    function* createEmployeeRequest(
      action: ReturnType<typeof employeeActions.createEmployeeRequest>
    ) {
      try {
        const response: IEmployee = yield call(createEmployee, action.model);

        yield put(employeeActions.createEmployeeLoad(response));

        yield call(successToast, 'Employee has been created.');

        if (action.callback) action.callback();
      } catch (error) {
        yield put(employeeActions.createEmployeeFailed());
        yield call(failureToast, error);
      }
    }
  );

  yield takeLatest(
    EmployeeEvents.VIEW_EMPLOYEE_REQUESTED,
    function* viewEmployeeRequest(
      action: ReturnType<typeof employeeActions.viewEmployeeRequest>
    ) {
      try {
        const response: IEmployee = yield call(viewEmployee, action.model_id);

        yield put(employeeActions.viewEmployeeLoad(response));
      } catch (error) {
        yield put(employeeActions.viewEmployeeFailed());
        yield call(failureToast, error);
      }
    }
  );

  yield takeLatest(
    EmployeeEvents.UPDATE_EMPLOYEE_REQUESTED,
    function* updateEmployeeRequest(
      action: ReturnType<typeof employeeActions.updateEmployeeRequest>
    ) {
      try {
        const response: IEmployee = yield call(updateEmployee, action.model);

        yield put(employeeActions.updateEmployeeLoad(response));
        yield call(successToast, 'Employee has been updated.');
      } catch (error) {
        yield put(employeeActions.updateEmployeeFailed());
        yield call(failureToast, error);
      }
    }
  );

  yield takeLatest(
    EmployeeEvents.DELETE_EMPLOYEE_REQUESTED,
    function* deleteEmployeeRequest(
      action: ReturnType<typeof employeeActions.deleteEmployeeRequest>
    ) {
      try {
        const response: IEmployee = yield call(deleteEmployee, action.model);

        yield put(employeeActions.deleteEmployeeLoad(response));
        yield call(successToast, 'Employee has been deleted.');

        yield put(employeeActions.listEmployeeRequest());
      } catch (error) {
        yield put(employeeActions.deleteEmployeeFailed());
        yield call(failureToast, error);
      }
    }
  );

  yield takeLatest(
    EmployeeEvents.LIST_EMPLOYEE_REQUESTED,
    function* listEMPLOYEERequest() {
      try {
        const response: PaginatedValue<IEmployee> = yield call(listEmployee);

        yield put(employeeActions.listEmployeeLoad(response));
      } catch (error) {
        yield put(employeeActions.listEmployeeFailed());
        yield call(failureToast, error);
      }
    }
  );

  yield takeLatest(
    EmployeeEvents.SEARCH_EMPLOYEE_REQUESTED,
    function* searchEmployeeRequest(
      action: ReturnType<typeof employeeActions.searchEmployeeRequest>
    ) {
      try {
        const response: PaginatedValue<IEmployee> = yield call(
          listEmployee,
          action.search
        );

        yield put(employeeActions.searchEmployeeLoad(response));
      } catch (error) {
        yield put(employeeActions.searchEmployeeFailed());
        yield call(failureToast, error);
      }
    }
  );
}
