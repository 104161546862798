import { useEffect, useRef, useState } from 'react';
import { KTSVG } from '../../../../../../_start/helpers';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import {
  AboutUsUniqueSellingPoint,
  defaultUSP,
  IUSP,
} from './AboutUsUniqueSellingPoint';

type AboutUsUniqueSellingPointItemProps = {
  usp: IUSP;
  uspIndex: number;
  uniqueSellingPoint: AboutUsUniqueSellingPoint;
  updateUSP: (idx: number, usp: IUSP) => void;
  removeUSP: (idx: number) => void;
};

export const AboutUsUniqueSellingPointItem = ({
  usp,
  uspIndex,
  uniqueSellingPoint,
  updateUSP,
  removeUSP,
}: AboutUsUniqueSellingPointItemProps) => {
  const [data, setData] = useState(defaultUSP);

  useEffect(() => {
    setData(usp);
  }, [usp]);

  const updateData = (fieldsToUpdate: Partial<IUSP>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
    updateUSP(uspIndex, updatedData);
  };

  const removeItemRef = useRef<HTMLSpanElement>(null);

  const handleRemove = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    event.preventDefault();
    removeUSP(uspIndex);
  };

  const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files!;
    let fileContent: string | ArrayBuffer | null = '';

    const fr = new FileReader();
    fr.onload = () => {
      fileContent = fr.result;
      updateData({
        [event.target.name]: fileContent,
      });
    };

    fr.readAsText(fileList[0]);
  };

  return (
    <div className="item">
      {/* begin::Delete Item */}
      {uniqueSellingPoint.list.length > 1 && (
        <span
          ref={removeItemRef}
          onClick={handleRemove}
          className="position-absolute z-index-2 top-0 end-0 me-2 mt-2 opacity-75-hover"
        >
          <KTSVG
            path="/media/icons/duotone/Interface/Minus-Square.svg"
            className="svg-icon-danger svg-icon-1"
          />
        </span>
      )}
      {/* end::Delete Item */}

      <div className="item__figure" style={{ height: '8rem', width: '8rem' }}>
        <div className="overlay">
          <div className="overlay-layer bg-dark bg-opacity-10">
            <label
              htmlFor={`specializations-cases-input-${uspIndex}`}
              className="btn btn-light-primary btn-shadow"
            >
              Change
            </label>
          </div>
          <div>
            <KTSVG
              className="icon"
              isSVGData
              path={data.icon ?? ''}
              svgClassName=""
            />
          </div>
        </div>
        <input
          id={`specializations-cases-input-${uspIndex}`}
          type="file"
          accept=".svg"
          name="icon"
          style={{ display: 'none' }}
          onChange={(event) => {
            handleFile(event);
          }}
        />
      </div>

      <div className="item__content">
        {/* begin::Title */}
        <TextAreaEdit
          name="title"
          className="content-title"
          value={data.title}
          setValue={updateData}
        />
        {/* end::Title */}

        {/* begin::Description */}
        <TextAreaEdit
          name="description"
          className="content-description"
          value={data.description}
          setValue={updateData}
        />
        {/* end::Description */}
      </div>
    </div>
  );
};
