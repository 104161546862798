/* eslint-disable jsx-a11y/anchor-is-valid */

import { useEffect, useRef, useState } from 'react';
import { KTSVG } from '../../../_start/helpers';

type ActionsDropdownProps = {
  children?: React.ReactNode;
};

export const ActionsDropdown: React.FC<ActionsDropdownProps> = ({
  children,
}) => {
  const listRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen && listRef.current) {
      listRef.current.focus();
    }
  }, [isOpen]);

  const onBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    if (!event.target.contains(event.relatedTarget)) {
      setIsOpen(false);
    }
  };
  return (
    <div className="position-relative">
      {/* begin::Open action*/}
      <button
        onClick={() => setIsOpen(true)}
        type="button"
        className={`${
          isOpen ? 'visually-hidden' : ''
        } btn btn-sm btn-icon btn-color-primary btn-active-light-primary`}
      >
        <KTSVG
          path="/media/icons/duotone/Text/Dots.svg"
          className="svg-icon-4"
        />
      </button>
      {/* end::Open action*/}

      {/* begin::Close action*/}
      <button
        onClick={() => setIsOpen(false)}
        type="button"
        className={`${
          isOpen ? '' : 'visually-hidden'
        } btn btn-sm btn-icon btn-color-primary btn-active-light-primary`}
      >
        <KTSVG
          path="/media/icons/duotone/Text/Dots.svg"
          className="svg-icon-4"
        />
      </button>
      {/* end::Close action*/}

      {isOpen && (
        <div
          tabIndex={1}
          ref={listRef}
          onBlur={onBlur}
          className="mt-2 end-0 position-absolute menu menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px text-start bg-white shadow-sm rounded"
          style={{
            outline: 'none',
            zIndex: '50',
          }}
        >
          <div className="menu-item px-3">
            <div className="menu-content fs-6 text-dark fw-bolder px-3 py-4">
              Actions
            </div>
          </div>

          <div className="separator mb-3 opacity-75"></div>

          {children}
        </div>
      )}
    </div>
  );
};
