import {
  IActionUnion,
  makeBaseAction,
  makeParamsAction,
  makePayloadAction,
} from '../../../../setup/redux/utils';
import { PaginatedValue } from '../../../support/utils';
import { ICustomer } from '../models/Customer';
import { CustomerEvents } from './CustomerEvents';

// CREATE --
export const createCustomerRequest = makeParamsAction<
  CustomerEvents.CREATE_CUSTOMER_REQUESTED,
  { customer: ICustomer }
>(CustomerEvents.CREATE_CUSTOMER_REQUESTED);

export const createCustomerLoad = makePayloadAction<
  CustomerEvents.CREATE_CUSTOMER_LOADED,
  ICustomer
>(CustomerEvents.CREATE_CUSTOMER_LOADED);

export const createCustomerFailed =
  makeBaseAction<CustomerEvents.CREATE_CUSTOMER_FAILED>(
    CustomerEvents.CREATE_CUSTOMER_FAILED
  );

// VIEW --
export const viewCustomerRequest = makeParamsAction<
  CustomerEvents.VIEW_CUSTOMER_REQUESTED,
  { customer_id: string | number }
>(CustomerEvents.VIEW_CUSTOMER_REQUESTED);

export const viewCustomerLoad = makePayloadAction<
  CustomerEvents.VIEW_CUSTOMER_LOADED,
  ICustomer
>(CustomerEvents.VIEW_CUSTOMER_LOADED);

export const viewCustomerFailed =
  makeBaseAction<CustomerEvents.VIEW_CUSTOMER_FAILED>(
    CustomerEvents.VIEW_CUSTOMER_FAILED
  );

// UPDATE --
export const updateCustomerRequest = makeParamsAction<
  CustomerEvents.UPDATE_CUSTOMER_REQUESTED,
  { customer: ICustomer }
>(CustomerEvents.UPDATE_CUSTOMER_REQUESTED);

export const updateCustomerLoad = makePayloadAction<
  CustomerEvents.UPDATE_CUSTOMER_LOADED,
  ICustomer
>(CustomerEvents.UPDATE_CUSTOMER_LOADED);

export const updateCustomerFailed =
  makeBaseAction<CustomerEvents.UPDATE_CUSTOMER_FAILED>(
    CustomerEvents.UPDATE_CUSTOMER_FAILED
  );

// DELETE --
export const deleteCustomerRequest = makeParamsAction<
  CustomerEvents.DELETE_CUSTOMER_REQUESTED,
  { customer: ICustomer }
>(CustomerEvents.DELETE_CUSTOMER_REQUESTED);

export const deleteCustomerLoad = makePayloadAction<
  CustomerEvents.DELETE_CUSTOMER_LOADED,
  ICustomer
>(CustomerEvents.DELETE_CUSTOMER_LOADED);

export const deleteCustomerFailed =
  makeBaseAction<CustomerEvents.DELETE_CUSTOMER_FAILED>(
    CustomerEvents.DELETE_CUSTOMER_FAILED
  );

// LIST --
export const listCustomerRequest =
  makeBaseAction<CustomerEvents.LIST_CUSTOMER_REQUESTED>(
    CustomerEvents.LIST_CUSTOMER_REQUESTED
  );

export const listCustomerLoad = makePayloadAction<
  CustomerEvents.LIST_CUSTOMER_LOADED,
  PaginatedValue<ICustomer>
>(CustomerEvents.LIST_CUSTOMER_LOADED);

export const listCustomerFailed =
  makeBaseAction<CustomerEvents.LIST_CUSTOMER_FAILED>(
    CustomerEvents.LIST_CUSTOMER_FAILED
  );

export const resetCreateCustomer =
  makeBaseAction<CustomerEvents.RESET_CREATE_CUSTOMER>(
    CustomerEvents.RESET_CREATE_CUSTOMER
  );

// SEARCH
export const searchCustomerRequest = makeParamsAction<
  CustomerEvents.SEARCH_CUSTOMER_REQUESTED,
  { search: string }
>(CustomerEvents.SEARCH_CUSTOMER_REQUESTED);

export const searchCustomerLoad = makePayloadAction<
  CustomerEvents.SEARCH_CUSTOMER_LOADED,
  PaginatedValue<ICustomer>
>(CustomerEvents.SEARCH_CUSTOMER_LOADED);

export const searchCustomerFailed =
  makeBaseAction<CustomerEvents.SEARCH_CUSTOMER_FAILED>(
    CustomerEvents.SEARCH_CUSTOMER_FAILED
  );

export const actions = {
  createCustomerRequest,
  createCustomerLoad,
  createCustomerFailed,
  viewCustomerRequest,
  viewCustomerLoad,
  viewCustomerFailed,
  updateCustomerRequest,
  updateCustomerLoad,
  updateCustomerFailed,
  deleteCustomerRequest,
  deleteCustomerLoad,
  deleteCustomerFailed,
  listCustomerRequest,
  listCustomerLoad,
  listCustomerFailed,
  resetCreateCustomer,
  searchCustomerRequest,
  searchCustomerLoad,
  searchCustomerFailed,
};

export type CustomerActions = IActionUnion<typeof actions>;
