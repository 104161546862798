/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { KTSVG } from '../../../../../_start/helpers';
import { FileFieldModal } from './FileFieldModal';

/* eslint-disable jsx-a11y/anchor-has-content */
type FileFieldProps = {
  name: string;
  altText?: string;
  src: string;
  update: (fieldsToUpdate: Partial<any>) => void;
  modalId: string;
  imageWidth?: string;
  imageHeight?: string;
};

export const FileField = ({
  modalId,
  imageWidth = '300px',
  imageHeight = '200px',
  name,
  altText = '',
  src,
  update,
}: FileFieldProps) => {
  return (
    <>
      {/* begin::Media Modal */}
      <FileFieldModal
        modalId={modalId}
        name={name}
        altText={altText}
        update={update}
      />
      {/* end::Media Modal */}

      {/* begin::Media Preview */}
      <div className="position-relative w-100 p-5 d-flex rounded border-dashed border-2 opacity-75-hover overlay-wrapper">
        {src ? (
          <div
            className="m-auto d-flex flex-column"
            data-bs-toggle="modal"
            data-bs-target={`#${modalId}`}
          >
            <KTSVG
              path="/media/icons/duotone/Files/File.svg"
              className="svg-icon-muted svg-icon-3hx text-center"
            />
            <span className="mt-4 text-gray-500">{src}</span>
          </div>
        ) : (
          <div
            className="m-auto d-flex flex-column"
            data-bs-toggle="modal"
            data-bs-target={`#${modalId}`}
          >
            <KTSVG
              path="/media/icons/duotone/Files/File.svg"
              className="svg-icon-muted svg-icon-3hx text-center"
            />
            <span className="mt-4 text-gray-500">Select file</span>
          </div>
        )}
      </div>
      {/* end::Media Preview */}

      {/* begin::Media Actions */}
      <div
        className={`mt-3 d-flex gap-3 justify-content-center ${
          src ? '' : 'd-none'
        }`}
      >
        <a
          className="text-dark underline"
          data-bs-toggle="modal"
          data-bs-target={`#${modalId}`}
        >
          Update
        </a>
        {`|`}
        <a
          className="text-dark underline"
          onClick={() => update({ [name]: '' })}
        >
          Remove
        </a>
      </div>
      {/* end::Media Actions */}
    </>
  );
};
