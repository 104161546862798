/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from '../../../../setup';
import { KTSVG } from '../../../../_start/helpers';
import { ActionsDropdown } from '../../../components/dropdown/ActionsDropdown';
import { DeleteModal } from '../../../components/modals/DeleteModal';
import { useDebounce } from '../../../hooks/useDebounce';
import { defaultCustomer, ICustomer } from '../models/Customer';
import * as customerActions from '../redux/CustomerActions';

export const CustomersPage = () => {
  const dispatch = useDispatch();

  const loading = useSelector(
    (state: RootState) => state.customerManagement.loading
  );

  const paginatedCustomers = useSelector(
    (state: RootState) => state.customerManagement.paginatedCustomers
  );

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) {
      dispatch(customerActions.listCustomerRequest());
      setInitialized(true);
    }
  }, []);

  const [search, setSearch] = useState('');

  const debouncedSearch = useDebounce<string>(search, 750);

  const handleSearch = () => {
    dispatch(customerActions.searchCustomerRequest({ search }));
  };

  useEffect(() => {
    if (initialized) {
      handleSearch();
    }
  }, [debouncedSearch]);

  const [selected, setSelected] = useState<ICustomer>(defaultCustomer);

  const handleDelete = (item: ICustomer) => {
    dispatch(customerActions.deleteCustomerRequest({ customer: item }));
  };

  return (
    <>
      <DeleteModal
        headerTitle="Delete Customer"
        item={selected}
        handleDelete={handleDelete}
      />
      <div
        className={`card card-custom card-flush ${
          loading ? 'overlay overlay-block' : ''
        }`}
      >
        <div className="pt-8 card-header">
          <div className="d-flex flex-center border py-1 px-4 bg-white rounded">
            <KTSVG
              path="/media/icons/duotone/General/Search.svg"
              className="svg-icon-1 svg-icon-primary"
            />
            <input
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              type="text"
              className={`form-control border-0 fw-bold ps-2 ${
                '' ? 'w-xxl-600px' : 'w-xxl-350px'
              }`}
              placeholder="Search"
            />
          </div>
          <div className="card-toolbar">
            <Link className="btn btn-sm btn-light" to="customers/create">
              Create Customer
            </Link>
          </div>
        </div>
        <div className={`card-body py-5 ${loading ? 'overlay-wrapper' : ''}`}>
          <table className="table table-row-gray-300 gy-7">
            <thead>
              <tr className="fw-bolder fs-6 text-gray-800">
                <th>Name</th>
                <th>Business Name</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {paginatedCustomers.data.map((customer, idx) => (
                <tr key={idx} className="align-middle">
                  <td>{customer.name}</td>
                  <td>{customer.business_name}</td>
                  <td className="text-end">
                    {/* begin::Dropdown */}
                    <ActionsDropdown>
                      <div className="menu-item px-3">
                        <Link
                          to={`customers/${customer.id}/update`}
                          className="menu-link px-3"
                        >
                          Update
                        </Link>
                      </div>
                      <div className="menu-item px-3">
                        <a
                          onClick={() => setSelected(customer)}
                          href="#"
                          className="menu-link px-3 text-danger"
                          data-bs-toggle="modal"
                          data-bs-target="#custom_delete_kt_modal_1"
                        >
                          Delete
                        </a>
                      </div>
                      <div className="separator mt-3 opacity-75"></div>
                    </ActionsDropdown>
                    {/* end::Dropdown */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {loading && (
            <div className="overlay-layer rounded bg-dark bg-opacity-5">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
        {/* <div className="card-footer">
        <Pagination
          loading={loading}
          pagination={paginatedValue}
          handlePaginateState={handlePaginateState}
        />
      </div> */}
      </div>
    </>
  );
};
