import { ElementType, IElement } from '../../../models/Element';

export interface IUSP {
  title: string;
  description: string;
  icon: string;
}

export const defaultUSPIcon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 41" xml:space="preserve">
  <path d="M62.94 2.31a2.17 2.17 0 0 0-.07-.24 2.46 2.46 0 0 0-.09-.29 2.49 2.49 0 0 0-.15-.28l-.11-.22a3.2 3.2 0 0 0-.8-.8l-.23-.12-.26-.14c-.1 0-.2-.06-.3-.09a1.09 1.09 0 0 0-.24-.07 2.76 2.76 0 0 0-.57-.06H42.51a2.88 2.88 0 1 0 0 5.75h10.67L33.7 25.24l-9-9a2.89 2.89 0 0 0-4.07 0L.84 36.09a2.88 2.88 0 0 0 4.07 4.07l17.78-17.79 9 9a2.89 2.89 0 0 0 4.07 0L57.25 9.82V20.5a2.88 2.88 0 1 0 5.75 0V2.88a2.76 2.76 0 0 0-.06-.57Z" />
</svg>`;

export const defaultUSP: IUSP = {
  title: 'Search Engine Optimalisation (SEO)',
  description:
    'SEO. Oftewel hoog staan in Google. Tegenwoordig een stuk lastiger dan grofweg 5 jaar geleden. Toch loont de moeite om aandacht aan zoekmachine optimalisatie te besteden. Op de lange termijn zijn goede organische zoekresultaten rendabel.',
  icon: defaultUSPIcon,
};

export type AboutUsUniqueSellingPoint = IElement & {
  list: Array<IUSP>;
};

export const defaultAboutUsUniqueSellingPoint: AboutUsUniqueSellingPoint = {
  id: 0,
  name: ElementType.ABOUT_US_UNIQUE_SELLING_POINT,
  list: [
    defaultUSP,
    {
      ...defaultUSP,
      title: 'Advertising',
      description:
        'Adverteren is in veel sitauties een uitstekende oplossing. Resultaat op korte termijn of ondersteunend aan organische resultaten. Of je moet adverteren op je eige bedrijfsnaam? Bel ons, dan nemen we die vraag graag met je door ;).',
    },
    {
      ...defaultUSP,
      title: 'WordPress websites',
      description:
        'Wij ontwikkelen WordPress websites. Elke dag en met veel plezier. Wij werken volgens de WordPress coding-standard. Zo zorgen we dat onderhoud en doorontwikkeling zonder grote struikelblokken plaats kan vinden.',
    },
    {
      ...defaultUSP,
      title: 'Maatwerk plugins',
      description:
        'Valt jouw vraag buiten de mogelijkheden van standaard plugins? Geen enkel probleem. Wij hebben veel ervaring in het ontwikkelen van maatwerk WordPress plugins. Op grote schaal, maar ook individueel. We kunnen je dus ongetwijfeld helpen. Vraag ons gerust.',
    },
  ],
};
