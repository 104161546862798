import React from 'react';
import { FormImageFieldModal } from '../../../../media-management/components/form-image/FormImageFieldModal';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { IElement } from '../../../models/Element';
import { SpecializationFloatingLeftModal } from './SpecializationsFloatingLeftModal';
import { SpecializationsFloatingLeftModalContent } from './SpecializationsFloatingLeftModalContent';
import { SpecializationsFloatingLeftModalFigure } from './SpecializationsFloatingLeftModalFigure';

interface Props {
  index: number;
  data: SpecializationFloatingLeftModal;
  updateData: (element: IElement, index: number) => void;
}

export const SpecializationsFloatingLeftModalFC: React.FC<Props> = ({
  index,
  data,
  updateData,
}) => {
  const update = (fieldsToUpdate: Partial<SpecializationFloatingLeftModal>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  return (
    <>
      <FormImageFieldModal
        modalId={`${data.name}-${data.id}`}
        name={`image_preview`}
        altText={`image_preview_alt`}
        update={update}
      />

      <ElementWrapper elementIndex={index}>
        <div className="specializations-left-modal body-my-8">
          <SpecializationsFloatingLeftModalContent
            data={data}
            update={update}
          />
          <SpecializationsFloatingLeftModalFigure data={data} update={update} />
        </div>
      </ElementWrapper>
    </>
  );
};
