import { ElementWrapper } from '../../../components/ElementWrapper';
import { StaticWidthInlineEdit } from '../../../components/StaticWidthInlineEdit';
import { IElement } from '../../../models/Element';
import { TitleElement } from './TitleElement';

type TitleElementFCProps = {
  index: number;
  data: TitleElement;
  updateData: (body: IElement, index: number) => void;
};

export const TitleElementFC = ({
  index,
  data,
  updateData,
}: TitleElementFCProps) => {
  const update = (fieldsToUpdate: Partial<TitleElement>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  return (
    <ElementWrapper elementIndex={index}>
      <div className="case-title body-my-8">
        <div className="case-title__content">
          <StaticWidthInlineEdit
            name="title"
            className="content-title"
            value={data.title}
            setValue={update}
          />
        </div>
      </div>
    </ElementWrapper>
  );
};
