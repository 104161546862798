import React from 'react';
import { KTSVG } from '../../../../../../_start/helpers';
import { ButtonTooltip } from '../../../components/ButtonTooltip';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { InlineEdit } from '../../../components/InlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { IElement } from '../../../models/Element';
import { ReviewsElement } from './ReviewsElement';

interface Props {
  index: number;
  data: ReviewsElement;
  updateData: (element: IElement, index: number) => void;
}

export const ReviewsElementFC: React.FC<Props> = ({
  index,
  data,
  updateData,
}) => {
  const update = (fieldsToUpdate: Partial<ReviewsElement>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  return (
    <ElementWrapper elementIndex={index}>
      <div className="reviews-element">
        <TextAreaEdit
          className="heading"
          name="heading"
          value={data.heading}
          setValue={update}
        />

        <div className="rating">
          {[...Array(5)].map((x) => (
            <KTSVG
              path="/assets/icons/icon-star-filled.svg"
              className="rating__stars"
              svgClassName="rating__filled"
            />
          ))}
          <div className="rating__score">{'(Rate will show here)'}</div>
        </div>

        <div className="review__cards">{'Reviews will be shown here'}</div>

        <div className="review__buttons">
          <div className="btn btn-success rounded-pill">
            <div className="px-1 text-white text-center">Alle Reviews</div>
          </div>

          <div
            className="btn btn-light rounded-pill"
            style={{ borderStyle: 'solid', borderWidth: '2px' }}
          >
            <div className="px-1 text-black text-center">Review Schrijven</div>
          </div>
        </div>
      </div>
    </ElementWrapper>
  );
};
