import { KTSVG } from '../../../../../../_start/helpers';
import { ButtonTooltip } from '../../../components/ButtonTooltip';
import { InlineEdit } from '../../../components/InlineEdit';
import { StaticWidthInlineEdit } from '../../../components/StaticWidthInlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { ContactsHeader } from './ContactsHeader';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

type ContactsContentProps = {
  data: ContactsHeader;
  update: (fieldsToUpdate: Partial<ContactsHeader>) => void;
};

export const ContactsHeaderContent = ({
  data,
  update,
}: ContactsContentProps) => {
  return (
    <div className="contacts-header__content">
      {/* begin::Title */}
      <StaticWidthInlineEdit
        name="title"
        className="content-title"
        value={data.title}
        setValue={update}
        activeVerticalPadding={`py-0`}
      />
      {/* end::Title */}

      {/* begin::Heading */}
      <TextAreaEdit
        name="heading"
        className="content-heading"
        value={data.heading}
        setValue={update}
      />
      {/* end::Heading */}

      {/* begin::Description */}
      {/* <TextAreaEdit
        name="description"
        className="content-description"
        value={data.description}
        setValue={update}
      /> */}
      <div className="content-description">
        <CKEditor
          editor={ClassicEditor}
          data={data.description}
          // @ts-ignore
          onReady={(editor) => {
            // You can store the "editor" and use when it is needed.
            // console.log('Editor is ready to use!', editor);
          }}
          // @ts-ignore
          onChange={(event, editor) => {
            const editorData = editor.getData();

            update({ description: editorData });
          }}
          // @ts-ignore
          onBlur={(event, editor) => {
            // console.log('Blur.', editor);
          }}
          // @ts-ignore
          onFocus={(event, editor) => {
            // console.log('Focus.', editor);
          }}
        />
      </div>
      {/* end::Description */}

      {/* begin::Actions */}
      <div className="content-action">
        <ButtonTooltip
          name={`button_url1`}
          className="content-action__button"
          value={data.button_url1}
          setValue={update}
        >
          <button className="btn btn-success rounded-pill">
            <InlineEdit
              name="button_title1"
              className="px-1 text-white text-center"
              value={data.button_title1}
              setValue={update}
              activeVerticalPadding={'py-1'}
              dynamicWidth
              style={{
                maxWidth: '200px',
              }}
            />
          </button>
        </ButtonTooltip>

        <ButtonTooltip
          name={`button_url2`}
          className="content-action__button"
          value={data.button_url2}
          setValue={update}
        >
          <div>
            <InlineEdit
              name="button_title2"
              className="px-1 text-center text-success fw-bolder"
              value={data.button_title2}
              setValue={update}
              activeVerticalPadding={'py-1'}
              dynamicWidth
              style={{
                maxWidth: '200px',
              }}
            />
          </div>
        </ButtonTooltip>
      </div>
      {/* end::Actions */}
    </div>
  );
};
