/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../setup';
import { KTSVG, toAbsoluteUrl } from '../../../../../../_start/helpers';
import { ButtonTooltip } from '../../../components/ButtonTooltip';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { InlineEdit } from '../../../components/InlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { IElement } from '../../../models/Element';
import { SpecializationFeaturedPosts } from './SpecializationsFeaturedPosts';

import immutabilityHelper from 'immutability-helper';
import {
  defaultPostPlaceholder,
  IPost,
} from '../../../../post-management/models/Post';
import { SpecializationFeaturedPostWrapper } from './SpecializationFeaturedPostWrapper';
import { setPage } from '../../../redux/EditContentActions';
import { useEffect, useState } from 'react';
// import { BlogFeaturedFigure } from "./BlogFeaturedFigure";

type SpecializationsFeaturedPostsProps = {
  index: number;
  data: SpecializationFeaturedPosts;
  updateData: (element: IElement, index: number) => void;
};

export const SpecializationFeaturePostsFC = ({
  index,
  data,
  updateData,
}: SpecializationsFeaturedPostsProps) => {
  const dispatch = useDispatch();

  const page = useSelector((state: RootState) => state.editContent.page);

  // initialize base from the Post model featured_posts
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if (page.id !== 0 && !initialized) {
      // initialize CONTENT featured post image preview
      const tempPosts = page.page_featured_posts.map((item, idx) => ({
        ...item,
        // image_preview: data.featured_posts[idx].image_preview,
        // image_preview_alt: data.featured_posts[idx].image_preview_alt,
      }));

      update({
        featured_posts: tempPosts,
      });

      setInitialized(true);
    }
  }, [page]);

  const update = (fieldsToUpdate: Partial<SpecializationFeaturedPosts>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);

    // update the featured_posts on post data
    dispatch(
      setPage({
        ...page,
        page_featured_posts: updatedData.featured_posts,
      })
    );
  };

  const updateFeaturedPost = (idx: number, post: IPost) => {
    let featured_posts = [...data.featured_posts];
    featured_posts[idx] = post;

    const updatedData: SpecializationFeaturedPosts = {
      ...data,
      featured_posts,
    };

    update(updatedData);
  };

  const addPost = () => {
    const featured_posts = immutabilityHelper(data.featured_posts, {
      $push: [defaultPostPlaceholder],
    });

    const updatedData = { ...data, featured_posts };
    update(updatedData);
  };

  const removePost = (itemIndex: number) => {
    let featured_posts = immutabilityHelper(data.featured_posts, {
      $splice: [[itemIndex, 1]],
    });

    const updatedData = { ...data, featured_posts };
    update(updatedData);
  };

  const allPosts = useSelector(
    (state: RootState) => state.editContent.allPosts
  );

  const [selectedPostsMap, setSelectedPostsMap] = useState<Map<number, IPost>>(
    new Map()
  );

  useEffect(() => {
    if (allPosts.length) {
      const iterable: Array<[number, IPost]> = page.page_featured_posts.map(
        (post, idx) => [post.id, post]
      );

      setSelectedPostsMap(new Map(iterable));
    }
  }, [allPosts, page]);

  return (
    <ElementWrapper elementIndex={index}>
      <div className={`blog-featured w-100 my-10`}>
        <div className="blog-featured__heading">
          <TextAreaEdit
            className={`heading-title`}
            name="title"
            value={data.title}
            setValue={update}
          />

          <ButtonTooltip
            name={`button_url`}
            className="heading-action"
            value={data.button_url}
            setValue={update}
          >
            <button className="btn btn-success rounded-pill">
              <InlineEdit
                className="action-text"
                name="button_title"
                value={data.button_title}
                setValue={update}
                activeVerticalPadding={'py-0'}
                dynamicWidth
                style={{
                  maxWidth: '22rem',
                }}
              />
            </button>
          </ButtonTooltip>
        </div>

        <div className="blog-featured__list">
          {data.featured_posts.map((element, idx) => (
            <SpecializationFeaturedPostWrapper
              key={idx}
              itemIndex={idx}
              blog={element}
              updateSelected={updateFeaturedPost}
              selectedMap={selectedPostsMap}
            >
              <div className="list-item">
                <span
                  onClick={() => removePost(idx)}
                  className="position-absolute z-index-2 top-0 end-0 me-2 mt-2 opacity-75-hover"
                >
                  <KTSVG
                    path="/media/icons/duotone/Interface/Minus-Square.svg"
                    className="svg-icon-danger svg-icon-1"
                  />
                </span>

                <div className="item-figure">
                  {element.image_preview ? (
                    <img src={element.image_preview} alt="Placeholder" />
                  ) : (
                    <img
                      src={toAbsoluteUrl('/assets/images/400x344.png')}
                      alt="Placeholder"
                    />
                  )}
                </div>

                {/* <BlogFeaturedFigure
									dataIndex={idx}
									post={element}
									updateFeaturedPost={updateFeaturedPost}
									blogFeatured={data}
								/> */}

                <div className="item-post-type">
                  {
                    // @ts-ignore
                    element.tags.map(
                      // @ts-ignore
                      (tag: ITag, idx) => (
                        <a key={idx} className="post-type-category">
                          {tag.name}
                        </a>
                      )
                    )
                  }
                </div>

                <div className="item-title">{element.title}</div>
              </div>
            </SpecializationFeaturedPostWrapper>
          ))}

          <div onClick={addPost} className="add-item">
            <div className="add-item__inner">
              <div className="m-auto d-flex flex-column">
                <KTSVG
                  path="/media/icons/duotone/Interface/Plus-Square.svg"
                  className="svg-icon-muted svg-icon-3hx text-center"
                />
                <span className="mt-4 text-gray-500">Add new item</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ElementWrapper>
  );
};
