import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { BlogContentInfoPanel } from "./BlogContentInfoPanel";
import { IElement } from "../../../models/Element";
import { ElementWrapper } from "../../../components/ElementWrapper";

type BlogContentInfoPanelFCProps = {
	index: number;
	data: BlogContentInfoPanel;
	updateData: (body: IElement, index: number) => void;
};

export const BlogContentInfoPanelFC = ({
	index,
	data,
	updateData,
}: BlogContentInfoPanelFCProps) => {
	const update = (fieldsToUpdate: Partial<BlogContentInfoPanel>) => {
		const updatedData = { ...data, ...fieldsToUpdate };
		updateData(updatedData, index);
	};

	return (
		<ElementWrapper elementIndex={index}>
			<div className="blog-content-info-panel body-my-8">
				<div className="blog-content">
					<CKEditor
						className="blog-content__editor"
						editor={ClassicEditor}
						data={data.content}
						// @ts-ignore
						onReady={(editor) => {
							// You can store the "editor" and use when it is needed.
							// console.log('Editor is ready to use!', editor);
						}}
						// @ts-ignore
						onChange={(event, editor) => {
							const editorData = editor.getData();

							update({ content: editorData });
						}}
						// @ts-ignore
						onBlur={(event, editor) => {
							//   console.log('Blur.', editor);
						}}
						// @ts-ignore
						onFocus={(event, editor) => {
							//   console.log('Focus.', editor);
						}}
					/>
				</div>
			</div>
		</ElementWrapper>
	);
};
