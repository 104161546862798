
import { defaultEmployee, IEmployee } from "../../../../employee-management/pages/employees/Employee";
import { ElementType, IElement } from "../../../models/Element";

export type BlogAuthorHeader = IElement & {
	author: IEmployee;
	button_title: string;
	button_url: string;
};

export const defaultBlogAuthorHeader: BlogAuthorHeader = {
	id: 0,
	name: ElementType.BLOG_AUTHOR_HEADER,
	author: { ...defaultEmployee, name: "John Doe" },
	button_title: "Artikel delen",
	button_url: "",
};
