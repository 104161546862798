import React from 'react';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { InlineEdit } from '../../../components/InlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { IElement } from '../../../models/Element';
import { SpecializationsFullText } from './SpecializationsFullText';

interface Props {
  index: number;
  data: SpecializationsFullText;
  updateData: (element: IElement, index: number) => void;
}

export const SpecializationsFullTextFC: React.FC<Props> = ({
  index,
  data,
  updateData,
}) => {
  const update = (fieldsToUpdate: Partial<SpecializationsFullText>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  return (
    <ElementWrapper elementIndex={index}>
      <div className="specializations-full-text body-my-8">
        {/* begin::Heading */}
        <div className="specializations-full-text__heading">
          <InlineEdit
            name="heading"
            className="text-heading"
            value={data.heading}
            setValue={update}
          />
        </div>
        {/* end::Heading */}

        <div className="specializations-full-text__message">
          {/* begin::Message */}
          <TextAreaEdit
            name="message"
            className="text-message"
            value={data.message}
            setValue={update}
          />
          {/* end::Message */}
        </div>
      </div>
    </ElementWrapper>
  );
};
