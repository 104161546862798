export enum EmployeeEvents {
  INITIALIZATION_EMPLOYEE_REQUESTED = '[Request Initialization EMPLOYEE] Action',
  INITIALIZATION_EMPLOYEE_LOADED = '[Load Initialization EMPLOYEE] Action',
  INITIALIZATION_EMPLOYEE_FAILED = '[Initialization EMPLOYEE Failed] Action',

  CREATE_EMPLOYEE_REQUESTED = '[Request Create EMPLOYEE] Action',
  CREATE_EMPLOYEE_LOADED = '[Load Created EMPLOYEE] API',
  CREATE_EMPLOYEE_FAILED = '[Create EMPLOYEE Failed] API',

  VIEW_EMPLOYEE_REQUESTED = '[Request View EMPLOYEE] Action',
  VIEW_EMPLOYEE_LOADED = '[Load Viewed EMPLOYEE] API',
  VIEW_EMPLOYEE_FAILED = '[View EMPLOYEE Failed] API',

  UPDATE_EMPLOYEE_REQUESTED = '[Request Update EMPLOYEE] Action',
  UPDATE_EMPLOYEE_LOADED = '[Load Updated EMPLOYEE] API',
  UPDATE_EMPLOYEE_FAILED = '[Update EMPLOYEE Failed] API',

  DELETE_EMPLOYEE_REQUESTED = '[Request Delete EMPLOYEE] Action',
  DELETE_EMPLOYEE_LOADED = '[Load Deleted EMPLOYEE] API',
  DELETE_EMPLOYEE_FAILED = '[Delete EMPLOYEE Failed] API',

  LIST_EMPLOYEE_REQUESTED = '[Request List EMPLOYEE] Action',
  LIST_EMPLOYEE_LOADED = '[Load List EMPLOYEE] API',
  LIST_EMPLOYEE_FAILED = '[List EMPLOYEE Failed] API',

  RESET_CREATE_EMPLOYEE = '[Reset Create EMPLOYEE] Action',

  SEARCH_EMPLOYEE_REQUESTED = '[Request Search EMPLOYEE] Action',
  SEARCH_EMPLOYEE_LOADED = '[Load Search EMPLOYEE] API',
  SEARCH_EMPLOYEE_FAILED = '[Search EMPLOYEE Failed] API',
}
