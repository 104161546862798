import { Redirect, Route, Switch } from "react-router-dom";
import { PageTitle } from "../../../_start/layout/core";
// import { CreateFAQ } from "../content-management/pages/faq/CreateFAQ";
// import { FAQsPage } from "../content-management/pages/faq/FAQsPage";
// import { UpdateFAQ } from "../content-management/pages/faq/UpdateFAQ";
import { CategoriesPage } from "./pages/Category/CategoriesPage";
import { CreateCategory } from "./pages/Category/CreateCategory";
import { UpdateCategory } from "./pages/Category/UpdateCategory";
import { CreatePost } from "./pages/Post/CreatePost";
import { PostEditContent } from "./pages/Post/PostEditContent";
import { PostsPage } from "./pages/Post/PostsPage";
import { UpdatePost } from "./pages/Post/UpdatePost";
import { CreateTag } from "./pages/Tag/CreateTag";
import { TagsPage } from "./pages/Tag/TagsPage";
import { UpdateTag } from "./pages/Tag/UpdateTag";

export const PostManagementPage = () => {
	return (
		<Switch>
			{/* begin::CATEGORY ROUTES */}
			<Route exact={true} path="/post-management/categories">
				<>
					<PageTitle>Categories</PageTitle>
					<CategoriesPage />
				</>
			</Route>
			<Route exact={true} path="/post-management/categories/create">
				<>
					<PageTitle>Categories</PageTitle>
					<CreateCategory />
				</>
			</Route>
			<Route exact={true} path="/post-management/categories/:id/update">
				<>
					<PageTitle>Categories</PageTitle>
					<UpdateCategory />
				</>
			</Route>
			{/* end::CATEGORY ROUTES */}

			{/* begin::TAG ROUTES */}
			<Route exact={true} path="/post-management/tags">
				<>
					<PageTitle>Tags</PageTitle>
					<TagsPage />
				</>
			</Route>
			<Route exact={true} path="/post-management/tags/create">
				<>
					<PageTitle>Tags</PageTitle>
					<CreateTag />
				</>
			</Route>
			<Route exact={true} path="/post-management/tags/:id/update">
				<>
					<PageTitle>Tags</PageTitle>
					<UpdateTag />
				</>
			</Route>
			{/* end::TAG ROUTES */}

			{/* begin::POST ROUTES */}
			<Route exact={true} path="/post-management/posts">
				<>
					<PageTitle>Posts</PageTitle>
					<PostsPage />
				</>
			</Route>
			<Route exact={true} path="/post-management/posts/create">
				<>
					<PageTitle>Posts</PageTitle>
					<CreatePost />
				</>
			</Route>
			<Route exact={true} path="/post-management/posts/:id/update">
				<>
					<PageTitle>Posts</PageTitle>
					<UpdatePost />
				</>
			</Route>
			<Route exact={true} path="/post-management/posts/:id/edit-content">
				<>
					<PageTitle>Posts</PageTitle>
					<PostEditContent />
				</>
			</Route>
			{/* end::POST ROUTES */}

			{/* FAQs */}
			{/* <Route exact={true} path="/post-management/faqs">
				<>
					<PageTitle>FAQs</PageTitle>
					<FAQsPage />
				</>
			</Route>
			<Route exact={true} path="/post-management/faqs/create">
				<>
					<PageTitle>FAQs</PageTitle>
					<CreateFAQ cancelUrl={`/post-management/faqs`} />
				</>
			</Route>
			<Route exact={true} path="/post-management/faqs/:id/update">
				<>
					<PageTitle>FAQs</PageTitle>
					<UpdateFAQ cancelUrl={`/post-management/faqs`} />
				</>
			</Route> */}
			{/* FAQs */}

			<Redirect
				from="/post-management"
				exact={true}
				to="/post-management/posts"
			/>
			<Redirect to="/post-management/posts" />
		</Switch>
	);
};
