/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { KTSVG } from '../../../../../../_start/helpers';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import {
  CustomerQuestion,
  CustomerQuestionItemTypes,
  ICustomerQuestionItem,
} from './CustomerQuestion';

type CustomerQuestionItemProps = {
  index: number;
  item: ICustomerQuestionItem;
  customerQuestion: CustomerQuestion;
  updateItem: (idx: number, item: ICustomerQuestionItem) => void;
  removeListItem: (itemIndex: number) => void;
  moveListItem: (dragIndex: number, hoverIndex: number) => void;
};

export const CustomerQuestionItem = ({
  index,
  item,
  customerQuestion,
  updateItem,
  removeListItem,
  moveListItem,
}: CustomerQuestionItemProps) => {
  const [data, setData] = useState(item);

  const update = (fieldsToUpdate: Partial<ICustomerQuestionItem>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
    updateItem(index, updatedData);
  };

  useEffect(() => {
    setData(item);
  }, [item.content]);

  const ref = useRef<HTMLLIElement>(null);
  const removeItemRef = useRef<HTMLSpanElement>(null);

  const onMouseEnter = (event: React.MouseEvent<HTMLLIElement>) => {
    removeItemRef.current?.classList.remove('d-none');
  };

  const onMouseLeave = (event: React.MouseEvent<HTMLLIElement>) => {
    removeItemRef.current?.classList.add('d-none');
  };

  const [{ isDragging }, drag] = useDrag(() => ({
    type: `${CustomerQuestionItemTypes.ITEM}-${customerQuestion.id}`,
    item: () => ({ id: data.id, index: index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const [{ isOver, canDrop, handlerId }, drop] = useDrop({
    accept: `${CustomerQuestionItemTypes.ITEM}-${customerQuestion.id}`,
    drop: (item: { id: number; index: number }) => {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      moveListItem(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      handlerId: monitor.getHandlerId(),
    }),
  });

  drop(drag(ref));

  const opacity = isDragging ? 0.3 : 1;

  return (
    <li
      ref={ref}
      data-handler-id={handlerId}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{ opacity }}
      className={`list-item ${canDrop ? 'can-drop-highlight' : 'bg-light'} ${
        isOver ? 'is-over-highlight' : ''
      }`}
    >
      {/* begin::Delete Item */}
      {customerQuestion.list.length > 1 && (
        <span
          ref={removeItemRef}
          onClick={() => removeListItem(index)}
          className="position-absolute top-0 end-0 d-none mt-n2 me-n2 opacity-75-hover"
        >
          <KTSVG
            path="/media/icons/duotone/Interface/Minus-Square.svg"
            className="svg-icon-danger svg-icon-1"
          />
        </span>
      )}
      {/* end::Delete Item */}

      <KTSVG
        path="/assets/icons/icon-check.svg"
        className="svg-icon-3"
        svgClassName="item-icon"
      />
      <TextAreaEdit
        className="item-text"
        name="content"
        value={data.content}
        setValue={update}
      />
    </li>
  );
};
