/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { IFAQCategory } from '../../../../page-management/models/FAQCategory';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { IElement } from '../../../models/Element';

import { FAQSelectNarrow } from './FAQSelectNarrow';
import { FAQSelectNarrowList } from './FAQSelectNarrowList';
import Select from 'react-select';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { IFAQ } from '../../../../page-management/models/FAQ';
import { getAllFAQCategory } from '../../../../page-management/redux/FAQCategoryCRUD';
import { failureToast } from '../../../../../support/utils';

interface FAQProps {
  index: number;
  data: FAQSelectNarrow;
  updateData: (body: IElement, index: number) => void;
}

export const FAQSelectNarrowFC: React.FC<FAQProps> = ({
  index,
  data,
  updateData,
}) => {
  const [categories, setCategories] = useState<Array<IFAQCategory>>([]);
  const [selectedCategory, setSelectedCategory] = useState<IFAQCategory | null>(
    null
  );
  const [currentFAQs, setCurrentFAQs] = useState<Array<IFAQ>>([]);

  const [loading, setLoading] = useState(false);

  const update = (fieldsToUpdate: Partial<FAQSelectNarrow>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  const handleInitialize = async () => {
    setLoading(true);

    try {
      const categories = await getAllFAQCategory();

      setCategories(categories);

      setSelectedCategory(data.category ?? null);

      setLoading(false);
    } catch (e) {
      failureToast(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedCategory) {
      update({ category: selectedCategory, item_list: selectedCategory.faqs });
    }
  }, [selectedCategory]);

  useEffect(() => {
    handleInitialize();
  }, []);

  return (
    <ElementWrapper elementIndex={index}>
      <div className="specializations-faq body-my-8">
        <div className="specializations-grid">
          <div className="grid__inner">
            <div className="specializations-faq__content">
              <Select
                placeholder={`Select category...`}
                value={selectedCategory}
                onChange={setSelectedCategory}
                getOptionValue={(model: IFAQCategory) => model.id.toString()}
                getOptionLabel={(model: IFAQCategory) => model.title}
                options={categories}
                isSearchable
                className="content-description"
              />
            </div>
            {selectedCategory && (
              <FAQSelectNarrowList data={data} update={update} />
            )}
          </div>
        </div>
      </div>
    </ElementWrapper>
  );
};
