import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { BlogContentDescription } from "./BlogContentDescription";
import { IElement } from "../../../models/Element";
import { ElementWrapper } from "../../../components/ElementWrapper";
import { TextAreaEdit } from "../../../components/TextAreaEdit";
import {
	getHeaderClassName,
	HeaderTypeSelect,
} from "../../../components/HeaderTypeSelect";

type BlogContentDescriptionFCProps = {
	index: number;
	data: BlogContentDescription;
	updateData: (body: IElement, index: number) => void;
};

export const BlogContentDescriptionFC = ({
	index,
	data,
	updateData,
}: BlogContentDescriptionFCProps) => {
	const update = (fieldsToUpdate: Partial<BlogContentDescription>) => {
		const updatedData = { ...data, ...fieldsToUpdate };
		updateData(updatedData, index);
	};

	return (
		<ElementWrapper elementIndex={index}>
			<div className="blog-content-description my-10">
				<div className="blog-content">
					<div className="position-relative">
						<TextAreaEdit
							name="title"
							className={`blog-content__title ${getHeaderClassName(
								data.title_type
							)}`}
							value={data.title}
							setValue={update}
						/>
						<HeaderTypeSelect
							name={`title_type`}
							value={data.title_type}
							setValue={update}
						/>
					</div>

					<CKEditor
						className="blog-content__editor"
						editor={ClassicEditor}
						data={data.content}
						// @ts-ignore
						onReady={(editor) => {
							// You can store the "editor" and use when it is needed.
							// console.log('Editor is ready to use!', editor);
						}}
						// @ts-ignore
						onChange={(event, editor) => {
							const editorData = editor.getData();

							update({ content: editorData });
						}}
						// @ts-ignore
						onBlur={(event, editor) => {
							//   console.log('Blur.', editor);
						}}
						// @ts-ignore
						onFocus={(event, editor) => {
							//   console.log('Focus.', editor);
						}}
					/>
				</div>
			</div>
		</ElementWrapper>
	);
};
