/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { useEffect, useRef, useState } from 'react';
import { KTSVG } from '../../../../../../_start/helpers';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { defaultPlugin, HomePlugins, Plugin } from './HomePlugins';
import { LinkTooltipWrapper } from './LinkTooltipWrapper';
import { SelectIconWrapper } from './SelectIconWrapper';


type HomePluginsItemProps = {
  homePlugin: HomePlugins;
  plugin: Plugin;
  pluginIndex: number;
  updatePlugin: (idx: number, plugin: Plugin) => void;
  removePlugin: (idx: number) => void;
};

export const HomePluginsItem = ({
  homePlugin,
  plugin,
  pluginIndex,
  updatePlugin,
  removePlugin,
}: HomePluginsItemProps) => {
  const [data, setData] = useState(defaultPlugin);

  useEffect(() => {
    setData(plugin);
  }, [plugin]);

  const updateData = (fieldsToUpdate: Partial<Plugin>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
    updatePlugin(pluginIndex, updatedData);
  };

  const removeItemRef = useRef<HTMLSpanElement>(null);

  const handleRemove = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    event.preventDefault();
    removePlugin(pluginIndex);
  };

  return (
    <div className="item">
      {/* begin::Delete Item */}
      {homePlugin.plugins.length > 3 && (
        <span
          ref={removeItemRef}
          onClick={handleRemove}
          className="position-absolute z-index-2 top-0 end-0 me-2 mt-2 opacity-75-hover"
        >
          <KTSVG
            path="/media/icons/duotone/Interface/Minus-Square.svg"
            className="svg-icon-danger svg-icon-1"
          />
        </span>
      )}
      {/* end::Delete Item */}

      <div className="item__inner bg-light">
        <div className="icon">
          <SelectIconWrapper plugin={plugin} update={updateData}>
            <img
              className="text-center icon__placeholder"
              src={plugin.icon.path}
              alt="plugin-icon"
            />
            {/* <KTSVG
              path={plugin.icon.path}
              className="text-center icon__placeholder"
            /> */}
          </SelectIconWrapper>
        </div>

        <TextAreaEdit
          name="title"
          className="title"
          value={data.title}
          setValue={updateData}
        />

        <TextAreaEdit
          name="description"
          className="description"
          value={data.description}
          setValue={updateData}
        />

        <LinkTooltipWrapper
          name="url_link"
          value={data.url_link}
          setValue={updateData}
          className={`z-index-1`}
        >
          <div className="action">
            <span className="action__link">
              <span className="text">Meer info</span>
              <KTSVG
                path="/assets/icons/icon-arrow.svg"
                className="ms-2 svg-icon-muted text-icon"
              />
            </span>
            <a href="#" className="action__button">
              <KTSVG
                path="/assets/icons/icon-cart-regular.svg"
                className="svg-icon-muted"
              />
            </a>
          </div>
        </LinkTooltipWrapper>
      </div>
    </div>
  );
};
