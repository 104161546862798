import { IRole } from "../../role-and-permission/pages/roles/Role";

export interface IMember {
  id: number;
  name: string;
  email: string;
  password?: string;
  role_id?: number;
  roles?: Array<IRole>
}

export const defaultMember: IMember = {
  id: 0,
  name: '',
  email: '',
  password: '',
  role_id: 4,
};
