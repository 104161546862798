/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { removeElement } from "../redux/EditContentActions";
import { RemoveElementButton } from "./RemoveElementButton";

type ElementWrapperProps = {
  elementIndex: number;
};

export const ElementWrapper: React.FC<ElementWrapperProps> = ({
  elementIndex,
  children,
}) => {
  const dispatch = useDispatch();

  const remove = useCallback((index: number) => {
    dispatch(removeElement({ index }));
  }, []);

  const parentDivRef = useRef<HTMLDivElement>(null);
  const removeButtonRef = useRef<HTMLButtonElement>(null);

  const onMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    removeButtonRef.current?.classList.remove("d-none");

    if (event.currentTarget === event.target) {
      event.stopPropagation();
    }

    event.currentTarget.style.cursor = "pointer";
  };

  const onMouseLeave = (event: React.MouseEvent<HTMLDivElement>) => {
    removeButtonRef.current?.classList.add("d-none");
    event.currentTarget.style.cursor = "auto";
  };

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={parentDivRef}
      className="position-relative d-flex flex-row justify-content-between mb-10"
    >
      <RemoveElementButton
        index={elementIndex}
        remove={remove}
        buttonRef={removeButtonRef}
      />

      {children}
    </div>
  );
};
