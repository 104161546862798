import { ElementType, IElement } from '../../../models/Element';

export type ReviewsElement = IElement & {
  heading: string;
};

export const defaultReviewsElement: ReviewsElement = {
  id: 0,
  name: ElementType.REVIEWS_ELEMENT,
  heading: 'Reviews',
};
