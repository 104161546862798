import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import * as auth from '../../app/modules/auth';
import * as userManagement from '../../app/modules/user-management/redux/UserManagementRedux';
import * as page from '../../app/modules/page-management/redux/page/PageRedux';
import * as customerManagement from '../../app/modules/customer-management/redux/CustomerRedux';
import * as employeeManagement from '../../app/modules/employee-management/redux/EmployeeRedux';
import * as departmentManagement from '../../app/modules/employee-management/redux/DepartmentRedux';

import * as editContent from "../../app/modules/edit-content/redux/EditContentRedux";
import * as mediaLibrary from "../../app/modules/media-management/redux/MediaLibraryRedux";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  userManagement: userManagement.reducer,
  page: page.reducer,
  customerManagement: customerManagement.reducer,
  employeeManagement: employeeManagement.reducer,
  departmentManagement: departmentManagement.reducer,
  editContent: editContent.reducer,
  mediaLibrary: mediaLibrary.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export function* rootSaga() {
  yield all([
    auth.saga(),
    userManagement.saga(),
    page.saga(),
    customerManagement.saga(),
    employeeManagement.saga(),
    departmentManagement.saga(),
    editContent.saga(),
  ]);
}
