import { PageLink } from '../../../_start/layout/core';

export const mediaManagementSubmenu: Array<PageLink> = [
  {
    title: 'Media',
    path: '/media-management/media',
    isActive: true,
  },

  {
    title: 'Files',
    path: '/media-management/files',
    isActive: true,
  },
];
