import React from 'react';
import { useSelector } from 'react-redux';
import { RoleNames } from '../../../../app/modules/role-and-permission/pages/roles/Role';
import { RootState } from '../../../../setup';

type AuthMenuProps = {
  requiredRoles: Array<RoleNames | undefined>;
};
export const AuthMenu: React.FC<AuthMenuProps> = ({
  requiredRoles,
  children,
}) => {
  const user = useSelector((state: RootState) => state.auth.user);

  const hasRequiredRole = requiredRoles.includes(user?.roles[0].name);

  return <>{hasRequiredRole && children}</>;
};
