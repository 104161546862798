import { ButtonTooltip } from '../../../components/ButtonTooltip';
import { InlineEdit } from '../../../components/InlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { PluginPartners } from './PluginPartners';

type HomeEmployeesContentProps = {
  data: PluginPartners;
  update: (fieldsToUpdate: Partial<PluginPartners>) => void;
};

export const PluginPartnersContent = ({
  data,
  update,
}: HomeEmployeesContentProps) => {
  return (
    <div className="plugin-partners__content">
      {/* begin::Heading */}
      <TextAreaEdit
        name="heading"
        className="content-heading"
        value={data.heading}
        setValue={update}
      />
      {/* end::Heading */}
    </div>
  );
};
