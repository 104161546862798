import {
  defaultCaseDetailPlaceholder,
  ICaseDetail,
} from '../../../../page-management/models/CaseDetail';
import { ElementType, IElement } from '../../../models/Element';
import { ElementHeaderType } from '../../utils';

export type SpecializationFeaturedCases = IElement & {
  title: string;
  description: string;
  title_type: ElementHeaderType;
  button_title: string;
  button_url: string;
  featured_cases: Array<ICaseDetail>;
};

export const defaultSpecializationFeaturedCases: SpecializationFeaturedCases = {
  id: 0,
  name: ElementType.SPECIALIZATION_FEATURED_CASES,
  title: 'Ons werk',
  description:
    'Van WordPress website en webwinkel ontwikkeling. Malesuada AeneanAenean lacinia bibendum nulla.',
  title_type: ElementHeaderType.H2,
  button_title: 'Meer artikelen',
  button_url: '',
  featured_cases: [defaultCaseDetailPlaceholder],
};
