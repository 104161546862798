/* eslint-disable jsx-a11y/anchor-is-valid */
import { toAbsoluteUrl } from '../../../../../../_start/helpers';
import { FormImageFieldModal } from '../../../../media-management/components/form-image/FormImageFieldModal';
import { ButtonTooltip } from '../../../components/ButtonTooltip';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { InlineEdit } from '../../../components/InlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { IElement } from '../../../models/Element';
import { WebsiteScreenshotWithTextRight } from './WebsiteScreenshotWithTextRight';

type WebsiteScreenshotWithTextRightFCProps = {
  index: number;
  data: WebsiteScreenshotWithTextRight;
  updateData: (body: IElement, index: number) => void;
};

export const WebsiteScreenshotWithTextRightFC = ({
  index,
  data,
  updateData,
}: WebsiteScreenshotWithTextRightFCProps) => {
  const update = (fieldsToUpdate: Partial<WebsiteScreenshotWithTextRight>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  return (
    <>
      <FormImageFieldModal
        modalId={`${data.name}-${data.id}`}
        name={`image_preview`}
        altText={`image_preview_alt`}
        update={update}
      />

      <ElementWrapper elementIndex={index}>
        <div className="case-website-screenshot-with-text-right">
          {/* begin::Figure */}
          <div className="case-website-screenshot-with-text-right__figure">
            <div className="screen screen--dark">
              {data.image_preview ? (
                <div className="overlay">
                  <div className="overlay-wrapper">
                    <img
                      alt="dummy"
                      src={data.image_preview}
                      style={{
                        width: '100%',
                        height: '400px',
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                  <div className="overlay-layer bg-dark bg-opacity-10 flex-column">
                    <div>
                      <button
                        name="image_preview"
                        className="btn btn-primary btn-shadow"
                        onClick={() =>
                          update({
                            image_preview: '',
                            image_preview_alt: '',
                          })
                        }
                      >
                        Remove
                      </button>
                      <label
                        className="btn btn-light-primary btn-shadow ms-2"
                        data-bs-toggle="modal"
                        data-bs-target={`#${data.name}-${data.id}`}
                      >
                        Change
                      </label>
                    </div>

                    <input
                      placeholder="Alt attribute"
                      className="w-50 mt-2 form-control form-control-lg form-control-solid"
                      type="text"
                      value={data.image_preview_alt ?? ''}
                      onChange={(event) =>
                        update({ image_preview_alt: event.target.value })
                      }
                    />
                  </div>
                </div>
              ) : (
                <>
                  <label
                    data-bs-toggle="modal"
                    data-bs-target={`#${data.name}-${data.id}`}
                  >
                    <img
                      src={toAbsoluteUrl('/assets/images/900x630.png')}
                      alt=""
                      style={{
                        width: '100%',
                        height: '400px',
                        objectFit: 'cover',
                      }}
                    />
                  </label>
                </>
              )}
            </div>
          </div>
          {/* end::Figure */}

          {/* begin::Content */}
          <div className="case-website-screenshot-with-text-right__content">
            <TextAreaEdit
              name="title"
              className="content-title"
              value={data.title}
              setValue={update}
            />

            <TextAreaEdit
              name="description"
              className="content-description"
              value={data.description}
              setValue={update}
            />

            <div className="content-action">
              <ButtonTooltip
                name={`button_url`}
                value={data.button_url}
                setValue={update}
              >
                <a>
                  <InlineEdit
                    className="action-text"
                    name="button_title"
                    value={data.button_title}
                    setValue={update}
                    activeVerticalPadding={'py-0'}
                    dynamicWidth
                    style={{
                      maxWidth: '22rem',
                    }}
                  />
                </a>
              </ButtonTooltip>
            </div>
          </div>
          {/* end::Content */}
        </div>
      </ElementWrapper>
    </>
  );
};
