import { Redirect, Route, Switch } from 'react-router-dom';
import { PageTitle } from '../../../_start/layout/core';
import { CreateTag } from '../post-management/pages/Tag/CreateTag';
import { TagsPage } from '../post-management/pages/Tag/TagsPage';
import { UpdateTag } from '../post-management/pages/Tag/UpdateTag';
import { CreateFeature } from './pages/feature/CreateFeature';
import { FeaturesPage } from './pages/feature/FeaturesPage';
import { UpdateFeature } from './pages/feature/UpdateFeature';
import { CreateModule } from './pages/module/CreateModule';
import { ModulesPage } from './pages/module/ModulesPage';
import { UpdateModule } from './pages/module/UpdateModule';
import { CreatePackage } from './pages/package/CreatePackage';
import { PackagesPage } from './pages/package/PackagesPage';
import { UpdatePackage } from './pages/package/UpdatePackage';
import { CreatePlugin } from './pages/plugin/CreatePlugin';
import { PluginEditContent } from './pages/plugin/PluginEditContent';
import { PluginsPage } from './pages/plugin/PluginsPage';
import { UpdatePlugin } from './pages/plugin/UpdatePlugin';
import { CreateRelease } from './pages/release/CreateRelease';
import { ReleasesPage } from './pages/release/ReleasesPage';
import { UpdateRelease } from './pages/release/UpdateRelease';

export const ModuleManagementPage = () => {
  return (
    <Switch>
      {/* begin::Plugin ROUTES */}
      <Route exact={true} path="/module-management/plugins">
        <>
          <PageTitle>Plugins</PageTitle>
          <PluginsPage />
        </>
      </Route>
      <Route exact={true} path="/module-management/plugins/create">
        <>
          <PageTitle>Plugins</PageTitle>
          <CreatePlugin />
        </>
      </Route>
      <Route exact={true} path="/module-management/plugins/:id/update">
        <>
          <PageTitle>Plugins</PageTitle>
          <UpdatePlugin />
        </>
      </Route>
      <Route exact={true} path="/module-management/plugins/:id/edit-content">
        <>
          <PageTitle>Plugins</PageTitle>
          <PluginEditContent />
        </>
      </Route>
      {/* end::Plugin ROUTES */}

      {/* begin::Module ROUTES */}
      <Route exact={true} path="/module-management/modules">
        <>
          <PageTitle>Modules</PageTitle>
          <ModulesPage />
        </>
      </Route>
      <Route exact={true} path="/module-management/modules/create">
        <>
          <PageTitle>Modules</PageTitle>
          <CreateModule />
        </>
      </Route>
      <Route exact={true} path="/module-management/modules/:id/update">
        <>
          <PageTitle>Modules</PageTitle>
          <UpdateModule />
        </>
      </Route>
      {/* end::Module ROUTES */}

      {/* begin::Release ROUTES */}
      <Route exact={true} path="/module-management/releases">
        <>
          <PageTitle>Releases</PageTitle>
          <ReleasesPage />
        </>
      </Route>
      <Route exact={true} path="/module-management/releases/create">
        <>
          <PageTitle>Releases</PageTitle>
          <CreateRelease />
        </>
      </Route>
      <Route exact={true} path="/module-management/releases/:id/update">
        <>
          <PageTitle>Releases</PageTitle>
          <UpdateRelease />
        </>
      </Route>
      {/* end::Release ROUTES */}

      {/* begin::Package ROUTES */}
      <Route exact={true} path="/module-management/packages">
        <>
          <PageTitle>Packages</PageTitle>
          <PackagesPage />
        </>
      </Route>
      <Route exact={true} path="/module-management/packages/create">
        <>
          <PageTitle>Packages</PageTitle>
          <CreatePackage />
        </>
      </Route>
      <Route exact={true} path="/module-management/packages/:id/update">
        <>
          <PageTitle>Packages</PageTitle>
          <UpdatePackage />
        </>
      </Route>
      {/* end::Package ROUTES */}

      {/* begin::Feature ROUTES */}
      <Route exact={true} path="/module-management/features">
        <>
          <PageTitle>Features</PageTitle>
          <FeaturesPage />
        </>
      </Route>
      <Route exact={true} path="/module-management/features/create">
        <>
          <PageTitle>Features</PageTitle>
          <CreateFeature />
        </>
      </Route>
      <Route exact={true} path="/module-management/features/:id/update">
        <>
          <PageTitle>Features</PageTitle>
          <UpdateFeature />
        </>
      </Route>
      {/* end::Feature ROUTES */}

      {/* Tags */}
      <Route exact={true} path="/module-management/tags">
        <>
          <PageTitle>Tags</PageTitle>
          <TagsPage />
        </>
      </Route>
      <Route exact={true} path="/module-management/tags/create">
        <>
          <PageTitle>Tags</PageTitle>
          <CreateTag cancelUrl={`/module-management/tags`} />
        </>
      </Route>
      <Route exact={true} path="/module-management/tags/:id/update">
        <>
          <PageTitle>Tags</PageTitle>
          <UpdateTag cancelUrl={`/module-management/tags`} />
        </>
      </Route>
      {/* Tags */}

      <Redirect
        from="/module-management"
        exact={true}
        to="/module-management/plugins"
      />
      <Redirect to="/module-management/plugins" />
    </Switch>
  );
};
