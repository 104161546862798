import {
  defaultCustomer,
  ICustomer,
} from '../../customer-management/models/Customer';
import { IElement } from '../../edit-content/models/Element';
import {
  defaultEmployee,
  IEmployee,
} from '../../employee-management/pages/employees/Employee';
import {
  defaultCategory,
  ICategory,
} from '../../post-management/models/Category';

import {
  PostStatusType,
  RobotType,
  SchemaArticleType,
  SchemaPageType,
  SEOTitleType,
} from '../../post-management/models/Post';
import { defaultTag, ITag } from '../../post-management/models/Tag';

export interface ICaseDetail {
  id: number;
  title: string;
  slug: string;
  publish_date: string | Date;
  status: PostStatusType;
  description: string;
  image_preview: string;
  image_preview_alt_text: string;
  category_id: number;
  category: ICategory;
  tags: Array<ITag>;
  content: Array<IElement>;
  author_id: number | null;
  author: IEmployee;

  featured_case_details: Array<ICaseDetail>;

  customer_id: number | null;
  customer: ICustomer;

  // SEO stuff
  seo_title: Array<SEOTitleType>;
  robots: Array<RobotType>;

  schema_page_type: SchemaPageType;
  schema_article_type: SchemaArticleType;

  facebook_image: string;
  facebook_title: string;
  facebook_description: string;

  twitter_image: string;
  twitter_title: string;
  twitter_description: string;

  preview_type?: string;
  mobile_preview?: string;
}

export const defaultCaseDetail: ICaseDetail = {
  id: 0,
  title: '',
  slug: '',
  publish_date: new Date(),
  status: PostStatusType.DRAFT,
  description: '',
  image_preview: '',
  image_preview_alt_text: '',
  category_id: 0,
  category: defaultCategory,
  tags: [],
  featured_case_details: [],
  content: [],
  author_id: 0,
  author: { ...defaultEmployee, name: 'John Doe' },

  customer_id: null,
  customer: defaultCustomer,

  seo_title: [],
  robots: [],

  schema_page_type: SchemaPageType.WEB_PAGE,
  schema_article_type: SchemaArticleType.ARTICLE,

  facebook_image: '',
  facebook_title: '',
  facebook_description: '',

  twitter_image: '',
  twitter_title: '',
  twitter_description: '',
};

export const defaultCaseDetailPlaceholder: ICaseDetail = {
  ...defaultCaseDetail,
  title: 'Placeholder',
  tags: [
    {
      ...defaultTag,
      name: 'Tag',
    },
  ],
};
