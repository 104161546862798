import { defaultEmployee, IEmployee } from "../../../../employee-management/pages/employees/Employee";
import { ElementType, IElement } from "../../../models/Element";

export type HomeEmployees = IElement & {
  title: string;
  heading: string;
  description: string;
  button_url: string;
  button_title: string;
  employees: Array<IEmployee>;
};

export const defaultHomeEmployees: HomeEmployees = {
  id: 0,
  name: ElementType.HOME_EMPLOYEES,
  title: 'Jouw helden',
  heading: 'Wij zijn Tussendoor',
  description:
    'Van ontspanning met Netflix tot All-in met pokeren. Aangenaam.',
  button_url: '',
  button_title: 'Leer ons kennen',
  employees: [defaultEmployee, defaultEmployee],
};
