/* eslint-disable react-hooks/exhaustive-deps */
// import './pages/Pages/edit-content/styles/_displays.scss';
// import './pages/Pages/edit-content/styles/_edit-content.scss';

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  getConfig,
  IThemeConfig,
  PageDataContainer,
  PageLink,
  useTheme,
} from '../../../_start/layout/core';
import { PageManagementPage } from './PageManagementPage';
import { pageManagementSubmenu } from './PageManagementPageData';

const pageManagementBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/',
    isActive: false,
  },
  {
    title: 'Paginabeheer',
    path: '/page-management',
    isActive: false,
  },
];

const defaultPageConfig = getConfig();
const pageManagementPageConfig: Partial<IThemeConfig> = {
  sidebar: {
    ...defaultPageConfig.sidebar,
    display: false,
    content: 'user',
    bgColor: 'bg-info',
  },
};

export const editContentPageConfig: Partial<IThemeConfig> = {
  toolbar: {
    ...defaultPageConfig.toolbar,
    display: true,
  },
  sidebar: {
    ...defaultPageConfig.sidebar,
    display: true,
    content: 'edit-content',
    bgColor: 'bg-white',
  },
};

export const PageManagementWrapper = () => {
  const { setTheme } = useTheme();

  const location = useLocation();

  // Refresh UI after config updates
  useEffect(() => {
    setTheme(pageManagementPageConfig);

    if (location.pathname.includes('edit-content')) {
      setTheme(editContentPageConfig);
    }

    return () => {
      setTheme(defaultPageConfig);
    };
  }, []);

  return (
    <>
      <PageDataContainer
        breadcrumbs={pageManagementBreadCrumbs}
        submenu={pageManagementSubmenu}
      />
      <PageManagementPage />
    </>
  );
};
