import axios from 'axios';
import { API_URL } from '../../../../support/api';
import { PaginatedValue } from '../../../../support/utils';
import { IPage } from '../../pages/Pages/Page';

export const BASE_PAGE_URL = `${API_URL}/v1/management/page-management/page`;
export const CREATE_PAGE_URL = `${API_URL}/v1/management/page-management/page/create`;

export const getAllPages = async (page_type = '') => {
  const {
    data: { value },
  } = await axios.get<{ value: Array<IPage> }>(
    `${BASE_PAGE_URL}/get-all?page_type=${page_type}`
  );

  return value;
};

export const listPages = async (search = '', direction = 'asc') => {
  const URL = `${BASE_PAGE_URL}/list?search=${search}&direction=${direction}`;
  const {
    data: { value },
  } = await axios.get<{ value: PaginatedValue<IPage> }>(URL);

  return value;
};

export const createPage = async (payload: IPage) => {
  const {
    data: { value },
  } = await axios.post<{ value: IPage }>(CREATE_PAGE_URL, {
    ...payload,
    author_id: payload.author_id ? payload.author_id : null,
    customer_id: payload.customer_id ? payload.customer_id : null,
  });

  return value;
};

export const deletePage = async (payload: IPage) => {
  const {
    data: { value },
  } = await axios.post<{ value: IPage }>(
    `${BASE_PAGE_URL}/${payload.id}/delete`,
    payload
  );

  return value;
};

export const viewPage = async (id: number | string) => {
  const {
    data: { value },
  } = await axios.get<{ value: IPage }>(`${BASE_PAGE_URL}/${id}/get`);

  return value;
};

export const updatePage = async (payload: IPage) => {
  const {
    data: { value },
  } = await axios.post<{ value: IPage }>(
    `${BASE_PAGE_URL}/${payload.id}/update`,
    payload
  );

  return value;
};
