import { ElementType, IElement } from '../../../models/Element';

export type PluginLeftImageHighlightInfo = IElement & {
  image: string;
  image_alt: string;
  heading: string;
  description: string;
};

export const defaultPluginLeftImageHighlightInfo: PluginLeftImageHighlightInfo =
  {
    id: 0,
    name: ElementType.PLUGIN_LEFT_IMAGE_HIGHLIGHT_INFO,
    image: '',
    image_alt: '',
    heading: 'Als makelaar kun je niet zonder deze plugin',
    description:
      'De "WordPress Makelaars plugin" is onmisbaar voor de makelaar. Niet alleen toont de plugin het actuele aanbod, ook scoort de website beter in zoekmachines zoals Google. Omdat alle objecten op de website staan, worden deze door zoekmachines beter geindexeerd.',
  };
