import axios from 'axios';
import { API_URL } from '../../../support/api';
import { PaginatedValue } from '../../../support/utils';
import { ICaseDetail } from '../models/CaseDetail';

export const BASE_CASE_DETAIL_URL = `${API_URL}/v1/management/page-management/case-details`;
export const GET_ALL_CASE_DETAIL_URL = `${API_URL}/v1/management/page-management/case-details/get-all`;
export const CREATE_CASE_DETAIL_URL = `${API_URL}/v1/management/page-management/case-details/create`;

export const listCaseDetail = async (search = '', direction = 'asc') => {
  const URL = `${BASE_CASE_DETAIL_URL}/list?search=${search}&direction=${direction}`;
  const {
    data: { value },
  } = await axios.get<{ value: PaginatedValue<ICaseDetail> }>(URL);

  return value;
};

export const createCaseDetail = async (payload: ICaseDetail) => {
  const {
    data: { value },
  } = await axios.post<{ value: ICaseDetail }>(CREATE_CASE_DETAIL_URL, payload);

  return value;
};

export const viewCaseDetail = async (id: number | string) => {
  const {
    data: { value },
  } = await axios.get<{ value: ICaseDetail }>(
    `${BASE_CASE_DETAIL_URL}/${id}/get`
  );

  return value;
};

export const updateCaseDetail = async (payload: ICaseDetail) => {
  const {
    data: { value },
  } = await axios.post<{ value: ICaseDetail }>(
    `${BASE_CASE_DETAIL_URL}/${payload.id}/update`,
    payload
  );

  return value;
};

export const deleteCaseDetail = async (payload: ICaseDetail) => {
  const {
    data: { value },
  } = await axios.post<{ value: ICaseDetail }>(
    `${BASE_CASE_DETAIL_URL}/${payload.id}/delete`,
    payload
  );

  return value;
};

// unpaginated categories
export const getAllCaseDetail = async () => {
  const {
    data: { value },
  } = await axios.get<{ value: Array<ICaseDetail> }>(GET_ALL_CASE_DETAIL_URL);

  return value;
};

export const publishCaseDetail = async (payload: ICaseDetail) => {
  const {
    data: { value },
  } = await axios.post<{ value: ICaseDetail }>(
    `${BASE_CASE_DETAIL_URL}/${payload.id}/publish`,
    payload
  );

  return value;
};
