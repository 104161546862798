/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { v4 as uidv4 } from 'uuid';
import { IFAQ } from '../../../../page-management/models/FAQ';

interface FAQListItemProps {
  index: number;
  data: IFAQ;
  faqs: IFAQ[];
  remove: (index: number) => void;
  update: (fieldsToUpdate: Partial<IFAQ>, index: number) => void;
}

export const FAQSelectNarrowListItem: React.FC<FAQListItemProps> = ({
  index,
  data,
  faqs,
  update,
  remove,
}) => {
  const [active, setActive] = useState(false);

  const uid = uidv4();

  return (
    <div
      className="accordion__item"
      id={`faq_accordion_${uid}`}
      itemScope
      itemProp="mainEntity"
      itemType="https://schema.org/Question"
      data-element="accordion"
    >
      <a
        className={`accordion__toggle ${active && 'accordion__active'}`}
        onClick={() => setActive(!active)}
      >
        <div className="accordion__text">{data.title ?? 'Select A FAQ'}</div>

        <span className="accordion__holder u-flex u-align-center u-justify-center">
          <svg
            className="accordion__icon icon-14 rotate-180"
            data-src="assets/icons/icon-angle.svg"
            aria-hidden="true"
            focusable="false"
            data-cache="disabled"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            xmlSpace="preserve"
            data-id="svg-loader_36"
          >
            <path d="M16 11.5c0 .3-.1.5-.3.7-.4.4-1 .4-1.4 0L8 5.9l-6.3 6.3c-.4.4-1 .4-1.4 0s-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0l7 7c.2.2.3.4.3.7z"></path>
          </svg>
        </span>
      </a>
      <div
        className="accordion__inner"
        style={{ display: !active ? 'none' : 'block' }}
      >
        {/* <p>
          <InlineEdit
            value={faq?.title_description ?? ""}
            setValue={updateData}
            name="heading"
            className="accordion__heading"
          />
        </p> */}
        <div className="accordion__heading mb-8">
          <p>{data.title_description}</p>
        </div>

        {/* <TextAreaEdit
          value={faq?.description ?? ""}
          setValue={updateData}
          name="description"
          className="accordion__description"
        /> */}
        <div className="accordion__description mb-8">{data.description}</div>

        {/* begin::Action Button with Editable Link Tooltip */}
        {/* <ButtonTooltip
          name={`button_url`}
          className="content-action"
          value={faq?.url ?? ""}
          setValue={updateData}
        >
          <button className="btn btn-success rounded-pill">
            <InlineEdit
              name="button_title"
              className="px-1 text-white text-center"
              value={faq?.slug ?? ""}
              setValue={updateData}
              activeVerticalPadding={"py-1"}
              dynamicWidth
              style={{
                maxWidth: "200px",
              }}
            />
          </button>
        </ButtonTooltip> */}
        {/* end::Action Button with Editable Link Tooltip */}
        {data.url && (
          <div className="btn btn-success rounded-pill">
            {/* <InlineEdit
              name="button_title"
              className="px-1 text-white text-center"
              value={data.button_title}
              setValue={updateData}
              activeVerticalPadding={"py-1"}
              dynamicWidth
              style={{
                maxWidth: "200px",
              }}
            /> */}
            <div className="px-1 text-white text-center">
              {data.button_title}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
