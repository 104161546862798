import { ElementType, IElement } from '../../../models/Element';

export type ShowcaseBigBanner = IElement & {
  title: string;
  description: string;
  image_preview: string;
  image_preview_alt: string;
  image_caption: string;
};

export const defaultShowcaseBigBanner: ShowcaseBigBanner = {
  id: 0,
  name: ElementType.SHOWCASE_BIG_BANNER,
  title: 'Functioneel ontwerp',
  description:
    'In de eerste fase hebben we een functioneel ontwerp van de site gemaakt waarbij de focus ligt op de functies van de website en de gebruiksvriendelijkheid ervan. Door korte communicatielijnen te hanteren met het team van het Noordelijk Film Festival, was het voor alle partijen direct vanaf de start al duidelijk hoe de website er op functioneel vlak uit zou komen te zien.',
  image_preview: '',
  image_preview_alt: '',
  image_caption: 'Foto door: Olaf the Great',
};
