import axios from 'axios';
import { API_URL } from '../../../support/api';
import { PaginatedValue } from '../../../support/utils';
import { IPostType } from '../pages/post-types/PostType';

export const BASE_POST_TYPE_URL = `${API_URL}/v1/management/page-management/post-type`;
export const CREATE_POST_TYPE_URL = `${API_URL}/v1/management/page-management/post-type/create`;

export const listPostTypes = async (search = '', direction = 'asc') => {
  const URL = `${BASE_POST_TYPE_URL}/list?search=${search}&direction=${direction}`;
  const {
    data: { value },
  } = await axios.get<{ value: PaginatedValue<IPostType> }>(URL);

  return value;
};

export const createPostType = async (payload: IPostType<number>) => {
  const {
    data: { value },
  } = await axios.post<{ value: IPostType }>(CREATE_POST_TYPE_URL, payload);

  return value;
};

export const viewPostType = async (id: number | string) => {
  const {
    data: { value },
  } = await axios.get<{ value: IPostType }>(`${BASE_POST_TYPE_URL}/${id}/get`);

  return value;
};

export const updatePostType = async (payload: IPostType<number>) => {
  const {
    data: { value },
  } = await axios.post<{ value: IPostType }>(
    `${BASE_POST_TYPE_URL}/${payload.id}/update`,
    payload
  );

  return value;
};

export const deletePostType = async (payload: IPostType) => {
  const {
    data: { value },
  } = await axios.post<{ value: IPostType }>(
    `${BASE_POST_TYPE_URL}/${payload.id}/delete`,
    payload
  );

  return value;
};

export const getAllPostTypes = async () => {
  const {
    data: { value },
  } = await axios.get<{ value: Array<IPostType> }>(
    `${BASE_POST_TYPE_URL}/get-all`
  );

  return value;
};

export const requestPostTypes = () => {
  return axios.get<{ value: Array<IPostType> }>(
    `${BASE_POST_TYPE_URL}/get-all`
  );
};
