import { defaultPlugin, HomePlugins, Plugin } from './HomePlugins';

import Carousel from 'react-elastic-carousel';
import { HomePluginsItem } from './HomePluginsItem';

import immutabilityHelper from 'immutability-helper';
import { KTSVG } from '../../../../../../_start/helpers';

type HomePluginsListProps = {
  data: HomePlugins;
  update: (fieldsToUpdate: Partial<HomePlugins>) => void;
};

export const HomePluginsList = ({ data, update }: HomePluginsListProps) => {
  const updatePlugin = (idx: number, plugin: Plugin) => {
    let plugins = [...data.plugins];
    plugins[idx] = plugin;

    const updatedData = { ...data, plugins };
    update(updatedData);
  };

  const addPlugin = () => {
    const plugins = immutabilityHelper(data.plugins, {
      $push: [defaultPlugin],
    });

    const updatedData = { ...data, plugins };
    update(updatedData);
  };

  const removePlugin = (itemIndex: number) => {
    let plugins = immutabilityHelper(data.plugins, {
      $splice: [[itemIndex, 1]],
    });

    const updatedData = { ...data, plugins };
    update(updatedData);
  };

  return (
    <div className="home-plugins__carousel">
      <Carousel
        itemsToShow={4}
        isRTL={false}
        pagination={true}
        enableSwipe={false}
        enableMouseSwipe={false}
      >
        {data.plugins.map((plugin, idx) => (
          <HomePluginsItem
            key={idx}
            homePlugin={data}
            plugin={plugin}
            pluginIndex={idx}
            updatePlugin={updatePlugin}
            removePlugin={removePlugin}
          />
        ))}

        <div onClick={addPlugin} className="add-item">
          <div className="add-item__inner">
            <div className="m-auto d-flex flex-column">
              <KTSVG
                path="/media/icons/duotone/Interface/Plus-Square.svg"
                className="svg-icon-muted svg-icon-3hx text-center"
              />
              <span className="mt-4 text-gray-500">Add new item</span>
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  );
};
