import { ElementType, IElement } from '../../../models/Element';

export interface IProcess {
  id: number;
  label: string;
  heading: string;
  title: string;
  description: string;
  button_title: string;
  button_url: string;
}

export const defaultProcess: IProcess = {
  id: 0,
  label: 'Strategy',
  heading: 'Step 0',
  title: 'Briefing',
  description:
    'Hoe zorgen we ervoor dat de bezoeker op elk schermformaat moeiteloos een product kan bestellen? De tekst kan hier wel wat langer.',
  button_title: 'Lees meer',
  button_url: '',
};

export type ProcessHighlight = IElement & {
  title: string;
  description: string;
  processes: Array<IProcess>;
};

export const defaultProcessHighlight: ProcessHighlight = {
  id: 0,
  name: ElementType.PROCESS_HIGHLIGHT,
  title: 'Het proces',
  description:
    'Donec ullamcorper nulla non metus auctor fringilla. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Nulla vitae elit libero, a pharetra augue. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Lorem ipsum dolor sit.',
  processes: [defaultProcess],
};

export enum ProcessHighlightItemTypes {
  PROCESS = 'process',
}
