export enum ElementType {
  // UTILITY
  TITLE = 'title-element',
  UTILITY_MARGIN_TOP = 'margin-top-element',
  UTILITY_MARGIN_BOTTOM = 'margin-bottom-element',

  // BLOG
  BLOG_HEADER = 'blog-header',
  BLOG_AUTHOR_HEADER = 'blog-author-header',
  BLOG_CONTENT_HEADING = 'blog-content-heading',
  BLOG_CONTENT_DESCRIPTION = 'blog-content-description',
  BLOG_CONTENT_IMAGE = 'blog-content-image',
  BLOG_CONTENT_DESCRIPTION_WITH_INFO = 'blog-content-description-with-info',
  BLOG_CONTENT_WIDE_DESCRIPTION_TWO_COLUMN = 'blog-content-wide-description-two-column',
  BLOG_CONTENT_WIDE_DESCRIPTION = 'blog-content-wide-description',
  BLOG_CONTENT_INFO_PANEL = 'blog-content-info-panel',
  BLOG_CONTENT_AUTHOR_INFO = 'blog-content-author-info',
  BLOG_AUTHOR_FOOTER = 'blog-author-footer',
  BLOG_FEATURED = 'blog-featured',

  // CASE DETAIL
  CASE_HEADER = 'case-header',
  WEBSITE_SCREENSHOT = 'website-screenshot',
  CUSTOMER_QUESTION = 'customer-question',
  PROJECT_HIGHLIGHTS = 'project-highlights',
  SHOWCASE_BIG_BANNER = 'showcase-big-banner',
  WEBSITE_SCREENSHOT_WITH_TEXT_RIGHT = 'website-screenshot-with-text-right',
  WEBSITE_SCREENSHOT_WITH_TEXT_LEFT = 'website-screenshot-with-text-left',
  SHOWCASE_FEATURED = 'showcase-featured',
  SHOWCASE_WITH_TEXT_RIGHT = 'showcase-with-text-right',
  SHOWCASE_WITH_TEXT_LEFT = 'showcase-with-text-left',
  PROCESS_HIGHLIGHT = 'process-highlight',
  PERSON_HIGHLIGHT_EXPERIENCE = 'person-highlight-experience',
  FEATURED_USED_PLUGINS = 'featured-used-plugins',
  FEATURED_OTHER_CASES = 'featured-other-cases',

  // ABOUT US
  ABOUT_US_HEADER = 'about-us-header',
  ABOUT_US_INTRODUCTION = 'about-us-introduction',
  ABOUT_US_UNIQUE_SELLING_POINT = 'about-us-unique-selling-point',
  ABOUT_US_IMAGE_BANNER = 'about-us-image-banner',
  ABOUT_US_FEATURED_CONTENT_ONE = 'about-us-featured-content-one',
  ABOUT_US_EMPLOYEES = 'about-us-employees-grid',
  ABOUT_US_CONTENT_HEADING = 'about-us-content-heading',
  ABOUT_US_CONTENT_DESCRIPTION = 'about-us-content-description',
  ABOUT_US_SHOWCASE_WITH_TEXT_RIGHT = 'about-us-showcase-with-text-right',
  ABOUT_US_SHOWCASE_WITH_TEXT_LEFT = 'about-us-showcase-with-text-left',
  ABOUT_US_CENTERED_PARAGRAPH = 'about-us-centered-paragraph',
  ABOUT_US_FEATURED_CONTENT_TWO = 'about-us-featured-content-two',

  // HOME
  HOME_IMAGE_RIGHT_DESCRIPTION = 'home-image-with-right-heading-and-description',
  HOME_IMAGE_LEFT_DESCRIPTION = 'home-image-with-left-heading-and-description',
  HOME_IMAGE_RIGHT_DESCRIPTION_BLUE = 'home-image-with-right-description-and-blue-theme',
  HOME_IMAGE_LEFT_DESCRIPTION_BLUE = 'home-image-with-left-description-and-blue-theme',
  HOME_IMAGE_RIGHT_DESCRIPTION_WITH_FLOATING = 'home-image-with-right-description-and-floating-window',
  HOME_IMAGE_LEFT_DESCRIPTION_WITH_FLOATING = 'home-image-with-left-description-and-floating-window',
  HOME_IMAGE_RIGHT_DESCRIPTION_FLOATING_WITH_ICONS = 'home-image-with-right-description-floating-window-and-icons',
  HOME_IMAGE_LEFT_DESCRIPTION_FLOATING_WITH_ICONS = 'home-image-with-left-description-floating-window-and-icons',
  HOME_IMAGE_TWO_RIGHT_DESCRIPTION_WITH_FLOATING = 'home-image-with-two-right-description-and-floating-comp',
  HOME_IMAGE_TWO_LEFT_DESCRIPTION_WITH_FLOATING = 'home-image-with-two-left-description-and-floating-comp',
  HOME_THREE_ICONS_HEADINGS_AND_DESCRIPTIONS = 'home-three-icons-headings-and-descriptions',
  HOME_THREE_ICONS_HEADINGS_AND_DESCRIPTIONS_NARROW = 'home-three-icons-headings-and-descriptions-narrow',
  HOME_SIX_ICONS_WITH_TITLES = 'home-six-icons-with-titles',
  HOME_FAQ = 'home-text-with-frequently-asked-questions',
  HOME_HERO_WIDE_THREE_ICONS = 'home-hero-wide-three-icons',
  HOME_HERO_WIDE_BUTTONS = 'home-hero-wide-buttons',
  HOME_CUSTOMER_REVIEWS = 'home-customer-reviews',
  HOME_PACKAGES = 'home-packages',
  HOME_HEADER = 'home-header',
  HOME_FEATURED_PROJECTS = 'home-featured-projects',
  HOME_EMPLOYEES = 'home-employees',
  HOME_PLUGINS = 'home-plugins',
  HOME_SERVICES = 'home-services',

  // Contacts
  // CONTACTS_IMAGE_RIGHT_DESCRIPTION = 'contacts-image-with-right-heading-and-description',
  // CONTACTS_IMAGE_LEFT_DESCRIPTION = 'contacts-image-with-left-heading-and-description',
  // CONTACTS_IMAGE_LEFT_DESCRIPTION_FLOATING_WITH_ICONS = 'contacts-image-with-left-description-floating-window-and-icons',
  // CONTACTS_OTHER_INFO_WITH_ICONS = 'contacts-other-info-with-icons',
  CONTACTS_HEADER = 'contacts-header',

  // FAQ
  FAQ = 'faq-text-with-frequently-asked-questions',
  FAQ2 = 'faq-built-in',
  FAQSELECT = 'faq-select',
  FAQSELECT_NARROW = 'faq-select-narrow',

  // Specializations
  SPECIALIZATION_HEADER = 'specialization-header',
  SPECIALIZATION_FULL_TEXT = 'full-text',
  SPECIALIZATION_FLOATING_RIGHT_MODAL = 'image-with-floating-right-modal',
  SPECIALIZATION_FLOATING_LEFT_MODAL = 'image-with-floating-left-modal',
  SPECIALIZATION_CASES = 'text-with-a-single-button-with-three-cases',
  SPECIALIZATION_BLOGPOSTS = 'text-with-a-single-button-with-three-blogposts',
  SPECIALIZATION_FAQ = 'text-with-frequently-asked-questions',
  SPECIALIZATION_MODAL_STEPS = 'dark-background-with-text-and-multiple-modal-steps',
  SPECIALIZATION_FEATURED_POSTS = 'specialization-featured-posts',
  SPECIALIZATION_FEATURED_CASES = 'specialization-featured-cases',

  // Reviews
  REVIEWS_ELEMENT = 'reviews-element',

  // Plugins
  PLUGIN_HEADER = 'plugin-header',
  PLUGIN_LEFT_IMAGE_INFO = 'plugin-left-image-info',
  PLUGIN_RIGHT_IMAGE_INFO = 'plugin-right-image-info',
  PLUGIN_RIGHT_IMAGE_HIGHLIGHT_INFO = 'plugin-right-image-highlight-info',
  PLUGIN_LEFT_IMAGE_HIGHLIGHT_INFO = 'plugin-left-image-highlight-info',
  PLUGIN_HIGHLIGHT_RIGHT_IMAGE_INFO = 'plugin-highlight-right-image-info',
  PLUGIN_HIGHLIGHT_LEFT_IMAGE_INFO = 'plugin-highlight-left-image-info',
  PLUGIN_LEFT_IMAGE_INFO_NO_BUTTONS = 'plugin-left-image-info-no-buttons',
  PLUGIN_RIGHT_IMAGE_INFO_NO_BUTTONS = 'plugin-right-image-info-no-buttons',
  PLUGIN_LEFT_SCREENSHOT_INFO_NO_BUTTONS = 'plugin-left-screenshot-info-no-buttons',
  PLUGIN_RIGHT_SCREENSHOT_INFO_NO_BUTTONS = 'plugin-right-screenshot-info-no-buttons',
  PLUGIN_NO_HIGHLIGHT_RIGHT_IMAGE_INFO = 'plugin-no-highlight-right-image-info',
  PLUGIN_NO_HIGHLIGHT_LEFT_IMAGE_INFO = 'plugin-no-highlight-left-image-info',
  PLUGIN_LEFT_SCREENSHOTS_INFO = 'plugin-left-screenshots-info',
  PLUGIN_RIGHT_SCREENSHOTS_INFO = 'plugin-right-screenshots-info',
  PLUGIN_ARRAY_HEADER_AND_DESCRIPTION = 'plugin-array-header-and-description',
  PLUGIN_PARTNERS = 'plugin-partners',
  PLUGIN_FEATURED_TUTORIALS = 'plugin-featured-tutorials',
  PLUGIN_FEATURED_PLUGINS = 'plugin-featured-plugins',
}

export enum EditContentItemType {
  ELEMENT_MOVE = 'element-move',
  ELEMENT_INSERT = 'element-insert',
}

export interface IElement {
  id: number;
  name: ElementType;
}
