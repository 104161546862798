/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { format } from "date-fns";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../setup";
import { KTSVG, toAbsoluteUrl } from "../../../../../../_start/helpers";
import { defaultAuthorPlaceholder, IEmployee } from "../../../../employee-management/pages/employees/Employee";

import { ElementWrapper } from "../../../components/ElementWrapper";
import { InlineEdit } from "../../../components/InlineEdit";
import { IElement } from "../../../models/Element";
import { setAuthor } from "../../../redux/EditContentActions";
import { SelectAuthorWrapper } from "../components/SelectAuthorWrapper";
import { BlogAuthorHeader } from "./BlogAuthorHeader";

type BlogAuthorHeaderFCProps = {
	index: number;
	data: BlogAuthorHeader;
	updateData: (body: IElement, index: number) => void;
};

export const BlogAuthorHeaderFC = ({
	index,
	data,
	updateData,
}: BlogAuthorHeaderFCProps) => {
	const dispatch = useDispatch();
	//

	const update = (fieldsToUpdate: Partial<BlogAuthorHeader>) => {
		const updatedData = { ...data, ...fieldsToUpdate };
		updateData(updatedData, index);
	};

	const post = useSelector((state: RootState) => state.editContent.post);

	const author = useSelector(
		(state: RootState) => state.editContent.post.author
	);

	useEffect(() => {
		if (author !== null) {
			update({ author });
		} else {
			update({ author: defaultAuthorPlaceholder });
		}
	}, [author]);

	const updateAuthor = (selected: IEmployee) => {
		update({ author: selected });
		dispatch(setAuthor(selected));
	};

	return (
		<ElementWrapper elementIndex={index}>
			<div className={`blog-author-header my-10`}>
				<div className="author-content">
					<SelectAuthorWrapper
						author={data.author}
						updateAuthor={updateAuthor}
					>
						<div className="author-content__info">
							{/* begin::Figure */}
							<div className="info-figure">
								{data.author.profile_image ? (
									<label>
										<img
											src={data.author.profile_image}
											alt=""
										/>
									</label>
								) : (
									<img
										src={toAbsoluteUrl(
											"/assets/images/250x250.png"
										)}
										alt=""
										style={{
											width: "100%",
											objectFit: "cover",
										}}
									/>
								)}
							</div>
							{/* end::Figure */}

							{/* begin::Content */}
							<div className="info-content">
								<span className="info-content__name">
									{data.author.name}
								</span>
								<span className="info-content__department">
									{data.author.department.name ?? ""}
								</span>
								<span className="info-content__date">
									{format(
										new Date(
											post.publish_date ?? new Date()
										),
										"MMM d, yyyy HH:MM"
									)}
								</span>
							</div>
							{/* end::Content */}
						</div>
					</SelectAuthorWrapper>

					{/* begin::Action */}
					<div className="content-action__button">
						<a className="author-content__action">
							<InlineEdit
								className="action-text"
								name="button_title"
								value={data.button_title}
								setValue={update}
								activeVerticalPadding={"py-1"}
								dynamicWidth
								style={{
									maxWidth: "200px",
								}}
							/>
							<KTSVG
								path="/assets/icons/icon-share.svg"
								className="svg-icon-2"
								svgClassName="action-icon"
							/>
						</a>
					</div>
					{/* end::Action */}
				</div>
			</div>
		</ElementWrapper>
	);
};
