/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

type ButtonTooltipProps = {
  name: string;
  className?: string;
  value: string;
  setValue: (fieldsToUpdate: Partial<any>) => void;
};

export const ButtonTooltip: React.FC<ButtonTooltipProps> = ({
  name = "",
  className = "",
  value = "",
  setValue,
  children,
}) => {
  const [active, setActive] = useState(false);

  const [editingValue, setEditingValue] = useState<string>("");

  useEffect(() => {
    if (value !== editingValue) {
      setEditingValue(value);
    }
  }, [value]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setEditingValue(event.target.value);

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    if (event.key === "Enter" || event.key === "Escape") {
      target.blur();
    }
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      if (event.target.value.trim() === "") {
        setEditingValue(value);
        setActive(false);
      } else {
        setValue({ [event.target.name]: event.target.value });
        setActive(false);
      }
    }
  };

  const onBlurInput = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value.trim() === "" || event.target.value === value) {
      setEditingValue(value);
    } else {
      setValue({ [event.target.name]: event.target.value });
    }
  };

  const parentClassnames = `position-relative ${className}`;

  return (
    <div
      className={parentClassnames}
      onClick={() => setActive(true)}
      onBlur={onBlur}
    >
      {active && (
        <div className="position-absolute mt-n18">
          <div className="d-flex flex-column align-items-center">
            <input
              name={name}
              value={editingValue ?? ""}
              onBlur={onBlurInput}
              onChange={onChange}
              onKeyDown={onKeyDown}
              type="text"
              className="min-w-150px form-control form-control-solid"
              placeholder="Enter link here"
            />
            {/* begin::ArrowDown */}
            <div
              className=""
              style={{
                width: "0",
                height: "0",
                borderLeft: "12px solid transparent",
                borderRight: "12px solid transparent",
                borderTop: "12px solid #F5F8FA",
              }}
            ></div>
            {/* end::ArrowDown */}
          </div>
        </div>
      )}
      {/* Wrapping */}
      {children}
    </div>
  );
};
