import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../setup';
import { KTSVG } from '../../../../../../_start/helpers';
import { IPost } from '../../../../post-management/models/Post';

type FeaturedPostWrapperProps = {
  itemIndex: number;
  blog: IPost;
  updateSelected: (index: number, blog: IPost) => void;
  selectedMap: Map<number, IPost>;
};

export const SpecializationFeaturedPostWrapper: React.FC<
  FeaturedPostWrapperProps
> = ({ itemIndex, blog, updateSelected, selectedMap, children }) => {
  const post = useSelector((state: RootState) => state.editContent.post);

  const blogPages = useSelector(
    (state: RootState) => state.editContent.allPosts
  );

  const [pages, setPages] = useState<Array<IPost>>([]);

  useEffect(() => {
    if (blogPages) {
      setPages(blogPages);
    }
  }, [blogPages]);

  const [active, setActive] = useState(false);

  const sectionRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (active) {
      sectionRef.current?.focus();
    }
  }, [active]);

  const onBlur = (event: React.FocusEvent<HTMLLIElement>) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setActive(false);
    }
  };

  const handleSelect = (item: IPost) => {
    updateSelected(itemIndex, {
      ...blog,
      id: item.id,
      title: item.title,
      tags: item.tags,
      image_preview: item.image_preview,
    });
    setActive(false);
  };

  const handleLocalSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value.toLowerCase();

    const filtered_items = blogPages.filter((page) => {
      const pageTitle = page.title.toLowerCase();
      return pageTitle.indexOf(keyword) > -1;
    });

    setPages(filtered_items);
  };

  const handleActivate = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    let dataValue = (event.target as HTMLElement).getAttribute('data-value');

    if (dataValue !== 'child-li') {
      setActive(true);
    }
  };

  return (
    <div
      data-value="parent-li"
      // onClick={handleActivate}
      className="position-relative"
    >
      <button
        onClick={handleActivate}
        className={`position-absolute start-0 end-0 z-index-2 mx-auto w-50 btn btn-sm btn-light-primary btn-shadow ${
          active ? 'visually-hidden' : ''
        }`}
        style={{
          // marginTop: `${-17}rem`,
          top: '63%',
          transform: 'translate(0, -50%)',
        }}
      >
        Select Post
      </button>

      {/* begin::Select Case Detail with Search */}
      {active && (
        <section
          ref={sectionRef}
          onBlur={onBlur}
          tabIndex={1}
          className="position-absolute start-0 end-0 z-index-2 other-case-list"
          style={{
            // marginTop: `${-17}rem`,
            top: '45%',
            transform: 'translate(0, -50%)',
          }}
        >
          <div className="d-flex flex-column align-items-center">
            {/* begin::Case Page List*/}
            <div
              className="rounded"
              style={{
                background: 'white',
              }}
            >
              {/* begin::Search */}
              <div className="position-relative px-3 pt-3">
                <span
                  className="position-absolute"
                  style={{
                    top: '60%',
                    transform: 'translateY(-50%)',
                  }}
                >
                  <KTSVG
                    path="/media/icons/duotone/General/Search.svg"
                    className="ps-1 svg-icon-1 svg-icon-primary"
                  />
                </span>
                <input
                  onChange={handleLocalSearch}
                  className="ps-10 form-control form-control-solid"
                  type="text"
                  placeholder="Zoeken"
                />
              </div>
              {/* end::Search */}

              <div className="separator mt-2"></div>

              {/* begin::OtherCases */}
              <ul
                className="my-1 me-2 p-1 list-unstyled overflow-auto select-other-case"
                style={{
                  maxHeight: '128px',
                }}
              >
                {pages.map(
                  (page, idx) =>
                    post.id !== page.id &&
                    !selectedMap.has(page.id) && (
                      <li
                        key={idx}
                        data-value="child-li"
                        onClick={() => handleSelect(page)}
                        className={`mb-1 px-2 py-1 ${
                          page.id === blog.id ? 'bg-success' : ''
                        }`}
                      >
                        {page.title}
                      </li>
                    )
                )}
                {(pages.length === 0 ||
                  selectedMap.size >= pages.length - 1) && (
                  <div className="mb-1 px-2 py-1 text-center text-gray-500">
                    No data available
                  </div>
                )}
              </ul>
              {/* end::OtherCases */}

              <div className="separator mb-2"></div>
            </div>
            {/* end::Case Page List*/}
            {/* begin::ArrowDown Element*/}
            <div
              style={{
                width: '0',
                height: '0',
                borderLeft: '12px solid transparent',
                borderRight: '12px solid transparent',
                borderTop: '12px solid white',
              }}
            ></div>
            {/* end::ArrowDown Element*/}
          </div>
        </section>
      )}
      {/* end::Select Case Detail with Search */}

      {children}
    </div>
  );
};
