import { toAbsoluteUrl } from '../../../../../../_start/helpers';
import { AboutUsHeader } from './AboutUsHeader';

type AboutUsHeaderFigureProps = {
  data: AboutUsHeader;
  update: (fieldsToUpdate: Partial<AboutUsHeader>) => void;
};

export const AboutUsHeaderFigure = ({
  data,
  update,
}: AboutUsHeaderFigureProps) => {
  return (
    <div className="about-us-header__figure">
      {data.image ? (
        <div className="overlay">
          <div className="overlay-wrapper">
            <img alt="Uploaded" src={data.image} />
          </div>
          <div className="overlay-layer bg-dark bg-opacity-10 flex-column">
            <div>
              <button
                name="image"
                className="btn btn-primary btn-shadow"
                onClick={() =>
                  update({
                    image: '',
                    image_alt: '',
                  })
                }
              >
                Remove
              </button>
              <label
                className="btn btn-light-primary btn-shadow ms-2"
                data-bs-toggle="modal"
                data-bs-target={`#${data.name}-${data.id}`}
              >
                Change
              </label>
            </div>

            <input
              placeholder="Alt attribute"
              className="w-50 mt-2 form-control form-control-lg form-control-solid"
              type="text"
              value={data.image_alt ?? ''}
              onChange={(event) => update({ image_alt: event.target.value })}
            />
          </div>
        </div>
      ) : (
        <>
          <label
            data-bs-toggle="modal"
            data-bs-target={`#${data.name}-${data.id}`}
          >
            <img
              src={toAbsoluteUrl('/assets/images/900x774.png')}
              alt="Placeholder"
            />
          </label>
        </>
      )}
    </div>
  );
};
