import update from 'immutability-helper';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { RootState } from '../../../../../setup';
import { failureToast, successToast } from '../../../../support/utils';
import { IBody } from '../../pages/Pages/edit-content/components/body/Body';
import { defaultPage, IPage, PageTypes } from '../../pages/Pages/Page';
import { defaultPostType, IPostType } from '../../pages/post-types/PostType';
import { getAllPages, updatePage, viewPage } from './PageCRUD';
import { getAllPostTypes } from '../PostTypesCRUD';
import * as actions from './PageActions';
import { PageEvents } from './PageEvents';
import { IHeader } from '../../pages/Pages/edit-content/components/header/Header';
import { ICustomer } from '../../../customer-management/models/Customer';
import { getAllCustomer } from '../../../customer-management/redux/CustomerCRUD';
import { IEmployee } from '../../../employee-management/pages/employees/Employee';
import { getAllEmployee } from '../../../employee-management/redux/EmployeeCRUD';
import { ITag } from '../../pages/tags/Tag';

export interface IPageState {
  loading: boolean;
  allPostTypes: Array<IPostType>;
  allCasePages: Array<IPage>;
  allBlogPages: Array<IPage>;
  allCustomers: Array<ICustomer>;
  allEmployees: Array<IEmployee>;
  editContent: IPage & {
    wasInitiallySaved?: boolean;
  };
}

const defaultPageState: IPageState = {
  loading: false,
  allPostTypes: [defaultPostType],
  allCasePages: [],
  allBlogPages: [],
  allCustomers: [],
  allEmployees: [],
  editContent: { ...defaultPage, wasInitiallySaved: false },
};

export const reducer = (
  state: IPageState = defaultPageState,
  action: actions.PageActions
): IPageState => {
  switch (action.type) {
    case PageEvents.EDIT_PAGE_CONTENT_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case PageEvents.EDIT_PAGE_CONTENT_LOADED:
      return {
        ...state,
        loading: false,
      };

    case PageEvents.EDIT_PAGE_CONTENT_FAILED:
      return {
        ...state,
        loading: false,
      };

    case PageEvents.SET_WAS_INITIALLY_SAVED:
      return {
        ...state,
        editContent: {
          ...state.editContent,
          wasInitiallySaved: action.payload,
        },
      };

    case PageEvents.SET_POST_TYPES:
      return {
        ...state,
        allPostTypes: action.payload,
      };

    case PageEvents.SET_CASE_PAGES:
      return {
        ...state,
        allCasePages: action.payload,
      };

    case PageEvents.SET_BLOG_PAGES:
      return {
        ...state,
        allBlogPages: action.payload,
      };

    case PageEvents.SET_CUSTOMERS:
      return {
        ...state,
        allCustomers: action.payload,
      };

    case PageEvents.SET_EMPLOYEES:
      return {
        ...state,
        allEmployees: action.payload,
      };

    case PageEvents.SET_PAGE:
      return {
        ...state,
        editContent: {
          ...state.editContent,
          ...action.payload,
        },
      };

    case PageEvents.UPDATE_HEADER:
      return {
        ...state,
        editContent: {
          ...state.editContent,
          content: {
            ...state.editContent.content,
            header: {
              ...action.payload,
              // make sure to set correct post type from page data
              post_type: state.editContent.post_type as IPostType,
            },
          },
        },
      };

    case PageEvents.REMOVE_HEADER:
      return {
        ...state,
        editContent: {
          ...state.editContent,
          content: {
            ...state.editContent.content,
            header: null,
          },
        },
      };

    case PageEvents.SAVE_PAGE_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case PageEvents.SAVE_PAGE_LOADED:
      return {
        ...state,
        loading: false,
      };

    case PageEvents.SAVE_PAGE_FAILED:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

// selectors
const page = (state: RootState) => state.page.editContent;

export function* saga() {
  // initialization for edit page content
  yield takeLatest(
    PageEvents.EDIT_PAGE_CONTENT_REQUESTED,
    function* editPageContentRequested(
      action: ReturnType<typeof actions.editPageContentRequest>
    ) {
      try {
        const [postTypes, page, casePages, blogPages, customers, employees]: [
          Array<IPostType>,
          IPage,
          Array<IPage>,
          Array<IPage>,
          Array<ICustomer>,
          Array<IEmployee>
        ] = yield all([
          call(getAllPostTypes),
          call(viewPage, action.page_id),
          call(getAllPages, PageTypes.CASE),
          call(getAllPages, PageTypes.BLOG),
          call(getAllCustomer),
          call(getAllEmployee),
        ]);

        const filteredCasePages = casePages.filter(
          // eslint-disable-next-line eqeqeq
          (page) => action.page_id != page.id
        );

        const filteredBlogPages = blogPages.filter(
          // eslint-disable-next-line eqeqeq
          (page) => action.page_id != page.id
        );

        yield put(actions.setPostTypes(postTypes));
        yield put(actions.setPage(page));
        yield put(actions.setCasePages(filteredCasePages));
        yield put(actions.setBlogPages(filteredBlogPages));
        yield put(actions.setCustomers(customers));
        yield put(actions.setEmployees(employees));

        yield put(actions.setWasInitiallySaved(checkWasInitiallySaved(page)));

        yield put(actions.editPageContentLoaded());
      } catch (error) {
        yield put(actions.editPageContentFailed());
        yield call(failureToast, error);
      }
    }
  );

  yield takeLatest(
    PageEvents.SAVE_PAGE_REQUESTED,
    function* savePageRequested(
      action: ReturnType<typeof actions.savePageRequest>
    ) {
      try {
        let tags: Array<number> = [];
        action.page.tags.forEach(
          // @ts-ignore
          (element: ITag) => {
            tags.push(element.id);
          }
        );

        const result: IPage = yield call(updatePage, {...action.page, tags});

        yield put(actions.setPage(result));
        yield put(actions.savePageLoaded());

        yield put(actions.setWasInitiallySaved(true));

        yield call(successToast, 'Page content has been updated.');
      } catch (error) {
        yield put(actions.savePageFailed());
        yield call(failureToast, error);
      }
    }
  );

  yield takeLatest(
    PageEvents.INSERT_HEADER,
    function* insertingHeader(action: ReturnType<typeof actions.insertHeader>) {
      const statePage: IPage = yield select(page);

      const updatedHeader: IHeader = {
        ...action.header,
        post_type: statePage.post_type!,
      };

      const updatedPage: IPage = {
        ...statePage,
        content: {
          ...statePage.content,
          header: updatedHeader,
        },
      };

      yield put(actions.setPage(updatedPage));
    }
  );

  yield takeLatest(
    PageEvents.INSERT_BODY,
    function* insertingBody(action: ReturnType<typeof actions.insertBody>) {
      const statePage: IPage = yield select(page);

      let updatedBody = [...statePage.content.body];

      // assign an id, this is importantly needed for the move method
      const component: IBody = {
        ...action.body,
        id: generateUniqueComponentId(updatedBody),
      };

      updatedBody.push(component);

      const updatedPage = updatePageContentBody(statePage, updatedBody);

      yield put(actions.setPage(updatedPage));
    }
  );

  yield takeLatest(
    PageEvents.MOVE_BODY,
    function* movingBody(action: ReturnType<typeof actions.moveBody>) {
      const statePage: IPage = yield select(page);
      const contentBody = statePage.content.body;

      const updatedBody = moveBodyComponent(
        contentBody,
        action.dragIndex,
        action.hoverIndex
      );

      const updatedPage = updatePageContentBody(statePage, updatedBody);

      yield put(actions.setPage(updatedPage));
    }
  );

  yield takeLatest(
    PageEvents.REMOVE_BODY,
    function* removingBody(action: ReturnType<typeof actions.removeBody>) {
      const statePage: IPage = yield select(page);
      const contentBody = statePage.content.body;

      const updatedBody = removeBodyComponent(contentBody, action.index);

      const updatedPage = updatePageContentBody(statePage, updatedBody);

      yield put(actions.setPage(updatedPage));
    }
  );

  yield takeLatest(
    PageEvents.UPDATE_BODY,
    function* updatingBody(action: ReturnType<typeof actions.updateBody>) {
      const statePage: IPage = yield select(page);
      const contentBody = statePage.content.body;

      const updatedBody = updateBodyComponent(
        contentBody,
        action.body,
        action.index
      );

      const updatedPage = updatePageContentBody(statePage, updatedBody);

      yield put(actions.setPage(updatedPage));
    }
  );
}

const generateUniqueComponentId = (items: Array<IBody>): number => {
  if (items.length === 0) {
    return 0;
  }

  let sortedItems = [...items].sort((a, b) => {
    return a.id - b.id;
  });

  return sortedItems[sortedItems.length - 1].id + 1;
};

const updatePageContentBody = (
  currentPage: IPage,
  updatedBody: Array<IBody>
): IPage => ({
  ...currentPage,
  content: {
    ...currentPage.content,
    body: updatedBody,
  },
});

const moveBodyComponent = (
  bodyList: Array<IBody>,
  dragIndex: number,
  hoverIndex: number
) => {
  return update(bodyList, {
    $splice: [
      [dragIndex, 1],
      [hoverIndex, 0, bodyList[dragIndex] as IBody],
    ],
  });
};

const updateBodyComponent = (
  bodyList: Array<IBody>,
  body: IBody,
  index: number
) => {
  return update(bodyList, { [index]: { $set: body } });
};

const removeBodyComponent = (bodyList: Array<IBody>, index: number) => {
  return update(bodyList, {
    $splice: [[index, 1]],
  });
};

const checkWasInitiallySaved = (page: IPage) => {
  if (page.content.header && page.content.body.length) {
    return true;
  }

  return false;
};
