import { ElementType, IElement } from '../../../models/Element';

export type WebsiteScreenshot = IElement & {
  image_preview_desktop: string;
  image_preview_desktop_alt: string;
  image_preview_mobile: string;
  image_preview_mobile_alt: string;
};

export const defaultWebsiteScreenshot: WebsiteScreenshot = {
  id: 0,
  name: ElementType.WEBSITE_SCREENSHOT,
  image_preview_desktop: '',
  image_preview_desktop_alt: '',
  image_preview_mobile: '',
  image_preview_mobile_alt: '',
};
