import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { BlogContentWideDescriptionTwoColumn } from "./BlogContentWideDescriptionTwoColumn";
import { IElement } from "../../../models/Element";
import { ElementWrapper } from "../../../components/ElementWrapper";
import { TextAreaEdit } from "../../../components/TextAreaEdit";
import {
	getHeaderClassName,
	HeaderTypeSelect,
} from "../../../components/HeaderTypeSelect";

type BlogContentWideDescriptionTwoColumnFCProps = {
	index: number;
	data: BlogContentWideDescriptionTwoColumn;
	updateData: (body: IElement, index: number) => void;
};

export const BlogContentWideDescriptionTwoColumnFC = ({
	index,
	data,
	updateData,
}: BlogContentWideDescriptionTwoColumnFCProps) => {
	const update = (
		fieldsToUpdate: Partial<BlogContentWideDescriptionTwoColumn>
	) => {
		const updatedData = { ...data, ...fieldsToUpdate };
		updateData(updatedData, index);
	};

	return (
		<ElementWrapper elementIndex={index}>
			<div className="blog-content-wide-description-two-column body-my-8">
				<div className="blog-content">
					<div className="position-relative">
						<TextAreaEdit
							name="title"
							className={`blog-content__title ${getHeaderClassName(
								data.title_type
							)}`}
							value={data.title}
							setValue={update}
						/>
						<HeaderTypeSelect
							name={`title_type`}
							value={data.title_type}
							setValue={update}
						/>
					</div>

					<CKEditor
						className="blog-content__editor"
						editor={ClassicEditor}
						data={data.content}
						// @ts-ignore
						onReady={(editor) => {
							// You can store the "editor" and use when it is needed.
							// console.log('Editor is ready to use!', editor);
						}}
						// @ts-ignore
						onChange={(event, editor) => {
							const editorData = editor.getData();

							update({ content: editorData });
						}}
						// @ts-ignore
						onBlur={(event, editor) => {
							//   console.log('Blur.', editor);
						}}
						// @ts-ignore
						onFocus={(event, editor) => {
							//   console.log('Focus.', editor);
						}}
					/>
				</div>
			</div>
		</ElementWrapper>
	);
};
