import { ITag } from "../../../../post-management/models/Tag";
import { ElementType, IElement } from "../../../models/Element";

export type BlogHeader = IElement & {
	title: string;
	image: string;
	image_alt: string;
	// author: IEmployee;
	button_title: string;
	button_url: string;
	tags: Array<ITag>;
};

export const defaultBlogHeader: BlogHeader = {
	id: 1,
	name: ElementType.BLOG_HEADER,
	// post_type: defaultPostType,
	title: "Je advertentie geweigerd? Facebook geeft inzicht in reviewproces",
	image: "",
	image_alt: "",
	// author: defaultEmployee,
	button_title: "Artikel delen",
	button_url: "",
	tags: [],
};
