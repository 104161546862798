/* eslint-disable react-hooks/exhaustive-deps */
// import './pages/Pages/edit-content/styles/_displays.scss';
// import './pages/Pages/edit-content/styles/_edit-content.scss';

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  getConfig,
  IThemeConfig,
  PageDataContainer,
  PageLink,
  useTheme,
} from '../../../_start/layout/core';
import { ModuleManagementPage } from './ModuleManagementPage';
import { moduleManagementSubmenu } from './ModuleManagementPageData';

const moduleManagementBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/',
    isActive: true,
  },
  {
    title: 'Module Management',
    path: '/module-management',
    isActive: true,
  },
];

const defaultPageConfig = getConfig();
const pageManagementPageConfig: Partial<IThemeConfig> = {
  sidebar: {
    ...defaultPageConfig.sidebar,
    display: false,
    content: 'user',
    bgColor: 'bg-info',
  },
};

export const editContentPageConfig: Partial<IThemeConfig> = {
  toolbar: {
    ...defaultPageConfig.toolbar,
    display: true,
  },
  sidebar: {
    ...defaultPageConfig.sidebar,
    display: true,
    content: 'edit-content',
    bgColor: 'bg-white',
  },
};

export const ModuleManagementWrapper = () => {
  const { setTheme } = useTheme();

  const location = useLocation();

  // Refresh UI after config updates
  useEffect(() => {
    setTheme(pageManagementPageConfig);

    if (location.pathname.includes('edit-content')) {
      setTheme(editContentPageConfig);
    }

    return () => {
      setTheme(defaultPageConfig);
    };
  }, []);

  return (
    <>
      <PageDataContainer
        breadcrumbs={moduleManagementBreadCrumbs}
        submenu={moduleManagementSubmenu}
      />
      <ModuleManagementPage />
    </>
  );
};
