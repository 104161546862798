import { IElement } from '../models/Element';
import { editContentActions, EditContentActions } from './EditContentActions';
import { EditContentEvents } from './EditContentEvents';

import immutabilityHelper from 'immutability-helper';
import { put, select, takeLatest } from 'redux-saga/effects';
import { RootState } from '../../../../setup';
import { defaultPost, IPost } from '../../post-management/models/Post';
import { IEmployee } from '../../employee-management/pages/employees/Employee';
import { defaultPage, IPage } from '../../page-management/models/Page';
import {
  defaultCaseDetail,
  ICaseDetail,
} from '../../page-management/models/CaseDetail';
import { ICustomer } from '../../customer-management/models/Customer';
import {
  defaultTutorial,
  ITutorial,
} from '../../tutorial-management/models/Tutorial';
import { defaultPlugin, IPlugin } from '../../module-management/models/Plugin';

export interface IEditContentState {
  content: Array<IElement>;
  post: IPost;
  page: IPage;
  caseDetail: ICaseDetail;
  tutorial: ITutorial;
  plugin: IPlugin;
  allPosts: Array<IPost>;
  allCaseDetails: Array<ICaseDetail>;
  allTutorials: Array<ITutorial>;
  allPlugins: Array<IPlugin>;
  allEmployees: Array<IEmployee>;
  allCustomers: Array<ICustomer>;
  featuredCaseDetails: Array<ICaseDetail>;
  featuredPosts: Array<IPost>;
}

const defaultEditContentState: IEditContentState = {
  content: [],
  post: defaultPost,
  page: defaultPage,
  caseDetail: defaultCaseDetail,
  tutorial: defaultTutorial,
  plugin: defaultPlugin,
  allPosts: [],
  allTutorials: [],
  allCaseDetails: [],
  allPlugins: [],
  allEmployees: [],
  allCustomers: [],
  featuredCaseDetails: [],
  featuredPosts: [],
};

export const reducer = (
  state: IEditContentState = defaultEditContentState,
  action: EditContentActions
): IEditContentState => {
  switch (action.type) {
    case EditContentEvents.SET_CONTENT:
      return {
        ...state,
        content: action.payload,
      };

    case EditContentEvents.SET_POST:
      return {
        ...state,
        post: action.payload,
      };

    case EditContentEvents.SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };

    case EditContentEvents.SET_CASE_DETAIL:
      return {
        ...state,
        caseDetail: action.payload,
      };

    case EditContentEvents.SET_TUTORIAL:
      return {
        ...state,
        tutorial: action.payload,
      };

    case EditContentEvents.SET_PLUGIN:
      return {
        ...state,
        plugin: action.payload,
      };

    case EditContentEvents.SET_ALL_POSTS:
      return {
        ...state,
        allPosts: action.payload,
      };

    case EditContentEvents.SET_ALL_CASE_DETAIL:
      return {
        ...state,
        allCaseDetails: action.payload,
      };

    case EditContentEvents.SET_ALL_TUTORIALS:
      return {
        ...state,
        allTutorials: action.payload,
      };

    case EditContentEvents.SET_ALL_PLUGINS:
      return {
        ...state,
        allPlugins: action.payload,
      };

    case EditContentEvents.SET_ALL_EMPLOYEES:
      return {
        ...state,
        allEmployees: action.payload,
      };

    case EditContentEvents.SET_ALL_CUSTOMERS:
      return {
        ...state,
        allCustomers: action.payload,
      };

    case EditContentEvents.SET_FEATURED_CASE_DETAILS:
      return {
        ...state,
        featuredCaseDetails: action.payload,
      };

    case EditContentEvents.SET_FEATURED_POSTS:
      return {
        ...state,
        featuredPosts: action.payload,
      };

    case EditContentEvents.SET_AUTHOR:
      return {
        ...state,
        post: {
          ...state.post,
          author_id: action.payload.id,
          author: action.payload,
        },
      };

    default:
      return state;
  }
};

const generateUniqueComponentId = (items: Array<IElement>): number => {
  if (items.length === 0) {
    return 0;
  }

  let sortedItems = [...items].sort((a, b) => {
    return a.id - b.id;
  });

  return sortedItems[sortedItems.length - 1].id + 1;
};

const moveBodyComponent = (
  bodyList: Array<IElement>,
  dragIndex: number,
  hoverIndex: number
) => {
  return immutabilityHelper(bodyList, {
    $splice: [
      [dragIndex, 1],
      [hoverIndex, 0, bodyList[dragIndex] as IElement],
    ],
  });
};

const updateBodyComponent = (
  bodyList: Array<IElement>,
  body: IElement,
  index: number
) => {
  return immutabilityHelper(bodyList, { [index]: { $set: body } });
};

const removeBodyComponent = (bodyList: Array<IElement>, index: number) => {
  return immutabilityHelper(bodyList, {
    $splice: [[index, 1]],
  });
};

// SELECTOR
const content = (state: RootState) => state.editContent.content;

export function* saga() {
  yield takeLatest(
    EditContentEvents.INSERT_ELEMENT,
    function* insertingElement(
      action: ReturnType<typeof editContentActions.insertElement>
    ) {
      const stateContent: Array<IElement> = yield select(content);

      let contentSnapshot = [...stateContent];

      // assign an id, this is importantly needed for the move method
      const component: IElement = {
        ...action.element,
        id: generateUniqueComponentId(contentSnapshot),
      };

      contentSnapshot.push(component);

      yield put(editContentActions.setContent(contentSnapshot));
    }
  );

  yield takeLatest(
    EditContentEvents.MOVE_ELEMENT,
    function* movingBody(
      action: ReturnType<typeof editContentActions.moveElement>
    ) {
      const stateContent: Array<IElement> = yield select(content);

      const updatedBody = moveBodyComponent(
        stateContent,
        action.dragIndex,
        action.hoverIndex
      );

      yield put(editContentActions.setContent(updatedBody));
    }
  );

  yield takeLatest(
    EditContentEvents.REMOVE_ELEMENT,
    function* removingBody(
      action: ReturnType<typeof editContentActions.removeElement>
    ) {
      const stateContent: Array<IElement> = yield select(content);

      const updatedBody = removeBodyComponent(stateContent, action.index);

      yield put(editContentActions.setContent(updatedBody));
    }
  );

  yield takeLatest(
    EditContentEvents.UPDATE_ELEMENT,
    function* updatingBody(
      action: ReturnType<typeof editContentActions.updateElement>
    ) {
      const stateContent: Array<IElement> = yield select(content);

      const updatedBody = updateBodyComponent(
        stateContent,
        action.element,
        action.index
      );

      yield put(editContentActions.setContent(updatedBody));
    }
  );
}
