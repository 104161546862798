/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';

type LinkTooltipWrapperProps = {
  name: string;
  value: string;
  setValue: (fieldsToUpdate: Partial<any>) => void;
  className?: string;
};

export const LinkTooltipWrapper: React.FC<LinkTooltipWrapperProps> = ({
  name,
  value,
  setValue,
  className = '',
  children,
}) => {
  const [active, setActive] = useState(false);

  const [editingValue, setEditingValue] = useState<string>('');

  useEffect(() => {
    if (value !== editingValue) {
      setEditingValue(value);
    }
  }, [value]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setEditingValue(event.target.value);

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    if (event.key === 'Enter' || event.key === 'Escape') {
      target.blur();
    }
  };

  const onBlur = (event: React.FocusEvent<HTMLLIElement>) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setActive(false);
    }
  };

  const onBlurInput = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value.trim() === '' || event.target.value === value) {
      setEditingValue(value);
    } else {
      setValue({ [event.target.name]: event.target.value });
    }
  };

  const parentClassnames = `position-relative link-tooltip-wrapper ${className}`;

  const sectionRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (active) {
      sectionRef.current?.focus();
    }
  }, [active]);

  return (
    <div
      className={parentClassnames}
      onClick={() => setActive(true)}
    >
      {active && (
        <section
          className="position-absolute mt-n18"
          ref={sectionRef}
          onBlur={onBlur}
          tabIndex={1}
        >
          <div className="d-flex flex-column align-items-center">
            <input
              name={name}
              value={editingValue ?? ''}
              onBlur={onBlurInput}
              onChange={onChange}
              onKeyDown={onKeyDown}
              type="text"
              className="min-w-150px form-control"
              placeholder="Enter link here"
            />
            {/* begin::ArrowDown */}
            <div
              className=""
              style={{
                width: '0',
                height: '0',
                borderLeft: '12px solid transparent',
                borderRight: '12px solid transparent',
                borderTop: '12px solid #F5F8FA',
              }}
            ></div>
            {/* end::ArrowDown */}
          </div>
        </section>
      )}
      {/* Wrapping */}
      {children}
    </div>
  );
};
