import { ElementWrapper } from '../../../components/ElementWrapper';
import { IElement } from '../../../models/Element';
import { FeaturedUsedPlugins } from './FeaturedUsedPlugins';
import { FeaturedUsedPluginsHeader } from './FeaturedUsedPluginsHeader';
import { FeaturedUsedPluginsList } from './FeaturedUsedPluginsList';

type FeaturedUsedPluginsFCProps = {
  index: number;
  data: FeaturedUsedPlugins;
  updateData: (body: IElement, index: number) => void;
};

export const FeaturedUsedPluginsFC = ({
  index,
  data,
  updateData,
}: FeaturedUsedPluginsFCProps) => {
  const update = (fieldsToUpdate: Partial<FeaturedUsedPlugins>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  return (
    <ElementWrapper elementIndex={index}>
      <div className="case-featured-used-plugins body-my-8">
        <FeaturedUsedPluginsHeader data={data} update={update} />
        <FeaturedUsedPluginsList data={data} update={update} />
      </div>
    </ElementWrapper>
  );
};
