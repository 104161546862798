import { ElementType, IElement } from '../../../models/Element';

export type AboutUsShowcaseWithTextRight = IElement & {
  title: string;
  description: string;
  image_preview: string;
  image_preview_alt: string;
};

export const defaultAboutUsShowcaseWithTextRight: AboutUsShowcaseWithTextRight =
  {
    id: 0,
    name: ElementType.ABOUT_US_SHOWCASE_WITH_TEXT_RIGHT,
    title: 'Onze organisatie',
    description:
      'Tussendoor B.V. is opgericht medio 2010 en is sinds haar oprichting uitgegroeid tot een gewaardeerde WordPress organisatie binnen Nederland, maar ook buiten haar landsgrenzen. Tussendoor staat voor een platte bedrijfscultuur waar niet naar "de baas" wordt gevraagd. Korte lijnen, direct contact en beslissings bevoegdheid voor iedereen. Wel zo prettig.',
    image_preview: '',
    image_preview_alt: '',
  };
