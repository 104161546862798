// @ts-nocheck

import { AboutUsCenteredParagraph } from './AboutUsCenteredParagraph';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { IElement } from '../../../models/Element';
import { ElementWrapper } from '../../../components/ElementWrapper';

type AboutUsCenteredParagraphFCProps = {
  index: number;
  data: AboutUsCenteredParagraph;
  updateData: (body: IElement, index: number) => void;
};

export const AboutUsCenteredParagraphFC = ({
  index,
  data,
  updateData,
}: AboutUsCenteredParagraphFCProps) => {
  const update = (fieldsToUpdate: Partial<AboutUsCenteredParagraph>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  return (
    <ElementWrapper elementIndex={index}>
      <div className="about-us-centered-paragraph body-my-8">
        <div className="paragraph__content">
          {/* begin::Content */}
          <CKEditor
            className="blog-content__editor"
            editor={ClassicEditor}
            data={data.content}
            onReady={(editor) => {
              // You can store the "editor" and use when it is needed.
              // console.log('Editor is ready to use!', editor);
            }}
            onChange={(event, editor) => {
              const editorData = editor.getData();

              update({ content: editorData });
            }}
            onBlur={(event, editor) => {
              //   console.log('Blur.', editor);
            }}
            onFocus={(event, editor) => {
              //   console.log('Focus.', editor);
            }}
          />
          {/* end::Content */}
        </div>
      </div>
    </ElementWrapper>
  );
};
