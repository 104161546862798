import { ElementType, IElement } from '../../../models/Element';

export type AboutUsIntroduction = IElement & {
  heading: string;
  message: string;
};

export const defaultAboutUsIntroduction: AboutUsIntroduction = {
  id: 0,
  name: ElementType.ABOUT_US_INTRODUCTION,
  heading: 'Samen sterk',
  message:
    'Alleen is maar alleen en samen staan we sterk. Maar dan echt. Wij hebben álle kennis in huis om jouw online beter zichtbaar te maken. Te helpen met de mooiste maatwerk oplossingen. Tijd te besparen. Geld te verdienen en te helpen bij WordPress uitdagingen waar menigeen bang van wordt.\n\nMaar alleen is maar alleen. Met alleen techniek komen we niet zo ver. Daarom hebben we elkaar nodig. Wij zorgen voor de techniek, jij voor de inhoud. We vertellen samen jouw verhaal, verkopen jouw dienst of product en zorgen dat de wereld dat kan vinden.',
};
