import immutabilityHelper from 'immutability-helper';

import { HomeFeaturedProjects } from './HomeFeaturedProjects';
import { HomeFeaturedProjectsHeader } from './HomeFeaturedProjectsHeader';

import { IElement } from '../../../models/Element';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { setFeaturedCaseDetails } from '../../../redux/EditContentActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../setup';
import {
  defaultCaseDetailPlaceholder,
  ICaseDetail,
} from '../../../../page-management/models/CaseDetail';
import { FeaturedCaseSinglePreview } from './FeaturedCaseSinglePreview';
import { FeaturedCaseWrapper } from '../../case-detail/featured-other-cases/FeaturedCaseWrapper';
import { useEffect, useState } from 'react';
import { FeaturedCaseDualPreview } from './FeaturedCaseDualPreview';

type HomeFeaturedProjectsFCProps = {
  index: number;
  data: HomeFeaturedProjects;
  updateData: (body: IElement, index: number) => void;
};

export const HomeFeaturedProjectsFC = ({
  index,
  data,
  updateData,
}: HomeFeaturedProjectsFCProps) => {
  const dispatch = useDispatch();

  const featuredCaseDetails = useSelector(
    (state: RootState) => state.editContent.featuredCaseDetails
  );

  const update = (fieldsToUpdate: Partial<HomeFeaturedProjects>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);

    // update the featured_posts on post data
    dispatch(setFeaturedCaseDetails(updatedData.featured_case_details));
  };

  const updateFeatured = (idx: number, item: ICaseDetail) => {
    let featured_case_details = [...data.featured_case_details];
    featured_case_details[idx] = item;

    const updatedData: HomeFeaturedProjects = {
      ...data,
      featured_case_details,
    };

    update(updatedData);
  };

  const addFeatured = (preview_type: string = 'single') => {
    const featured_case_details = immutabilityHelper(
      data.featured_case_details,
      {
        $push: [
          preview_type === 'single'
            ? { ...defaultCaseDetailPlaceholder, preview_type: 'single' }
            : { ...defaultCaseDetailPlaceholder, preview_type: 'dual' },
        ],
      }
    );

    const updatedData = { ...data, featured_case_details };
    update(updatedData);
  };

  const removeFeatured = (itemIndex: number) => {
    let featured_case_details = immutabilityHelper(data.featured_case_details, {
      $splice: [[itemIndex, 1]],
    });

    const updatedData = { ...data, featured_case_details };
    update(updatedData);
  };

  const allCaseDetails = useSelector(
    (state: RootState) => state.editContent.allCaseDetails
  );

  const [selectedPostsMap, setSelectedPostsMap] = useState<
    Map<number, ICaseDetail>
  >(new Map());

  useEffect(() => {
    if (allCaseDetails.length) {
      const iterable: Array<[number, ICaseDetail]> = featuredCaseDetails.map(
        (element, idx) => [element.id, element]
      );

      setSelectedPostsMap(new Map(iterable));
    }
  }, [allCaseDetails, featuredCaseDetails]);

  return (
    <ElementWrapper elementIndex={index}>
      <div className="home-featured-projects body-my-8">
        <HomeFeaturedProjectsHeader data={data} update={update} />
        {/* <HomeFeaturedProjectsCases data={data} updateData={update} /> */}

        <ul className="home-featured-projects__cases">
          {data.featured_case_details.map((caseDetail, idx) => (
            <FeaturedCaseWrapper
              key={idx}
              itemIndex={idx}
              data={caseDetail}
              updateSelected={updateFeatured}
              selectedMap={selectedPostsMap}
            >
              {caseDetail.preview_type === 'single' && (
                <FeaturedCaseSinglePreview
                  key={idx}
                  idx={idx}
                  caseDetail={caseDetail}
                  removeFeatured={removeFeatured}
                />
              )}

              {caseDetail.preview_type === 'dual' && (
                <FeaturedCaseDualPreview
                  key={idx}
                  idx={idx}
                  data={data}
                  caseDetail={caseDetail}
                  updateFeatured={updateFeatured}
                  removeFeatured={removeFeatured}
                />
              )}
            </FeaturedCaseWrapper>
          ))}
          <div className="add-item">
            <div className="add-item__inner">
              <div className="m-auto gap-4 d-flex flex-column">
                <button
                  onClick={() => addFeatured()}
                  className="btn btn-sm btn-light"
                >
                  Single Preview
                </button>
                <button
                  onClick={() => addFeatured('dual')}
                  className="btn btn-sm btn-light"
                >
                  Dual Preview
                </button>
              </div>
            </div>
          </div>
        </ul>
      </div>
    </ElementWrapper>
  );
};
