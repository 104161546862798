import { ElementType, IElement } from '../../../models/Element';

export type ContactsHeader = IElement & {
  image: string;
  image_alt: string;
  title: string;
  heading: string;
  description: string;
  button_url1: string;
  button_title1: string;
  button_url2: string;
  button_title2: string;
};

export const defaultContactsHeader: ContactsHeader = {
  id: 0,
  name: ElementType.CONTACTS_HEADER,
  image: '',
  image_alt: '',
  title: 'Contact ons',
  heading: 'Enthousiast team van specialisten',
  description:
    'Heb je vragen of wil je contact met ons opnemen? Vul het onderstaande contact formulier in of bel ons op +31(0)58 711 0916 mailen kan ook direct naar info@tussendoor.nl.',
  button_url1: '',
  button_title1: 'Stuur bericht',
  button_url2: '',
  button_title2: 'Veel gestelde vragen',
};
