import { useRef, useState } from 'react';
import { KTSVG } from '../../../../_start/helpers';
import { ButtonTooltip } from '../../edit-content/components/ButtonTooltip';
import { StaticWidthInlineEdit } from '../../edit-content/components/StaticWidthInlineEdit';
import { defaultMenuLink, MenuLink } from '../models/Menu';

type GroupLinkProps = {
  index: number;
  link: MenuLink;
  removeLink: (index: number) => void;
  updateLink: (index: number, link: MenuLink) => void;
};

export const GroupLink = ({
  index,
  link,
  removeLink,
  updateLink,
}: GroupLinkProps) => {
  const [data, setData] = useState<MenuLink>(link ? link : defaultMenuLink);

  const updateData = (fieldsToUpdate: Partial<MenuLink>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
    updateLink(index, updatedData);
  };

  const removeItemRef = useRef<HTMLSpanElement>(null);

  const onMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    removeItemRef.current?.classList.remove('d-none');
  };

  const onMouseLeave = (event: React.MouseEvent<HTMLDivElement>) => {
    removeItemRef.current?.classList.add('d-none');
  };

  return (
    <div
      className="position-relative"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <span
        ref={removeItemRef}
        onClick={() => removeLink(index)}
        className="position-absolute top-0 end-0 z-index-2 d-none opacity-75-hover"
      >
        <KTSVG
          path="/media/icons/duotone/Interface/Minus-Square.svg"
          className="svg-icon-danger svg-icon-1"
        />
      </span>

      <ButtonTooltip
        name={`link_url`}
        className={`z-index-1`}
        value={data.link_url}
        setValue={updateData}
      >
        <StaticWidthInlineEdit
          name="link_title"
          style={{ width: '90%' }}
          value={data.link_title}
          setValue={updateData}
        />
      </ButtonTooltip>
    </div>
  );
};
