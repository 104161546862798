import { ITutorial } from './Tutorial';

export interface ITutorialCategory {
  id: number;
  name: string;
  slug: string;
  description: string;
  color: string;
  tutorials: Array<ITutorial>;
  image_preview?: string;
  image_preview_alt_text?: string;
}

export const defaultCategory: ITutorialCategory = {
  id: 0,
  name: '',
  slug: '',
  description: '',
  color: '#000000',
  image_preview: '',
  image_preview_alt_text: '',
  tutorials: [],
};
