/* eslint-disable jsx-a11y/anchor-is-valid */
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../setup';
import { ButtonTooltip } from '../../../components/ButtonTooltip';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { InlineEdit } from '../../../components/InlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { IElement } from '../../../models/Element';

import { CaseHeader } from './CaseHeader';

type CaseHeaderFCProps = {
  index: number;
  data: CaseHeader;
  updateData: (element: IElement, index: number) => void;
};

export const CaseHeaderFC = ({
  index,
  data,
  updateData,
}: CaseHeaderFCProps) => {
  const caseDetail = useSelector(
    (state: RootState) => state.editContent.caseDetail
  );

  const update = (fieldsToUpdate: Partial<CaseHeader>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  return (
    <ElementWrapper elementIndex={index}>
      <div className="case-header">
        <div className="case-header__content">
          <div className="content-header">
            <InlineEdit
              name="title"
              className="header-title"
              value={data.title}
              setValue={update}
            />

            <TextAreaEdit
              name="heading"
              className="header-heading"
              value={data.heading}
              setValue={update}
            />

            <div className="header-post-type">
              {caseDetail.tags.map((tag, idx) => {
                return (
                  <a
                    key={idx}
                    className="post-type-category"
                    style={{
                      color: 'white',
                      backgroundColor: tag.color,
                    }}
                  >
                    {tag.name}
                  </a>
                );
              })}
            </div>
          </div>

          <TextAreaEdit
            name="description"
            className="content-description"
            value={data.description}
            setValue={update}
          />

          <ButtonTooltip
            name={`button_url`}
            className="content-action"
            value={data.button_url}
            setValue={update}
          >
            <a>
              <InlineEdit
                className="action-text"
                name="button_title"
                value={data.button_title}
                setValue={update}
                activeVerticalPadding={'py-0'}
                dynamicWidth
                style={{
                  maxWidth: '22rem',
                }}
              />
            </a>
          </ButtonTooltip>
        </div>
      </div>
    </ElementWrapper>
  );
};
