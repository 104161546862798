import { ICaseDetail } from '../../../../page-management/models/CaseDetail';
import { ElementType, IElement } from '../../../models/Element';

export type FeaturedOtherCases = IElement & {
  title: string;
  featured_case_details: Array<ICaseDetail>;
};

export const defaultFeaturedOtherCases: FeaturedOtherCases = {
  id: 0,
  name: ElementType.FEATURED_OTHER_CASES,
  title: 'Andere oplossingen',
  featured_case_details: [],
};
