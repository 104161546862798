import { IElement } from '../../edit-content/models/Element';
import {
  defaultEmployee,
  IEmployee,
} from '../../employee-management/pages/employees/Employee';
import { ITutorialCategory } from './TutorialCategory';

export enum StatusType {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  FUTURE = 'future',
}

export enum SEOTitleType {
  SITE_TITLE = 'site-title',
  TITLE = 'title',
  TITLE_SEPARATOR = 'title-separator',
}

export enum RobotType {
  INDEX = 'index',
  NO_INDEX = 'noindex',
  NONE = 'none',
  NO_IMAGE_INDEX = 'noimageindex',
  FOLLOW = 'follow',
  NO_FOLLOW = 'nofollow',
  NO_ARCHIVE_NO_CACHE = 'noarchive/nocache',
  NO_SNIPPET = 'nosnippet',
  NO_TRANSLATE = 'notranslate',
  UNAVAILABLE_AFTER = 'unavailable_after',
  NOODP = 'noodp',
  NOYDIR = 'noydir',
}

export enum SchemaPageType {
  WEB_PAGE = 'web page',
  ITEM_PAGE = 'item page',
  ABOUT_PAGE = 'about page',
  FAQ_PAGE = 'faq page',
  QA_PAGE = 'qa page',
  PROFILE_PAGE = 'profile page',
  CONTACT_PAGE = 'contact page',
  MEDICAL_WEB_PAGE = 'medical web page',
  COLLECTION_PAGE = 'collection page',
  CHECKOUT_PAGE = 'checkout page',
  REAL_ESTATE_LISTING = 'real estate listing',
  SEARCH_RESULT_PAGE = 'search results page',
}

export enum SchemaArticleType {
  ARTICLE = 'article',
  BLOG_POST = 'blog post',
  SOCIAL_MEDIA_POSTING = 'social media posting',
  NEWS_ARTICLE = 'news article',
  ADVISER_CONTENT_ARTICLE = 'advertiser content article',
  SATIRICAL_ARTICLE = 'satirical article',
  SCHOLARLY_ARTICLE = 'scholarly article',
  TECH_ARTICLE = 'tech article',
  REPORT = 'report',
  NONE = 'none',
}

export interface ITutorial {
  id: number;
  title: string;
  slug: string;
  publish_date: string | Date;
  status: StatusType;
  description: string;
  image_preview: string;
  image_preview_alt_text: string;
  categories: Array<ITutorialCategory>;
  tutorial_categories: Array<number>;
  content: Array<IElement>;
  author_id: number | null;
  author: IEmployee;

  // SEO stuff
  seo_title: Array<SEOTitleType>;
  robots: Array<RobotType>;

  schema_page_type: SchemaPageType;
  schema_article_type: SchemaArticleType;

  facebook_image: string;
  facebook_title: string;
  facebook_description: string;

  twitter_image: string;
  twitter_title: string;
  twitter_description: string;

  customer_id: number | null;
}

export const defaultTutorial: ITutorial = {
  id: 0,
  title: '',
  slug: '',
  publish_date: new Date(),
  status: StatusType.DRAFT,
  description: '',
  image_preview: '',
  image_preview_alt_text: '',
  categories: [],
  tutorial_categories: [],
  content: [],
  author_id: 0,
  author: { ...defaultEmployee, name: 'John Doe' },

  seo_title: [],
  robots: [],

  schema_page_type: SchemaPageType.WEB_PAGE,
  schema_article_type: SchemaArticleType.ARTICLE,

  facebook_image: '',
  facebook_title: '',
  facebook_description: '',

  twitter_image: '',
  twitter_title: '',
  twitter_description: '',

  customer_id: null,
};

export const defaultTutorialPlaceholder: ITutorial = {
  ...defaultTutorial,
  title: 'Placeholder',
};
