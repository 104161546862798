import { ElementType, IElement } from '../../../models/Element';

export type SpecializationHeader = IElement & {
  image: string;
  image_alt: string;
  title: string;
  heading: string;
  description: string;
  button_primary_url: string;
  button_primary_title: string;
  button_secondary_url: string;
  button_secondary_title: string;
};

export const defaultSpecializationHeader: SpecializationHeader = {
  id: 0,
  name: ElementType.SPECIALIZATION_HEADER,
  image: '',
  image_alt: '',
  title: 'Wordpress plugin',
  heading: 'Search Engine Optimalisation',
  description:
    'De ideale koppeling geschikt voor Realworks om automatisch objecten en woningen te beheren. Lees meer over deze WordPress koppeling',
  button_primary_url: '',
  button_primary_title: 'Kopen',
  button_secondary_url: '',
  button_secondary_title: 'Bekijk whitepaper',
};
