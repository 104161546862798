import React from 'react';
import { ButtonTooltip } from '../../../components/ButtonTooltip';
import { InlineEdit } from '../../../components/InlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { PluginLeftImageHighlightInfo } from './PluginLeftImageHighlightInfo';

type Props = {
  data: PluginLeftImageHighlightInfo;
  update: (fieldsToUpdate: Partial<PluginLeftImageHighlightInfo>) => void;
};

export const PluginLeftImageHighlightInfoContent: React.FC<Props> = ({
  data,
  update,
}) => {
  return (
    <div className="showcase__content">
      <div>
        {/* begin::Heading */}
        <TextAreaEdit
          name="heading"
          className="heading"
          value={data.heading}
          setValue={update}
        />
        {/* end::Heading */}
      </div>

      {/* begin::Description */}
      <TextAreaEdit
        name="description"
        className="description"
        value={data.description}
        setValue={update}
      />
      {/* end::Description */}
    </div>
  );
};
