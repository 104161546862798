import { ElementType, IElement } from '../../../models/Element';

export type ShowcaseFeatured = IElement & {
  title: string;
  description: string;
  image_preview_desktop: string;
  image_preview_desktop_alt: string;
  image_preview_mobile: string;
  image_preview_mobile_alt: string;
  button_url: string;
  button_title: string;
};

export const defaultShowcaseFeatured: ShowcaseFeatured = {
  id: 0,
  name: ElementType.SHOWCASE_FEATURED,
  title: 'Pimp My Soxs Configurator',
  description:
    'Product prices are automatically calculated based on the desired rental period. In addition, products can be extended online.',
  image_preview_desktop: '',
  image_preview_desktop_alt: '',
  image_preview_mobile: '',
  image_preview_mobile_alt: '',
  button_url: '',
  button_title: 'Contact',
};
