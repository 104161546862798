import { ElementType, IElement } from '../../../models/Element';

export type AboutUsFeaturedContentTwo = IElement & {
  title: string;
  description: string;
  button_title: string;
  button_url: string;
};

export const defaultAboutUsFeaturedContentTwo: AboutUsFeaturedContentTwo = {
  id: 0,
  name: ElementType.ABOUT_US_FEATURED_CONTENT_TWO,
  title: 'Werken bij Tussendoor',
  description:
    'Tussendoor bestaat uit een team van 19 enthousiastelingen. Stuk voor stuk met hun eigen expertise en verspreid over de hele wereld. Wil jij ons team komen versterken met jouw unieke eigenschappen en specialiteit? Dan komen we graag in contact. Je vindt onze vacatures op TOV.Team. Een open sollicitatie insturen mag natuurlijk ook altijd.',
  button_title: 'Naar TOV.Team',
  button_url: '',
};
