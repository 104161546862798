/* eslint-disable react-hooks/exhaustive-deps */
import "../scss/_edit-content.scss"

import { useCallback } from "react";
import { useDrop } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { EditContentItemType, IElement } from "../models/Element";
import {
	insertElement,
	moveElement,
	removeElement,
	updateElement,
} from "../redux/EditContentActions";
import { ElementDrag } from "./ElementDrag";

export const ElementDropContainer = () => {
	const dispatch = useDispatch();

	const content = useSelector(
		(state: RootState) => state.editContent.content
	);

	const move = useCallback((dragIndex: number, hoverIndex: number) => {
		dispatch(moveElement({ dragIndex, hoverIndex }));
	}, []);

	const remove = useCallback((index: number) => {
		dispatch(removeElement({ index }));
	}, []);

	const updateData = useCallback((element: IElement, index: number) => {
		dispatch(updateElement({ element, index }));
	}, []);

	const [{ isOver, canDrop }, drop] = useDrop(
		() => ({
			accept: EditContentItemType.ELEMENT_INSERT,
			drop: (item: IElement) => {
				// logic for inserting new body cannot be applied in this object
				// because states inside here is not reactive
				dispatch(insertElement({ element: item }));
			},
			collect: (monitor) => ({
				isOver: monitor.isOver(),
				canDrop: monitor.canDrop(),
			}),
		}),
		[]
	);

	const renderItem = useCallback((item: IElement, index: number) => {
		return (
			<ElementDrag
				key={item.id}
				index={index}
				component={item}
				move={move}
				remove={remove}
				updateData={updateData}
			/>
		);
	}, []);

	return (
		<>
			{content.length ? (
				<div
					ref={drop}
					className={`content-list ${canDrop ? "bg-light" : ""} ${
						isOver ? "bg-light-primary" : ""
					}`}
				>
					{content.map((item, idx) => renderItem(item, idx))}
				</div>
			) : (
				<div
					ref={drop}
					className={`content-placeholder mt-10 d-flex justify-content-center align-items-center ${
						canDrop ? "border-success bg-light" : "border-gray-400"
					} ${isOver ? "bg-light-primary" : ""} border-2`}
					style={{
						borderStyle: "dashed",
						border: "1",
						height: "320px",
						width: "100%",
					}}
				>
					<div className="d-flex flex-column text-center">
						<i className="bi bi-box-arrow-in-down-left display-5"></i>
						<span className="d-block mt-1 fw-bold">
							Insert Element
						</span>
						<p className="d-block mt-1 text-gray-600 fs-7 underline">
							Drag and drop elements here
						</p>
					</div>
				</div>
			)}
			{/* <div className="mt-10">
    {items.map((item, idx) => renderItem(item, idx))}
  </div> */}
		</>
	);
};
