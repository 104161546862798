import React from 'react';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { IElement } from '../../../models/Element';
import { SpecializationsCases } from './SpecializationsCases';
import { SpecializationsCasesContent } from './SpecializationsCasesContent';
import { SpecializationsCasesList } from './SpecializationsCasesList';

interface Props {
  index: number;
  data: SpecializationsCases;
  updateData: (element: IElement, index: number) => void;
}

export const SpecializationsCasesFC: React.FC<Props> = ({
  index,
  data,
  updateData,
}) => {
  const update = (fieldsToUpdate: Partial<SpecializationsCases>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };
  return (
    <ElementWrapper elementIndex={index}>
      <div className="specializations-cases body-my-8">
        <div className="specializations-cases__content">
          <SpecializationsCasesContent data={data} update={update} />
        </div>

        <div className="specializations-cases__list">
          <SpecializationsCasesList data={data} update={update} />
        </div>
      </div>
    </ElementWrapper>
  );
};
