import { ElementType, IElement } from '../../../models/Element';

export interface IHeaderAndDescription {
  id: number;
  heading: string;
  description: string;
  button_title: string;
  button_url: string;
}

export type PluginArrayHeaderAndDescription = IElement & {
  item_list: Array<IHeaderAndDescription>;
};

export const defaultHeaderAndDescription: IHeaderAndDescription = {
  id: 0,
  heading: 'Nieuwbouw.',
  description:
    'Binnen de WordPress Makelaars plugin worden ook nieuwbouwprojecten ondersteund. Toon projecten, kavels & type woningen.',
  button_title: 'Meer informatie',
  button_url: '',
};

export const defaultPluginArrayHeaderAndDescription: PluginArrayHeaderAndDescription =
  {
    id: 0,
    name: ElementType.PLUGIN_ARRAY_HEADER_AND_DESCRIPTION,
    item_list: [
      defaultHeaderAndDescription,
      defaultHeaderAndDescription,
      defaultHeaderAndDescription,
      defaultHeaderAndDescription,
    ],
  };

export enum HeaderAndDescriptionItemTypes {
  HEADER_AND_DESCRIPTION = 'header-and-description',
}
