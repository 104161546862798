/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import immutabilityHelper from 'immutability-helper';

import { RootState } from '../../../../../../setup';
import {
  defaultCaseDetailPlaceholder,
  ICaseDetail,
} from '../../../../page-management/models/CaseDetail';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { StaticWidthInlineEdit } from '../../../components/StaticWidthInlineEdit';
import { IElement } from '../../../models/Element';
import { FeaturedOtherCases } from './FeaturedOtherCases';
import { KTSVG, toAbsoluteUrl } from '../../../../../../_start/helpers';
import { FeaturedCaseWrapper } from './FeaturedCaseWrapper';
import { setFeaturedCaseDetails } from '../../../redux/EditContentActions';

type FeaturedOtherCasesFCProps = {
  index: number;
  data: FeaturedOtherCases;
  updateData: (body: IElement, index: number) => void;
};

export const FeaturedOtherCasesFC = ({
  index,
  data,
  updateData,
}: FeaturedOtherCasesFCProps) => {
  const dispatch = useDispatch();

  const featuredCaseDetails = useSelector(
    (state: RootState) => state.editContent.featuredCaseDetails
  );

  const update = (fieldsToUpdate: Partial<FeaturedOtherCases>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);

    // update the featured_posts on post data
    dispatch(setFeaturedCaseDetails(updatedData.featured_case_details));
  };

  const updateFeatured = (idx: number, item: ICaseDetail) => {
    let featured_case_details = [...data.featured_case_details];
    featured_case_details[idx] = item;

    const updatedData: FeaturedOtherCases = {
      ...data,
      featured_case_details,
    };

    update(updatedData);
  };

  const addFeatured = () => {
    const featured_case_details = immutabilityHelper(
      data.featured_case_details,
      {
        $push: [defaultCaseDetailPlaceholder],
      }
    );

    const updatedData = { ...data, featured_case_details };
    update(updatedData);
  };

  const removeFeatured = (itemIndex: number) => {
    let featured_case_details = immutabilityHelper(data.featured_case_details, {
      $splice: [[itemIndex, 1]],
    });

    const updatedData = { ...data, featured_case_details };
    update(updatedData);
  };

  const allCaseDetails = useSelector(
    (state: RootState) => state.editContent.allCaseDetails
  );

  const [selectedPostsMap, setSelectedPostsMap] = useState<
    Map<number, ICaseDetail>
  >(new Map());

  useEffect(() => {
    if (allCaseDetails.length) {
      const iterable: Array<[number, ICaseDetail]> = featuredCaseDetails.map(
        (element, idx) => [element.id, element]
      );

      setSelectedPostsMap(new Map(iterable));
    }
  }, [allCaseDetails, featuredCaseDetails]);

  return (
    <ElementWrapper elementIndex={index}>
      <div className="case-featured-other-cases body-my-8">
        {/* begin::Content */}
        <div className="case-featured-other-cases__content">
          <StaticWidthInlineEdit
            name="title"
            className="content-title"
            value={data.title}
            setValue={update}
          />
        </div>
        {/* end::Content */}

        {/* begin::List */}
        <ul className="case-featured-other-cases__list">
          {data.featured_case_details.map((element, idx) => (
            <FeaturedCaseWrapper
              key={idx}
              itemIndex={idx}
              data={element}
              updateSelected={updateFeatured}
              selectedMap={selectedPostsMap}
            >
              <div className="list-item">
                <span
                  onClick={() => removeFeatured(idx)}
                  className="position-absolute z-index-3 top-0 end-0 me-2 mt-2 opacity-75-hover"
                >
                  <KTSVG
                    path="/media/icons/duotone/Interface/Minus-Square.svg"
                    className="svg-icon-danger svg-icon-1"
                  />
                </span>
                {/* begin::Figure */}
                <img
                  className="list-item__figure"
                  src={
                    element.image_preview
                      ? element.image_preview
                      : toAbsoluteUrl('/assets/images/400x500.png')
                  }
                  alt=""
                />
                {/* end::Figure */}

                {/* begin::Container */}
                <div className="list-item__container">
                  <div className="container-content">
                    {/* begin::Labels */}
                    <div className="content-labels">
                      {element.tags.length === 0 && (
                        <>
                          <a className="labels-item back-purple1">
                            <span className="labels-item__title">WEB</span>
                          </a>

                          <a className="labels-item back-pink1">
                            <span className="labels-item__title">
                              MARKETING
                            </span>
                          </a>
                        </>
                      )}

                      {
                        // @ts-ignore
                        element.tags.map(
                          // @ts-ignore
                          (tag: ITag, idx) => {
                            // limit to 2 labels shown
                            if (idx > 1) return '';
                            return (
                              <a
                                key={idx}
                                className="labels-item back-purple1"
                                style={{
                                  color: 'white',
                                  backgroundColor: tag.color,
                                }}
                              >
                                <span className="labels-item__title">
                                  {tag.name}
                                </span>
                              </a>
                            );
                          }
                        )
                      }
                    </div>
                    {/* end::Labels */}

                    {/* begin::Case Detail Title */}
                    <span className="content-title">{element.title}</span>
                    {/* end::Case Detail Title */}
                  </div>
                </div>
                {/* end::Container */}
              </div>
            </FeaturedCaseWrapper>
          ))}

          <div onClick={addFeatured} className="add-item">
            <div className="add-item__inner">
              <div className="m-auto d-flex flex-column">
                <KTSVG
                  path="/media/icons/duotone/Interface/Plus-Square.svg"
                  className="svg-icon-muted svg-icon-3hx text-center"
                />
                <span className="mt-4 text-gray-500">Add new item</span>
              </div>
            </div>
          </div>
        </ul>

        {/* end::List */}
      </div>
    </ElementWrapper>
  );
};
