export enum MarginType {
  MARGIN_10 = '10px',
  MARGIN_15 = '15px',
  MARGIN_20 = '20px',
  MARGIN_25 = '25px',
  MARGIN_30 = '30px',
  MARGIN_35 = '35px',
  MARGIN_40 = '40px',
}

export enum ElementHeaderType {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
}