import { useRef } from 'react';

import updateHelper from 'immutability-helper';
import { Process } from './Process';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { KTSVG } from '../../../../../../_start/helpers';
import { IElement } from '../../../models/Element';
import { defaultProcess, IProcess, ProcessHighlight } from './ProcessHighlight';
import { generateListItemId } from '../../../../../support/helpers';

type ProcessHighlightProps = {
  index: number;
  data: ProcessHighlight;
  updateData: (body: IElement, index: number) => void;
};

export const ProcessHighlightFC = ({
  index,
  data,
  updateData,
}: ProcessHighlightProps) => {
  const update = (fieldsToUpdate: Partial<ProcessHighlight>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  const addItemRef = useRef<HTMLLIElement>(null);

  const onMouseEnter = (event: React.MouseEvent<HTMLUListElement>) => {
    addItemRef.current?.classList.remove('d-none');

    if (event.currentTarget === event.target) {
      event.stopPropagation();
    }

    event.currentTarget.style.cursor = 'pointer';
  };

  const onMouseLeave = (event: React.MouseEvent<HTMLUListElement>) => {
    addItemRef.current?.classList.add('d-none');
    event.currentTarget.style.cursor = 'auto';
  };

  const updateItem = (idx: number, process: IProcess) => {
    let processes = [...data.processes];
    processes[idx] = process;
    const updatedData = { ...data, processes };
    updateData(updatedData, index);
  };

  const addListItem = () => {
    const processes = updateHelper(data.processes, {
      $push: [{ ...defaultProcess, id: generateListItemId(data.processes) }],
    });

    const updatedData = { ...data, processes };
    updateData(updatedData, index);
  };

  const removeListItem = (itemIndex: number) => {
    let processes = updateHelper(data.processes, {
      $splice: [[itemIndex, 1]],
    });

    const updatedData = { ...data, processes };
    updateData(updatedData, index);
  };

  const moveListItem = (dragIndex: number, hoverIndex: number) => {
    let processes = updateHelper(data.processes, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, data.processes[dragIndex]],
      ],
    });

    const updatedData = { ...data, processes };
    updateData(updatedData, index);
  };

  return (
    <ElementWrapper elementIndex={index}>
      <div className="case-process-highlight body-my-8">
        {/* begin::Content */}
        <div className="case-process-highlight__content">
          <TextAreaEdit
            name="title"
            className="content-title"
            value={data.title}
            setValue={update}
          />

          <TextAreaEdit
            name="description"
            className="content-description"
            value={data.description}
            setValue={update}
          />
        </div>
        {/* end::Content */}

        {/* begin::List */}
        <ul
          className="case-process-highlight__list"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {data.processes.map((process, idx) => (
            <Process
              key={idx}
              index={idx}
              process={process}
              processHighlight={data}
              updateProcess={updateItem}
              removeListItem={removeListItem}
              moveListItem={moveListItem}
            />
          ))}

          <li
            ref={addItemRef}
            onClick={addListItem}
            style={{
              minHeight: '263px',
            }}
            className="d-none w-100 h-100 d-flex rounded border-light-400 border-dashed border-2 opacity-75-hover"
          >
            <div className="m-auto d-flex flex-column">
              <KTSVG
                path="/media/icons/duotone/Interface/Plus-Square.svg"
                className="svg-icon-light svg-icon-3hx text-center"
              />
              <span className="mt-4 text-light">Add new item</span>
            </div>
          </li>
        </ul>
        {/* end::List */}
      </div>
    </ElementWrapper>
  );
};
