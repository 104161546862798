import { ElementType, IElement } from '../../../models/Element';

export interface ISpecializationsStep {
  id: number;
  title: string;
  heading: string;
  description: string;
  button_url: string;
  button_title: string;
}

export type SpecializationsModalSteps = IElement & {
  heading: string;
  description: string;
  item_list: Array<ISpecializationsStep>;
};

export const defaultSpecializationsStep: ISpecializationsStep = {
  id: 0,
  title: 'Stap 1',
  heading: 'Briefing',
  description:
    'Hoe zorgen we ervoor dat de bezoeker op elk schermformaat moeiteloos een product kan bestellen? De tekst kan hier wel wat langer.',
  button_title: 'Lees meer',
  button_url: '',
};

export const defaultSpecializationsModalSteps: SpecializationsModalSteps = {
  id: 0,
  name: ElementType.SPECIALIZATION_MODAL_STEPS,
  heading: 'Het proces',
  description:
    'Donec ullamcorper nulla non metus auctor fringilla. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Nulla vitae elit libero, a pharetra augue. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Lorem ipsum dolor sit.',
  item_list: [
    defaultSpecializationsStep,
    defaultSpecializationsStep,
    defaultSpecializationsStep,
  ],
};
