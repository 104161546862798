import { ElementHeaderType } from "../elements/utils";

type HeaderTypeSelectProps = {
	name: string;
	value: string;
	marginLeft?: string;
	setValue: (fieldsToUpdate: Partial<any>) => void;
};

export const getHeaderClassName = (data: ElementHeaderType) => {
	switch (data) {
		case ElementHeaderType.H1:
			return "element-h1";

		case ElementHeaderType.H2:
			return "element-h2";

		case ElementHeaderType.H3:
			return "element-h3";

		default:
			return "";
	}
};

export const HeaderTypeSelect = ({
	name,
	value,
	setValue,
	marginLeft = "-7rem",
}: HeaderTypeSelectProps) => {
	return (
		<div
			className="position-absolute start-0"
			style={{
				marginLeft: marginLeft,
				top: "50%",
				transform: "translateY(-50%)",
			}}
		>
			<select
				className="form-select form-select-sm form-select-solid text-capitalize"
				data-control="select2"
				data-placeholder="Select Status..."
				name={name}
				value={value}
				onChange={(event) =>
					setValue({ [event.target.name]: event.target.value })
				}
			>
				<option value={ElementHeaderType.H1}>
					{ElementHeaderType.H1}
				</option>
				<option value={ElementHeaderType.H2}>
					{ElementHeaderType.H2}
				</option>
				<option value={ElementHeaderType.H3}>
					{ElementHeaderType.H3}
				</option>
			</select>
		</div>
	);
};
