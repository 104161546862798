import React from 'react';
import { ButtonTooltip } from '../../../components/ButtonTooltip';
import { InlineEdit } from '../../../components/InlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { ISpecializationsStep } from './SpecializationsModalSteps';

interface ModalStepsItemProps {
  index: number;
  data: ISpecializationsStep;
  update: (
    fieldsToUpdate: Partial<ISpecializationsStep>,
    index: number
  ) => void;
  deleteItem: (index: number) => void;
}

export const SpecializationsModalStepsItem: React.FC<ModalStepsItemProps> = ({
  index,
  data,
  update,
  deleteItem,
}) => {
  const updateData = (fieldsToUpdate: Partial<ISpecializationsStep>) => {
    update(fieldsToUpdate, index);
  };

  const deleteStep = () => {
    deleteItem(index);
  };
  return (
    <div className="specializations-modal-steps__item">
      <InlineEdit
        className="step_number"
        value={data.title}
        name={'title'}
        setValue={updateData}
        dynamicWidth
      />

      <InlineEdit
        className="heading"
        value={data.heading}
        name="heading"
        setValue={updateData}
        dynamicWidth
      />

      <div className="card-tags">
        <span className="tag">Strategie</span>
      </div>

      <div className="button-delete " onClick={deleteStep}>
        <span className="svg-icon svg-icon-danger svg-icon-2hx">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mh-50px"
          >
            <path
              opacity="0.25"
              d="M6.54184 2.36899C4.34504 2.65912 2.65912 4.34504 2.36899 6.54184C2.16953 8.05208 2 9.94127 2 12C2 14.0587 2.16953 15.9479 2.36899 17.4582C2.65912 19.655 4.34504 21.3409 6.54184 21.631C8.05208 21.8305 9.94127 22 12 22C14.0587 22 15.9479 21.8305 17.4582 21.631C19.655 21.3409 21.3409 19.655 21.631 17.4582C21.8305 15.9479 22 14.0587 22 12C22 9.94127 21.8305 8.05208 21.631 6.54184C21.3409 4.34504 19.655 2.65912 17.4582 2.36899C15.9479 2.16953 14.0587 2 12 2C9.94127 2 8.05208 2.16953 6.54184 2.36899Z"
              fill="#12131A"
            ></path>
            <path
              d="M8 13C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H8Z"
              fill="#12131A"
            ></path>
          </svg>
        </span>
      </div>

      <TextAreaEdit
        name={'description'}
        value={data.description}
        setValue={updateData}
        className="description"
      />

      <ButtonTooltip
        name={`button_url`}
        className="content-action"
        value={data.button_url}
        setValue={updateData}
      >
        <button className="button rounded-pill">
          <InlineEdit
            name="button_title"
            className="px-1 text-center"
            value={data.button_title}
            setValue={updateData}
            activeVerticalPadding={'py-1'}
            dynamicWidth
            style={{
              maxWidth: '200px',
              fontWeight: 600,
            }}
          />
        </button>
      </ButtonTooltip>
    </div>
  );
};
