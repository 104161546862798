import { ElementWrapper } from '../../../components/ElementWrapper';
import { InlineEdit } from '../../../components/InlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { IElement } from '../../../models/Element';
import { AboutUsContentHeading } from './AboutUsContentHeading';

type AboutUsContentHeadingFCProps = {
  index: number;
  data: AboutUsContentHeading;
  updateData: (body: IElement, index: number) => void;
};

export const AboutUsContentHeadingFC = ({
  index,
  data,
  updateData,
}: AboutUsContentHeadingFCProps) => {
  const update = (fieldsToUpdate: Partial<AboutUsContentHeading>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  return (
    <ElementWrapper elementIndex={index}>
      <div className="about-us-content-heading body-my-8">
        <div className="element__content">
          {/* begin::Title */}
          <InlineEdit
            name="title"
            className="content-title"
            value={data.title}
            setValue={update}
            activeVerticalPadding={`py-0`}
          />
          {/* end::Title */}

          {/* begin::Heading */}
          <TextAreaEdit
            name="heading"
            className="content-heading"
            value={data.heading}
            setValue={update}
          />
          {/* end::Heading */}

          {/* begin::Description */}
          <TextAreaEdit
            name="description"
            className="content-description"
            value={data.description}
            setValue={update}
          />
          {/* end::Description */}
        </div>
      </div>
    </ElementWrapper>
  );
};
