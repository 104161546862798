import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ButtonTooltip } from '../../../components/ButtonTooltip';
import { InlineEdit } from '../../../components/InlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { PluginHighlightLeftImageInfo } from './PluginHighlightLeftImageInfo';

type Props = {
  data: PluginHighlightLeftImageInfo;
  update: (fieldsToUpdate: Partial<PluginHighlightLeftImageInfo>) => void;
};

export const PluginHighlightLeftImageInfoContent: React.FC<Props> = ({
  data,
  update,
}) => {
  return (
    <div className="showcase__content">
      <div>
        {/* begin::Heading */}
        <TextAreaEdit
          name="heading"
          className="heading"
          value={data.heading}
          setValue={update}
        />
        {/* end::Heading */}
      </div>

      {/* begin::Description */}
      <CKEditor
        className="content-description"
        editor={ClassicEditor}
        data={data.description}
        // @ts-ignore
        onReady={(editor) => {
          // You can store the "editor" and use when it is needed.
          // console.log('Editor is ready to use!', editor);
        }}
        // @ts-ignore
        onChange={(event, editor) => {
          const editorData = editor.getData();

          update({ description: editorData });
        }}
        // @ts-ignore
        onBlur={(event, editor) => {
          //   console.log('Blur.', editor);
        }}
        // @ts-ignore
        onFocus={(event, editor) => {
          //   console.log('Focus.', editor);
        }}
      />
      {/* end::Description */}

      {/* begin::Actions */}
      <div className="buttons">
        <ButtonTooltip
          name={`button_url`}
          className="content-action__button"
          value={data.button_url}
          setValue={update}
        >
          <button className="btn btn-success rounded-pill">
            <InlineEdit
              name="button_title"
              className="px-1 text-white text-center"
              value={data.button_title}
              setValue={update}
              activeVerticalPadding={'py-1'}
              dynamicWidth
              style={{
                maxWidth: '200px',
              }}
            />
          </button>
        </ButtonTooltip>
      </div>
      {/* end::Actions */}
    </div>
  );
};
