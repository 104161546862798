import { ButtonTooltip } from '../../../components/ButtonTooltip';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { InlineEdit } from '../../../components/InlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { IElement } from '../../../models/Element';
import { AboutUsFeaturedContentOne } from './AboutUsFeaturedContentOne';

type AboutUsFeaturedContentOneFCProps = {
  index: number;
  data: AboutUsFeaturedContentOne;
  updateData: (body: IElement, index: number) => void;
};

export const AboutUsFeaturedContentOneFC = ({
  index,
  data,
  updateData,
}: AboutUsFeaturedContentOneFCProps) => {
  const update = (fieldsToUpdate: Partial<AboutUsFeaturedContentOne>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  return (
    <ElementWrapper elementIndex={index}>
      <div className="about-us-featured-content-one body-my-8">
        {/* begin::Title */}
        <InlineEdit
          name="title"
          className="content-title"
          value={data.title}
          setValue={update}
          activeVerticalPadding={`py-0`}
        />
        {/* end::Title */}

        {/* begin::Description */}
        <TextAreaEdit
          name="description"
          className="content-description"
          value={data.description}
          setValue={update}
        />
        {/* end::Description */}

        <div className="content-action">
          <ButtonTooltip
            name={`button_url`}
            className="content-action__button"
            value={data.button_url}
            setValue={update}
          >
            <button className="btn btn-success rounded-pill">
              <InlineEdit
                name="button_title"
                className="px-1 text-white text-center"
                value={data.button_title}
                setValue={update}
                activeVerticalPadding={'py-1'}
                dynamicWidth
                style={{
                  maxWidth: '22rem',
                }}
              />
            </button>
          </ButtonTooltip>
        </div>
      </div>
    </ElementWrapper>
  );
};
