import axios from 'axios';
import { API_URL } from '../../../support/api';
import { PaginatedValue } from '../../../support/utils';
import { IEmployee } from '../pages/employees/Employee';

const EMPLOYEE_URL = `${API_URL}/v1/management/employee-management/employees`;

export const createEmployee = async (payload: IEmployee) => {
  const response = await axios.post<{ value: IEmployee }>(
    `${EMPLOYEE_URL}/create`,
    payload
  );

  return response.data.value;
};

export const viewEmployee = async (id: string | number) => {
  const response = await axios.get<{ value: IEmployee }>(
    `${EMPLOYEE_URL}/${id}/get`
  );

  return response.data.value;
};

export const updateEmployee = async (payload: IEmployee) => {
  const response = await axios.post<{ value: IEmployee }>(
    `${EMPLOYEE_URL}/${payload.id}/update`,
    payload
  );

  return response.data.value;
};

export const deleteEmployee = async (payload: IEmployee) => {
  const response = await axios.post<{ value: IEmployee }>(
    `${EMPLOYEE_URL}/${payload.id}/delete`,
    payload
  );

  return response.data.value;
};

export const listEmployee = async (search = '', direction = 'asc') => {
  const URL = `${EMPLOYEE_URL}/list?search=${search}&direction=${direction}`;
  const response = await axios.get<{ value: PaginatedValue<IEmployee> }>(URL);

  return response.data.value;
};

export const getAllEmployee = async () => {
  const response = await axios.get<{ value: Array<IEmployee> }>(
    `${EMPLOYEE_URL}/get-all`
  );

  return response.data.value;
};

export const transferEmployee = async (
  payload: IEmployee & { password: string; transfer_to: number }
) => {
  const {
    data: { value },
  } = await axios.post<{ value: IEmployee }>(
    `${EMPLOYEE_URL}/${payload.id}/transfer-ownership`,
    payload
  );

  return value;
};
