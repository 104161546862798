/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import ReactHtmlParser from "react-html-parser";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../setup";

import { ElementWrapper } from "../../../components/ElementWrapper";
import { IElement } from "../../../models/Element";
import { setAuthor } from "../../../redux/EditContentActions";
import { BlogAuthorFooter } from "./BlogAuthorFooter";
import { ButtonTooltip } from "../../../components/ButtonTooltip";
import { InlineEdit } from "../../../components/InlineEdit";
import { SelectAuthorWrapper } from "../components/SelectAuthorWrapper";
import { toAbsoluteUrl } from "../../../../../../_start/helpers";
import { defaultAuthorPlaceholder, IEmployee } from "../../../../employee-management/pages/employees/Employee";

type BlogAuthorFooterFCProps = {
	index: number;
	data: BlogAuthorFooter;
	updateData: (body: IElement, index: number) => void;
};

export const BlogAuthorFooterFC = ({
	index,
	data,
	updateData,
}: BlogAuthorFooterFCProps) => {
	const dispatch = useDispatch();

	const update = (fieldsToUpdate: Partial<BlogAuthorFooter>) => {
		const updatedData = { ...data, ...fieldsToUpdate };
		updateData(updatedData, index);
	};

	const author = useSelector(
		(state: RootState) => state.editContent.post.author
	);

	useEffect(() => {
		if (author !== null) {
			update({ author });
		} else {
			update({ author: defaultAuthorPlaceholder });
		}
	}, [author]);

	const updateAuthor = (selected: IEmployee) => {
		update({ author: selected });
		dispatch(setAuthor(selected));
	};

	const [isActive, setIsActive] = useState(false);

	return (
		<ElementWrapper elementIndex={index}>
			<div className={`blog-author-footer my-10`}>
				<div className="blog-content">
					<div className="blog-content__text">
						<div
							className={`text-editor__container ${
								isActive ? "" : "d-none"
							}`}
						>
							<div
								style={{
									marginLeft: "3rem",
									width: "0",
									height: "0",
									borderLeft: "12px solid transparent",
									borderRight: "12px solid transparent",
									borderBottom: "12px solid white",
								}}
							></div>

							<div className="container-editor bg-light">
								<CKEditor
									editor={ClassicEditor}
									data={data.content}
									// @ts-ignore
									onReady={(editor) => {
										// You can store the "editor" and use when it is needed.
										// console.log('Editor is ready to use!', editor);
									}}
									// @ts-ignore
									onChange={(event, editor) => {
										const editorData = editor.getData();

										update({ content: editorData });
									}}
									// @ts-ignore
									onBlur={(event, editor) => {
										//   console.log('Blur.', editor);
										// setIsActive(false);
									}}
									// @ts-ignore
									onFocus={(event, editor) => {
										//   console.log('Focus.', editor);
									}}
								/>
							</div>
						</div>

						<div
							onClick={() => setIsActive(true)}
							className="text-value"
						>
							{ReactHtmlParser(data.content)}
						</div>

						{/* <TextAreaEdit
              name="content"
              className="w-100"
              value={data.content}
              setValue={update}
            /> */}
					</div>

					<ButtonTooltip
						name={`button_url`}
						className="blog-content__action"
						value={data.button_url}
						setValue={update}
					>
						<a>
							<InlineEdit
								className="text-center"
								name="button_title"
								value={data.button_title}
								setValue={update}
								activeVerticalPadding={"py-1"}
								dynamicWidth
								style={{
									maxWidth: "22rem",
								}}
							/>
						</a>
					</ButtonTooltip>

					<div className="blog-content__avatar">
						<SelectAuthorWrapper
							author={data.author}
							updateAuthor={updateAuthor}
						>
							{data.author.profile_image ? (
								<label className="m-6 symbol symbol-100px symbol-circle">
									<img
										src={data.author.profile_image}
										alt=""
									/>
								</label>
							) : (
								<div className="m-6 symbol symbol-100px symbol-circle">
									<img
										src={toAbsoluteUrl(
											"/assets/images/250x250.png"
										)}
										alt=""
										style={{
											width: "100%",
											objectFit: "cover",
										}}
									/>
								</div>
							)}
						</SelectAuthorWrapper>
					</div>
				</div>
			</div>
		</ElementWrapper>
	);
};
