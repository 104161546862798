import React from "react";
import { KTSVG } from "../../../../../_start/helpers";
import { IEmployee } from "./Employee";

type TransferOwnershipModalProps = {
	employee: IEmployee;
	handleTransfer: (employee: IEmployee) => void;
};
export const TransferOwnershipModal: React.FC<TransferOwnershipModalProps> = ({
	employee,
	handleTransfer,
	children,
}) => {
	return (
		<div className="modal fade" tabIndex={-1} id="transfer_ownership_modal">
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">{`Transfer Ownership`}</h5>
						<div
							className="btn btn-icon btn-sm btn-active-light-primary ms-2"
							data-bs-dismiss="modal"
							aria-label="Close"
						>
							<KTSVG
								path="/media/icons/duotone/Navigation/Close.svg"
								className="svg-icon svg-icon-2x"
							/>
						</div>
					</div>
					<div className="modal-body">{children}</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-light"
							data-bs-dismiss="modal"
						>
							Close
						</button>
						<button
							onClick={() => handleTransfer(employee)}
							type="button"
							className="btn btn-warning"
							data-bs-dismiss="modal"
						>
							Confirm
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
