/* eslint-disable react-hooks/exhaustive-deps */
import { format } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Select, { MultiValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { convertToSlug } from '../../../../support/helpers';
import {
  customFailureToast,
  failureToast,
  successToast,
} from '../../../../support/utils';
import {
  defaultRobotTypeOption,
  defaultSEOTitleTypeOption,
  RobotTypeOption,
  SEOTitleTypeOption,
} from '../../../post-management/pages/Post/CreatePost';
import { defaultCaseDetail, ICaseDetail } from '../../models/CaseDetail';
import { createCaseDetail } from '../../redux/CaseDetailCRUD';

import {
  PostStatusType,
  RobotType,
  SchemaArticleType,
  SchemaPageType,
} from '../../../post-management/models/Post';
import { FormImageField } from '../../../media-management/components/form-image/FormImageField';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../setup';
import { ITag } from '../../../post-management/models/Tag';
import { getAllTag } from '../../../post-management/redux/TagsCRUD';
import { ICategory } from '../../../post-management/models/Category';
import { getAllCategory } from '../../redux/CaseCategoryCRUD';

export const CreateCaseDetail = () => {
  const history = useHistory();

  const employee = useSelector((state: RootState) => state.auth.user?.employee);

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<ICaseDetail>(defaultCaseDetail);

  const updateData = (fieldsToUpdate: Partial<ICaseDetail>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  // for removing unnecessary re-render
  // caused by calling the same api/s during initilization
  const [initialized, setInitialized] = useState(false);

  const [categories, setCategories] = useState<Array<ICategory>>([]);
  const [selectedCategory, setSelectedCategory] = useState<ICategory | null>(
    null
  );

  const [tags, setTags] = useState<Array<ITag>>([]);
  const [selectedTags, setSelectedTags] = useState<MultiValue<ITag>>([]);

  const seoTitles: Array<SEOTitleTypeOption> = defaultSEOTitleTypeOption;
  const [selectedSeoTitles, setSelectedSeoTitles] = useState<
    MultiValue<SEOTitleTypeOption>
  >(defaultSEOTitleTypeOption);

  const robots: Array<RobotTypeOption> = defaultRobotTypeOption;
  const [selectedRobots, setSelectedRobots] = useState<
    MultiValue<RobotTypeOption>
  >([
    { value: RobotType.INDEX, label: RobotType.INDEX },
    { value: RobotType.FOLLOW, label: RobotType.FOLLOW },
  ]);

  const handleInitialization = async () => {
    setLoading(true);

    try {
      const [categories, tags] = await Promise.all([
        getAllCategory(),
        getAllTag(),
      ]);

      setCategories(categories);
      setTags(tags);

      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!initialized) {
      handleInitialization();
      setInitialized(true);
    }
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);

    if (!selectedTags.length) {
      customFailureToast({
        title: 'The given data is invalid',
        message: 'Tag/s is required',
      });
      setLoading(false);
      return;
    }

    try {
      const payload = {
        ...data,
        publish_date: format(data.publish_date as Date, 'yyyy-MM-dd HH:mm:ss'),
        author_id: employee?.id ?? 0,
        category_id: selectedCategory?.id!,
        // @ts-ignore
        tags: selectedTags.map((tag) => tag.id),
        seo_title: selectedSeoTitles.map((seoTitle) => seoTitle.value),
        robots: selectedRobots.map((robot) => robot.value),
      };

      // @ts-ignore
      await createCaseDetail(payload);
      successToast('Case detail has been created.');
      setLoading(false);
      setData(defaultCaseDetail);
      history.goBack();
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  const formSubmitButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Create Case Detail</h3>
        <div className="card-toolbar gap-5">
          <Link
            className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3"
            to={`/page-management/case-details`}
          >
            Cancel
          </Link>
          <button
            type="button"
            onClick={() => formSubmitButtonRef.current?.click()}
            disabled={loading}
            className="btn btn-primary fw-bolder px-6 py-3"
          >
            {!loading && <span className="indicator-label">Save</span>}
            {loading && (
              <span className="indicator-progress" style={{ display: 'block' }}>
                Please wait...{' '}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </div>
      {/* begin::Form */}
      <form onSubmit={handleSubmit} className="form d-flex flex-center">
        <div className="card-body mw-800px py-20">
          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Title</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter title"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.title}
                  onChange={(e) =>
                    updateData({
                      title: e.target.value,
                      slug: convertToSlug(e.target.value),
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Slug</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter slug"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.slug}
                  onChange={(e) => updateData({ slug: e.target.value })}
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Status</label>
            <div className="col-lg-9">
              <select
                className="form-select form-select-lg form-select-solid text-capitalize"
                data-control="select2"
                data-placeholder="Select Status..."
                value={data.status}
                onChange={(e) =>
                  updateData({
                    status: e.target.value as PostStatusType,
                  })
                }
              >
                <option value={PostStatusType.DRAFT}>Draft</option>
                <option value={PostStatusType.PUBLISHED}>Published</option>
                <option value={PostStatusType.FUTURE}>Future</option>
              </select>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Publish Date</label>
            <div className="col-lg-9">
              <DatePicker
                selected={data.publish_date as Date}
                onChange={(date: Date) => updateData({ publish_date: date })}
                showTimeSelect
                timeFormat="HH:mm"
                dateFormat="MMMM d, yyyy HH:mm"
                className="w-100 form-control form-control-lg form-control-solid"
              />
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Category</label>
            <div className="col-lg-9">
              <Select
                placeholder={`Select category...`}
                value={selectedCategory}
                onChange={setSelectedCategory}
                getOptionValue={(model: ICategory) => model.id.toString()}
                getOptionLabel={(model: ICategory) => model.name}
                options={categories}
                isSearchable
              />
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Tags</label>
            <div className="col-lg-9">
              <Select
                closeMenuOnSelect={false}
                placeholder={`Select tags...`}
                value={selectedTags}
                onChange={setSelectedTags}
                getOptionValue={(model: ITag) => model.id.toString()}
                getOptionLabel={(model: ITag) => model.name}
                options={tags}
                isMulti
                isSearchable
                isClearable
              />
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-20">
            <label className="col-lg-3 col-form-label">Image Preview</label>
            <div className="col-lg-9">
              <FormImageField
                name={`image_preview`}
                altText={`image_preview_alt_text`}
                src={data.image_preview}
                update={updateData}
                modalId={`image_preview_modal`}
              />
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">
              Image Preview Alt Attribute
            </label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter alt attribute"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.image_preview_alt_text}
                  onChange={(e) =>
                    updateData({
                      image_preview_alt_text: e.target.value,
                    })
                  }
                  required={data.image_preview !== ''}
                  disabled={data.image_preview === ''}
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Description</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <textarea
                  rows={2}
                  placeholder="Enter description"
                  className="form-control form-control-lg form-control-solid"
                  value={data.description}
                  onChange={(e) =>
                    updateData({
                      description: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          <div className={`separator my-10`}></div>

          <div className="row mb-8">
            <div className="col-lg-12 col-form-label text-center">SEO</div>
          </div>

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">SEO Title</label>
            <div className="col-lg-9">
              <CreatableSelect
                placeholder={`Select SEO title/s ...`}
                value={selectedSeoTitles}
                onChange={setSelectedSeoTitles}
                options={seoTitles}
                isMulti
                isSearchable
                isClearable
              />
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Robots</label>
            <div className="col-lg-9">
              <Select
                closeMenuOnSelect={false}
                placeholder={`Select robot/s ...`}
                value={selectedRobots}
                onChange={setSelectedRobots}
                options={robots}
                isMulti
                isSearchable
                isClearable
              />
            </div>
          </div>
          {/* end::Form row */}

          <div className={`separator my-10`}></div>

          <div className="row mb-8">
            <div className="col-lg-12 col-form-label text-center">SCHEMA</div>
          </div>

          {/* begin::Form row */}
          <div className={`row mb-8`}>
            <label className="col-lg-3 col-form-label">Page Type</label>
            <div className="col-lg-9">
              <select
                className="form-select form-select-lg form-select-solid"
                data-control="select2"
                data-placeholder="Select Package..."
                value={data.schema_page_type}
                onChange={(e) =>
                  updateData({
                    schema_page_type: e.target.value as SchemaPageType,
                  })
                }
              >
                <option value={SchemaPageType.WEB_PAGE}>
                  {SchemaPageType.WEB_PAGE}
                </option>
                <option value={SchemaPageType.ITEM_PAGE}>
                  {SchemaPageType.ITEM_PAGE}
                </option>
                <option value={SchemaPageType.ABOUT_PAGE}>
                  {SchemaPageType.ABOUT_PAGE}
                </option>
                <option value={SchemaPageType.FAQ_PAGE}>
                  {SchemaPageType.FAQ_PAGE}
                </option>
                <option value={SchemaPageType.QA_PAGE}>
                  {SchemaPageType.QA_PAGE}
                </option>
                <option value={SchemaPageType.PROFILE_PAGE}>
                  {SchemaPageType.PROFILE_PAGE}
                </option>
                <option value={SchemaPageType.CONTACT_PAGE}>
                  {SchemaPageType.CONTACT_PAGE}
                </option>
                <option value={SchemaPageType.MEDICAL_WEB_PAGE}>
                  {SchemaPageType.MEDICAL_WEB_PAGE}
                </option>
                <option value={SchemaPageType.COLLECTION_PAGE}>
                  {SchemaPageType.COLLECTION_PAGE}
                </option>
                <option value={SchemaPageType.CHECKOUT_PAGE}>
                  {SchemaPageType.CHECKOUT_PAGE}
                </option>
                <option value={SchemaPageType.REAL_ESTATE_LISTING}>
                  {SchemaPageType.REAL_ESTATE_LISTING}
                </option>
                <option value={SchemaPageType.SEARCH_RESULT_PAGE}>
                  {SchemaPageType.SEARCH_RESULT_PAGE}
                </option>
              </select>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className={`row mb-8`}>
            <label className="col-lg-3 col-form-label">Article Type</label>
            <div className="col-lg-9">
              <select
                className="form-select form-select-lg form-select-solid"
                data-control="select2"
                data-placeholder="Select Package..."
                value={data.schema_article_type}
                onChange={(e) =>
                  updateData({
                    schema_article_type: e.target.value as SchemaArticleType,
                  })
                }
              >
                <option value={SchemaArticleType.ARTICLE}>
                  {SchemaArticleType.ARTICLE}
                </option>
                <option value={SchemaArticleType.BLOG_POST}>
                  {SchemaArticleType.BLOG_POST}
                </option>
                <option value={SchemaArticleType.SOCIAL_MEDIA_POSTING}>
                  {SchemaArticleType.SOCIAL_MEDIA_POSTING}
                </option>
                <option value={SchemaArticleType.NEWS_ARTICLE}>
                  {SchemaArticleType.NEWS_ARTICLE}
                </option>
                <option value={SchemaArticleType.ADVISER_CONTENT_ARTICLE}>
                  {SchemaArticleType.ADVISER_CONTENT_ARTICLE}
                </option>
                <option value={SchemaArticleType.SATIRICAL_ARTICLE}>
                  {SchemaArticleType.SATIRICAL_ARTICLE}
                </option>
                <option value={SchemaArticleType.SCHOLARLY_ARTICLE}>
                  {SchemaArticleType.SCHOLARLY_ARTICLE}
                </option>
                <option value={SchemaArticleType.TECH_ARTICLE}>
                  {SchemaArticleType.TECH_ARTICLE}
                </option>
                <option value={SchemaArticleType.REPORT}>
                  {SchemaArticleType.REPORT}
                </option>
                <option value={SchemaArticleType.NONE}>
                  {SchemaArticleType.NONE}
                </option>
              </select>
            </div>
          </div>
          {/* end::Form row */}

          <div className={`separator my-10`}></div>

          <div className="row mb-8">
            <div className="col-lg-12 col-form-label text-center">
              Social - Facebook
            </div>
          </div>

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Facebook Title</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter facebook title"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.facebook_title}
                  onChange={(e) =>
                    updateData({
                      facebook_title: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">
              Facebook Description
            </label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <textarea
                  rows={2}
                  placeholder="Enter facebook description"
                  className="form-control form-control-lg form-control-solid"
                  value={data.facebook_description}
                  onChange={(e) =>
                    updateData({
                      facebook_description: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-20">
            <label className="col-lg-3 col-form-label">Facebook Image</label>
            <div className="col-lg-9">
              <FormImageField
                name={`facebook_image`}
                src={data.facebook_image}
                update={updateData}
                modalId={`facebook_image_modal`}
              />
            </div>
          </div>
          {/* end::Form row */}

          <div className={`separator my-10`}></div>

          <div className="row mb-8">
            <div className="col-lg-12 col-form-label text-center">
              Social - Twitter
            </div>
          </div>

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Twitter Title</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter twitter title"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.twitter_title}
                  onChange={(e) =>
                    updateData({
                      twitter_title: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">
              Twitter Description
            </label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <textarea
                  rows={2}
                  placeholder="Enter twitter description"
                  className="form-control form-control-lg form-control-solid"
                  value={data.twitter_description}
                  onChange={(e) =>
                    updateData({
                      twitter_description: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-20">
            <label className="col-lg-3 col-form-label">Twitter Image</label>
            <div className="col-lg-9">
              <FormImageField
                name={`twitter_image`}
                src={data.twitter_image}
                update={updateData}
                modalId={`twitter_image_modal`}
              />
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row">
            <label className="col-lg-3 col-form-label"></label>
            <div className="col-lg-9">
              <button
                ref={formSubmitButtonRef}
                type="submit"
                disabled={loading}
                className="btn btn-primary fw-bolder px-6 py-3 me-3"
              >
                {!loading && <span className="indicator-label">Save</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Please wait...{' '}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
              <Link
                className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3"
                to={`/page-management/case-details`}
              >
                Cancel
              </Link>
            </div>
          </div>
          {/* end::Form row */}
        </div>
      </form>
      {/* end::Form */}
    </div>
  );
};
