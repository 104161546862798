/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { KTSVG } from '../../../../../_start/helpers';
import { ActionsDropdown } from '../../../../components/dropdown/ActionsDropdown';
import { Pagination } from '../../../../components/Pagination';
import { useDebounce } from '../../../../hooks/useDebounce';
import {
  failureToast,
  handlePaginate,
  PaginatedValue,
} from '../../../../support/utils';
import { listPermissions } from '../../redux/PermissionCRUD';
import { IPermission } from './Permission';

export const PermissionsPage = () => {
  const [loading, setLoading] = useState(false);
  const [paginatedValue, setPaginatedValue] = useState<
    PaginatedValue<IPermission>
  >(new PaginatedValue());

  const handlePaginateState = async (page_url: string) => {
    setLoading(true);
    try {
      const result = await handlePaginate<IPermission>(page_url);
      setPaginatedValue(result);
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  const handleFetchList = async () => {
    setLoading(true);
    try {
      const result = await listPermissions();
      setPaginatedValue(result);
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetchList();
  }, []);

  const [search, setSearch] = useState('');

  const debouncedSearch = useDebounce<string>(search, 750);

  const handleSearch = async () => {
    setLoading(true);
    try {
      const result = await listPermissions(search);
      setPaginatedValue(result);
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleSearch();
  }, [debouncedSearch]);

  return (
    <div
      className={`card card-custom card-flush ${
        loading ? 'overlay overlay-block' : ''
      }`}
    >
      <div className="pt-8 card-header">
        <div className="d-flex flex-center border py-1 px-4 bg-white rounded">
          <KTSVG
            path="/media/icons/duotone/General/Search.svg"
            className="svg-icon-1 svg-icon-primary"
          />
          <input
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            type="text"
            className={`form-control border-0 fw-bold ps-2 ${
              '' ? 'w-xxl-600px' : 'w-xxl-350px'
            }`}
            placeholder="Search"
          />
        </div>
      </div>
      <div className={`card-body py-5 ${loading ? 'overlay-wrapper' : ''}`}>
        <table className="table table-row-gray-300 gy-7">
          <thead>
            <tr className="fw-bolder fs-6 text-gray-800">
              <th>Name</th>
              <th>Description</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {paginatedValue.data.map((permission, idx) => (
              <tr key={idx}>
                <td>{permission.name}</td>
                <td>{permission.description}</td>
                <td className="text-end">
                  <ActionsDropdown>
                    <div className="menu-item px-3">
                      <Link
                        to={`permissions/${permission.id}/update`}
                        className="menu-link px-3"
                      >
                        Update
                      </Link>
                    </div>
                    <div className="separator mt-3 opacity-75"></div>
                  </ActionsDropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {loading && (
          <div className="overlay-layer rounded bg-dark bg-opacity-5">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
      </div>
      <div className="card-footer">
        <Pagination
          loading={loading}
          pagination={paginatedValue}
          handlePaginateState={handlePaginateState}
        />
      </div>
    </div>
  );
};
