import axios from 'axios';
import { API_URL } from '../../../support/api';
import { PaginatedValue } from '../../../support/utils';
import { IPage } from '../models/Page';

export const BASE_PAGE_URL = `${API_URL}/v1/management/page-management/pages`;
export const GET_ALL_PAGE_URL = `${API_URL}/v1/management/page-management/pages/get-all`;
export const CREATE_PAGE_URL = `${API_URL}/v1/management/page-management/pages/create`;

export type CreatePagePayload = IPage & {
  page_featured_posts: Array<number>;
  page_featured_case_details: Array<number>;
};

export type UpdatePagePayload = CreatePagePayload;

export const listPage = async (search = '', direction = 'asc') => {
  const URL = `${BASE_PAGE_URL}/list?search=${search}&direction=${direction}`;
  const {
    data: { value },
  } = await axios.get<{ value: PaginatedValue<IPage> }>(URL);

  return value;
};

export const createPage = async (payload: CreatePagePayload) => {
  const {
    data: { value },
  } = await axios.post<{ value: IPage }>(CREATE_PAGE_URL, payload);

  return value;
};

export const viewPage = async (id: number | string) => {
  const {
    data: { value },
  } = await axios.get<{ value: IPage }>(`${BASE_PAGE_URL}/${id}/get`);

  return value;
};

export const updatePage = async (payload: UpdatePagePayload) => {
  const {
    data: { value },
  } = await axios.post<{ value: IPage }>(
    `${BASE_PAGE_URL}/${payload.id}/update`,
    payload
  );

  return value;
};

export const deletePage = async (payload: IPage) => {
  const {
    data: { value },
  } = await axios.post<{ value: IPage }>(
    `${BASE_PAGE_URL}/${payload.id}/delete`,
    payload
  );

  return value;
};

// unpaginated categories
export const getAllPage = async () => {
  const {
    data: { value },
  } = await axios.get<{ value: Array<IPage> }>(GET_ALL_PAGE_URL);

  return value;
};

export const getAllParentPages = async () => {
  const {
    data: { value },
  } = await axios.get<{ value: Array<IPage> }>(
    `${GET_ALL_PAGE_URL}?is_parent=true`
  );

  return value;
};

export const publishPage = async (payload: IPage) => {
  const {
    data: { value },
  } = await axios.post<{ value: IPage }>(
    `${BASE_PAGE_URL}/${payload.id}/publish`,
    payload
  );

  return value;
};
