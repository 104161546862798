export enum CustomerEvents {
  CREATE_CUSTOMER_REQUESTED = '[Request Create Customer] Action',
  CREATE_CUSTOMER_LOADED = '[Load Created Customer] API',
  CREATE_CUSTOMER_FAILED = '[Create Customer Failed] API',

  VIEW_CUSTOMER_REQUESTED = '[Request View Customer] Action',
  VIEW_CUSTOMER_LOADED = '[Load Viewed Customer] API',
  VIEW_CUSTOMER_FAILED = '[View Customer Failed] API',

  UPDATE_CUSTOMER_REQUESTED = '[Request Update Customer] Action',
  UPDATE_CUSTOMER_LOADED = '[Load Updated Customer] API',
  UPDATE_CUSTOMER_FAILED = '[Update Customer Failed] API',

  DELETE_CUSTOMER_REQUESTED = '[Request Delete Customer] Action',
  DELETE_CUSTOMER_LOADED = '[Load Deleted Customer] API',
  DELETE_CUSTOMER_FAILED = '[Delete Customer Failed] API',

  LIST_CUSTOMER_REQUESTED = '[Request List Customer] Action',
  LIST_CUSTOMER_LOADED = '[Load List Customer] API',
  LIST_CUSTOMER_FAILED = '[List Customer Failed] API',

  RESET_CREATE_CUSTOMER = '[Reset Create Customer] Action',

  SEARCH_CUSTOMER_REQUESTED = '[Request Search Customer] Action',
  SEARCH_CUSTOMER_LOADED = '[Load Search Customer] API',
  SEARCH_CUSTOMER_FAILED = '[Search Customer Failed] API',
}
