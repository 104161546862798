import { ElementType, IElement } from '../../../models/Element';

export interface IPartner {
  id: number;
  image: string;
  image_alt: string;
}

export type PluginPartners = IElement & {
  heading: string;
  partners: Array<IPartner>;
};

export const defaultPartner: IPartner = {
  id: 0,
  image: '',
  image_alt: '',
};

export const defaultPluginPartners: PluginPartners = {
  id: 0,
  name: ElementType.PLUGIN_PARTNERS,
  heading: 'Deze bedrijven gingen u voor',
  partners: [defaultPartner],
};
