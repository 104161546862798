export enum ModuleType {
  GENERIC = 'generic',
  PLUGIN = 'plugin',
  THEME = 'theme',
}

export interface IModule {
  id: number;
  title: string;
  description: string;
  type: ModuleType;
  release_id: number;
  feature_request_id: number;
}

export const defaultModule: IModule = {
  id: 0,
  title: '',
  description: '',
  type: ModuleType.GENERIC,
  release_id: 0,
  feature_request_id: 0,
};
