/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTSVG, toAbsoluteUrl } from '../../../../../../_start/helpers';
import { FormImageFieldModal } from '../../../../media-management/components/form-image/FormImageFieldModal';
import { ICaseDetail } from '../../../../page-management/models/CaseDetail';
import { HomeFeaturedProjects } from './HomeFeaturedProjects';

type FeaturedCaseDualPreviewProps = {
  idx: number;
  data: HomeFeaturedProjects;
  caseDetail: ICaseDetail;
  updateFeatured: (idx: number, caseDetail: ICaseDetail) => void;
  removeFeatured: (idx: number) => void;
};

export const FeaturedCaseDualPreview = ({
  idx,
  data,
  caseDetail,
  updateFeatured,
  removeFeatured,
}: FeaturedCaseDualPreviewProps) => {
  const update = (fieldsToUpdate: Partial<ICaseDetail>) => {
    const updatedData = { ...caseDetail, ...fieldsToUpdate };
    updateFeatured(idx, updatedData);
  };

  return (
    <div className="featured-case">
      <span
        onClick={() => removeFeatured(idx)}
        className="position-absolute z-index-3 top-0 end-0 me-2 mt-2 opacity-75-hover"
      >
        <KTSVG
          path="/media/icons/duotone/Interface/Minus-Square.svg"
          className="svg-icon-danger svg-icon-1"
        />
      </span>

      <FormImageFieldModal
        modalId={`${data.name}-${data.id}-dual-preview-${idx}`}
        name={`mobile_preview`}
        update={update}
      />

      {/* begin::Case Preview Image */}
      <div className="figure-with-overlay">
        {
          <>
            {caseDetail.image_preview ? (
              <>
                <img
                  className="figure-with-overlay__base-image"
                  src={caseDetail.image_preview}
                  style={{
                    width: '100%',
                    height: '500px',
                    objectFit: 'cover',
                  }}
                  alt=""
                />

                {caseDetail.mobile_preview !== undefined &&
                caseDetail.mobile_preview !== '' ? (
                  <img
                    className="figure-with-overlay__overlay-image"
                    src={caseDetail.mobile_preview}
                    style={{
                      width: '100%',
                      height: '300px',
                      objectFit: 'cover',
                    }}
                    alt=""
                    data-bs-toggle="modal"
                    data-bs-target={`#${data.name}-${data.id}-dual-preview-${idx}`}
                  />
                ) : (
                  <img
                    className="figure-with-overlay__overlay-image"
                    src={toAbsoluteUrl('/assets/images/185x327.png')}
                    style={{
                      width: '100%',
                      height: '300px',
                      objectFit: 'cover',
                    }}
                    alt=""
                    data-bs-toggle="modal"
                    data-bs-target={`#${data.name}-${data.id}-dual-preview-${idx}`}
                  />
                )}
              </>
            ) : (
              <>
                <img
                  className="figure-with-overlay__base-image"
                  src={toAbsoluteUrl('/assets/images/400x530.png')}
                  style={{
                    width: '100%',
                    height: '500px',
                    objectFit: 'cover',
                  }}
                  alt=""
                />

                {caseDetail.mobile_preview !== undefined &&
                caseDetail.mobile_preview !== '' ? (
                  <img
                    className="figure-with-overlay__overlay-image"
                    src={caseDetail.mobile_preview}
                    style={{
                      width: '100%',
                      height: '300px',
                      objectFit: 'cover',
                    }}
                    alt=""
                  />
                ) : (
                  <img
                    className="figure-with-overlay__overlay-image"
                    src={toAbsoluteUrl('/assets/images/185x327.png')}
                    style={{
                      width: '100%',
                      height: '300px',
                      objectFit: 'cover',
                    }}
                    alt=""
                  />
                )}
              </>
            )}
          </>
        }
      </div>
      {/* end::Case Preview Image */}

      <span className="case-title">{caseDetail.title}</span>
      <p className="case-description">{caseDetail.description}</p>

      <div className="case-tags-list">
        {caseDetail.tags.map((tag, idx) => {
          if (idx > 2) return null;

          return (
            <a
              key={idx}
              className="case-tags-list__item back-green1"
              style={{
                color: 'white',
                backgroundColor: tag.color,
              }}
            >
              <span>{tag.name}</span>
            </a>
          );
        })}
      </div>
    </div>
  );
};
