/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { KTSVG } from "../../../../../_start/helpers";
import { ActionsDropdown } from "../../../../components/dropdown/ActionsDropdown";
import { DeleteModal } from "../../../../components/modals/DeleteModal";
import { Pagination } from "../../../../components/Pagination";
import { useDebounce } from "../../../../hooks/useDebounce";
import {
	failureToast,
	handlePaginate,
	PaginatedValue,
	successToast,
} from "../../../../support/utils";
import { defaultCategory, ICategory } from "../../../post-management/models/Category";
import { deleteCategory, listCategory } from "../../redux/CaseCategoryCRUD";

export const CaseCategoriesPage = () => {
	const [loading, setLoading] = useState(false);

	const [initialized, setInitialized] = useState(false);

	/**
	 * SEARCH
	 */
	const [search, setSearch] = useState("");
	const debouncedSearch = useDebounce<string>(search, 500);

	const handleSearch = async () => {
		setLoading(true);
		try {
			const result = await listCategory(search);
			setPaginatedValue(result);
			setLoading(false);
		} catch (error) {
			failureToast(error);
			setLoading(false);
		}
	};

	useEffect(() => {
		if (initialized) {
			handleSearch();
		}
	}, [debouncedSearch]);

	/**
	 * DELETE
	 */
	const [selected, setSelected] = useState<ICategory>(defaultCategory);

	const handleDelete = async (item: ICategory) => {
		setLoading(true);
		try {
			await deleteCategory(item);
			successToast("Case category has been deleted.");
			setLoading(false);
			handleFetchList();
		} catch (error) {
			failureToast(error);
			setLoading(false);
		}
	};

	/**
	 * PAGINATION
	 */
	const [paginatedValue, setPaginatedValue] = useState<
		PaginatedValue<ICategory>
	>(new PaginatedValue());

	const handlePaginateState = async (page_url: string) => {
		setLoading(true);
		try {
			const result = await handlePaginate<ICategory>(page_url);
			setPaginatedValue(result);
			setLoading(false);
		} catch (error) {
			failureToast(error);
			setLoading(false);
		}
	};

	/**
	 * INITIALIZATION
	 */
	const handleFetchList = async () => {
		setLoading(true);
		try {
			const result = await listCategory();
			setPaginatedValue(result);
			setLoading(false);
		} catch (error) {
			failureToast(error);
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!initialized) {
			handleFetchList();
			setInitialized(true);
		}
	}, []);

	return (
		<>
			<DeleteModal
				headerTitle={`Delete Case Category`}
				item={selected}
				handleDelete={handleDelete}
			/>
			<div
				className={`card card-custom card-flush ${
					loading ? "overlay overlay-block" : ""
				}`}
			>
				<div className="pt-8 card-header">
					<div className="d-flex flex-center border py-1 px-4 bg-white rounded">
						<KTSVG
							path="/media/icons/duotone/General/Search.svg"
							className="svg-icon-1 svg-icon-primary"
						/>
						<input
							value={search}
							onChange={(event) => setSearch(event.target.value)}
							type="text"
							className={`form-control border-0 fw-bold ps-2 ${
								"" ? "w-xxl-600px" : "w-xxl-350px"
							}`}
							placeholder="Zoeken"
						/>
					</div>
					<div className="card-toolbar">
						<Link
							className="btn btn-sm btn-light"
							to={`case-categories/create`}
						>
							Create Case Category
						</Link>
					</div>
				</div>
				<div
					className={`card-body py-5 ${
						loading ? "overlay-wrapper" : ""
					}`}
				>
					<table className="table table-row-gray-300 gy-7">
						<thead>
							<tr className="fw-bolder fs-6 text-gray-800">
								<th>Name</th>
								<th>Slug</th>
								<th>Description</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{paginatedValue.data.map((element, idx) => (
								<tr key={idx} className="align-middle">
									<td>{element.name}</td>
									<td>{element.slug}</td>
									<td>{element.description}</td>
									<td className="text-end">
										{/* begin::Dropdown */}
										<ActionsDropdown>
											<div className="menu-item px-3">
												<Link
													to={`case-categories/${element.id}/update`}
													className="menu-link px-3"
												>
													Update
												</Link>
											</div>
											<div className="menu-item px-3">
												<a
													onClick={() =>
														setSelected(element)
													}
													href="#"
													className="menu-link px-3 text-danger"
													data-bs-toggle="modal"
													data-bs-target="#custom_delete_kt_modal_1"
												>
													Delete
												</a>
											</div>
											<div className="separator mt-3 opacity-75"></div>
										</ActionsDropdown>
										{/* end::Dropdown */}
									</td>
								</tr>
							))}
						</tbody>
					</table>
					{loading && (
						<div className="overlay-layer rounded bg-dark bg-opacity-5">
							<div
								className="spinner-border text-primary"
								role="status"
							>
								<span className="visually-hidden">
									Loading...
								</span>
							</div>
						</div>
					)}
				</div>
				<div className="card-footer">
					<Pagination
						loading={loading}
						pagination={paginatedValue}
						handlePaginateState={handlePaginateState}
					/>
				</div>
			</div>
		</>
	);
};
