/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { KTSVG } from '../../../../../_start/helpers';
import { ActionsDropdown } from '../../../../components/dropdown/ActionsDropdown';
import { DeleteModal } from '../../../../components/modals/DeleteModal';
import { Pagination } from '../../../../components/Pagination';
import { useDebounce } from '../../../../hooks/useDebounce';
import { WEB_URL } from '../../../../support/api';
import {
  failureToast,
  handlePaginate,
  PaginatedValue,
  successToast,
} from '../../../../support/utils';
import { defaultPlugin, IPlugin } from '../../models/Plugin';
import { deletePlugin, listPlugin } from '../../redux/PluginCRUD';

export const PluginsPage = () => {
  const [loading, setLoading] = useState(false);

  const [initialized, setInitialized] = useState(false);

  const [paginatedValue, setPaginatedValue] = useState<PaginatedValue<IPlugin>>(
    new PaginatedValue()
  );

  const handlePaginateState = async (page_url: string) => {
    setLoading(true);
    try {
      const result = await handlePaginate<IPlugin>(page_url);
      setPaginatedValue(result);
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  const handleFetchList = async () => {
    setLoading(true);
    try {
      const result = await listPlugin();
      //   console.log(result);
      //   await handleFetchParentPages();
      setPaginatedValue(result);
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!initialized) {
      handleFetchList();

      setInitialized(true);
    }
  }, []);

  const [search, setSearch] = useState('');

  const debouncedSearch = useDebounce<string>(search, 750);

  const handleSearch = async () => {
    setLoading(true);
    try {
      const result = await listPlugin(search);
      setPaginatedValue(result);
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (initialized) {
      handleSearch();
    }
  }, [debouncedSearch]);

  const [selected, setSelected] = useState<IPlugin>(defaultPlugin);

  const handleDelete = async (item: IPlugin) => {
    setLoading(true);
    try {
      await deletePlugin(item);
      successToast('Page has been deleted.');
      setLoading(false);
      handleFetchList();
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  return (
    <>
      <DeleteModal
        headerTitle={`Delete Page`}
        item={selected}
        handleDelete={handleDelete}
      />
      <div
        className={`card card-custom card-flush ${
          loading ? 'overlay overlay-block' : ''
        }`}
      >
        <div className="pt-8 card-header">
          <div className="d-flex flex-center border py-1 px-4 bg-white rounded">
            <KTSVG
              path="/media/icons/duotone/General/Search.svg"
              className="svg-icon-1 svg-icon-primary"
            />
            <input
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              type="text"
              className={`form-control border-0 fw-bold ps-2 ${
                '' ? 'w-xxl-600px' : 'w-xxl-350px'
              }`}
              placeholder="Search"
            />
          </div>
          <div className="card-toolbar">
            <Link className="btn btn-sm btn-light" to={`plugins/create`}>
              Create Plugin
            </Link>
          </div>
        </div>
        <div className={`card-body py-5 ${loading ? 'overlay-wrapper' : ''}`}>
          <table className="table table-row-gray-300 gy-7">
            <thead>
              <tr className="fw-bolder fs-6 text-gray-800">
                <th>Title</th>
                <th>Status</th>
                <th>Publish Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {paginatedValue.data.map((element, idx) => {
                return (
                  <tr key={idx} className="align-middle">
                    <td>{element.title}</td>
                    <td className="text-uppercase">{element.status}</td>
                    <td>
                      {format(
                        new Date(element.publish_date),
                        'yyyy-MM-dd HH:mm'
                      )}
                    </td>
                    <td className="text-end">
                      {/* begin::Dropdown */}
                      <ActionsDropdown>
                        <div className="menu-item px-3">
                          <Link
                            to={`plugins/${element.id}/update`}
                            className="menu-link px-3"
                          >
                            Update
                          </Link>
                        </div>
                        <div className="menu-item px-3">
                          <a
                            className="menu-link px-3"
                            href={`${WEB_URL}/plugins/${element.slug}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Preview
                          </a>
                        </div>
                        <div className="menu-item px-3">
                          <a
                            onClick={() => setSelected(element)}
                            href="#"
                            className="menu-link px-3 text-danger"
                            data-bs-toggle="modal"
                            data-bs-target="#custom_delete_kt_modal_1"
                          >
                            Delete
                          </a>
                        </div>
                        <div className="separator mt-3 opacity-75"></div>

                        <div className="menu-item px-3">
                          <div className="menu-content px-3 py-3">
                            <Link
                              to={`plugins/${element.id}/edit-content`}
                              className="btn btn-success fw-bold btn-sm px-4"
                            >
                              Edit Content
                            </Link>
                          </div>
                        </div>
                      </ActionsDropdown>
                      {/* end::Dropdown */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {loading && (
            <div className="overlay-layer rounded bg-dark bg-opacity-5">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
        <div className="card-footer">
          <Pagination
            loading={loading}
            pagination={paginatedValue}
            handlePaginateState={handlePaginateState}
          />
        </div>
      </div>
    </>
  );
};
