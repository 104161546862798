import axios from "axios";
import { API_URL } from "../../../support/api";
import { PaginatedValue } from "../../../support/utils";
import { IMedia } from "../models/Media";

export const BASE_MEDIA_URL = `${API_URL}/v1/media-library`;
export const GET_ALL_MEDIA_URL = `${API_URL}/v1/media-libraryget-all`;
export const CREATE_MEDIA_URL = `${API_URL}/v1/media-library/store`;

export const listMedia = async (search = "", direction = "asc") => {
	const URL = `${BASE_MEDIA_URL}/list?search=${search}&direction=${direction}`;
	const {
		data: { value },
	} = await axios.get<{ value: PaginatedValue<IMedia> }>(URL);

	return value;
};

export const createMedia = async (payload: IMedia) => {
	const {
		data: { value },
	} = await axios.post<{ value: IMedia }>(CREATE_MEDIA_URL, payload);

	return value;
};

export const viewMedia = async (id: number | string) => {
	const {
		data: { value },
	} = await axios.get<{ value: IMedia }>(`${BASE_MEDIA_URL}/${id}/view`);

	return value;
};

export type UpdateMediaPayload = {
	id: number;
	name: string;
	alt_text: string;
	caption: string;
};
export const updateMedia = async (payload: UpdateMediaPayload) => {
	const {
		data: { value },
	} = await axios.post<{ value: IMedia }>(
		`${BASE_MEDIA_URL}/${payload.id}/update`,
		payload
	);

	return value;
};

export const deleteMedia = async (payload: IMedia) => {
	const {
		data: { value },
	} = await axios.post<{ value: IMedia }>(
		`${BASE_MEDIA_URL}/${payload.id}/delete`,
		payload
	);

	return value;
};

// unpaginated categories
export const getAllMedia = async () => {
	const {
		data: { value },
	} = await axios.get<{ value: Array<IMedia> }>(GET_ALL_MEDIA_URL);

	return value;
};
