/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTSVG, toAbsoluteUrl } from '../../../../../../_start/helpers';
import { ICaseDetail } from '../../../../page-management/models/CaseDetail';

type FeaturedCaseSinglePreviewProps = {
  idx: number;
  caseDetail: ICaseDetail;
  removeFeatured: (idx: number) => void;
};

export const FeaturedCaseSinglePreview = ({
  idx,
  caseDetail,
  removeFeatured,
}: FeaturedCaseSinglePreviewProps) => {
  return (
    <div className="featured-case">
      <span
        onClick={() => removeFeatured(idx)}
        className="position-absolute z-index-3 top-0 end-0 me-2 mt-2 opacity-75-hover"
      >
        <KTSVG
          path="/media/icons/duotone/Interface/Minus-Square.svg"
          className="svg-icon-danger svg-icon-1"
        />
      </span>

      {/* begin::Case Preview Image */}
      <div className="case-preview-image">
        {caseDetail.image_preview ? (
          <img
            src={caseDetail.image_preview}
            style={{
              width: '100%',
              height: '500px',
              objectFit: 'cover',
            }}
            alt=""
          />
        ) : (
          <img
            src={toAbsoluteUrl('/assets/images/400x530.png')}
            style={{
              width: '100%',
              height: '500px',
              objectFit: 'cover',
            }}
            alt=""
          />
        )}
      </div>
      {/* end::Case Preview Image */}

      <span className="case-title">{caseDetail.title}</span>
      <p className="case-description">{caseDetail.description}</p>

      <div className="case-tags-list">
        {caseDetail.tags.map((tag, idx) => {
          if (idx > 2) return null;

          return (
            <a
              key={idx}
              className="case-tags-list__item back-green1"
              style={{
                color: 'white',
                backgroundColor: tag.color,
              }}
            >
              <span>{tag.name}</span>
            </a>
          );
        })}
      </div>
    </div>
  );
};
