import { call, put, takeLatest } from 'redux-saga/effects';
import { DepartmentEvents } from './DepartmentEvents';
import * as departmentActions from './DepartmentActions';
import {
  defaultDepartment,
  IDepartment,
} from '../pages/departments/Department';
import {
  createDepartment,
  deleteDepartment,
  listDepartment,
  updateDepartment,
  viewDepartment,
} from './DepartmentCRUD';
import {
  defaultPaginatedValue,
  failureToast,
  PaginatedValue,
  successToast,
} from '../../../support/utils';

interface IDepartmentManagementState {
  loading: boolean;
  createdDepartment: IDepartment;
  viewedDepartment: IDepartment;
  updatedDepartment: IDepartment;
  deletedDepartment: IDepartment;
  paginatedDepartments: PaginatedValue<IDepartment>;
}

const defaultDepartmentManagementState: IDepartmentManagementState = {
  loading: false,
  createdDepartment: defaultDepartment,
  viewedDepartment: defaultDepartment,
  updatedDepartment: defaultDepartment,
  deletedDepartment: defaultDepartment,
  paginatedDepartments: defaultPaginatedValue,
};

export const reducer = (
  state: IDepartmentManagementState = defaultDepartmentManagementState,
  action: departmentActions.DepartmentActions
): IDepartmentManagementState => {
  switch (action.type) {
    // CREATE
    case DepartmentEvents.CREATE_DEPARTMENT_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case DepartmentEvents.CREATE_DEPARTMENT_LOADED:
      return {
        ...state,
        loading: false,
        createdDepartment: action.payload,
      };

    case DepartmentEvents.CREATE_DEPARTMENT_FAILED:
      return {
        ...state,
        loading: false,
      };

    // VIEW
    case DepartmentEvents.VIEW_DEPARTMENT_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case DepartmentEvents.VIEW_DEPARTMENT_LOADED:
      return {
        ...state,
        loading: false,
        viewedDepartment: action.payload,
      };

    case DepartmentEvents.VIEW_DEPARTMENT_FAILED:
      return {
        ...state,
        loading: false,
      };

    // UPDATE
    case DepartmentEvents.UPDATE_DEPARTMENT_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case DepartmentEvents.UPDATE_DEPARTMENT_LOADED:
      return {
        ...state,
        loading: false,
        updatedDepartment: action.payload,
      };

    case DepartmentEvents.UPDATE_DEPARTMENT_FAILED:
      return {
        ...state,
        loading: false,
      };

    // DELETE
    case DepartmentEvents.DELETE_DEPARTMENT_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case DepartmentEvents.DELETE_DEPARTMENT_LOADED:
      return {
        ...state,
        loading: false,
        deletedDepartment: action.payload,
      };

    case DepartmentEvents.DELETE_DEPARTMENT_FAILED:
      return {
        ...state,
        loading: false,
      };

    // LIST
    case DepartmentEvents.LIST_DEPARTMENT_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case DepartmentEvents.LIST_DEPARTMENT_LOADED:
      return {
        ...state,
        loading: false,
        paginatedDepartments: action.payload,
      };

    case DepartmentEvents.LIST_DEPARTMENT_FAILED:
      return {
        ...state,
        loading: false,
      };

    case DepartmentEvents.RESET_CREATE_DEPARTMENT:
      return {
        ...state,
        createdDepartment: defaultDepartment,
      };

    // SEARCH
    case DepartmentEvents.SEARCH_DEPARTMENT_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case DepartmentEvents.SEARCH_DEPARTMENT_LOADED:
      return {
        ...state,
        loading: false,
        paginatedDepartments: action.payload,
      };

    case DepartmentEvents.SEARCH_DEPARTMENT_FAILED:
      return {
        ...state,
        loading: false,
      };

    default:
      return {
        ...state,
      };
  }
};

export function* saga() {
  yield takeLatest(
    DepartmentEvents.CREATE_DEPARTMENT_REQUESTED,
    function* createDepartmentRequest(
      action: ReturnType<typeof departmentActions.createDepartmentRequest>
    ) {
      try {
        const response: IDepartment = yield call(
          createDepartment,
          action.model
        );

        yield put(departmentActions.createDepartmentLoad(response));

        yield call(successToast, 'Department has been created.');

        if (action.callback) action.callback();
      } catch (error) {
        yield put(departmentActions.createDepartmentFailed());
        yield call(failureToast, error);
      }
    }
  );

  yield takeLatest(
    DepartmentEvents.VIEW_DEPARTMENT_REQUESTED,
    function* viewDepartmentRequest(
      action: ReturnType<typeof departmentActions.viewDepartmentRequest>
    ) {
      try {
        const response: IDepartment = yield call(
          viewDepartment,
          action.model_id
        );

        yield put(departmentActions.viewDepartmentLoad(response));
      } catch (error) {
        yield put(departmentActions.viewDepartmentFailed());
        yield call(failureToast, error);
      }
    }
  );

  yield takeLatest(
    DepartmentEvents.UPDATE_DEPARTMENT_REQUESTED,
    function* updateDepartmentRequest(
      action: ReturnType<typeof departmentActions.updateDepartmentRequest>
    ) {
      try {
        const response: IDepartment = yield call(
          updateDepartment,
          action.model
        );

        yield put(departmentActions.updateDepartmentLoad(response));
        yield call(successToast, 'Department has been updated.');
      } catch (error) {
        yield put(departmentActions.updateDepartmentFailed());
        yield call(failureToast, error);
      }
    }
  );

  yield takeLatest(
    DepartmentEvents.DELETE_DEPARTMENT_REQUESTED,
    function* deleteDepartmentRequest(
      action: ReturnType<typeof departmentActions.deleteDepartmentRequest>
    ) {
      try {
        const response: IDepartment = yield call(
          deleteDepartment,
          action.model
        );

        yield put(departmentActions.deleteDepartmentLoad(response));
        yield call(successToast, 'Department has been deleted.');

        yield put(departmentActions.listDepartmentRequest());
      } catch (error) {
        yield put(departmentActions.deleteDepartmentFailed());
        yield call(failureToast, error);
      }
    }
  );

  yield takeLatest(
    DepartmentEvents.LIST_DEPARTMENT_REQUESTED,
    function* listDEPARTMENTRequest() {
      try {
        const response: PaginatedValue<IDepartment> = yield call(
          listDepartment
        );

        yield put(departmentActions.listDepartmentLoad(response));
      } catch (error) {
        yield put(departmentActions.listDepartmentFailed());
        yield call(failureToast, error);
      }
    }
  );

  yield takeLatest(
    DepartmentEvents.SEARCH_DEPARTMENT_REQUESTED,
    function* searchDepartmentRequest(
      action: ReturnType<typeof departmentActions.searchDepartmentRequest>
    ) {
      try {
        const response: PaginatedValue<IDepartment> = yield call(
          listDepartment,
          action.search
        );

        yield put(departmentActions.searchDepartmentLoad(response));
      } catch (error) {
        yield put(departmentActions.searchDepartmentFailed());
        yield call(failureToast, error);
      }
    }
  );
}
