import axios from "axios";
import { API_URL } from "../../../support/api";
import { PaginatedValue } from "../../../support/utils";
import { IMenu } from "../models/Menu";

export const BASE_MENU_URL = `${API_URL}/v1/layout/menu`;
export const GET_ALL_MENU_URL = `${API_URL}/v1/layout/menu/get-all`;
export const CREATE_MENU_URL = `${API_URL}/v1/layout/menu`;

export const listMenu = async (search = "", direction = "asc") => {
	const URL = `${BASE_MENU_URL}/list?search=${search}&direction=${direction}`;
	const {
		data: { value },
	} = await axios.get<{ value: PaginatedValue<IMenu> }>(URL);

	return value;
};

export const createMenu = async (payload: IMenu) => {
	const {
		data: { value },
	} = await axios.post<{ value: IMenu }>(CREATE_MENU_URL, payload);

	return value;
};

export const viewMenu = async (id: number | string) => {
	const {
		data: { value },
	} = await axios.get<{ value: IMenu }>(`${BASE_MENU_URL}/${id}/view`);

	return value;
};

export const updateMenu = async (payload: IMenu) => {
	const {
		data: { value },
	} = await axios.post<{ value: IMenu }>(
		`${BASE_MENU_URL}/${payload.id}/update`,
		payload
	);

	return value;
};

export const deleteMenu = async (payload: IMenu) => {
	const {
		data: { value },
	} = await axios.post<{ value: IMenu }>(
		`${BASE_MENU_URL}/${payload.id}/delete`,
		payload
	);

	return value;
};

// unpaginated categories
export const getAllMenu = async () => {
	const {
		data: { value },
	} = await axios.get<{ value: Array<IMenu> }>(GET_ALL_MENU_URL);

	return value;
};
