import axios from 'axios';
import { API_URL } from '../../../support/api';
import { PaginatedValue } from '../../../support/utils';
import { IRole } from '../../role-and-permission/pages/roles/Role';
import { IMember } from '../models/Member';

export const LIST_USERS_URL = `${API_URL}/v1/management/users/list`;
export const GET_ALL_ROLES_URL = `${API_URL}/v1/management/roles/get-all`;
export const CREATE_MEMBER_URL = `${API_URL}/v1/management/users/create`;

export const getMembers = async (search = '') => {
  const response = await axios.get<{ value: PaginatedValue<IMember> }>(
    `${API_URL}/v1/management/users/list/${search}`
  );

  return response.data.value;
};

export const getAllRoles = () => {
  return axios.get<{ value: Array<IRole> }>(GET_ALL_ROLES_URL);
};

export const createMember = (payload: IMember) => {
  return axios.post(CREATE_MEMBER_URL, payload);
};

export const viewMember = (member_id: number | string) => {
  return axios.get<{ value: IMember }>(
    `${API_URL}/v1/management/users/${member_id}/view`
  );
};

export const updateMember = (payload: IMember) => {
  return axios.post<{ value: IMember }>(
    `${API_URL}/v1/management/users/${payload.id}/update`,
    payload
  );
};

export const deleteMember = async (payload: IMember & { password: string }) => {
  const {
    data: { value },
  } = await axios.post<{ value: IMember }>(
    `${API_URL}/v1/management/users/${payload.id}/delete`,
    payload
  );

  return value;
};
