import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../setup';
import { KTSVG } from '../../../../../../_start/helpers';
import { IPage } from '../../../../page-management/pages/Pages/Page';
import {
  SpecializationsCases,
  SpecializationsCasesItem,
} from './SpecializationsCases';
import { SpecializationsCasesFigure } from './SpecializationsCasesFigure';

interface Props {
  index: number;
  data: SpecializationsCasesItem;
  update: (fieldsToUpdate: Partial<SpecializationsCases>) => void;
}

export const SpecializationsCasesListItem: React.FC<Props> = ({
  data,
  update,
  index,
}) => {
  const casePages = useSelector((state: RootState) => state.page.allCasePages);

  const [pages, setPages] = useState<Array<IPage>>([]);

  useEffect(() => {
    if (casePages) {
      setPages(casePages);
    }
  }, [casePages]);
  const [active, setActive] = useState(false);

  const sectionRef = useRef<HTMLLIElement>(null);

  const updateData = (updatedValue: Partial<SpecializationsCasesItem>) => {
    const updatedData = { ...data, ...updatedValue };
    update(updatedData);
  };

  const updateHomeCaseSelected = (case_detail: IPage) => {
    updateData({
      item_info: case_detail.description,
      case_detail,
    });
  };

  const handleActivate = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setActive(true);
  };

  const onBlur = (event: React.FocusEvent<HTMLLIElement>) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setActive(false);
    }
  };

  const handleSelect = (item: IPage) => {
    updateHomeCaseSelected(item);
    setActive(false);
  };

  const handleLocalSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value.toLowerCase();

    const filtered_items = casePages.filter((page) => {
      const pageTitle = page.title.toLowerCase();
      return pageTitle.indexOf(keyword) > -1;
    });

    setPages(filtered_items);
  };

  useEffect(() => {
    if (active) {
      sectionRef.current?.focus();
    }
  }, [active]);
  return (
    <article className="card" onClick={handleActivate}>
      {/* begin::Select Case Detail with Search */}
      {active && (
        <section
          ref={sectionRef}
          onBlur={onBlur}
          tabIndex={1}
          className="position-absolute start-0 end-0 z-index-2 other-case-list"
          style={{
            // marginTop: `${-17}rem`,
            top: '50%',
            transform: 'translate(0, -50%)',
          }}
        >
          <div className="d-flex flex-column align-items-center">
            {/* begin::Case Page List*/}
            <div
              className="rounded"
              style={{
                background: 'white',
              }}
            >
              {/* begin::Search */}
              <div className="position-relative px-3 pt-3">
                <span
                  className="position-absolute"
                  style={{
                    top: '60%',
                    transform: 'translateY(-50%)',
                  }}
                >
                  <KTSVG
                    path="/media/icons/duotone/General/Search.svg"
                    className="ps-1 svg-icon-1 svg-icon-primary"
                  />
                </span>
                <input
                  onChange={handleLocalSearch}
                  className="ps-10 form-control form-control-solid"
                  type="text"
                  placeholder="Search"
                />
              </div>
              {/* end::Search */}

              <div className="separator mt-2"></div>

              {/* begin::OtherCases */}
              <ul
                className="my-1 me-2 p-1 list-unstyled overflow-auto select-other-case"
                style={{
                  maxHeight: '128px',
                }}
              >
                {pages.map((page, idx) => (
                  <li
                    key={idx}
                    data-value="child-li"
                    onClick={() => handleSelect(page)}
                    className={`mb-1 px-2 py-1 ${
                      page.id === data.case_detail.id ? 'bg-success' : ''
                    }`}
                  >
                    {page.title}
                  </li>
                ))}
                {pages.length === 0 && (
                  <div className="mb-1 px-2 py-1 text-center text-gray-500">
                    No data available
                  </div>
                )}
              </ul>
              {/* end::OtherCases */}

              <div className="separator mb-2"></div>
            </div>
            {/* end::Case Page List*/}
            {/* begin::ArrowDown Element*/}
            <div
              style={{
                width: '0',
                height: '0',
                borderLeft: '12px solid transparent',
                borderRight: '12px solid transparent',
                borderTop: '12px solid white',
              }}
            ></div>
            {/* end::ArrowDown Element*/}
          </div>
        </section>
      )}
      {/* end::Select Case Detail with Search */}
      <SpecializationsCasesFigure data={data} update={update} />
      <div className="card__content">
        <div className="card__tags">
          {/* begin::Placeholder Labels */}
          {data.case_detail.tags.length === 0 && (
            <>
              <span className="tag-green card__tag">Label 1</span>
              <span className="tag-pink card__tag">Label 2</span>
            </>
          )}
          {/* end::Placeholder Labels */}

          {
            // @ts-ignore
            data.case_detail.tags.map(
              // @ts-ignore
              (category: ITag, idx) => {
                if (idx > 2) return null;

                return (
                  <span
                    key={category.id}
                    className="card__tag"
                    style={{
                      color: 'white',
                      backgroundColor: category.color,
                    }}
                  >
                    {category.name}
                  </span>
                );
              }
            )
          }
        </div>
        <p className="figure-description">{data.item_info}</p>
      </div>
    </article>
  );
};
