import { Redirect, Route, Switch } from 'react-router-dom';
import { PageTitle } from '../../../_start/layout/core';
import { CreateTag } from '../post-management/pages/Tag/CreateTag';
import { TagsPage } from '../post-management/pages/Tag/TagsPage';
import { UpdateTag } from '../post-management/pages/Tag/UpdateTag';
import { CaseCategoriesPage } from './pages/case-category/CaseCategoriesPage';
import { CreateCaseCategory } from './pages/case-category/CreateCaseCategory';
import { UpdateCaseCategory } from './pages/case-category/UpdateCaseCategory';
import { CaseDetailEditContent } from './pages/case-detail/CaseDetailEditContent';
import { CaseDetailsPage } from './pages/case-detail/CaseDetailsPage';
import { CreateCaseDetail } from './pages/case-detail/CreateCaseDetail';
import { UpdateCaseDetail } from './pages/case-detail/UpdateCaseDetail';
import { CreatePage } from './pages/CreatePage';
import { CreateFAQ } from './pages/FAQ/CreateFAQ';
import { FAQsPage } from './pages/FAQ/FAQsPages';
import { UpdateFAQ } from './pages/FAQ/UpdateFAQ';
import { CreateFAQCategory } from './pages/FAQCategory/CreateFAQCategory';
import { FAQCategoriesPage } from './pages/FAQCategory/FAQCategoriesPage';
import { UpdateFAQCategory } from './pages/FAQCategory/UpdateFAQCategory';
import { PageEditContent } from './pages/PageEditContent';

import { PagesPage } from './pages/PagesPage';
import { UpdatePage } from './pages/UpdatePage';

export const PageManagementPage = () => {
  return (
    <Switch>
      {/* begin::PAGES ROUTES */}
      <Route exact={true} path="/page-management/pages">
        <>
          <PageTitle>Pages</PageTitle>
          <PagesPage />
        </>
      </Route>
      <Route exact={true} path="/page-management/pages/create">
        <>
          <PageTitle>Pages</PageTitle>
          <CreatePage />
        </>
      </Route>
      <Route exact={true} path="/page-management/pages/:id/update">
        <>
          <PageTitle>Pages</PageTitle>
          <UpdatePage />
        </>
      </Route>
      <Route exact={true} path="/page-management/pages/:id/edit-content">
        <>
          <PageTitle>Pages</PageTitle>
          <PageEditContent />
        </>
      </Route>
      {/* end::PAGES ROUTES */}

      {/* begin::CASE DETAILS ROUTES */}
      <Route exact={true} path="/page-management/case-details">
        <>
          <PageTitle>Case Details</PageTitle>
          <CaseDetailsPage />
        </>
      </Route>
      <Route exact={true} path="/page-management/case-details/create">
        <>
          <PageTitle>Case Details</PageTitle>
          <CreateCaseDetail />
        </>
      </Route>
      <Route exact={true} path="/page-management/case-details/:id/update">
        <>
          <PageTitle>Case Details</PageTitle>
          <UpdateCaseDetail />
        </>
      </Route>
      <Route exact={true} path="/page-management/case-details/:id/edit-content">
        <>
          <PageTitle>Pages</PageTitle>
          <CaseDetailEditContent />
        </>
      </Route>
      {/* end::CASE DETAILS ROUTES */}

      {/* begin::CASE CATEGORIES ROUTES */}
      <Route exact={true} path="/page-management/case-categories">
        <>
          <PageTitle>Case Categories</PageTitle>
          <CaseCategoriesPage />
        </>
      </Route>
      <Route exact={true} path="/page-management/case-categories/create">
        <>
          <PageTitle>Case Categories</PageTitle>
          <CreateCaseCategory />
        </>
      </Route>
      <Route exact={true} path="/page-management/case-categories/:id/update">
        <>
          <PageTitle>Case Categories</PageTitle>
          <UpdateCaseCategory />
        </>
      </Route>
      {/* end::CASE CATEGORIES ROUTES */}

      {/* Tags */}
      <Route exact={true} path="/page-management/tags">
        <>
          <PageTitle>Tags</PageTitle>
          <TagsPage />
        </>
      </Route>
      <Route exact={true} path="/page-management/tags/create">
        <>
          <PageTitle>Tags</PageTitle>
          <CreateTag cancelUrl={`/page-management/tags`} />
        </>
      </Route>
      <Route exact={true} path="/page-management/tags/:id/update">
        <>
          <PageTitle>Tags</PageTitle>
          <UpdateTag cancelUrl={`/page-management/tags`} />
        </>
      </Route>
      {/* Tags */}

      {/* FAQs */}
      <Route exact={true} path="/page-management/faqs">
        <>
          <PageTitle>FAQs</PageTitle>
          <FAQsPage />
        </>
      </Route>
      <Route exact={true} path="/page-management/faqs/create">
        <>
          <PageTitle>FAQs</PageTitle>
          <CreateFAQ cancelUrl={`/page-management/faqs`} />
        </>
      </Route>
      <Route exact={true} path="/page-management/faqs/:id/update">
        <>
          <PageTitle>FAQs</PageTitle>
          <UpdateFAQ cancelUrl={`/page-management/faqs`} />
        </>
      </Route>
      {/* FAQs */}

      {/* FAQ Categories */}
      <Route exact={true} path="/page-management/faq-categories">
        <>
          <PageTitle>FAQ Categories</PageTitle>
          <FAQCategoriesPage />
        </>
      </Route>
      <Route exact={true} path="/page-management/faq-categories/create">
        <>
          <PageTitle>FAQ Categories</PageTitle>
          <CreateFAQCategory cancelUrl="/page-management/faq-categories" />
        </>
      </Route>
      <Route exact={true} path="/page-management/faq-categories/:id/update">
        <>
          <PageTitle>FAQ Categories</PageTitle>
          <UpdateFAQCategory cancelUrl="/page-management/faq-categories" />
        </>
      </Route>
      {/* FAQ Categories */}

      <Redirect
        from="/page-management"
        exact={true}
        to="/page-management/pages"
      />
      <Redirect to="/page-management/pages" />
    </Switch>
  );
};
