import React from 'react';
import { FormImageFieldModal } from '../../../../media-management/components/form-image/FormImageFieldModal';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { IElement } from '../../../models/Element';
import { PluginNoHighlightRightImageInfo } from './PluginNoHighlightRightImageInfo';
import { PluginNoHighlightRightImageInfoContent } from './PluginNoHighlightRightImageInfoContent';
import { PluginNoHighlightRightImageInfoFigure } from './PluginNoHighlightRightImageInfoFigure';

type Props = {
  index: number;
  data: PluginNoHighlightRightImageInfo;
  updateData: (element: IElement, index: number) => void;
};

export const PluginNoHighlightRightImageInfoFC: React.FC<Props> = ({
  index,
  data,
  updateData,
}) => {
  const update = (fieldsToUpdate: Partial<PluginNoHighlightRightImageInfo>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };
  return (
    <>
      <FormImageFieldModal
        modalId={`${data.name}-${data.id}`}
        name={`image`}
        altText={`image_alt`}
        update={update}
      />

      <ElementWrapper elementIndex={index}>
        <div className="plugin-no-highlight-right-image-info">
          <div className="showcase">
            <PluginNoHighlightRightImageInfoContent
              data={data}
              update={update}
            />
            <PluginNoHighlightRightImageInfoFigure
              data={data}
              update={update}
            />
          </div>
        </div>
      </ElementWrapper>
    </>
  );
};
