import {
  AboutUsUniqueSellingPoint,
  defaultUSP,
  IUSP,
} from './AboutUsUniqueSellingPoint';
import { AboutUsUniqueSellingPointItem } from './AboutUsUniqueSellingPointItem';

import immutabilityHelper from 'immutability-helper';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { IElement } from '../../../models/Element';
import { KTSVG } from '../../../../../../_start/helpers';

type AboutUsUniqueSellingPointFCProps = {
  index: number;
  data: AboutUsUniqueSellingPoint;
  updateData: (body: IElement, index: number) => void;
};

export const AboutUsUniqueSellingPointFC = ({
  index,
  data,
  updateData,
}: AboutUsUniqueSellingPointFCProps) => {
  const update = (fieldsToUpdate: Partial<AboutUsUniqueSellingPoint>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  const updateUSP = (idx: number, usp: IUSP) => {
    let list = [...data.list];
    list[idx] = usp;

    const updatedData = { ...data, list };
    update(updatedData);
  };

  const addUSP = () => {
    const list = immutabilityHelper(data.list, {
      $push: [defaultUSP],
    });
    const updatedData = { ...data, list };
    update(updatedData);
  };

  const removeUSP = (itemIndex: number) => {
    let list = immutabilityHelper(data.list, {
      $splice: [[itemIndex, 1]],
    });
    const updatedData = { ...data, list };
    update(updatedData);
  };

  return (
    <ElementWrapper elementIndex={index}>
      <div className="about-us-unique-selling-point body-my-8">
        {data.list.map((usp, idx) => (
          <AboutUsUniqueSellingPointItem
            key={idx}
            usp={usp}
            uspIndex={idx}
            uniqueSellingPoint={data}
            updateUSP={updateUSP}
            removeUSP={removeUSP}
          />
        ))}
        <div onClick={addUSP} className="add-item">
          <div className="m-auto d-flex flex-column">
            <KTSVG
              path="/media/icons/duotone/Interface/Plus-Square.svg"
              className="svg-icon-muted svg-icon-3hx text-center"
            />
            <span className="mt-4 text-gray-500">Add new item</span>
          </div>
        </div>
      </div>
    </ElementWrapper>
  );
};
