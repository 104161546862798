/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../setup';
import { toAbsoluteUrl } from '../../../../../../_start/helpers';
import {
  defaultCustomer,
  ICustomer,
} from '../../../../customer-management/models/Customer';
import { ButtonTooltip } from '../../../components/ButtonTooltip';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { InlineEdit } from '../../../components/InlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { IElement } from '../../../models/Element';
import { setCaseDetail } from '../../../redux/EditContentActions';
import { PersonHighlightExperience } from './PersonHighlightExperience';
import { SelectPersonWrapper } from './SelectPersonWrapper';

type PersonHighlightExperienceFCProps = {
  index: number;
  data: PersonHighlightExperience;
  updateData: (body: IElement, index: number) => void;
};

export const PersonHighlightExperienceFC = ({
  index,
  data,
  updateData,
}: PersonHighlightExperienceFCProps) => {
  const dispatch = useDispatch();

  const caseDetail = useSelector(
    (state: RootState) => state.editContent.caseDetail
  );

  const customer = useSelector(
    (state: RootState) => state.editContent.caseDetail.customer
  );

  useEffect(() => {
    if (customer) {
      update({ customer });
    } else {
      update({ customer: defaultCustomer });
    }
  }, [customer]);

  const update = (fieldsToUpdate: Partial<PersonHighlightExperience>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  const customers = useSelector((state: RootState) => state.editContent.allCustomers);

  const updateCustomer = (selected: ICustomer) => {
    update({ customer: selected });
    dispatch(
      setCaseDetail({
        ...caseDetail,
        customer: selected,
        customer_id: selected.id,
      })
    );
  };

  return (
    <ElementWrapper elementIndex={index}>
      <div className="case-person-highlight-experience body-my-8">
        <div className="case-person-highlight-experience__container">
          <SelectPersonWrapper
            customers={customers}
            customer={data.customer}
            updateSelected={updateCustomer}
          >
            <div className="container__person">
              {/* begin::Person Image */}
              {data.customer.person_image ? (
                <label className="symbol symbol-100px symbol-circle">
                  <img
                    className="person-figure"
                    src={data.customer.person_image}
                    alt=""
                  />
                </label>
              ) : (
                <div className="symbol symbol-100px symbol-circle">
                  <img
                    className="person-figure"
                    src={toAbsoluteUrl('/assets/images/250x250.png')}
                    alt=""
                  />
                </div>
              )}
              {/* end::Person Image */}

              {/* begin::Name and Business Name */}
              <div>
                <span className="person-name">{data.customer.name}</span>
                <span className="person-business-name">
                  {data.customer.business_name}
                </span>
              </div>
              {/* end::Name and Business Name */}
            </div>
          </SelectPersonWrapper>

          <div className="container__content">
            <TextAreaEdit
              name="title"
              className="content-title"
              value={data.title}
              setValue={update}
            />

            <TextAreaEdit
              name="heading"
              className="content-heading"
              value={data.heading}
              setValue={update}
            />

            <TextAreaEdit
              name="description"
              className="content-description"
              value={data.description}
              setValue={update}
            />

            <ButtonTooltip
              name={`button_url`}
              className="content-action"
              value={data.button_url}
              setValue={update}
            >
              <a>
                <InlineEdit
                  className="action-text"
                  name="button_title"
                  value={data.button_title}
                  setValue={update}
                  activeVerticalPadding={'py-0'}
                  dynamicWidth
                  style={{
                    maxWidth: '22rem',
                  }}
                />
              </a>
            </ButtonTooltip>
          </div>

          <div className="container__figure">
            {data.customer.branding_image ? (
              <img
                src={data.customer.branding_image}
                alt=""
                //   style={{
                //     width: '100%',
                //     objectFit: 'cover',
                //     top: '50%',
                //     transform: 'translate(30%, -50%)',
                //   }}
              />
            ) : (
              <img
                src={toAbsoluteUrl('/assets/images/250x250.png')}
                alt=""
                //   style={{
                //     width: '100%',
                //     objectFit: 'cover',
                //     top: '50%',
                //     transform: 'translate(30%, -50%)',
                //   }}
              />
            )}
          </div>
        </div>
      </div>
    </ElementWrapper>
  );
};
