/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { KTSVG } from '../../../../../_start/helpers';
import { ActionsDropdown } from '../../../../components/dropdown/ActionsDropdown';
import { Pagination } from '../../../../components/Pagination';
import { useDebounce } from '../../../../hooks/useDebounce';
import {
  failureToast,
  handlePaginate,
  PaginatedValue,
  successToast,
} from '../../../../support/utils';
import {
  getAllEmployee,
  listEmployee,
  transferEmployee,
} from '../../redux/EmployeeCRUD';

import { defaultEmployee, IEmployee } from './Employee';
import { TransferOwnershipModal } from './TransferOwnershipModal';

export const EmployeesPage = () => {
  const [loading, setLoading] = useState(false);

  const [initialized, setInitialized] = useState(false);

  const [paginatedValue, setPaginatedValue] = useState<
    PaginatedValue<IEmployee>
  >(new PaginatedValue());

  const handlePaginateState = async (page_url: string) => {
    setLoading(true);
    try {
      const result = await handlePaginate<IEmployee>(page_url);
      setPaginatedValue(result);
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  const [employees, setEmployees] = useState<Array<IEmployee>>([]);

  const handleFetchList = async () => {
    setLoading(true);
    try {
      const [paginatedResult, employeesResult] = await Promise.all([
        listEmployee(),
        getAllEmployee(),
      ]);

      setPaginatedValue(paginatedResult);
      setEmployees(employeesResult);
      setTargetId(employeesResult[0].id);
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!initialized) {
      handleFetchList();
      setInitialized(true);
    }
  }, []);

  const [search, setSearch] = useState('');

  const debouncedSearch = useDebounce<string>(search, 750);

  const handleSearch = async () => {
    setLoading(true);
    try {
      const result = await listEmployee(search);
      setPaginatedValue(result);
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (initialized) {
      handleSearch();
    }
  }, [debouncedSearch]);

  const [selected, setSelected] = useState<IEmployee>(defaultEmployee);
  const [password, setPassword] = useState('');

  const [targetId, setTargetId] = useState(0);
  const handleTransfer = async (item: IEmployee) => {
    setLoading(true);
    try {
      const payload = {
        ...item,
        password,
        transfer_to: targetId,
      };
      await transferEmployee(payload);
      successToast('Employee ownership has been transferred.');
      setLoading(false);
      setPassword('');
      handleFetchList();
    } catch (error) {
      failureToast(error);
      setPassword('');
      setLoading(false);
    }
  };

  return (
    <>
      {/* begin::Transfer Modal */}
      <TransferOwnershipModal
        employee={selected}
        handleTransfer={handleTransfer}
      >
        <>
          <p>{`Are you sure to transfer ownership from this employee? This action is irreversible.`}</p>
          <p className="my-8 d-block text-center fw-bold">{selected.name}</p>

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Target</label>
            <div className="col-lg-9">
              <select
                className="form-select form-select-lg form-select-solid"
                data-control="select2"
                data-placeholder="Select Status..."
                value={targetId}
                onChange={(e) => setTargetId(parseInt(e.target.value))}
              >
                {employees.map((employee, idx) => (
                  <option value={employee.id} key={idx}>
                    {employee.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {/* end::Form row */}

          <div className="row">
            <label className="col-lg-3 col-form-label">Password</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  type="password"
                  className="form-control form-control-lg form-control-solid"
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
          </div>
        </>
      </TransferOwnershipModal>
      {/* end::Transfer Modal */}

      <div
        className={`card card-custom card-flush ${
          loading ? 'overlay overlay-block' : ''
        }`}
      >
        <div className="pt-8 card-header">
          <div className="d-flex flex-center border py-1 px-4 bg-white rounded">
            <KTSVG
              path="/media/icons/duotone/General/Search.svg"
              className="svg-icon-1 svg-icon-primary"
            />
            <input
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              type="text"
              className={`form-control border-0 fw-bold ps-2 ${
                '' ? 'w-xxl-600px' : 'w-xxl-350px'
              }`}
              placeholder="Search"
            />
          </div>
          <div className="card-toolbar">
            <Link className="btn btn-sm btn-light" to="employees/create">
              Create Employee
            </Link>
          </div>
        </div>
        <div className={`card-body py-5 ${loading ? 'overlay-wrapper' : ''}`}>
          <table className="table table-row-gray-300 gy-7">
            <thead>
              <tr className="fw-bolder fs-6 text-gray-800">
                <th>Name</th>
                <th>Department</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {paginatedValue.data.map((employee, idx) => (
                <tr key={idx} className="align-middle">
                  <td>{employee.name}</td>
                  <td>{employee.department?.name ?? 'NA'}</td>
                  <td className="text-end">
                    {/* begin::Dropdown */}
                    <ActionsDropdown>
                      <div className="menu-item px-3">
                        <Link
                          to={`employees/${employee.id}/update`}
                          className="menu-link px-3"
                        >
                          Update
                        </Link>
                      </div>
                      <div className="menu-item px-3">
                        <a
                          onClick={() => setSelected(employee)}
                          href="#"
                          className="menu-link px-3 text-warning"
                          data-bs-toggle="modal"
                          data-bs-target="#transfer_ownership_modal"
                        >
                          Transfer
                        </a>
                      </div>
                      <div className="separator mt-3 opacity-75"></div>
                    </ActionsDropdown>
                    {/* end::Dropdown */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {loading && (
            <div className="overlay-layer rounded bg-dark bg-opacity-5">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
        <div className="card-footer">
          <Pagination
            loading={loading}
            pagination={paginatedValue}
            handlePaginateState={handlePaginateState}
          />
        </div>
      </div>
    </>
  );
};
