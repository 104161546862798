import React from 'react';
import { ButtonTooltip } from '../../../components/ButtonTooltip';
import { InlineEdit } from '../../../components/InlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { PluginRightImageInfo } from './PluginRightImageInfo';

type Props = {
  data: PluginRightImageInfo;
  update: (fieldsToUpdate: Partial<PluginRightImageInfo>) => void;
};

export const PluginRightImageInfoContent: React.FC<Props> = ({
  data,
  update,
}) => {
  return (
    <div className="showcase__content">
      <div>
        {/* begin::Heading */}
        <TextAreaEdit
          name="heading"
          className="heading"
          value={data.heading}
          setValue={update}
        />
        {/* end::Heading */}
      </div>

      {/* begin::Description */}
      <TextAreaEdit
        name="description"
        className="description"
        value={data.description}
        setValue={update}
      />
      {/* end::Description */}

      {/* begin::Actions */}
      <div className="buttons">
        <ButtonTooltip
          name={`button1_url`}
          className="content-action__button"
          value={data.button1_url}
          setValue={update}
        >
          <button className="btn btn-success rounded-pill">
            <InlineEdit
              name="button2_title"
              className="px-1 text-white text-center"
              value={data.button1_title}
              setValue={update}
              activeVerticalPadding={'py-1'}
              dynamicWidth
              style={{
                maxWidth: '200px',
              }}
            />
          </button>
        </ButtonTooltip>

        <ButtonTooltip
          name={`button2_url`}
          className="content-action__button"
          value={data.button2_url}
          setValue={update}
        >
          <button className="btn btn-success rounded-pill">
            <InlineEdit
              name="button2_title"
              className="px-1 text-white text-center"
              value={data.button2_title}
              setValue={update}
              activeVerticalPadding={'py-1'}
              dynamicWidth
              style={{
                maxWidth: '200px',
              }}
            />
          </button>
        </ButtonTooltip>
      </div>
      {/* end::Actions */}
    </div>
  );
};
