import axios from 'axios';
import { API_URL } from '../../../support/api';
import { PaginatedValue } from '../../../support/utils';
import { IDepartment } from '../pages/departments/Department';

const DEPARTMENT_URL = `${API_URL}/v1/management/employee-management/departments`;

export const createDepartment = async (payload: IDepartment) => {
  const response = await axios.post<{ value: IDepartment }>(
    `${DEPARTMENT_URL}/create`,
    payload
  );

  return response.data.value;
};

export const viewDepartment = async (id: string | number) => {
  const response = await axios.get<{ value: IDepartment }>(
    `${DEPARTMENT_URL}/${id}/get`
  );

  return response.data.value;
};

export const updateDepartment = async (payload: IDepartment) => {
  const response = await axios.post<{ value: IDepartment }>(
    `${DEPARTMENT_URL}/${payload.id}/update`,
    payload
  );

  return response.data.value;
};

export const deleteDepartment = async (payload: IDepartment) => {
  const response = await axios.post<{ value: IDepartment }>(
    `${DEPARTMENT_URL}/${payload.id}/delete`,
    payload
  );

  return response.data.value;
};

export const listDepartment = async (search = '', direction = 'asc') => {
  const URL = `${DEPARTMENT_URL}/list?search=${search}&direction=${direction}`;
  const response = await axios.get<{ value: PaginatedValue<IDepartment> }>(URL);

  return response.data.value;
};

export const getAllDepartment = async () => {
  const response = await axios.get<{ value: Array<IDepartment> }>(
    `${DEPARTMENT_URL}/get-all`
  );

  return response.data.value;
};
