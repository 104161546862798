import { IPermission } from '../permissions/Permission';

export enum RoleNames {
  SUPER_ADMIN = 'super admin',
  ADMIN = 'admin',
  MODERATOR = 'moderator',
  USER = 'user',
}

export interface IRole {
  id: number;
  name: RoleNames;
  description: string;
  permissions?: Array<number | IPermission>;
}

export const defaultRole: IRole = {
  id: 0,
  name: RoleNames.USER,
  description: '',
};
