import {
  IActionUnion,
  makeBaseAction,
  makeParamsAction,
  makePayloadAction,
} from '../../../../setup/redux/utils';
import { PaginatedValue } from '../../../support/utils';
import { IDepartment } from '../pages/departments/Department';
import { IEmployee } from '../pages/employees/Employee';
import { EmployeeEvents } from './EmployeeEvents';

// INITIALIZATION
export const initializeEmployeeRequest =
  makeBaseAction<EmployeeEvents.INITIALIZATION_EMPLOYEE_REQUESTED>(
    EmployeeEvents.INITIALIZATION_EMPLOYEE_REQUESTED
  );

export const initializeEmployeeLoad = makePayloadAction<
  EmployeeEvents.INITIALIZATION_EMPLOYEE_LOADED,
  Array<IDepartment>
>(EmployeeEvents.INITIALIZATION_EMPLOYEE_LOADED);

export const initializeEmployeeFailed =
  makeBaseAction<EmployeeEvents.INITIALIZATION_EMPLOYEE_FAILED>(
    EmployeeEvents.INITIALIZATION_EMPLOYEE_FAILED
  );

// CREATE --
export const createEmployeeRequest = makeParamsAction<
  EmployeeEvents.CREATE_EMPLOYEE_REQUESTED,
  { model: IEmployee; callback?: () => void }
>(EmployeeEvents.CREATE_EMPLOYEE_REQUESTED);

export const createEmployeeLoad = makePayloadAction<
  EmployeeEvents.CREATE_EMPLOYEE_LOADED,
  IEmployee
>(EmployeeEvents.CREATE_EMPLOYEE_LOADED);

export const createEmployeeFailed =
  makeBaseAction<EmployeeEvents.CREATE_EMPLOYEE_FAILED>(
    EmployeeEvents.CREATE_EMPLOYEE_FAILED
  );

// VIEW --
export const viewEmployeeRequest = makeParamsAction<
  EmployeeEvents.VIEW_EMPLOYEE_REQUESTED,
  { model_id: string | number }
>(EmployeeEvents.VIEW_EMPLOYEE_REQUESTED);

export const viewEmployeeLoad = makePayloadAction<
  EmployeeEvents.VIEW_EMPLOYEE_LOADED,
  IEmployee
>(EmployeeEvents.VIEW_EMPLOYEE_LOADED);

export const viewEmployeeFailed =
  makeBaseAction<EmployeeEvents.VIEW_EMPLOYEE_FAILED>(
    EmployeeEvents.VIEW_EMPLOYEE_FAILED
  );

// UPDATE --
export const updateEmployeeRequest = makeParamsAction<
  EmployeeEvents.UPDATE_EMPLOYEE_REQUESTED,
  { model: IEmployee; callback?: () => void }
>(EmployeeEvents.UPDATE_EMPLOYEE_REQUESTED);

export const updateEmployeeLoad = makePayloadAction<
  EmployeeEvents.UPDATE_EMPLOYEE_LOADED,
  IEmployee
>(EmployeeEvents.UPDATE_EMPLOYEE_LOADED);

export const updateEmployeeFailed =
  makeBaseAction<EmployeeEvents.UPDATE_EMPLOYEE_FAILED>(
    EmployeeEvents.UPDATE_EMPLOYEE_FAILED
  );

// DELETE --
export const deleteEmployeeRequest = makeParamsAction<
  EmployeeEvents.DELETE_EMPLOYEE_REQUESTED,
  { model: IEmployee }
>(EmployeeEvents.DELETE_EMPLOYEE_REQUESTED);

export const deleteEmployeeLoad = makePayloadAction<
  EmployeeEvents.DELETE_EMPLOYEE_LOADED,
  IEmployee
>(EmployeeEvents.DELETE_EMPLOYEE_LOADED);

export const deleteEmployeeFailed =
  makeBaseAction<EmployeeEvents.DELETE_EMPLOYEE_FAILED>(
    EmployeeEvents.DELETE_EMPLOYEE_FAILED
  );

// LIST --
export const listEmployeeRequest =
  makeBaseAction<EmployeeEvents.LIST_EMPLOYEE_REQUESTED>(
    EmployeeEvents.LIST_EMPLOYEE_REQUESTED
  );

export const listEmployeeLoad = makePayloadAction<
  EmployeeEvents.LIST_EMPLOYEE_LOADED,
  PaginatedValue<IEmployee>
>(EmployeeEvents.LIST_EMPLOYEE_LOADED);

export const listEmployeeFailed =
  makeBaseAction<EmployeeEvents.LIST_EMPLOYEE_FAILED>(
    EmployeeEvents.LIST_EMPLOYEE_FAILED
  );

export const resetCreateEmployee =
  makeBaseAction<EmployeeEvents.RESET_CREATE_EMPLOYEE>(
    EmployeeEvents.RESET_CREATE_EMPLOYEE
  );

// SEARCH
export const searchEmployeeRequest = makeParamsAction<
  EmployeeEvents.SEARCH_EMPLOYEE_REQUESTED,
  { search: string }
>(EmployeeEvents.SEARCH_EMPLOYEE_REQUESTED);

export const searchEmployeeLoad = makePayloadAction<
  EmployeeEvents.SEARCH_EMPLOYEE_LOADED,
  PaginatedValue<IEmployee>
>(EmployeeEvents.SEARCH_EMPLOYEE_LOADED);

export const searchEmployeeFailed =
  makeBaseAction<EmployeeEvents.SEARCH_EMPLOYEE_FAILED>(
    EmployeeEvents.SEARCH_EMPLOYEE_FAILED
  );

export const actions = {
  initializeEmployeeRequest,
  initializeEmployeeLoad,
  initializeEmployeeFailed,
  createEmployeeRequest,
  createEmployeeLoad,
  createEmployeeFailed,
  viewEmployeeRequest,
  viewEmployeeLoad,
  viewEmployeeFailed,
  updateEmployeeRequest,
  updateEmployeeLoad,
  updateEmployeeFailed,
  deleteEmployeeRequest,
  deleteEmployeeLoad,
  deleteEmployeeFailed,
  listEmployeeRequest,
  listEmployeeLoad,
  listEmployeeFailed,
  resetCreateEmployee,
  searchEmployeeRequest,
  searchEmployeeLoad,
  searchEmployeeFailed,
};

export type EmployeeActions = IActionUnion<typeof actions>;
