import axios from 'axios';
import { API_URL } from '../../../support/api';
import { PaginatedValue } from '../../../support/utils';
import { IPackage } from '../models/Package';

export const BASE_PACKAGE_URL = `${API_URL}/v1/management/module-management/packages`;
export const GET_ALL_PACKAGE_URL = `${API_URL}/v1/management/module-management/packages/get-all`;
export const CREATE_PACKAGE_URL = `${API_URL}/v1/management/module-management/packages/create`;

export const listPackage = async (search = '', direction = 'asc') => {
  const URL = `${BASE_PACKAGE_URL}/list?search=${search}&direction=${direction}`;
  const {
    data: { value },
  } = await axios.get<{ value: PaginatedValue<IPackage> }>(URL);

  return value;
};

export const createPackage = async (
  payload: IPackage & { features: Array<number> }
) => {
  const {
    data: { value },
  } = await axios.post<{ value: IPackage }>(CREATE_PACKAGE_URL, payload);

  return value;
};

export const viewPackage = async (id: number | string) => {
  const {
    data: { value },
  } = await axios.get<{ value: IPackage }>(`${BASE_PACKAGE_URL}/${id}/get`);

  return value;
};

export const updatePackage = async (payload: IPackage) => {
  const {
    data: { value },
  } = await axios.post<{ value: IPackage }>(
    `${BASE_PACKAGE_URL}/${payload.id}/update`,
    payload
  );

  return value;
};

export const deletePackage = async (payload: IPackage) => {
  const {
    data: { value },
  } = await axios.post<{ value: IPackage }>(
    `${BASE_PACKAGE_URL}/${payload.id}/delete`,
    payload
  );

  return value;
};

// unpaginated categories
export const getAllPackage = async () => {
  const {
    data: { value },
  } = await axios.get<{ value: Array<IPackage> }>(GET_ALL_PACKAGE_URL);

  return value;
};
