import axios from 'axios';
import { API_URL } from '../../../../../support/api';
import { IFAQ } from '../../../../page-management/models/FAQ';
import { IFAQCategory } from '../../../../page-management/models/FAQCategory';
import { ElementType, IElement } from '../../../models/Element';

export const GET_ALL_FAQ_URL = `${API_URL}/v1/faq-management/faq/get-all`;

export type FAQSelectNarrow = IElement & {
  heading: string;
  description: string;
  category?: IFAQCategory;
  item_list: Array<IFAQ>;
};

export const defaultFAQNarrowItem: IFAQ = {
  id: -1,
  title: '',
  title_description: '',
  description: '',
  url: '',
  button_title: 'Insert title',
};

export const defaultFAQSelectNarrow: FAQSelectNarrow = {
  id: 0,
  name: ElementType.FAQSELECT_NARROW,
  heading: 'Veel gestelde vragen over SEO',
  description: 'Bellen en gebeld worden',
  item_list: [],
};

export const getAllFAQs = async () => {
  const {
    data: { value },
  } = await axios.get<{ value: Array<IFAQ> }>(GET_ALL_FAQ_URL);

  return value;
};
