import { ElementType, IElement } from '../../../models/Element';

export type AboutUsHeader = IElement & {
  title: string;
  heading: string;
  description: string;
  button_url: string;
  button_title: string;
  image: string;
  image_alt: string;
};

export const defaultAboutUsHeader: AboutUsHeader = {
  id: 0,
  name: ElementType.ABOUT_US_HEADER,
  title: 'Over Tussendoor',
  heading: 'Ons team. Vol enthousiaste specialisten',
  description:
    'WordPress ontwikkeling, online marketing, concept & strategie, maatwerk plugins en white-label samenwerkingen Niks is onmogelijk.',
  button_title: 'Ik wil kennismaken',
  button_url: '',
  image: '',
  image_alt: '',
};
