import { ElementType, IElement } from '../../../models/Element';

export type CustomerQuestion = IElement & {
  title: string;
  description: string;
  list: Array<ICustomerQuestionItem>;
};

export interface ICustomerQuestionItem {
  id: number;
  content: string;
}

export const defaultCustomerQuestionItem: ICustomerQuestionItem = {
  id: 0,
  content:
    'Het (film)programma moest op een heldere en visuele manier gepresenteerd worden',
};

export const defaultCustomerQuestion: CustomerQuestion = {
  id: 0,
  name: ElementType.CUSTOMER_QUESTION,
  title: 'De klantvraag',
  description:
    'De vorige website van het Noordelijk Film Festival voldeed niet meer aan de wensen van de bezoekers. Vandaar dat wij zijn ingeschakeld om een nieuwe site te ontwikkelen. Deze nieuwe website moest onder andere aan de volgende eisen voldoen: \n\nDeze eisen hebben we verwerkt in in een maatwerk-ontwerp dat vo ldoet aan de mobile-first filosofie.',
  list: [defaultCustomerQuestionItem],
};

export enum CustomerQuestionItemTypes {
  ITEM = 'customer-question-item',
}
