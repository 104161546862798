import axios from 'axios';
import { API_URL } from '../../../support/api';
import { PaginatedValue } from '../../../support/utils';
import {
  CreateFAQCategoryPayload,
  IFAQCategory,
  UpdateFAQCategoryPayload,
} from '../models/FAQCategory';

export const BASE_FAQ_CATEGORY_URL = `${API_URL}/v1/faq-management/categories`;
export const GET_ALL_FAQ_CATEGORY_URL = `${API_URL}/v1/faq-management/categories/get-all`;
export const CREATE_FAQ_CATEGORY_URL = `${API_URL}/v1/faq-management/categories/create`;

export const listFAQCategory = async (search = '', direction = 'asc') => {
  const URL = `${BASE_FAQ_CATEGORY_URL}/list?search=${search}&direction=${direction}`;
  const {
    data: { value },
  } = await axios.get<{ value: PaginatedValue<IFAQCategory> }>(URL);

  return value;
};

export const createFAQCategory = async (payload: CreateFAQCategoryPayload) => {
  const {
    data: { value },
  } = await axios.post<{ value: IFAQCategory }>(
    CREATE_FAQ_CATEGORY_URL,
    payload
  );

  return value;
};

export const viewFAQCategory = async (id: number | string) => {
  const {
    data: { value },
  } = await axios.get<{ value: IFAQCategory }>(
    `${BASE_FAQ_CATEGORY_URL}/${id}/get`
  );

  return value;
};

export const updateFAQCategory = async (
  id: number | string,
  payload: UpdateFAQCategoryPayload
) => {
  const {
    data: { value },
  } = await axios.post<{ value: IFAQCategory }>(
    `${BASE_FAQ_CATEGORY_URL}/${id}/update`,
    payload
  );

  return value;
};

export const deleteFAQCategory = async (id: number | string) => {
  const {
    data: { value },
  } = await axios.post<{ value: IFAQCategory }>(
    `${BASE_FAQ_CATEGORY_URL}/${id}/delete`
  );

  return value;
};

export const getAllFAQCategory = async () => {
  const {
    data: { value },
  } = await axios.get<{ value: Array<IFAQCategory> }>(GET_ALL_FAQ_CATEGORY_URL);

  return value;
};
