import axios from 'axios';
import { API_URL } from '../../../support/api';
import { PaginatedValue } from '../../../support/utils';
import { IModule } from '../models/Module';
import { IRelease } from '../models/Release';

export const BASE_MODULE_URL = `${API_URL}/v1/management/module-management/modules`;
export const GET_ALL_MODULE_URL = `${API_URL}/v1/management/module-management/modules/get-all`;
export const CREATE_MODULE_URL = `${API_URL}/v1/management/module-management/modules/create`;

export const listModule = async (search = '', direction = 'asc') => {
  const URL = `${BASE_MODULE_URL}/list?search=${search}&direction=${direction}`;
  const {
    data: { value },
  } = await axios.get<{ value: PaginatedValue<IModule> }>(URL);

  return value;
};

export const createModule = async (payload: IModule) => {
  const {
    data: { value },
  } = await axios.post<{ value: IModule }>(CREATE_MODULE_URL, payload);

  return value;
};

export const viewModule = async (id: number | string) => {
  const {
    data: { value },
  } = await axios.get<{ value: IModule }>(`${BASE_MODULE_URL}/${id}/get`);

  return value;
};

export const updateModule = async (payload: IModule) => {
  const {
    data: { value },
  } = await axios.post<{ value: IModule }>(
    `${BASE_MODULE_URL}/${payload.id}/update`,
    payload
  );

  return value;
};

export const deleteModule = async (payload: IModule) => {
  const {
    data: { value },
  } = await axios.post<{ value: IModule }>(
    `${BASE_MODULE_URL}/${payload.id}/delete`,
    payload
  );

  return value;
};

// unpaginated categories
export const getAllModule = async () => {
  const {
    data: { value },
  } = await axios.get<{ value: Array<IModule> }>(GET_ALL_MODULE_URL);

  return value;
};

export const getModuleReleases = async (id: number | string) => {
  const {
    data: { value },
  } = await axios.get<{ value: Array<IRelease> }>(
    `${BASE_MODULE_URL}/${id}/releases`
  );

  return value;
};
