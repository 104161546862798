import React from 'react';
import { toAbsoluteUrl } from '../../../../../../_start/helpers';
import {
  defaultSpecializationsStep,
  ISpecializationsStep,
  SpecializationsModalSteps,
} from './SpecializationsModalSteps';
import { SpecializationsModalStepsItem } from './SpecializationsModalStepsItem';

interface ModelStepsListProps {
  data: SpecializationsModalSteps;
  update: (fieldsToUpdate: Partial<SpecializationsModalSteps>) => void;
}

export const SpecializationsModalStepsList: React.FC<ModelStepsListProps> = ({
  data,
  update,
}) => {
  const updateData = (
    fieldsToUpdate: Partial<ISpecializationsStep>,
    index: number
  ) => {
    const items = [...data.item_list];
    items[index] = { ...items[index], ...fieldsToUpdate };
    update({ ...data, item_list: items });
  };

  const deleteItem = (index: number) => {
    const items = [...data.item_list];
    items.splice(index, 1);
    update({ ...data, item_list: items });
  };

  const addItem = () => {
    const items = [...data.item_list];
    items.push(defaultSpecializationsStep);
    update({ ...data, item_list: items });
  };
  return (
    <div className="specializations-modal-steps__list">
      {data.item_list.map((item, index) => (
        <SpecializationsModalStepsItem
          index={index}
          data={item}
          update={updateData}
          deleteItem={deleteItem}
        />
      ))}
      <div className="specializations-modal-steps__item">
        <div className="add-item" onClick={addItem}>
          <img
            alt='Plus Sign'
            src={toAbsoluteUrl('/assets/icons/plus-sign.svg')}
            style={{ width: '50px', height: '50px', justifyContent: 'center' }}
          />
        </div>
      </div>
    </div>
  );
};
