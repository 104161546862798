import { ElementType, IElement } from "../../../models/Element";

export type TitleElement = IElement & {
  title: string;
};

export const defaultTitleElement: TitleElement = {
  id: 0,
  name: ElementType.TITLE,
  title: 'Title',
};
