import { ButtonTooltip } from '../../../components/ButtonTooltip';
import { InlineEdit } from '../../../components/InlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { HomeEmployees } from './HomeEmployees';

type HomeEmployeesContentProps = {
  data: HomeEmployees;
  update: (fieldsToUpdate: Partial<HomeEmployees>) => void;
};

export const HomeEmployeesContent = ({
  data,
  update,
}: HomeEmployeesContentProps) => {
  return (
    <div className="home-employees__content">
      {/* begin::Title */}
      <InlineEdit
        name="title"
        className="content-title"
        value={data.title}
        setValue={update}
        activeVerticalPadding={`py-0`}
        dynamicWidth
      />
      {/* end::Title */}

      {/* begin::Heading */}
      <TextAreaEdit
        name="heading"
        className="content-heading"
        value={data.heading}
        setValue={update}
      />
      {/* end::Heading */}

      {/* begin::Description */}
      <TextAreaEdit
        name="description"
        className="content-description"
        value={data.description}
        setValue={update}
      />
      {/* end::Description */}

      <ButtonTooltip
        name={`button_url`}
        className="content-action__button"
        value={data.button_url}
        setValue={update}
      >
        <button className="btn btn-success rounded-pill">
          <InlineEdit
            name="button_title"
            className="px-1 text-white text-center"
            value={data.button_title}
            setValue={update}
            activeVerticalPadding={'py-1'}
            dynamicWidth
            style={{
              maxWidth: '200px',
            }}
          />
        </button>
      </ButtonTooltip>
    </div>
  );
};
