import { HomeServices, IServiceItem } from './HomeServices';
import { HomeServicesItem } from './HomeServicesItem';

type HomeServicesListProps = {
  data: HomeServices;
  update: (fieldsToUpdate: Partial<HomeServices>) => void;
};

export const HomeServicesList = ({ data, update }: HomeServicesListProps) => {
  const updateService = (idx: number, service: IServiceItem) => {
    let services = [...data.services];
    services[idx] = service;

    const updatedData = { ...data, services };
    update(updatedData);
  };
  return (
    <ul className="home-services__list">
      {data.services.map((service, idx) => (
        <HomeServicesItem
          key={idx}
          service={service}
          serviceIndex={idx}
          updateService={updateService}
        />
      ))}
    </ul>
  );
};
