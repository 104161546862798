import { ICategory } from '../categories/Category';

export interface IPostType<T = ICategory> {
  id: number;
  name: string;
  page_categories: Array<T>;
  description: string;
}

export const defaultPostType: IPostType = {
  id: 0,
  name: '',
  page_categories: [],
  description: '',
};

export const defaultPostTypeCategories: Array<ICategory> = [
  { id: 1, name: 'Web Development', color: '#31C1A7', description: '' },
  { id: 2, name: 'Marketing', color: '#EF5DA8', description: '' },
  { id: 3, name: 'Sales', color: '#9C41E3', description: '' },
];
