import React, { useRef } from 'react';
import { KTSVG, toAbsoluteUrl } from '../../../../../../_start/helpers';
import { IEmployee } from '../../../../employee-management/pages/employees/Employee';

import { HomeEmployees } from './HomeEmployees';

type HomeEmployeeItemProps = {
  homeEmployee: HomeEmployees;
  employee: IEmployee;
  employeeIndex: number;
  removeEmployee: (itemIndex: number) => void;
};

export const HomeEmployeeItem = ({
  employee,
  homeEmployee,
  employeeIndex,
  removeEmployee,
}: HomeEmployeeItemProps) => {
  const removeItemRef = useRef<HTMLSpanElement>(null);

  const handleRemove = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    event.preventDefault();
    removeEmployee(employeeIndex);
  };

  return (
    <div className="item">
      {/* begin::Delete Item */}
      {homeEmployee.employees.length > 2 && (
        <span
          ref={removeItemRef}
          onClick={handleRemove}
          className="position-absolute z-index-2 top-0 end-0 me-2 mt-2 opacity-75-hover"
        >
          <KTSVG
            path="/media/icons/duotone/Interface/Minus-Square.svg"
            className="svg-icon-danger svg-icon-1"
          />
        </span>
      )}
      {/* end::Delete Item */}

      {/* begin::Employee Image */}
      {employee.gif ? (
        <img
          className="item-figure"
          src={employee.gif}
          alt=""
          style={{
            width: '100%',
            height: '400px',
            objectFit: 'cover',
          }}
        />
      ) : (
        <img
          className="item-figure"
          src={toAbsoluteUrl('/assets/images/400x500.png')}
          alt=""
          style={{
            width: '100%',
            height: '400px',
            objectFit: 'cover',
          }}
        />
      )}
      {/* end::Employee Image */}

      <div className="item-content">
        <span className="item-content__name">{employee.name}</span>
        <span className="item-content__department">
          {employee.department?.name ?? 'NA'}
        </span>
      </div>
    </div>
  );
};
