import { ElementType, IElement } from '../../../models/Element';

export type AboutUsContentHeading = IElement & {
  title: string;
  heading: string;
  description: string;
};

export const defaultAboutUsContentHeading: AboutUsContentHeading = {
  id: 0,
  name: ElementType.ABOUT_US_CONTENT_HEADING,
  title: 'Tussendoor als partner',
  heading: 'B2B samenwerken? White label?',
  description:
    'Heb jij als webbureau interesse om krachten te bundelen? Wil je ons werkzaamheden uit laten voeren, bij voorkeur white label? Neem dan gerust contact met ons op. We kunnen assisteren bij kleine werkzaamheden tot grote vraagstukken. Direct contact opnemen met jouw klant, of in de anonimiteit het contact via jou of jullie laten verlopen. Informeer naar onze B2B mogelijkheden.',
};
