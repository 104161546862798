import { ElementWrapper } from '../../../components/ElementWrapper';
import { IElement } from '../../../models/Element';
import { HomePlugins } from './HomePlugins';
import { HomePluginsHeader } from './HomePluginsHeader';
import { HomePluginsList } from './HomePluginsList';

type HomePluginsFCProps = {
  index: number;
  data: HomePlugins;
  updateData: (body: IElement, index: number) => void;
};

export const HomePluginsFC = ({
  index,
  data,
  updateData,
}: HomePluginsFCProps) => {
  const update = (fieldsToUpdate: Partial<HomePlugins>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  return (
    <ElementWrapper elementIndex={index}>
      <div className="home-plugins body-my-8">
        <HomePluginsHeader data={data} update={update} />
        <HomePluginsList data={data} update={update} />
      </div>
    </ElementWrapper>
  );
};
