import {
  defaultCustomer,
  ICustomer,
} from '../../customer-management/models/Customer';
import { IElement } from '../../edit-content/models/Element';

import {
  IPost,
  RobotType,
  SchemaArticleType,
  SchemaPageType,
  SEOTitleType,
} from '../../post-management/models/Post';
import { ICaseDetail } from './CaseDetail';

export enum PageStatusType {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  FUTURE = 'future',
}

export interface IPage {
  id: number;
  title: string;
  slug: string;
  publish_date: string | Date;
  status: PageStatusType;
  description: string;
  content: Array<IElement>;

  // SEO stuff
  seo_title: Array<SEOTitleType>;
  robots: Array<RobotType>;

  schema_page_type: SchemaPageType;
  schema_article_type: SchemaArticleType;

  facebook_image: string;
  facebook_title: string;
  facebook_description: string;

  twitter_image: string;
  twitter_title: string;
  twitter_description: string;

  is_parent: boolean;
  parent_id?: number | null;
  parent?: IPage | null;

  customer_id: number | null;
  customer: ICustomer;

  page_featured_posts: Array<IPost>;
  page_featured_case_details: Array<ICaseDetail>;
}

export const defaultPage: IPage = {
  id: 0,
  title: '',
  slug: '',
  publish_date: new Date(),
  status: PageStatusType.DRAFT,
  description: '',
  content: [],

  seo_title: [],
  robots: [],

  schema_page_type: SchemaPageType.WEB_PAGE,
  schema_article_type: SchemaArticleType.ARTICLE,

  facebook_image: '',
  facebook_title: '',
  facebook_description: '',

  twitter_image: '',
  twitter_title: '',
  twitter_description: '',

  is_parent: true,

  customer_id: null,
  customer: defaultCustomer,

  page_featured_posts: [],
  page_featured_case_details: [],
};
