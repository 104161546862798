import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { failureToast, successToast } from '../../../../support/utils';
import { defaultPackage, IPackage } from '../../models/Package';
import { updatePackage, viewPackage } from '../../redux/PackageCRUD';

import Select, { MultiValue } from 'react-select';
import { getAllFeature } from '../../redux/FeaturedCRUD';
import { IFeature } from '../../models/Feature';

export const UpdatePackage = () => {
  const { id } = useParams<{ id: string }>();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<IPackage>(defaultPackage);

  const updateData = (fieldsToUpdate: Partial<IPackage>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  const [features, setFeatures] = useState<Array<IFeature>>([]);
  const [selectedFeatures, setSelectedFeatures] = useState<
    MultiValue<IFeature>
  >([]);

  const handleFetch = async (id: string) => {
    setLoading(true);

    try {
      const [result, features] = await Promise.all([
        viewPackage(id),
        getAllFeature(),
      ]);
      setData(result);
      setFeatures(features);
      setSelectedFeatures(result.features);
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      handleFetch(id);
    }
  }, [id]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);

    try {
      await updatePackage({
        ...data,
        // @ts-ignore
        features: selectedFeatures.map((element) => element.id),
      });
      successToast('Package has been updated.');
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Update Package</h3>
      </div>
      {/* begin::Form */}
      <form onSubmit={handleSubmit} className="form d-flex flex-center">
        <div className="card-body mw-800px py-20">
          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Name</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter name"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.name}
                  onChange={(e) =>
                    updateData({
                      name: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Price</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter price"
                  className="form-control form-control-lg form-control-solid"
                  type="number"
                  value={data.price}
                  onChange={(e) =>
                    updateData({
                      price: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Features</label>
            <div className="col-lg-9">
              <Select
                closeMenuOnSelect={false}
                placeholder={`Select features...`}
                value={selectedFeatures}
                onChange={setSelectedFeatures}
                getOptionValue={(model) => model.id.toString()}
                getOptionLabel={(model) => model.name}
                options={features}
                isMulti
                isSearchable
                isClearable
              />
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row">
            <label className="col-lg-3 col-form-label"></label>
            <div className="col-lg-9">
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary fw-bolder px-6 py-3 me-3"
              >
                {!loading && <span className="indicator-label">Save</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Please wait...{' '}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
              <Link
                className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3"
                to={`/module-management/packages`}
              >
                Cancel
              </Link>
            </div>
          </div>
          {/* end::Form row */}
        </div>
      </form>
      {/* end::Form */}
    </div>
  );
};
